import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";

import MetaTags from "../../../components/MetaTags";
import useRightNav from "../../../layouts/RightSideNav/useRightNav";
import { listInvoices } from "../../../redux/actions/InvoiceActions";
import { cancelLastRequest } from "../../../utils/api";
import { addPropsToChildren } from "../../../utils/children";
import { getPaymentsStatusFilters, getPaymentsTypeQueries } from "../../../utils/invoiceUtils";
import { useInvoices } from "../../../context/InvoiceContext";
import usePrevious from "../../../hooks/usePrevious";
import useDidUpdate from "../../../hooks/useDidUpdate";

// Function to fetch data and handle pagination
const fetchInvoiceList = ({
  dispatch,
  filter,
  types,
  invoiceDate,
  scope,
  project,
  currentPage,
  pageSize,
  ordering,
  invoice_email_status,
  payment_method = [],
}) => {
  const pm = payment_method.includes("all") ? "" : payment_method?.join(",");
  const newFilters = {
    ...getPaymentsStatusFilters(filter),
    ...getPaymentsTypeQueries(types, scope),
    ...(project !== null ? { project: project?.id } : {}),
    invoice_email_status: invoice_email_status.includes("all") ? "" : invoice_email_status,
    page: currentPage + 1,
    page_size: pageSize,
    min_date: invoiceDate?.from || "",
    max_date: invoiceDate?.to || "",
    payment_method: pm,
    ordering,
  };
  cancelLastRequest("listInvoices");
  listInvoices(newFilters)(dispatch);
};

// Function to generate the meta title
const generateMetaTitle = (type, filter, project) => {
  const inOrOut = type === "in" ? "Incoming" : "Outgoing";
  const projectPrefix = project?.id ? "Project - " : "";
  const filterName = {
    all: "All",
    paid: "Paid",
    "credit-notes": "Credit Note",
    "commitment-payments": "Commitment",
    archived: "Archived",
    overdue: "Overdue",
    pending: "Pending",
  };
  return `${projectPrefix}${filterName[filter]} ${inOrOut} Payments`;
};

const InvoiceListContainer = ({
  children,
  type, // invoice type
  project, // selected project
}) => {
  const {
    paymentState: {
      invoiceStatus,
      invoiceEmailStatus,
      invoiceType,
      date: invoiceDate,
      ordering: paymentOrdering,
    },
    payoutState: {
      invoiceStatus: payoutInvoiceStatus,
      date: payoutInvoiceDate,
      paymentPlatform,
      ordering: payoutOrdering,
    },
  } = useInvoices();
  const [selectedStatus] = invoiceStatus;
  const [selectedInvoiceEmailStatus] = invoiceEmailStatus;
  const [selectedPayoutStatus] = payoutInvoiceStatus;

  const dispatch = useDispatch();
  const pageSizeRef = useRef(20);
  const { status, close } = useRightNav();

  const { success, loading } = useSelector(({ Auth }) => Auth.rolePermissions);
  const permissionsReady = !loading && success;

  const { list, isSaved } = useSelector(({ Invoice }) => Invoice);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeRef.current);

  const isPayments = type === "in" || type === "payments";

  const activeSelectedStatus = isPayments ? selectedStatus : selectedPayoutStatus;
  const activeInvoiceType = isPayments ? invoiceType : "purchase";
  const activeInvoiceDate = isPayments ? invoiceDate : payoutInvoiceDate;
  const activePaymentMethod = isPayments ? [] : paymentPlatform;
  const activeSelectedInvoiceEmailStatus = isPayments ? selectedInvoiceEmailStatus : [];
  const activeOrdering = isPayments ? paymentOrdering : payoutOrdering;

  const prevSelectedStatus = usePrevious(activeSelectedStatus);
  const prevSelectedInvoiceEmailStatus = usePrevious(activeSelectedInvoiceEmailStatus);
  const prevPaymentType = usePrevious(type);
  const prevInvoiceType = usePrevious(activeInvoiceType);
  const prevInvoiceDate = usePrevious(activeInvoiceDate);
  const prevPaymentMethod = usePrevious(activePaymentMethod);
  const prevOrdering = usePrevious(activeOrdering);

  // Fetch data and handle pagination
  const getList = useCallback(() => {
    fetchInvoiceList({
      dispatch,
      filter: activeSelectedStatus,
      types: activeInvoiceType,
      invoiceDate: activeInvoiceDate,
      scope: type,
      project,
      currentPage,
      pageSize,
      ordering: activeOrdering,
      payment_method: activePaymentMethod,
      invoice_email_status: selectedInvoiceEmailStatus,
    });
    // Track event here
  }, [
    dispatch,
    activeSelectedStatus,
    activeInvoiceType,
    activeInvoiceDate,
    activePaymentMethod,
    selectedInvoiceEmailStatus,
    type,
    activeOrdering,
    project,
    currentPage,
    pageSize,
  ]);

  // Refresh the list
  const refreshList = useCallback(() => {
    setCurrentPage(0);
    setPageSize(pageSizeRef.current);
    getList();
  }, [getList]);

  useEffect(() => {
    if (permissionsReady) {
      getList();
    }
  }, [currentPage, pageSize, permissionsReady]);

  useEffect(() => {
    if (isSaved?.invoice) {
      getList();

      if (!status.collapsed) close();
    }
  }, [isSaved]);

  useDidUpdate(() => {
    if (
      (!!prevSelectedStatus && !isEqual(prevSelectedStatus, activeSelectedStatus)) ||
      (!!prevInvoiceType && !isEqual(prevInvoiceType, activeInvoiceType)) ||
      (!!prevInvoiceDate && !isEqual(prevInvoiceDate, activeInvoiceDate)) ||
      (!!prevPaymentType && !isEqual(prevPaymentType, type)) ||
      (!!prevOrdering && !isEqual(prevOrdering, activeOrdering)) ||
      (!!prevPaymentMethod && !isEqual(prevPaymentMethod, activePaymentMethod)) ||
      (!!prevSelectedInvoiceEmailStatus &&
        !isEqual(prevSelectedInvoiceEmailStatus, activeSelectedInvoiceEmailStatus))
    ) {
      refreshList();
    }
  }, [
    activeSelectedStatus,
    selectedInvoiceEmailStatus,
    type,
    activeInvoiceDate,
    activeOrdering,
    JSON.stringify(activeInvoiceType),
    JSON.stringify(activePaymentMethod),
  ]);

  // Render children with updated props
  const renderChildren = useMemo(() => {
    return addPropsToChildren(children, {
      ...list,
      getList,
      project,
      onLoadMore: (page, page_size) => {
        pageSizeRef.current = page_size;
        setPageSize(page_size);
        setCurrentPage(page);
      },
      currentPage,
      pageSize,
      refreshList,
    });
  }, [children, list, getList, project, currentPage, pageSize, refreshList]);

  return (
    <>
      <MetaTags
        title={generateMetaTitle(type, activeSelectedStatus, project)}
        description="Track, manage, and view all your payments."
      />
      {renderChildren}
    </>
  );
};

InvoiceListContainer.propTypes = {
  type: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      filter: PropTypes.string,
    }),
  }),
  project: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
};

export default InvoiceListContainer;
