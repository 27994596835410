import React, { useState } from "react";
import { Collapse } from "reactstrap";
import PropTypes from "prop-types";

import CheckboxLabel from "./CheckboxLabel";
import FilterHead from "./FilterHead";

const InnerDropdown = ({ label, columns, onChange }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <FilterHead as="button" label={label} isOpen={isOpen} onClick={toggle} />

      <Collapse className="options" isOpen={isOpen}>
        {columns.map(({ name, editable, visible }) => (
          <CheckboxLabel
            key={name}
            label={name}
            disabled={!editable}
            checked={visible}
            onChange={() => onChange(name, !visible)}
          />
        ))}
      </Collapse>
    </>
  );
};

InnerDropdown.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
};

export default InnerDropdown;
