/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React from "react";
import { Route, Switch } from "react-router-dom";

/* -------------------------------------------------------------------------- */
/*                             Internal Dependency                            */
/* -------------------------------------------------------------------------- */
import TalentProfile from "./TalentProfile";
import TalentPoolOverview from "./TalentPoolOverview";
import Pool from "./Pool";

const Routes = () => {
  return (
    <TalentPoolOverview>
      <Switch>
        <Route path="/talent-pool/talent/:talentId" render={() => <TalentProfile />} />
        <Route path="/talent-pool" render={() => <Pool />} />
      </Switch>
    </TalentPoolOverview>
  );
};

export default Routes;
