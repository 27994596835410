import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import Media from "react-media";

import OpportunityDetails from "./OpportunityDetails";
import Shortlist from "./Shortlist";
import Settings from "./settings";
import TalentPool from "./TalentPool";
import { isDev } from "../../../../utils/auth";

const OpportunityManagement = ({ match, project }) => {
  const sections = isDev()
    ? []
    : [
        ["network/shortlist", <Shortlist project={project} />],
        ["network/talent-pool", <TalentPool project={project} />],
        ["settings", <Settings key="settings" />],
      ];

  return project ? (
    <Media query="(min-width: 992px)">
      {() => (
        <Switch>
          {sections.map((path) => {
            return (
              <Route
                key={`opportunity-management-path--${path[0]}`}
                path={`${match.url}/${path[0]}`}
                render={() => path[1]}
              />
            );
          })}
          <Route
            path={`${match.url}/details`}
            render={() => <OpportunityDetails project={project} />}
          />
          <Redirect exact from={`${match.url}`} to={`${match.url}/details`} />
          <Redirect from="*" to={`${match.url}/details`} />
        </Switch>
      )}
    </Media>
  ) : null;
};

OpportunityManagement.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string }),
  project: PropTypes.shape({}),
};

export default OpportunityManagement;
