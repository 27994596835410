/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PaymentContainer from "./PaymentContainer";

import { Default } from "./styles";
import IbanLogo from "../../assets/images/iban_logo.png";
import ActionsHandler from "./ActionsHandler";
import useConnectDollarAccount from "../../hooks/useConnectDollarAccount";
import { PAYMENT_PROVIDER_DOLLAR, REGION_SEPA } from "../../configs/constants/invoiceConstants";

const DollarAccount = () => {
  const [isDefault, setIsDefault] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [connected, setConnected] = useState(false);
  const [dollarAccountDetails, setDollarAccountDetails] = useState({});

  const { connectDollarAccount, triggerDefaultPrompt, setShouldTriggerPrompt } =
    useConnectDollarAccount();

  const {
    dollarAccount,
    paymentProvider: { payment_provider_accounts, default_account_provider },
  } = useSelector(({ Payments }) => Payments);

  const payo_details = payment_provider_accounts;
  const filteredResult = payo_details?.filter(
    (w) => w.payment_provider === PAYMENT_PROVIDER_DOLLAR,
  );

  const payo_id = filteredResult && filteredResult[0]?.id;
  const provider_name = filteredResult && filteredResult[0]?.payment_provider;

  const maskedDetails =
    dollarAccountDetails?.region === REGION_SEPA
      ? `IBAN: ••••••••••${dollarAccountDetails.iban?.slice(-3)}`
      : `Account Number: ••••••••••${dollarAccountDetails?.account_number?.slice(-3)}`;

  useEffect(() => {
    if (dollarAccount && dollarAccount?.id) {
      setDollarAccountDetails(dollarAccount);
      setConnected(true);
    }
  }, [dollarAccount]);

  useEffect(() => {
    if (
      default_account_provider?.is_default &&
      default_account_provider?.payment_provider === PAYMENT_PROVIDER_DOLLAR
    ) {
      setIsDefault(default_account_provider?.is_default);
    } else {
      setIsDefault(false);
    }

    setProviderName(default_account_provider?.payment_provider);
  }, [default_account_provider, providerName, isDefault]);

  return (
    <PaymentContainer>
      <div className="d-flex w-100 h-100">
        <div className="w-20">
          <div>
            <img height={32} width={32} src={IbanLogo} className="mx-auto" alt="Dollar Account" />
          </div>
        </div>

        <div className="w-80">
          <div className="media pdb-40">
            <div className="media-body">
              <h4>
                SWIFT/BIC - Dollar Account
                {isDefault && providerName === PAYMENT_PROVIDER_DOLLAR && (
                  <Default>DEFAULT</Default>
                )}
              </h4>

              {connected ? (
                <p className="m-0" data-testid="connected_text">
                  You&#39;ve successfully connected your Dollar account
                  <span className="masked-details">{maskedDetails}</span>
                </p>
              ) : (
                <p className="m-0" data-testid="unconnected_text">
                  Configure your payment details to receive USD payouts. If you don&apos;t have a
                  USD account, you can set up an account with a compatible service
                </p>
              )}
            </div>
          </div>

          <ActionsHandler
            connected={connected}
            connectHandler={connectDollarAccount}
            paymentProviderID={payo_id}
            providerName={provider_name}
            isDefault={isDefault}
            triggerDefaultPrompt={triggerDefaultPrompt}
            setShouldTriggerPrompt={setShouldTriggerPrompt}
          />
        </div>
      </div>
    </PaymentContainer>
  );
};

export default DollarAccount;
