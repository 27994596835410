/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

// /* -------------------- Internel Dependencies (Utilites) -------------------- */
// /* ------------------------- Component dependencies ------------------------- */
import moment from "moment";
import Progress from "../../../../../components/Progress";
import { ContentSection } from "../../../../../utils/styles";
import * as inviteActions from "../../../../../redux/actions/InvitesActions";
import InviteContainer from "../InviteContainer";
import useDidUpdate from "../../../../../hooks/useDidUpdate";
import ReactTable from "../../../../../components/ReactTable";
import { trackEvent } from "../../../../../analytics/segment";
import { CATEGORIES, EVENTS } from "../../../../../analytics/events";

const PendingInvite = () => {
  const { invites } = useSelector((store) => store);
  const { user } = useSelector(({ Auth }) => Auth);
  const [page_size, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const { count, results, isFetching } = invites;

  const dispatch = useDispatch();

  const fetchUsers = (page, search = "") => {
    const updatedFilters = {
      search,
      page_size,
      page,
    };
    inviteActions.getPendingInvites(updatedFilters)(dispatch);
  };

  const onChangeSearch = (page, search = "") => {
    fetchUsers(page, search);
    setCurrentPage(0);
  };

  useEffect(() => {
    trackEvent(EVENTS.view_pending_invites, {
      user_id: user.id,
      username: user.display_name,
      event_category: CATEGORIES.settings,
    });
    fetchUsers(1);
    setCurrentPage(0);
  }, []);

  const resendInvite = (data) => {
    inviteActions.invite({
      id: data.id,
      email: data.email,
      resend: true,
    })(dispatch);
  };

  const fetchMoreData = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useDidUpdate(() => {
    fetchUsers(currentPage + 1);
  }, [page_size, currentPage]);

  const deleteInvite = (id) => {
    inviteActions.deleteInvite(id, "")(dispatch);
  };

  const data = useMemo(
    () => [
      ...(results || []).map((i) => {
        const row = {
          created_at: i.created_at,
          name: i.display_name,
          email: i.email,
          role: i.display_type,
          actions: { id: i.id, ...i },
        };
        return row;
      }),
    ],
    [results],
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sent On",
        accessor: "created_at",
      },
      {
        Header: "User",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "",
        accessor: "actions",
      },
    ],
    [],
  );

  const getTableDisplayValue = (cell) => {
    const key = `${cell.column.id}-${cell.row.id}`;
    switch (cell.column.id) {
      case "created_at":
        return <td key={`created_at${key}`}>{moment(cell.value).format("ll")}</td>;
      case "name":
        return <td key={`name${key}`}>{cell.value}</td>;
      case "email":
        return <td key={`email${key}`}>{cell.value}</td>;
      case "role":
        return <td key={`type${key}`}>{cell.value}</td>;
      case "actions":
        return (
          <td key={`actions${key}`} className="d-flex align-items-center">
            <button
              type="button"
              className="btn btn-resend"
              aria-label="resend"
              onClick={() => resendInvite(cell.value)}
            >
              Resend
            </button>
            <svg
              width="14"
              height="18"
              viewBox="0 0 14 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-label="delete"
              onClick={() => deleteInvite(cell.value.id)}
            >
              <path
                d="M1 16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H11C11.5304 18 12.0391 17.7893 12.4142 17.4142C12.7893 17.0391 13 16.5304 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
                fill="#8F9BB3"
              />
            </svg>
          </td>
        );
      default:
        return null;
    }
  };

  return (
    <InviteContainer fetchUsers={onChangeSearch}>
      <Wrapper>
        <ReactTable
          tableData={data}
          tableColumns={columns}
          currentPage={currentPage}
          count={count}
          getTableDisplayValue={getTableDisplayValue}
          loadPage={fetchMoreData}
          pageSize={page_size}
          loader={<Progress />}
          isLoading={isFetching}
          noDataMessage="No pending invites"
        />
      </Wrapper>
    </InviteContainer>
  );
};

const Wrapper = styled(ContentSection)`
  padding-bottom: 5px;
  .pending__header h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.05em;
    color: #8f9bb3;
    text-transform: uppercase;
  }
  td:nth-child(3) {
    text-transform: lowercase;
  }
  td:nth-child(4) {
    min-width: 160px;
  }
  .pending__header {
    text-align: center;
    display: flex;
    margin-bottom: 0.5rem;
    width: 100%;
    justify-content: center;
    position: relative;
    button {
      position: absolute;
      left: 25px;
      font-style: normal;
      background: transparent;
      font-weight: 500;
      font-size: 14px;
      border: none;
      line-height: 150%;
      /* identical to box height, or 21px */

      color: #062e64;
    }
  }

  table {
    border: none;
    border-radius: 1px;
    tbody tr td {
      font-size: 14px;
      svg {
        cursor: pointer;
      }
    }
    thead tr th {
      font-size: 13px;
    }
    .btn-resend {
      background: rgba(6, 46, 100, 0.05);
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #062e64;
      margin-right: 12px;
      height: 2.5rem;
    }
    .w-20 {
      width: 20%;
    }
  }
`;

export default PendingInvite;
