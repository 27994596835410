import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

const NavContext = React.createContext([{}, () => {}]);

const NavProvider = ({ children }) => {
  const [state, setState] = useState({
    collapsed: true,
    content: null,
    style: {},
    overlayVisible: false,
    closeOnClickOutside: false,
  });

  const value = useMemo(() => [state, setState], [state, setState]);

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};

const proptypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

NavProvider.propTypes = proptypes;

export { NavContext, NavProvider };
