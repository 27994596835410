import React from "react";
import { FormGroup } from "reactstrap";
import { string } from "yup";

import FieldError from "../components/FieldError";
import SmileysModal from "../components/SmileysModal";
import TextArea from "../components/TextArea";

export function filterValidProps(allowedPropKeys, props) {
  const filteredProps = {};
  (allowedPropKeys || []).forEach((item) => {
    if (props[item] || props[item] === "" || typeof props[item] === "boolean") {
      filteredProps[item] = props[item] || "";
    }
  });
  return filteredProps;
}

export function filterInputProps(props) {
  return filterValidProps(
    [
      "id",
      "name",
      "value",
      "defaultValue",
      "required",
      "disabled",
      "autoComplete",
      "step",
      "pattern",
      "aria-labelledby",
      "data-testid",
    ],
    props,
  );
}

export function filterButtonProps(props) {
  return filterValidProps(["disabled"], props);
}

export const getFormData = (formdata) => {
  const data = {};
  // eslint-disable-next-line no-return-assign
  formdata.forEach((val, key) => (data[key] = val));
  return data;
};

export const getSurveyQuestions = (questions) => {
  const initialValues = questions.reduce((finalObj, { id, answer }) => {
    return { ...finalObj, [id]: answer || "" };
  }, {});

  const validationSchemaObj = questions.reduce((finalObj, { id, required }) => {
    return {
      ...finalObj,
      [id]: required ? string().required("Field is required.") : string().notRequired(),
    };
  }, {});

  return { initialValues, validationSchemaObj };
};

export const renderSurveyQuestions = (questions, formikForm) => {
  const questionElements = questions.map(({ text, id, question_type, required }, i) => {
    return (
      <div key={id} className="card__section">
        <h4>
          {text}
          {required && <span className="label-style">*</span>}
        </h4>

        {question_type === "rating" ? (
          <>
            <SmileysModal
              name={String(id)}
              index={i}
              selected={formikForm.values[id]}
              onChange={(value) => formikForm.setFieldValue(id, value)}
              onBlur={() => formikForm.setFieldTouched(id, true)}
            />

            {formikForm.errors[id] && formikForm.touched[id] && (
              <FieldError message={formikForm.errors[id]} />
            )}
          </>
        ) : (
          <FormGroup>
            <TextArea
              id={id}
              rows={4}
              onChange={(e) => formikForm.setFieldValue(id, e.target.value)}
              value={formikForm.values[id]}
              placeholder="Enter answer here"
              onBlur={() => formikForm.setFieldTouched(id, true)}
            />
            {formikForm.errors[id] && formikForm.touched[id] && (
              <FieldError message={formikForm.errors[id]} />
            )}
          </FormGroup>
        )}
      </div>
    );
  });

  return questionElements;
};
