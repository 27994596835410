import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormGroup } from "reactstrap";
import styled from "styled-components";

import TextArea from "../TextArea";
import { StyledForm } from "../../utils/styles";

const AskTungaForm = ({ id, proceed }) => {
  const [question, setQuestion] = useState("");
  const onChangeValue = (key, value) => {
    setQuestion(value);
  };

  const onSave = (e) => {
    e.preventDefault();
    proceed(question);
  };

  return (
    <StyledForm id={id} onSubmit={(e) => onSave(e)}>
      <p>Enter your question(s) below and we’ll get in touch</p>

      <FormGroup>
        <label htmlFor="questions">
          Questions
          <LabelStyle>
            <span className="label-style">*</span>
          </LabelStyle>
        </label>
        <TextArea
          id="questions"
          placeholder="Enter your questions here"
          onChange={(e) => onChangeValue("reason", e.target.value)}
          required
        />
      </FormGroup>
    </StyledForm>
  );
};

const LabelStyle = styled.span`
  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
`;

AskTungaForm.propTypes = {
  id: PropTypes.string,
  proceed: PropTypes.func,
};

export default AskTungaForm;
