import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOCATION_CHANGE } from "react-router-redux";
import Switch from "react-switch";
import styled from "styled-components";
import { CATEGORIES, EVENTS } from "../../../../analytics/events";
import { trackEvent } from "../../../../analytics/segment";

import FieldError from "../../../../components/FieldError";
import Progress from "../../../../components/Progress";
import { shareProfile } from "../../../../redux/actions/ProfileActions";

const ShareProfileModal = () => {
  const dispatch = useDispatch();

  const { isRetrieving, errors } = useSelector(({ Profile }) => Profile);
  const { id: userId, display_name, share_settings } = useSelector(({ Auth }) => Auth.user);

  const [linkEnabled, setLinkEnabled] = useState(share_settings?.is_link_enabled || false);
  const [linkCopied, setLinkCopied] = useState(false);

  const updateShareSettings = (isLinkEnabled) => {
    dispatch({ type: LOCATION_CHANGE });
    dispatch(shareProfile(share_settings.id, isLinkEnabled, { method: "patch", silent: true }));
  };

  const handleSwitch = () => {
    const newLinkStatus = !linkEnabled;
    setLinkEnabled(newLinkStatus);
    updateShareSettings(newLinkStatus);
  };

  const copyToClipboard = () => {
    let timeout;
    clearTimeout(timeout);

    setLinkCopied(true);
    navigator.clipboard.writeText(share_settings?.shared_profile_url);

    timeout = setTimeout(() => setLinkCopied(false), 600);

    trackEvent(EVENTS.copy_profile_link, {
      user_id: userId,
      username: display_name,
      event_category: CATEGORIES.settings,
    });
  };

  useEffect(() => {
    if (errors?.share_settings) {
      setLinkEnabled(!linkEnabled);
    }
  }, [errors?.share_settings]);

  return (
    <Modal>
      {isRetrieving?.share_settings ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Progress />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <p className="m-0 label">Your unique link</p>

            <div className="d-flex align-items-center">
              <p className="m-0 me-2 switch-label">Enable link</p>

              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                height={13.5}
                width={22.5}
                onChange={handleSwitch}
                checked={linkEnabled}
                onColor="#062E64"
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center link-wrapper mt-2">
            <p className="m-0">{share_settings?.shared_profile_url || ""}</p>

            <button className="btn btn-copy px-2" type="button" onClick={copyToClipboard}>
              {linkCopied ? "Copied" : "Copy"}
            </button>
          </div>

          {(errors?.share_settings || !linkEnabled) && (
            <FieldError
              className="mt-1"
              message={
                errors?.share_settings ||
                "Link disabled, anyone with this link will not be able to view your profile."
              }
            />
          )}
        </>
      )}
    </Modal>
  );
};

const Modal = styled.div`
  .label {
    font-weight: 500;
    color: #22262a;
  }

  .switch-label {
    font-weight: 400;
    color: #3e4857;
  }

  .link-wrapper {
    background: #ededee;
    border-radius: 4px;
    height: 36px;
    padding: 8px 12px;
  }

  .btn-copy {
    background: #ffffff;
    border-radius: 100px;
    height: 28px;
    color: #22262a;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
`;

ShareProfileModal.propTypes = {};

export default ShareProfileModal;
