/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React from "react";
import PropTypes from "prop-types";

/* -------------------------------------------------------------------------- */
/*                             Internal Dependency                            */
/* -------------------------------------------------------------------------- */
import DashboardLayout from "../../../layouts/DashboardLayout";
import { TalentProfileProvider } from "../../../context/TalentProfileContext";
import MetaTags from "../../../components/MetaTags";
import { FilterProvider } from "../../../context/FilterContext";

const TalentPoolOverview = ({ children }) => {
  return (
    <TalentProfileProvider>
      <FilterProvider>
        <MetaTags
          title="Talent Pool"
          description="Update your personal, account, payment, and privacy settings."
        />
        <DashboardLayout>{children}</DashboardLayout>
      </FilterProvider>
    </TalentProfileProvider>
  );
};

TalentPoolOverview.propTypes = {
  children: PropTypes.node.isRequired,
};
export default TalentPoolOverview;
