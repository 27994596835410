import React from "react";
import { useFormik } from "formik";
import { FormGroup } from "reactstrap";
import styled from "styled-components";
import PropTypes from "prop-types";
import { bool, object } from "yup";

import CheckboxLabel from "../../../../components/InvoiceFilters/CheckboxLabel";
import FieldError from "../../../../components/FieldError";
import { StyledForm } from "../../../../utils/styles";

const ExportGigCV = ({ id, proceed }) => {
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      accepted: false,
    },
    validationSchema: object().shape({
      accepted: bool().isTrue("Please check the box to agree to the privacy terms.").required(),
    }),
    onSubmit: (formData) => {
      proceed({
        ...formData,
      });
    },
  });

  return (
    <StyledForm id={id} data-testid="gig-cv-export" onSubmit={form.handleSubmit}>
      <StyledP>
        You can now seamlessly export your profile data with the help of GigCV, a third-party tool
        we&apos;ve partnered with. Designed to meticulously capture and present your professional
        journey, GigCV enables you to share a detailed account of your work experiences with
        potential employers. This includes assignments completed through our platform as well as a
        comprehensive summary of your achievements. Expect to find in your GigCV details such as
        your name, email address, key work achievements, and the total number of tasks you&apos;ve
        successfully undertaken, among other crucial information.
        <br /> <br />
        For deeper insights into how GigCV facilitates this process and to understand our commitment
        to your privacy, we encourage you to review our privacy statement.
      </StyledP>

      <FormGroup>
        <StyledCheckboxLabel
          key="accept"
          testId="checkbox"
          label={
            <p className="inline-flex m-0">
              By ticking this checkbox, you agree to our{" "}
              <a
                className="fw-bolder"
                target="_blank"
                href="https://tunga.io/privacy-policy/"
                rel="noreferrer"
              >
                privacy term
              </a>
              , enabling the secure export of your profile data via GigCV.
            </p>
          }
          value="all"
          checked={form.values.accepted}
          onChange={() => form.setFieldValue("accepted", !form.values.accepted)}
          onBlur={() => form.setFieldTouched("accepted", true)}
        />
        {form.errors.accepted && form.touched.accepted && (
          <FieldError message={form.errors.accepted} />
        )}
      </FormGroup>
    </StyledForm>
  );
};

const StyledP = styled.p`
  margin-bottom: 40px;
`;

const StyledCheckboxLabel = styled(CheckboxLabel)`
  label {
    text-transform: unset !important;
    align-items: unset !important;
  }
`;

ExportGigCV.propTypes = {
  proceed: PropTypes.func,
  id: PropTypes.string,
};

export default ExportGigCV;
