/* eslint-disable react/prop-types */
import React, { useState } from "react";
import styled from "styled-components";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import Icon from "../../../../components/Icon";
import { filterPaymentSummaries } from "../../../../utils/invoiceUtils";

const PaymentFilterAndAddComponent = ({
  paymentScope,
  createAction,
  summariesRange,
  setSummariesRange,
}) => {
  const [openBtn, setOpenBtn] = useState(false);

  return (
    <WrapperNavActions>
      <button
        type="button"
        className="text-capitalize btn filter-btn ml-0"
        data-testid="filterSummaries"
        onClick={() => filterPaymentSummaries(summariesRange, paymentScope, setSummariesRange)}
      >
        <Icon name="filter-variant" size="xs" /> Filter Total{" "}
        {paymentScope === "in" ? "Payments" : "Payouts"}
      </button>
      <ButtonDropdown
        isOpen={openBtn}
        toggle={() => setOpenBtn(!openBtn)}
        data-testid="filterWrapper"
      >
        <DropdownToggle color="primary" className="create-btn dropdown-toggle">
          <Icon name="round-add" className="bsd-icon" />
          Create
          <Icon style={{ marginTop: "10px" }} name="arrow-down" size="sm" className="bsd-icon" />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu">
          {(createAction && createAction.add ? createAction.add : []).map((item, idx) => {
            return (
              <DropdownItem
                key={idx}
                className="dropdown-item add-btn"
                onClick={item.action}
                data-testid={item.title}
              >
                {item.title}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </ButtonDropdown>
    </WrapperNavActions>
  );
};

const WrapperNavActions = styled.div`
  display: flex;
  justify-content: flex-end;

  .filter-btn {
    background: #ffffff;
    padding: 1em;
    border-radius: 4px;
    border: 1px solid #e3e9f2;
    color: #8f9bb3;
    box-shadow: none !important;
  }

  .filter-btn,
  .create-btn {
    margin: 0 !important ;
    font-size: 0.875rem;
  }

  .btn-group {
    margin-left: 10px !important ;
  }

  .create-btn {
    background-color: #da3451;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    padding: 0 2em;
    min-width: 145px;

    i {
      vertical-align: baseline;
      margin-right: 5px;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  /*override reactstrap inline styles*/
  .dropdown-menu {
    transform: translate3d(0px, 54px, 0px) !important;
    left: initial !important;
    right: 0px;
    border: 1px solid #edf1f7 !important;
    box-shadow: 0px 4px 8px rgba(62, 72, 87, 0.04) !important;
    border-radius: 6px !important;
    padding: 10px !important;
    line-height: 2rem;
    margin-top: -10px;

    .dropdown-item,
    .dropdown-item:hover {
      padding: 0;
      margin: 0;
      font-size: 0.9rem;
      background-color: #fff !important;

      &:active {
        color: initial;
        background-color: initial;
      }
    }
  }
`;

export default PaymentFilterAndAddComponent;
