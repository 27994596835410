import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
// import Button from "../../../components/Button";
import CookieSettingForm from "./modals/CookieSettingForm";
import { createModal } from "../../../utils/modals";
import * as actions from "../../../redux/actions/SettingsActions";
import Progress from "../../../components/Progress";
import { trackEvent } from "../../../analytics/segment";
import { CATEGORIES, EVENTS } from "../../../analytics/events";

const event = {
  newsletter_email: EVENTS.update_newsletter_email_switch,
  event_email: EVENTS.update_event_email_switch,
  task_survey_reminder_email: EVENTS.update_task_survey_reminder_email_switch,
  new_task_progress_report_email:
    EVENTS.update_new_developer_progress_reports_reminder_email_switch,
  cookie_performance_functionality: EVENTS.update_cookie_performance_functionality_switch,
  cookie_analytics_customization: EVENTS.update_cookie_analytics_customization_switch,
  cookie_targeting_advertising: EVENTS.update_cookie_targeting_advertising_switch,
  "Privacy Policy": EVENTS.view_privacy_policy,
  "User Agreement": EVENTS.view_user_agreement,
  "Code of Conduct": EVENTS.view_code_of_conduct,
};

const Privacy = () => {
  const dispatch = useDispatch();
  const {
    profile: { settings },
    isRetrieving,
  } = useSelector(({ Profile }) => Profile);
  const { user } = useSelector(({ Auth }) => Auth);

  useEffect(() => {
    actions.getSettings()(dispatch);
  }, []);

  const onChange = (name, value) => {
    const setting = {};
    setting[name] = value ? "on" : "off";

    if (settings && settings.switches[name] !== setting[name]) {
      actions.updateSettings({
        switches: { ...setting },
      })(dispatch);

      trackEvent(event[name], {
        user_id: user.id,
        username: user.display_name,
        event_category: CATEGORIES.settings,
        switch_status: value ? "on" : "off",
      });
    }
  };

  const onCookieSettings = async (cookieSettings) => {
    const modal = createModal({
      body: (
        <CookieSettingForm
          id="cookie-settings-form"
          onChange={onChange}
          settings={cookieSettings}
        />
      ),
      canClose: true,
      header: null,
      options: {
        title: "Cookie Settings",
        className: "modal-payments",
        ok: "Save Settings",
        hideCancel: true,
        hideActions: false,
        size: "md",
        form: {
          type: "submit",
          form: "cookie-settings-form",
        },
      },
      proceed: () => modal.close(),
    });

    modal.open();
  };

  const getTrans = (userParam) => {
    if (userParam.is_developer || userParam.is_project_manager) {
      return [
        {
          name: "task_progress_report_reminder_email",
          label: "Email reminders about project progress updates.",
        },
        {
          name: "task_invitation_response_email",
          label: "Email notifications about task invitation responses from developers.",
        },
      ];
    }
    return [
      {
        name: "task_survey_reminder_email",
        label: "Email reminders about client progress surveys.",
      },
      {
        name: "new_task_progress_report_email",
        label: "Email notifications about new developer progress reports.",
      },
    ];
  };

  const labels = {
    promo: [
      {
        name: "newsletter_email",
        label: "Email newsletters from Tunga",
      },
      {
        name: "event_email",
        label: "Emails about interesting events from Tunga",
      },
    ],
    trans: [...getTrans(user)],
    agreement: [
      ["/privacy", "Privacy Policy"],
      ["/agreement", "User Agreement"],
      ["/code-of-conduct", "Code of Conduct"],
    ],
  };

  return (
    <ContentSection className="privacy-settings">
      {isRetrieving.settings ? (
        <Progress />
      ) : (
        <div>
          <div className="section">
            <div className="section-title" aria-label="cookie-model">
              Cookie Settings
            </div>

            <div className="row">
              <div className="col-md-12">
                <p>
                  Learn more about how we use cookies from the &quot;Cookies&quot; section of our{" "}
                  <a href="https://tunga.io/privacy/#cookies" className="text-decoration-underline">
                    Privacy Policy.
                  </a>
                </p>
              </div>
              <div className="col-md-12">
                <p>
                  You can opt-out of specific cookie categories (except essential website cookies)
                  by clicking on the button below.
                </p>
              </div>
              <div className="col">
                <button
                  type="button"
                  className="btn btn-primary save"
                  aria-label="submit"
                  onClick={(e) => {
                    e.target.blur();
                    onCookieSettings(settings);
                  }}
                >
                  Cookie Settings
                </button>
              </div>
            </div>
          </div>

          {settings && settings.switches && (
            <div className="section">
              <div className="section-title">Promotional Email Settings</div>
              {labels.promo.map((label) => {
                return (
                  <div className="form-check" key={`day-${label.name}`}>
                    <label className="form-check-label" htmlFor={`check-${label.name}`}>
                      {label.label}
                    </label>
                    <input
                      className="switch form-check-input"
                      id={`check-${label.name}`}
                      type="checkbox"
                      aria-label={`check-${label.name}`}
                      defaultChecked={settings.switches[label.name]}
                      onChange={(e) => onChange(label.name, e.target.checked)}
                    />
                  </div>
                );
              })}
            </div>
          )}

          {settings && settings.switches && (
            <div className="section">
              <div className="section-title">Transactional Email Settings</div>
              {labels.trans.map((label) => {
                return (
                  <div className="form-check" key={`day-${label.name}`}>
                    <label className="form-check-label" htmlFor={`check-${label.name}`}>
                      {label.label}
                    </label>

                    <input
                      className="switch form-check-input"
                      id={`check-${label.name}`}
                      type="checkbox"
                      aria-label={`check-${label.name}`}
                      defaultChecked={settings.switches[label.name]}
                      onChange={(e) => onChange(label.name, e.target.checked)}
                    />
                  </div>
                );
              })}
            </div>
          )}

          <div className="section agreements">
            <div className="section-title">Agreements and Policies</div>
            <ul className="list-unstyled">
              {labels.agreement.map((link, key) => {
                return (
                  <li key={key} style={{ marginBottom: "8px" }}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://tunga.io/${link[0]}`}
                      className="text-decoration-underline"
                      onClick={() => {
                        trackEvent(event[link[1]], {
                          user_id: user.id,
                          username: user.display_name,
                          event_category: CATEGORIES.settings,
                        });
                      }}
                    >
                      {link[1]}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </ContentSection>
  );
};

const ContentSection = styled.div`
  .section {
    border-bottom: 1px solid #edf1f7;
    padding-bottom: 24px;

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0px;
    }

    .section-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #151a30;
      margin-bottom: 24px;
    }

    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #3e4857;
    }

    .save {
      box-shadow: none;
      border: none;
      background: rgba(6, 46, 100, 0.05);
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #062e64;

      &:disabled {
        color: rgba(6, 46, 100, 0.25);
      }

      &:active:not(.disabled),
      &:focus:not(.disabled) {
        color: #fff;
        background-color: #b9223c;
        border-color: #ae2039;
        box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%), 0 0 0 0.2rem rgb(224 82 107 / 50%);
      }
    }
  }

  .form-check {
    margin-bottom: 18px;
    padding: 0;

    .form-check-input {
      position: initial;
      margin-top: 0;
      margin-left: 0;
      float: right;
    }

    @supports (-webkit-appearance: none) or (-moz-appearance: none) {
      input[type="checkbox"],
      input[type="radio"] {
        --active: #219653;
        --active-inner: #fff;
        --focus: 2px rgba(39, 94, 254, 0.3);
        --border: #bbc1e1;
        --border-hover: #219653;
        --background: var(--ab, var(--border));
        --disabled: #f6f8ff;
        --disabled-inner: rgba(33, 150, 83, 0.5);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        height: 15px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
        &:after {
          content: "";
          display: block;
          left: 0;
          top: 0;
          position: absolute;
          transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
        }
        &:checked {
          --b: var(--active);
          --bc: var(--active);
          --d-o: 0.3s;
          --d-t: 0.6s;
          --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
        }
        &:disabled {
          --b: var(--disabled);
          cursor: not-allowed;
          opacity: 0.9;
          &:checked {
            --b: var(--disabled-inner);
            --bc: var(--border);
          }
          & + label {
            cursor: not-allowed;
          }
        }
        &:hover {
          &:not(:checked) {
            &:not(:disabled) {
              --bc: var(--border-hover);
            }
          }
        }
        &:focus {
          box-shadow: 0 0 0 var(--focus);
        }
      }
      input[type="checkbox"] {
        &.switch {
          width: 30px;
          border-radius: 11px;
          &:after {
            left: 1px;
            top: 1px;
            border-radius: 50%;
            width: 11px;
            height: 11px;
            background: #fff;
            transform: translateX(var(--x, 0));
          }
          &:checked {
            --ab: var(--active-inner);
            --x: 15px;
          }
          &:disabled {
            &:not(:checked) {
              &:after {
                opacity: 0.6;
              }
            }
          }
        }
      }
      input[type="radio"] {
        border-radius: 50%;
        &:after {
          width: 19px;
          height: 19px;
          border-radius: 50%;
          background: var(--active-inner);
          opacity: 0;
          transform: scale(var(--s, 0.7));
        }
        &:checked {
          --s: 0.5;
        }
      }
    }
  }
`;

export default Privacy;
