import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import styled from "styled-components";
import PropTypes from "prop-types";
import * as Yup from "yup";

import FormInputs from "./FormInputs";
import { StyledForm } from "../../utils/styles";
import Error from "../Error";

const SuggestedSkillForm = ({ id, proceed, suggestedSkills }) => {
  const { errors: apiErrors } = useSelector(({ Skill }) => Skill);

  const [suggestedSkillsArr] = useState(() => {
    return suggestedSkills.map((skill) => ({ name: skill, type: "" }));
  });

  const form = useFormik({
    enableReinitialize: true,
    initialValues: { suggestedSkillsArr },
    validationSchema: Yup.object().shape({
      suggestedSkillsArr: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("Skill name is required"),
          type: Yup.string().required("Skill category is required"),
        }),
      ),
    }),
    onSubmit: (formData) => {
      proceed(formData.suggestedSkillsArr);
    },
  });

  const setTouched = (key, index) => {
    const prev = { ...(form.touched?.suggestedSkillsArr || {}) };

    form.setFieldTouched("suggestedSkillsArr", {
      ...prev,
      [index]: {
        ...prev[index],
        [key]: true,
      },
    });
  };

  const updateSuggestedSkillsArr = (key, value, index) => {
    const arr = [...form.values.suggestedSkillsArr];
    const obj = { ...arr[index], [key]: value };

    arr[index] = obj;
    form.setFieldValue("suggestedSkillsArr", arr);
  };

  const handleSkillNameChange = (e, index) => {
    updateSuggestedSkillsArr("name", e.target.value, index);
  };

  const handleSkillCategoryChange = (e, index) => {
    updateSuggestedSkillsArr("type", e.target.value, index);
  };

  const handleSkillNameBlur = (e, index) => {
    setTouched("name", index);
  };

  const handleSkillCategoryBlur = (e, index) => {
    setTouched("type", index);
  };

  const handleSkillRemoval = (e, index) => {
    e.preventDefault();
    if (form.values.suggestedSkillsArr.length === 1) return;

    const arr = [...form.values.suggestedSkillsArr];
    arr.splice(index, 1);

    form.setFieldValue("suggestedSkillsArr", arr);
  };

  return (
    <StyledForm id={id} data-testid="suggested-skill-form" onSubmit={form.handleSubmit} role="form">
      {apiErrors?.skill_suggestion_error && <Error message={apiErrors.skill_suggestion_error} />}

      <InnerWrapper>
        <div className="info mb-3">
          Thanks for suggesting a new skill! Please categorise each suggestion using the dropdown.
          Our team will review and add accepted skills to your profile.
        </div>

        <div className="form-grid mb-2">
          <p className="input-labels m-0">Skill Name</p>
          <p className="input-labels m-0">Category</p>
        </div>

        {form.values.suggestedSkillsArr.map(({ name, type }, index) => {
          const errObj = form.errors?.suggestedSkillsArr?.[index];
          const touched = form.touched?.suggestedSkillsArr?.[index];

          return (
            <FormGroup key={index}>
              <FormInputs
                index={index}
                skillName={name}
                skillCategory={type}
                onSkillNameChange={(e) => handleSkillNameChange(e, index)}
                onCategoryChange={(e) => handleSkillCategoryChange(e, index)}
                onSkillNameBlur={(e) => handleSkillNameBlur(e, index)}
                onCategoryBlur={(e) => handleSkillCategoryBlur(e, index)}
                onRemoveSkill={(e) => handleSkillRemoval(e, index)}
                errObj={errObj}
                touched={touched}
              />
            </FormGroup>
          );
        })}
      </InnerWrapper>
    </StyledForm>
  );
};

const InnerWrapper = styled.div`
  .info {
    font-size: 14px;
    color: #3e4857;
  }

  .form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
  }

  .input-labels {
    color: #22262a;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }
`;

SuggestedSkillForm.propTypes = {
  proceed: PropTypes.func,
  id: PropTypes.string,
  suggestedSkills: PropTypes.arrayOf(PropTypes.string),
};

export default SuggestedSkillForm;
