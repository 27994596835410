import React from "react";
import { Link, useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import styled, { withTheme } from "styled-components";

import Icon from "../../../../../components/Icon";
import SectionNav from "../../../../../components/SectionNav";
import useRightNav from "../../../../../layouts/RightSideNav/useRightNav";
import Navigation from "../../../../../layouts/RightSideNav/Navigation";
import { NavActions } from "../../../payments/styles";
import ReportsListContainer from "./ReportsListContainer";
import YourReports from "./YourReports";
import TeamReports from "./TeamReports";
import ClientReports from "./ClientReports";
import {
  getPermissionByCodeName,
  getUser,
  isAdmin,
  isAdminOrCMOrClientOrCSO,
  isAdminOrCMOrCSO,
  isClient,
  isCM,
  isCSO,
} from "../../../../../utils/auth";
import {
  PROGRESS_EVENT_TYPE_CLIENT_DEVELOPER_RATING,
  PROGRESS_EVENT_TYPE_CM,
  PROGRESS_EVENT_TYPE_DEVELOPER,
  PROGRESS_EVENT_TYPE_DEVELOPER_SURVEY,
} from "../../../../../utils/api";
import FilterSidebar from "./FilterSidebar";

const navSections = [
  {
    component: <FilterSidebar />,
    title: "Filters",
    headerText: true,
  },
];

const Reports = () => {
  const canViewClientReports = getPermissionByCodeName("can-view-client-reports");
  const canViewTeammembersReports = getPermissionByCodeName("can-view-teammembers-reports");

  const match = useRouteMatch();
  const { open } = useRightNav();

  const urlBase = match.url.split("/").splice(0, 3).join("/");
  const accessCheck = isAdminOrCMOrClientOrCSO();
  const canAccessConfigure = isAdminOrCMOrCSO();

  const generateRoute = (route) => `${match.url.replace("/", "")}/${route}`;

  const getLinks = () => {
    const links = [];

    if (accessCheck) {
      if (isClient() && canViewTeammembersReports) {
        links.push({ route: generateRoute("team"), name: "From Team Members" });
      }

      if (!isAdmin() && !isCM()) {
        links.push({ route: generateRoute("yours"), name: "Yours" });
      }

      if (!isClient() && canViewTeammembersReports) {
        links.push({ route: generateRoute("team"), name: "Team Members" });
      }

      if (!isClient() && canViewClientReports) {
        links.push({ route: generateRoute("client"), name: "Client" });
      }
    }

    return links;
  };

  const links = getLinks();

  return (
    <>
      <SectionNav
        links={links}
        rightActions={
          <StyledNavActions className={!accessCheck ? "single-action" : ""}>
            <button
              className="btn p-0"
              type="button"
              onClick={() => open(<Navigation borderBottom={false} navSections={navSections} />)}
            >
              <Icon name="filter-variant" size="sm" /> Filter
            </button>
            {canAccessConfigure && (
              <Link to={`${urlBase}/settings/reports`}>
                <Icon name="outline-settings" size="sm" /> Configure
              </Link>
            )}
          </StyledNavActions>
        }
      />

      <Switch>
        {[
          ...(!isAdmin() && !isCM() ? [["yours", <YourReports />]] : []),
          ...(canViewTeammembersReports ? [["team/:reportId", <TeamReports />]] : []),
          ...(canViewTeammembersReports ? [["team", <TeamReports />]] : []),
          ...(canViewClientReports ? [["client/:reportId", <ClientReports />]] : []),
          ...(canViewClientReports ? [["client", <ClientReports />]] : []),
        ].map((path) => (
          <Route
            key={`report-${path}`}
            path={`${match.url}/${path[0]}`}
            render={() => {
              const page = path[0];

              let type = PROGRESS_EVENT_TYPE_DEVELOPER;

              switch (page) {
                case "team":
                  type = `${PROGRESS_EVENT_TYPE_DEVELOPER}${
                    !(isCM() || isCSO())
                      ? `,${PROGRESS_EVENT_TYPE_CM},${PROGRESS_EVENT_TYPE_DEVELOPER_SURVEY}`
                      : ""
                  }`;
                  break;
                case "client":
                case page.includes("client"):
                  type = PROGRESS_EVENT_TYPE_CLIENT_DEVELOPER_RATING;
                  break;
                default:
                  if (isCM() || isCSO()) {
                    type = PROGRESS_EVENT_TYPE_CM;
                  } else if (isClient()) {
                    type = PROGRESS_EVENT_TYPE_CLIENT_DEVELOPER_RATING;
                  }
              }

              const params = page === "yours" ? { users: getUser().id } : null;

              return (
                <ReportsListContainer page={page} params={params} type={type}>
                  {path[1]}
                </ReportsListContainer>
              );
            }}
          />
        ))}
        <Redirect exact from={`${match.url}`} to={`${match.url}/${isAdmin() ? "team" : "yours"}`} />
      </Switch>
    </>
  );
};

const StyledNavActions = withTheme(styled(NavActions)`
  &.single-action {
    display: block;
    float: none;
  }

  a {
    color: ${(props) => props.theme.colors.gray || "initial"};
    font-weight: 500;
    font-size: ${(props) =>
      props.theme.functions.pxToRem(
        14,
      )}; /* TODO 14px can be added to global styles cause it seems all text is 14px  */
    text-decoration: none;
    margin-left: 25px;

    /* Pull in mixins from props */
    ${(props) => props.theme.mixins.link(props.theme.colors.gray)}

    i {
      vertical-align: baseline;
      margin-right: 5px;
    }
  }

  a:nth-child(1) {
    i {
      font-size: 11px; /* TODO: this icon dimensions have to be adjusted in resources directly */
    }
  }

  button.btn {
    height: unset;
    line-height: unset;

    :hover,
    :active {
      color: ${(props) => props.theme.colors.gray || "initial"};
    }
  }
`);
export default Reports;
