/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const PaymentContainer = ({ children }) => {
  return <PaymentDiv>{children}</PaymentDiv>;
};

const PaymentDiv = styled.div`
  display: table;
  height: 100%;
  padding-bottom: 0.5em;
  position: relative;

  h4 {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 18px;
    color: #151a30;
  }

  a {
    box-shadow: none !important;
  }

  p {
    font-size: 12px !important;
    line-height: 18px;
    color: #3e4857;

    .masked-details {
      display: block;
      font-weight: 700;
    }
  }

  .w {
    &-20 {
      width: 20%;
    }
    &-80 {
      width: 80%;
    }
  }

  .pdb-40 {
    padding-bottom: 4.4em;
  }
`;

PaymentContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default PaymentContainer;
