import React from "react";
import styled from "styled-components";

const TermsNdConditions = () => {
  return (
    <Wrapper className="agreement-page">
      <div className="container">
        <h2>Tunga Platform Conditions</h2>

        <p>
          Welcome to Tunga. Tunga is an online platform that connects African software programmers
          to tech businesses and software projects from all over the world (<b>us</b> or{" "}
          <b>Tunga</b>).
        </p>

        <p>
          This document (the <b>Tunga Platform Conditions</b>) contains the terms & conditions that
          apply to the use of the Tunga Platform. By registering as a developer on the Tunga
          Platform, you (<b>you</b> or <b>the Developer</b>) accept these terms & conditions
          regarding the use of the Tunga Platform and/or your engagement by Tunga to work on
          Projects, both as defined below.
        </p>

        <p>
          Tunga can modify the Tunga Platform Conditions as it sees fit, and the Tunga Platform
          Conditions as published on the Platform from time to time shall be the applicable Tunga
          Platform Conditions.
        </p>

        <p>This User Agreement has been last updated on Monday, 8th December, 2022.</p>

        <h3>1. DEFINITIONS</h3>
        <p>
          In this User Agreement, unless the context otherwise requires, the following terms shall
          have the following meanings:
        </p>

        <p>
          <strong>Confidential Information:</strong> shall include, but not necessarily be limited
          to, all information which is not publicly known including the business, finances,
          technology, trade secrets, and any other commercially sensitive information of either
          party regardless of its nature;
        </p>

        <p>
          <strong>Customer:</strong> a customer that acquires Services from Tunga;
        </p>

        <p>
          <strong>Deliverables:</strong> all works and other items to be provided by a Developers in
          connection with his assignment by Tunga to work on a Project;
        </p>

        <p>
          <strong>Developer Agreement:</strong> an agreement between a Developer and Tunga setting
          out the general terms and conditions on the basis whereof the Developer will be assigned
          by Tunga to work on any and all Projects;
        </p>

        <p>
          <strong>Intellectual Property Rights:</strong> all invention, patent application, patent
          improvement, data bases, computer software programmes, design, process, publication, copy
          right work, trade mark, trade name, logo, model and in general every creative result in
          the field of industrial or intellectual property, howsoever arising and in whatever media,
          whether or not registered, including (without limitation) patents, trademarks, service
          marks, trade names, registered design and any applications for the protection or
          registration of these rights and all renewals and extensions thereof throughout the world;
        </p>

        <p>
          <strong>Platform:</strong> the platform, owned, maintained and operated by Tunga, which is
          hosted at{" "}
          <a href="https://tunga.io" rel="nofollow noreferrer noopener" target="_blank">
            https://tunga.io
          </a>
          , and all the services, tools and applications offered there;
        </p>

        <p>
          <strong>Project:</strong> the project of a Customer for which project the Customer has
          engaged Tunga to provide software development services;
        </p>

        <p>
          <strong>Services:</strong> any service provided by Tunga to a Customer;
        </p>

        <p>
          <strong>Software:</strong> the software being developed or customised by Tunga for the
          Customer, preliminary details of which are set out in the Work, including any enhancements
          and modifications made;
        </p>

        <p>
          <strong>Statement of Work:</strong> an agreement between a Developer and Tunga setting out
          the specific terms and conditions on the basis whereof such Developer will be assigned by
          Tunga to work on a specific Project, including the nature of the Project, compensation
          terms, the specific Services that are required to be performed and any Deliverables that
          would be required to be produced by the Developer;
        </p>

        <p>
          <strong>Tunga Account:</strong> the personalized account created and registered by a
          Developer to in order to access and use the Platform;
        </p>

        <p>
          <strong>Tunga:</strong> Tunga BV, registered at Jan van Ghestellaan 40, 3054 CJ Rotterdam,
          The Netherlands (Chamber of Commerce nr: 67015679);
        </p>

        <h3>2 GENERAL</h3>

        <p>
          2.1 Tunga provides software development services to Customers on a project basis. To that
          end, Tunga owns, maintains, and operates the Platform on which Developers can create a
          Tunga Account that sets out their background and experience with software development and
          their willingness and availability to be assigned work as a software developer for Tunga.
        </p>

        <p>
          2.2 Tunga provides software development services to Customers in four (4) ways:
          <ol type="a">
            <li className="mb-1">
              by hiring out software developers and client managers as individuals either part-time
              or full-time,
            </li>
            <li className="mb-1">
              by hiring out software developers and client managers as entire teams,
            </li>
            <li className="mb-1">by executing entire projects, and</li>
            <li>by executing tasks.</li>
          </ol>
        </p>

        <p>
          2.3 After Tunga is engaged by a Customer via the execution of a formal agreement to
          provide software development services, Tunga may assign certain Developers as independent
          contractors to work on Projects. Upon a Developer’s first assignment, and as a condition
          for such assignment, the Developer and Tunga shall enter into a Developer Agreement, which
          Developer Agreement sets out the general terms and conditions on the basis whereof the
          Developer shall be assigned by Tunga to work on the first and any future Projects. The
          specific terms of each assignment to an applicable Project (including your first Project),
          such as the nature of the Project, compensation terms, the specific Services that are
          required to be performed and any Deliverables that would be required to be produced by the
          Developer, will be set out in a specific Statement of Work for each Project. Each
          Statement of Work must be accepted by you and, upon your acceptance, becomes an integral
          part of your Developer Agreement.
        </p>

        <p>
          2.4 For the avoidance of doubt, any and all work performed by you shall always be on the
          basis of a contractual relationship with Tunga and never on the basis of a contractual
          relationship with a Customer.
        </p>

        <h3>3. ACCEPTANCE</h3>

        <p>
          3.1 These Tunga Platform Conditions are a binding agreement made and entered between you
          and Tunga and set forth the legally binding terms and conditions that govern your use of
          the Platform as a Developer. By using or accessing the Platform you accept to be bound by
          these Tunga Platform Conditions. If you do not agree with all of the provisions of these
          Tunga Platform Conditions, you are not allowed to access or use the Platform.
        </p>

        <h3>4. USING THE TUNGA PLATFORM</h3>

        <p>
          4.1 You must register your Tunga Account to access and use the Platform. You may not use,
          and your Tunga Account shall not be visible on the Platform until Tunga has accepted the
          registration of your Tunga Account.
        </p>

        <p>
          4.2 You cannot use the Platform and/or create a Tunga Account if you are:
          <br />
          <ol type="a">
            <li className="mb-1">
              legally not allowed to enter into contracts by the laws governing your country of
              residence,
            </li>
            <li className="mb-1">under the age of 16,</li>
            <li className="mb-1">
              have been suspended from the Platform by Tunga or your Tunga Account has been
              terminated, or
            </li>
            <li> have not accepted these Tunga Platform Conditions.</li>
          </ol>
        </p>

        <p>
          4.3 You are obliged to provide Tunga with and include in your Tunga Account, only valid,
          accurate, and true information.
        </p>

        <p>
          4.4 You are responsible for maintaining the accuracy and secrecy of the information
          provided in your Tunga Account, including username and password. In the event of misuse or
          suspected misuse of your account, you must immediately notify Tunga.
        </p>

        <p>
          4.5 You are not entitled to perform any actions that would interfere with the proper
          working of the Platform and/or the Services.
        </p>

        <p>
          4.6 For the purposes of attracting new business opportunities, you agree that Tunga may
          present your Tunga Account and any information you have otherwise provided to Tunga, to
          existing and potential Customers.
        </p>

        <h3>5. PLATFORM RESTRICTIONS</h3>

        <p>
          5.1 You agree that you will not, and will not permit third parties to:
          <br />
          <ol type="a">
            <li className="mb-1">
              use the Platform for unlawful purposes or commercial activities;
            </li>
            <li className="mb-1">
              provide untruthful or inaccurate information in your Tunga Account or any other
              information that Tunga or a Customer may rely upon;
            </li>
            <li className="mb-1">
              circumvent or attempt to circumvent the Platform to contact Customers directly to an
              obtain assignment, engagement or employment outside the Platform to perform work for
              the Customer that directly or indirectly competes with the Services provided by Tunga;
            </li>
            <li className="mb-1">misrepresent your identity or affiliation with a third party;</li>
            <li className="mb-1">copy or reproduce the Platform;</li>
            <li className="mb-1">
              reverse engineer, disassemble, decompile, decode or otherwise attempt to derive or
              gain access to the source code of the Platform, our Customers and Projects, or any
              part thereof;
            </li>
            <li className="mb-1">
              rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or
              otherwise make available the Platform or any features or functionality thereof to any
              third party;
            </li>
            <li className="mb-1">
              remove, disable, circumvent or otherwise create or implement any workaround to any
              copy protection, rights management or security features in or protecting the Platform;
            </li>
            <li className="mb-1">
              upload, transmit or distribute to or through the Platform any computer viruses or any
              software intended to damage or alter a computer system or data;
            </li>
            <li className="mb-1">
              use software or automated agents or scripts to generate automated searches, requests
              or queries to, or strip, scrape, or mine data from, the Platform; or
            </li>
            <li className="mb-1">
              collect, upload, transmit, display or distribute any content or material that Tunga
              determines, in its sole discretion:
              <ol type="i">
                <li className="mb-1">violates any third party&apos;s rights,</li>
                <li className="mb-1">
                  is unlawful, harassing, threatening, harmful, invasive of another&apos;s privacy,
                  vulgar, defamatory, or is otherwise objectionable;
                </li>
                <li className="mb-1">is harmful to minors in any way; or</li>
                <li className="">
                  is in violation of any law, regulation, obligation or other similar restrictions
                  imposed by any third party, including any confidentiality obligations from former
                  employers.
                </li>
              </ol>
            </li>
          </ol>
        </p>

        <p>
          5.2 If any of the circumstances as set out in Article 5.1 above occur or become applicable
          to you, Tunga is entitled to terminate these Tunga Platform Conditions, terminate or
          suspend your Tunga Account, and/or deny you access to the Platform, without prejudice to
          Tunga’s right to hold you liable for any damages incurred by Tunga, to seek injunctive
          relief and/or a decree of specific performance or other equitable relief to enforce your
          obligations under Article 5.1.
        </p>

        <h3>6. REPRESENTATIONS AND WARRANTIES</h3>
        <p>
          6.1 You agree, represent, and warrant that you are in and will maintain compliance with
          and take reasonable measures to ensure that you maintain compliance with all applicable
          laws, including specifically, but without limiting the foregoing, those relating to
          bribery or corruption, and that you will inform Tunga without undue delay if you are or
          have been investigated for or charged with bribery, corruption or similar conduct by any
          agency or tribunal of competent jurisdiction.
        </p>

        <p>
          6.2 To the extent not already covered above, you represent and warrant that you are not a
          person targeted by economic, trade or financial sanctions laid down, administered, or
          enforced by the United Nations, the United States, the European Union, or its member
          states, the United Kingdom or any other jurisdiction that is applicable to the Platform.
          You agree, represent, and warrant that you will notify Tunga in writing immediately if you
          become the subject of any such sanction.
        </p>

        <p>
          6.3 Without limiting any other of Tunga’s rights to terminate these Tunga Platform
          Conditions and/or suspend or terminate your Tunga Account, Tunga may terminate your access
          to and our usage of the Platform and all other agreements and relationships with you
          effective immediately and without liability or obligation whatsoever, if:
          <ol type="a">
            <li className="mb-1">
              you engage in bribery, corruption or similar conduct, or are charged with such conduct
              by any agency or tribunal of competent jurisdiction;
            </li>
            <li className="mb-1">
              you fail to comply with your obligations as set out in this Article 6;
            </li>
            <li className="mb-1">
              the representations and warranties set forth in this Article 6, at any time, become
              untrue or Tunga has reasonable and specific grounds to believe that they have become
              untrue or to expect that they will become untrue;
            </li>
            <li className="mb-1">
              Tunga determines, in its sole and absolute discretion, that, by continuing to provide
              to you access to and/or usage of the Platform or otherwise maintain its relationship
              with you would (i) contravene, or risk contravening any applicable international,
              national, state, local or equivalent law, or (ii) be sanctionable under the economic,
              trade and financial sanctions administered and enforced by the United Nations, the
              United States, the European Union and its member states, or the United Kingdom or any
              other jurisdiction that is applicable to the Platform; or
            </li>
            <li>
              any transaction related to the Platform, Services or these Tunga Platform Conditions,
              is withheld, refused, declined or otherwise prevented by any of Tunga’s, a Customer’s
              or any of their affiliates’ banks or if Tunga determines in good faith that its credit
              facilities or other banking relationships may be materially adversely impacted based
              on concerns that you have engaged in conduct inconsistent with the text or spirit of
              this Article 6.
            </li>
          </ol>
        </p>

        <h3>7. PRIVACY</h3>

        <p>
          7.1 Tunga may record video or telephone calls between you and Tunga and/or other
          Developers, and add portions of these calls to your account information, which will be
          available to Customers. You explicitly acknowledge, agree, and consent to such use. Tunga
          reserves the right to monitor the content of messages that you send through our Platform,
          including email or electronic messaging service.
        </p>

        <p>
          7.2 The information you provide in your Tunga Account and/or is otherwise available on the
          Platform, as well as Tunga’s assessment of your skills and capabilities in connection with
          software development and any other information related to software development, is
          essential for Tunga to be able to provide the Services to the Customers. As such, Tunga
          may disclose, provide and grant access to the aforementioned information to Customers
          and/or other Developers. You explicitly acknowledge, agree, and consent to such use.
        </p>

        <p>
          7.3 Tunga and/or persons acting on Tunga’s behalf may collect statistical and/or other
          data and/or information related to your access to and/or usage of the Platform. You
          expressly and specifically acknowledge and agree that Tunga may utilize and disclose all
          this statistical and/or other data and/or information regarding the Platform for any
          purposes in connection with Tunga’s business. In addition, Tunga reserves the right to
          otherwise monitor the Platform to facilitate the operation of the Platform, to help
          resolve service requests, to detect and address threats to the functionality, security,
          integrity, and availability of the Platform, as well as any content, data or applications
          on or related to the Platform, to validate your compliance with these Tunga Platform
          Conditions, and to detect and address illegal or improper acts or misuse. You agree that
          Tunga’s monitoring of the Platform does not impose any obligation or responsibility on
          Tunga to monitor your or any third party’s usage of the Platform.
        </p>

        <h3>8. TERM & TERMINATION</h3>

        <p>
          8.1 This Tunga Platform Conditions are effective and binding as of the date you create and
          register your Tunga Account and shall continue to be effective and binding until your
          Tunga Account or these Tunga Platform Conditions are terminated.
        </p>

        <p>
          8.2 You may terminate these Tunga Platform Conditions at any time by deleting your Tunga
          Account. Tunga may terminate these Tunga Platform Conditions and your access to the
          Platform at any time and in Tunga’s sole discretion without advance notice.
        </p>

        <p>
          8.3 Forthwith upon the termination of these Tunga Platform Conditions, you shall return to
          Tunga any Software, all related materials and documentation, and any Confidential
          Information belonging to Tunga and/or the Customers and all copies of the whole or any
          part thereof or, if requested by Tunga, shall destroy the same and certify in writing to
          Tunga that it has been destroyed.
        </p>

        <p>
          8.4 Any termination of these Tunga Platform Conditions (howsoever occasioned) shall not
          affect any accrued rights or liabilities of you or Tunga nor shall it affect the coming
          into force or the continuance in force of any provision hereof which is expressly or by
          implication intended to come into or continue in force on or after such termination.
        </p>

        <h3>9. AMENDMENT OF WORKS PLATFORM CONDITIONS AND/OR PLATFORM</h3>

        <p>
          9.1 Tunga reserves the right to, at any time, (i) modify these Tunga Platform Conditions
          and/or (ii) discontinue or otherwise modify any part of the Platform, including your
          access to and usage of the Platform, in both cases without advance notice and at Tunga’s
          sole discretion.
        </p>

        <p>
          9.2 An amendment of these Tunga Platform Conditions by Tunga will be effectuated by
          updating them and providing an updated version of these Tunga Platform Conditions on the
          Platform. Such amendments will be effective immediately upon update, and your continued
          use of any part of the Platform thereafter shall constitute your acceptance of those
          amendments.
        </p>

        <p>
          9.3 If you object to an amendment of these Tunga Platform Conditions, or become
          dissatisfied with the Platform or its functionality in any way, your only recourse is to
          discontinue your usage of the Platform and terminate your Tunga Account.
        </p>

        <h3>10. INTELLECTUAL PROPERTY RIGHTS</h3>

        <p>
          10.1 You shall promptly disclose to Tunga any Intellectual Property Rights found on,
          provided by, or embodied in the Platform and/or created or discovered by you during the
          period of this Agreement related to the performance of the Services (whether capable of
          being patented or registered or not or whether or not made or discovered in the course
          your activities for Tunga), in conjunction with or in any way affecting or relating to the
          business of Tunga, or adapted for use in or in connection with such business. If the
          Intellectual Property Rights do not automatically accrue to Tunga, you shall at
          Tunga&apos;s first request transfer such rights to Tunga and co-operate fully in recording
          such rights in the name of Tunga as exclusive owner.
        </p>

        <p>
          10.2 In addition to the foregoing disclosure and assignment to Tunga of the Intellectual
          Property Rights, you hereby irrevocably and unconditionally transfer and assign to Tunga
          all Intellectual Property Rights, free from any encumbrances, and waive and agree never to
          assert any and all rights you may have in or with respect to any Intellectual Property
          Right, even after termination of this Agreement.
        </p>

        <p>
          10.3 During the period of this Agreement or thereafter, you shall refrain from registering
          any Intellectual Property Right in your own name and will refrain from exploiting any of
          the Intellectual Property Rights.
        </p>

        <h3>11. CONFIDENTIALITY</h3>

        <p>
          11.1 Both for the duration that these Tunga Platform Conditions are binding upon you and
          after their termination, you and Tunga shall treat as confidential and shall not use or
          disclose to any person, firm or company, any Confidential Information belonging to Tunga,
          Customers and/or other Developers, nor permit its use or disclosure.
        </p>

        <p>
          11.2 You shall promptly notify Tunga if you become aware of any breach of confidentiality
          by any person to whom it divulges all or any part of the Confidential Information and
          shall give Tunga all reasonable assistance in connection with any proceedings which Tunga
          or a Customer may institute against such person for breach of confidentiality.
        </p>

        <p>
          11.3 The provisions of this clause shall survive the termination of these Tunga Platform
          Conditions but the restrictions contained in clause 11.1 shall cease to apply to any
          information which may come into the public domain otherwise than through unauthorized
          disclosure.
        </p>

        <h3>12. NON-SOLICIT</h3>

        <p>
          12.1 During the period that these Tunga Platform Conditions are binding upon you and for
          two (2) years after their termination, you will not directly or indirectly solicit, hire,
          contract with, or engage the employment of Customers, relations, employees, prospects, or
          suppliers of Tunga and/or other Developers to attempt to cancel or negatively change their
          relationship with Tunga or its (future) subsidiaries.
        </p>

        <h3>13. NON-DISPARAGEMENT</h3>

        <p>
          13.1 You agree that, both for the duration that these Tunga Platform Conditions are
          binding upon you and after their termination, you will not make any disparaging or
          defamatory public statements or comments about Tunga, its affiliates, or their respective
          directors, officers, shareholders or members, employees, operations, products or services,
          or the Platform, or the Customers or other Developers or encourage or participate with
          anyone to make those statements or comments.
        </p>

        <h3>14. LIMITATION OF LIABILITY</h3>

        <p>
          14.1 To the maximum extent permitted by law: (i) in no event will Tunga be liable towards
          you for any incidental, indirect, special, punitive, enhanced, or consequential damages
          (including, but not limited to, loss of use, data, business, goodwill, profits or revenue)
          for any claim arising under these Tunga Platform Conditions and/or your usage of the
          Platform, regardless of the cause and (ii) in no event will Tunga&apos;s aggregate
          liability to you for any damages arising out of or relating to these Tunga Platform
          Conditions or your usage of the Platform, whether based on an action or claim in contract,
          equity, negligence, tort or otherwise, exceed the amount of fees paid to you by Tunga
          under your Developer Agreement in the twelve (12) months preceding the date on which the
          claim arose.
        </p>

        <p>
          14.2 In no event will Tunga be liable for any damages resulting from loss of data or use,
          lost profits, loss of anticipated savings, nor for any damages that are an indirect or
          secondary consequence of any act or omission of Tunga whether such damages were reasonably
          foreseeable or actually foreseen.
        </p>

        <p>
          14.3 Tunga is never liable for the content used or provided by you on the Platform. If you
          come across content that is not correct, offensive, or in violation of these Tunga
          Platform Conditions, you have to report this immediately to Tunga. Tunga has the right to
          delete the content of Developers, if necessary.
        </p>

        <p>
          14.4 Tunga is committed to a high standard of data security and precision. However, Tunga
          is never liable for any loss or damages that you may incur as a result of malfunctions,
          errors, security breaches, or any other reason.
        </p>

        <p>
          14.5 Tunga is never liable for claims as a result of outages or unavailability of the
          server and/or the Platform. Tunga has the objective of a hundred percent (100%) server
          up-time, but cannot guarantee this.
        </p>

        <h3>15. INDEMNIFICATION</h3>

        <p>
          15.1 You agree to indemnify, defend and hold harmless Tunga and all Tunga’s affiliates
          from any and all losses arising from or related to your access to and/or usage of the
          Platform, intentionally or unintentionally, including through the unauthorized use of your
          Tunga Account or arising from or related to your access to and/or usage of the Platform
          contrary to or beyond your rights hereunder, or otherwise arising from or relating to your
          access to and/or usage of the Platform.
        </p>

        <p>
          15.2 In addition, you agree to indemnify and hold harmless Tunga and its affiliates from
          any claims, damages, liabilities, penalties, costs, fees, expenses, and other losses
          whatsoever incurred as a result of:
          <ol type="a">
            <li className="mb-1">
              you engaging in bribery, corruption or similar conduct, or being charged with such
              conduct by any agency or tribunal of competent jurisdiction;
            </li>
            <li className="mb-1">
              you breaching or violating the restrictions for the usage of the Platform as set out
              in Article 5 at any time,
            </li>
            <li className="mb-1">
              the representations and warranties set out in Article 6 at any time, becoming untrue,
              or
            </li>
            <li>
              you failing to comply with your obligations under these Tunga Platform Conditions.
            </li>
          </ol>
        </p>

        <h3>16. NOTICES</h3>

        <p>
          16.1 Notices shall be deemed to have been duly given:
          <ol type="a">
            <li className="mb-1">
              when delivered, if delivered by courier or other messenger (including registered mail)
              during normal business hours of the recipient; or
            </li>
            <li className="mb-1">
              when sent, if transmitted by e-mail and a successful transmission report or return
              receipt is generated; or
            </li>
            <li className="mb-1">
              on the fifth business day following mailing, if mailed by national ordinary mail,
              postage prepaid; or
            </li>
            <li>
              on the tenth business day following mailing, if mailed by airmail, postage prepaid,
            </li>
          </ol>
          in each case addressed to the most recent address, e-mail address, or facsimile number
          notified to the other party.
        </p>

        <h3>17. MISCELLANEOUS</h3>

        <p>
          17.1 You are solely responsible for complying with the obligations following these Tunga
          Platform Conditions, including obligations relating to any applicable local or
          international laws and regulations.
        </p>

        <p>
          17.2 You shall not issue or make any public announcement or disclose any information
          regarding these Tunga Platform Conditions unless prior written consent has been obtained
          from Tunga.
        </p>

        <p>
          17.3 A failure by Tunga to take action in the event of non-performance or non-compliance
          by you with these Tunga Platform Conditions shall not operate as a waiver of such right.
        </p>

        <p>
          17.4 Except to the extent of assignment rights guaranteed by applicable law, your
          obligations and rights hereunder are not assignable. Ramboll’s obligations and rights
          hereunder, on the other hand, are freely assignable without notification or restriction.
        </p>

        <p>
          17.5 These Tunga Platform Conditions and your usage of the Platform shall not be construed
          as creating an agency, joint venture, partnership, or similar relationship between you and
          Tunga.
        </p>

        <p>
          17.6 These Tunga Platform Conditions supersede all prior agreements, arrangements, and
          undertakings between the parties and constitute the entire agreement between the parties
          relating to the subject matter of these Tunga Platform Conditions. However, the
          obligations of the parties under any pre-existing non-disclosure agreement shall remain in
          full force and effect insofar as there is no conflict between the same. The parties
          confirm that they have not entered into these Tunga Platform Conditions on the basis of
          any representation that is not expressly incorporated into these Tunga Platform
          Conditions.
        </p>

        <p>
          17.7 If any provision of these Tunga Platform Conditions is prohibited by law or judged by
          a court to be unlawful, void, or unenforceable, the provision shall, to the extent
          required, be severed from these Tunga Platform Conditions and rendered ineffective as far
          as possible without modifying the remaining provisions of these Tunga Platform Conditions,
          and shall not in any way affect any other circumstances of or the validity or enforcement
          of these Tunga Platform Conditions.
        </p>

        <p>
          17.8 These Tunga Platform Conditions shall be binding upon, and inure to the benefit of,
          the parties and their respective successors and permitted assignees, and references to a
          party in these Tunga Platform Conditions shall include its successors and permitted
          assignees.
        </p>

        <p>
          17.9 In these Tunga Platform Conditions references to a party include references to a
          person:
          <ol type="a">
            <li className="mb-1">
              who for the time being is entitled (by assignment, novation, or otherwise) to that
              party&apos;s rights under these Tunga Platform Conditions (or any interest in those
              rights); or
            </li>
            <li className="mb-1">
              who, as administrator, liquidator or otherwise, is entitled to exercise those rights,
              and in particular those references include a person to whom those rights (or any
              interest in those rights) are transferred or pass as a result of a merger, division,
              reconstruction or other reorganization involving that party. For this purpose,
              references to a party&apos;s rights under these Tunga Platform Conditions include any
              similar rights to which another person becomes entitled as a result of a novation of
              these Tunga Platform Conditions.
            </li>
          </ol>
        </p>

        <p>
          17.10 Tunga reserves the right to perform maintenance or changes to your Tunga Account
          and/or the Platform. This may result in periods during which Tunga Account, and/or the
          Platform may be temporarily unavailable. Tunga will announce such interruptions in
          advance, if possible. Tunga is never liable for claims as a result of the unavailability
          of your Tunga Account and/or the Platform.
        </p>

        <p>
          17.11 The content on the Platform, including, without limitation, the text, software,
          scripts, graphics, photographs, sounds, videos, articles, and the trademarks, service
          marks, and logos contained therein are owned by or licensed by Tunga, unless otherwise
          explicitly indicated by Tunga.
        </p>

        <p>
          17.12 All legal notices should be sent via e-mail to{" "}
          <a href="mailto:admin@tunga.com">admin@tunga.com</a>. Also, if you have any questions
          about this User Agreement or if you wish to report breaches of this User Agreement, please
          contact us by e-mail us to this e-mail address.
        </p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #151a30;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: #3e4857;
  }
  b {
    font-weight: 700;
  }
`;

export default TermsNdConditions;
