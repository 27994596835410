/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Avatar from "../../../../../components/Avatar";
import SmileysModal from "../../../../../components/SmileysModal";
import { generateUserInitials } from "../../../../../utils/stringUtils";

const ClientsModalContent = ({ activity }) => {
  const { project } = useSelector(({ Projects }) => Projects);

  const [developers] = useState(
    activity.developer_ratings.map((rating) => {
      return {
        id: rating.id,
        name: rating.user.display_name,
        rating: `${rating.rating}`,
      };
    }),
  );

  return (
    <>
      <ModalContentTitle>
        <p>Client</p>
        <div className="d-flex align-items-center">
          <Avatar
            image={activity.user.avatar_url}
            initials={generateUserInitials(activity.user)}
            size="dash"
          />
          {activity.user.display_name}
        </div>
      </ModalContentTitle>

      {project.category === "dedicated" ? (
        developers.map((dev, idx) => (
          <ModalContentDevRating key={`rating-smileys-${idx}`}>
            <p>
              How do you rate your collaboration with <span>{dev.name}</span> on this project?
            </p>
            <SmileysModal index={idx} name={`rating-${dev.id}`} selected={dev.rating} disabled />
          </ModalContentDevRating>
        ))
      ) : (
        <ModalContentDevRating>
          <p>How would you rate your collaboration with Tunga on this project?</p>
          <SmileysModal
            index={2}
            name="rating-2"
            selected={`${activity.progress_reports[0]?.rate_communication}`}
            disabled
          />
        </ModalContentDevRating>
      )}
    </>
  );
};

const ModalContentTitle = styled.div`
  border-bottom: 1px solid #edf1f7;
  padding-bottom: 25px;
  margin-bottom: 40px;
  border-bottom: 1px solid #edf1f7;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #151a30;
    margin-bottom: 12px;
  }
  div {
    font-size: 16px;
    line-height: 150%;
    color: #3e4857;

    .avatar.avatar-dash {
      width: 35px;
      height: 35px;
    }
    .avatar.avatar-initials {
      font-size: 12px;
    }
  }
`;

const ModalContentDevRating = styled.div`
  border-bottom: 1px solid #edf1f7;
  padding-bottom: 40px;
  margin-bottom: 40px;

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #151a30;
    margin-bottom: 12px;

    span {
      font-weight: 500;
    }
  }
`;

export default ClientsModalContent;
