/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import OnboardingWizard from "./OnboardingWizard";
import CreateLayout from "../../../layouts/CreateLayout";
import { OnboardingProvider } from "../../../context/OnboardingContext";

const OnboardingContainer = () => {
  return (
    <OnboardingProvider>
      <CreateLayout>
        <OnboardingWizard />
      </CreateLayout>
    </OnboardingProvider>
  );
};

export default OnboardingContainer;
