import React from "react";
import styled from "styled-components";

const Point = () => {
  return (
    <Wrapper>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8" r="8" fill="#062E64" />
        <circle cx="8" cy="8" r="5" fill="white" />
      </svg>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-block;
  position: absolute;
  top: -2px;
`;

export default Point;
