import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import PropTypes from "prop-types";

import Avatar from "../Avatar";
import FieldError from "../FieldError";
import Progress from "../Progress";
import Loading from "../svg/Loading";
import { addInvoiceComments } from "../../redux/actions/InvoiceActions";
import { generateUserInitials } from "../../utils/stringUtils";
import { trackEvent } from "../../analytics/segment";
import { CATEGORIES, EVENTS } from "../../analytics/events";

const Comments = ({ invoice }) => {
  const dispatch = useDispatch();

  const { isMakingRequest, comments, errors } = useSelector(({ Invoice }) => Invoice);

  const [comment, setComment] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => setComment(e.target.value);

  const successCallback = () => {
    setIsSubmitting(false);
    setComment("");

    trackEvent(EVENTS.add_comment, {
      project_id: invoice?.project?.id || "",
      project_name: invoice?.project?.title || "",
      payment_type: invoice.type,
      comment_total: comments.length + 1,
      event_category: invoice.type === "purchase" ? CATEGORIES.payouts : CATEGORIES.payments,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");

    dispatch(addInvoiceComments(invoice.id, comment, successCallback));
  };

  useEffect(() => {
    if (errors?.comments) {
      setIsSubmitting(false);
      setErrorMessage(errors.comments);
    }
  }, [errors?.comments]);

  return (
    <Wrapper>
      <div className="header">Comments</div>

      {isMakingRequest?.comments ? (
        <Progress />
      ) : (
        <>
          <form onSubmit={handleSubmit} aria-label="addComment-form" id="addComment">
            <div className="d-flex">
              <input
                className="w-100"
                type="text"
                value={comment}
                onChange={handleChange}
                placeholder="Comment"
                maxLength={250}
                required
              />

              <button
                disabled={!comment}
                className="btn btn-primary shadow-none add-comment-btn"
                type="submit"
              >
                {isSubmitting ? <Loading /> : "Add Comment"}
              </button>
            </div>

            {errorMessage && <FieldError message={errorMessage} />}
          </form>

          {comments.length > 0 && (
            <>
              {comments.map(({ id, actor, data, timestamp }) => {
                const commentData = typeof data === "string" ? JSON.parse(data) : data;

                return (
                  <div key={id} className="additional_info_span">
                    <p className="additional_info">{commentData.comment}</p>

                    <div className="d-flex align-items-center">
                      <Avatar
                        dataTestId="avatar"
                        className="avatar-dash avatar-initials"
                        image={actor.avatar_url}
                        initials={generateUserInitials(actor)}
                      />
                      <div>
                        <h6 className="commenter-name mb-0">{actor?.display_name || ""}</h6>
                        <p className="comment-date mb-0">
                          {moment.utc(timestamp).format("MMM DD, YYYY, hh:mm A")}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .header {
    margin-bottom: 11px;
  }

  input {
    background-color: #ffffff;
    border: 1px solid #e3e9f2;
    border-radius: 4px;
    padding: 10px 16px;
    height: 40px;

    ::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #7f8c9f;
    }
  }

  .add-comment-btn {
    font-weight: 500;
    height: 40px;
    max-width: 136px;
    width: 100%;
    margin-left: 12px;

    svg path {
      fill: #ffffff;
    }
  }

  .additional_info {
    font-style: italic;
    margin-left: 1em;
    margin-top: 0.4em;
    margin-bottom: 12px;
    color: #151a30;

    + div.d-flex {
      margin-left: 1em;
    }

    &_span {
      width: 100%;
      position: relative;
      display: table;
      margin-top: 18px;

      :before {
        width: 4px;
        position: absolute;
        content: "";
        height: 100%;
        background: #062e64;
        margin-right: 8px;
      }

      .avatar {
        width: 32.24px;
        height: 32px;
      }

      .comment {
        &er-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #151a30;
        }

        &-date {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #7f8c9f;
        }
      }
    }
  }
`;

Comments.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    project: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  }),
};

export default Comments;
