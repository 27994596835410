/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Iban from "../../../components/Payment/Iban";
import Payoneer from "../../../components/Payment/Payoneer";
import DollarAccount from "../../../components/Payment/DollarAccount";
import Progress from "../../../components/Progress";
import {
  fetchIban,
  fetchPaymentProviders,
  fetchPayoneer,
  fetchDollarAccount,
} from "../../../redux/actions/PaymentActions";
import { fetchProjects } from "../../../redux/actions/ProjectActions";

const col = "col-lg-4 col-md-4 col-sm-12";

const Payment = ({ fromSetup = false }) => {
  const dispatch = useDispatch();

  const { isMakingRequest } = useSelector(({ Payments }) => Payments);
  const { isMakingRequest: isMakingProjectRequest, projects } = useSelector(
    ({ Projects }) => Projects,
  );

  const userOnEURProject = projects?.results?.[0]?.currency === "EUR";

  useEffect(() => {
    dispatch(fetchPaymentProviders());
    dispatch(fetchIban());
    dispatch(fetchDollarAccount());
    dispatch(fetchPayoneer());
    dispatch(
      fetchProjects({
        category: "dedicated",
        stage: "active",
        archived: "False",
        show_all: true,
      }),
    );
  }, []);

  if (!fromSetup && (isMakingRequest.fetchDollarAccount || isMakingRequest.getPayoneer)) {
    return <Progress />;
  }

  return (
    <>
      {!isMakingProjectRequest?.list && (
        <Info className="info">
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 9H13V7H11V9ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM11 17H13V11H11V17Z"
              fill="#F6A323"
            />
          </svg>

          <div>
            <h5 className="mb-1">
              Get ready for action! You&apos;re on a {userOnEURProject ? "Euro" : "US Dollar"}{" "}
              project.
            </h5>
            <p className="m-0">
              Set up your payment method to align with your project&apos;s currency and receive your
              payments seamlessly.
            </p>
          </div>
        </Info>
      )}

      <PaymentCard className="payout-list">
        <div className="d-flex align-items-start">
          <div className="payment-header">
            <h4>Setup a payout methods</h4>
            <p className="m-0">
              {fromSetup
                ? "How do you like to get paid?"
                : "All payouts will be sent to your default method"}
            </p>
          </div>
        </div>

        <div className="row payment-row">
          <div className={`${col} bs-border-right`}>
            <Payoneer />
          </div>

          <div className={`${col} bs-border-right`}>
            <Iban />
          </div>

          <div className={col}>
            <DollarAccount />
          </div>
        </div>
      </PaymentCard>
    </>
  );
};

const Info = styled.div`
  display: flex;
  column-gap: 16px;
  border-radius: 6px;
  background-color: rgba(235, 143, 0, 0.05) !important;
  padding: 8px 16px 10px !important;
  max-width: 798px;
  margin: unset !important;
  margin-bottom: 40px !important;

  h5,
  p {
    font-size: 15px;
    color: #eb8f00;
  }

  h5 {
    font-weight: 500;
  }
`;

const PaymentCard = styled.div`
  border-radius: 8px;
  background-color: #ffffff !important;
  border: 2px solid #edf1f7 !important;
  padding: 24px 40px 34px !important;

  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #151a30;
  }

  a {
    box-shadow: none !important;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    color: #3e4857;
  }

  .payment {
    &-header {
      flex: 1;
    }

    &-row {
      padding-top: 2.635rem;
    }
  }

  .bs-border {
    &-right {
      border-right: 1px solid #ddd;
    }

    &-bottom {
      border-bottom: 1px solid #ddd;
    }
  }
`;

export default React.memo(Payment);
