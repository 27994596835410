/* eslint-disable no-underscore-dangle */
import moment from "moment";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Icon from "../../../../../components/Icon";
import { FILTER_PROJECT_REPORTS } from "../../../../../configs/constants/ActionTypes";
import { isClient } from "../../../../../utils/auth";
import { StyledDateTimePicker } from "../../../../../utils/styles";
import { trackEvent } from "../../../../../analytics/segment";
import { CATEGORIES, EVENTS } from "../../../../../analytics/events";
import usePrevious from "../../../../../hooks/usePrevious";

const SidebarFilter = () => {
  const _isClient = isClient();
  const pendingFilter = _isClient ? "pending,overdue" : "pending";

  const dispatch = useDispatch();

  const { filters, project } = useSelector(({ Projects }) => Projects);
  const { participation } = project || {};

  const [filterValues, setFilterValues] = useState(filters);
  const prevFilterValues = usePrevious(filterValues);

  const updateFiltersState = (key, newState) => {
    setFilterValues((prev) => ({
      ...prev,
      [key]: {
        ...newState,
      },
    }));
  };

  const filterProjectReports = (filtersParam) => {
    dispatch({
      type: FILTER_PROJECT_REPORTS,
      filters: filtersParam,
    });

    trackEvent(EVENTS.filter_project_report, {
      project_type: project.stage === "active" ? project.category : project.stage,
      project_id: project.id,
      project_name: project.title,
      event_category: CATEGORIES.projects,
      user: Object.keys(filtersParam?.checkedUsers || {}).length !== 0,
      status: Object.keys(filtersParam?.checkedStatus || {}).join(","),
      duration: Object.keys(filtersParam?.duration || {}).length !== 0,
    });
  };

  const handleChange = (event, data, group) => {
    if (group === "users") {
      if (event.target.checked) {
        const newState = {
          ...filterValues.checkedUsers,
          [event.target.name]: {
            id: data.id,
            name: data.display_name,
            status: event.target.checked,
          },
        };

        updateFiltersState("checkedUsers", newState);
      } else {
        const newState = { ...filterValues.checkedUsers };
        delete newState[event.target.name];
        updateFiltersState("checkedUsers", newState);
      }
    } else if (event.target.checked) {
      const newState = {
        ...filterValues.checkedStatus,
        [event.target.name]: {
          name: data.display_name,
          status: event.target.checked,
        },
      };
      updateFiltersState("checkedStatus", newState);
    } else {
      const newState = { ...filterValues.checkedStatus };
      delete newState[event.target.name];

      updateFiltersState("checkedStatus", newState);
    }
  };

  /* istanbul ignore next */
  const onChangeValue = (key, value) => {
    const newState = {
      ...filterValues.duration,
      [key]: value,
    };

    updateFiltersState("duration", newState);
  };

  const toggleDropdown = (event) => {
    event.target.classList.toggle("closed");
    const dropdownContent = event.target.nextElementSibling;
    if (dropdownContent.style.display === "none") {
      dropdownContent.style.display = "block";
    } else {
      dropdownContent.style.display = "none";
    }
  };

  useEffect(() => {
    if (!isEqual(filterValues, prevFilterValues)) {
      filterProjectReports(filterValues);
    }
  }, [JSON.stringify(filterValues)]);

  useEffect(() => {
    setFilterValues(filters);
  }, [filters]);

  return (
    <>
      {filters.showUsers && (
        <Dropdown>
          <button type="button" className="btn" onClick={(e) => toggleDropdown(e)}>
            Users <Icon name="rounded-keyboard-arrow-down" />
          </button>

          <div className="dropdown-content">
            {participation
              ?.filter(({ status }) => status === "accepted")
              .map((participant, idx) => (
                <ContainerCheckbox key={`participant-${idx}`}>
                  {participant.user.display_name}
                  <input
                    type="checkbox"
                    name={participant.user.id}
                    checked={
                      filterValues.checkedUsers[participant.user.id]
                        ? filterValues.checkedUsers[participant.user.id].status
                        : false
                    }
                    onChange={(e) => handleChange(e, participant.user, "users")}
                  />
                  <span className="checkmark" />
                </ContainerCheckbox>
              )) || []}
          </div>
        </Dropdown>
      )}

      <Dropdown>
        <button type="button" className="btn" onClick={(e) => toggleDropdown(e)}>
          Status <Icon name="rounded-keyboard-arrow-down" />
        </button>

        <div className="dropdown-content">
          {!_isClient && (
            <ContainerCheckbox>
              Overdue
              <input
                type="checkbox"
                name="overdue"
                checked={
                  filterValues.checkedStatus.overdue
                    ? filterValues.checkedStatus.overdue.status
                    : false
                }
                onChange={(e) => handleChange(e, { display_name: "Overdue" }, "status")}
              />
              <span className="checkmark" />
            </ContainerCheckbox>
          )}
          <ContainerCheckbox>
            Completed
            <input
              type="checkbox"
              name="completed"
              checked={
                filterValues.checkedStatus.completed
                  ? filterValues.checkedStatus.completed.status
                  : false
              }
              onChange={(e) => handleChange(e, { display_name: "Completed" }, "status")}
            />
            <span className="checkmark" />
          </ContainerCheckbox>
          <ContainerCheckbox>
            Pending
            <input
              type="checkbox"
              name={pendingFilter}
              checked={
                filterValues.checkedStatus[pendingFilter]
                  ? filterValues.checkedStatus[pendingFilter].status
                  : false
              }
              onChange={(e) => handleChange(e, { display_name: "Pending" }, "status")}
            />
            <span className="checkmark" />
          </ContainerCheckbox>
          {/* <ContainerCheckbox>
            Missed
            <input
              type="checkbox"
              name="missed"
              checked={checkedStatus.missed ? checkedStatus.missed.status : false}
              onChange={(e) => handleChange(e, { display_name: "Missed" }, "status")}
            />
            <span className="checkmark" />
          </ContainerCheckbox> */}
        </div>
      </Dropdown>
      <Dropdown>
        <button type="button" className="btn" onClick={(e) => toggleDropdown(e)}>
          Due Dates <Icon name="rounded-keyboard-arrow-down" />
        </button>

        <div className="dropdown-content">
          <span>From</span>
          <StyledDateTimePicker
            $calendar
            dropUp={!_isClient}
            $time={false}
            className="tg-date-field"
            format="DD MMM YYYY"
            max={
              filterValues.duration.end
                ? new Date(filterValues.duration.end)
                : new Date(2099, 11, 31)
            }
            value={filterValues.duration.start ? new Date(filterValues.duration.start) : null}
            onChange={
              /* istanbul ignore next */
              (date) => {
                onChangeValue("start", moment(date).format());
              }
            }
            required
          />
          <span>To</span>
          <StyledDateTimePicker
            dropUp
            $calendar
            $time={false}
            className="tg-date-field"
            format="DD MMM YYYY"
            value={filterValues.duration.end ? new Date(filterValues.duration.end) : null}
            min={filterValues.duration.start ? new Date(filterValues.duration.start) : null}
            onChange={
              /* istanbul ignore next */
              (date) => {
                onChangeValue("end", moment(date).format());
              }
            }
            required
          />
          <button
            type="button"
            className="btn clear text-capitalize"
            onClick={() => updateFiltersState("duration", { start: null, end: null })}
          >
            Clear Dates
          </button>
        </div>
      </Dropdown>
    </>
  );
};

const Dropdown = styled.div`
  &:first-of-type {
    button,
    h3 {
      padding-top: 0px;
    }
  }

  button,
  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: #8f9bb3;
    text-transform: uppercase;
    cursor: pointer;
    padding: 16px 0;
    border-bottom: 1px solid #edf1f7;
    margin-bottom: 0px;
    width: 100%;
    text-align: left;
    border: none;

    i:not(.tg-ic-calendar2) {
      font-size: 10px;
      float: right;
    }

    &.closed {
      i {
        transform: rotate(180deg);
      }
    }
  }
  .dropdown-content {
    padding: 16px 0;
    font-size: 16px;
    line-height: 21px;
    color: #3e4857;

    > span {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #3e4857;
      margin-bottom: 8px;
      display: block;
    }

    > button.clear {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #da3451;
      margin-bottom: 24px;
      cursor: pointer;
      width: fit-content;
    }
  }
`;

const ContainerCheckbox = styled.label`
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 16px;
  cursor: pointer;
  font-size: 16px;
  line-height: 21px;
  color: #3e4857;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 18px;
    width: 18px;
    border: 2px solid #8f9bb3;
    border-radius: 3px;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: rgba(143, 155, 179, 0.1);
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: #fff;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 11px;
    border: solid #8f9bb3;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export default SidebarFilter;
