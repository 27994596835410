import React from "react";
import PropTypes from "prop-types";

const ArrowRight = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="10"
        height="18"
        viewBox="0 0 10 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1.5L8.5 9L1 16.5"
          stroke="#7F8C9F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

ArrowRight.propTypes = {
  className: PropTypes.string,
};
export default ArrowRight;
