/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";
import { Table } from "react-bootstrap";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import DateTimePicker from "../components/DateTimePicker";
import Check from "../assets/images/icons/checkbox-check.svg";
import InputGroup from "../components/InputGroup";

export const StyledForm = styled.form`
  .tag-input {
    .selected {
      .selected-item {
        display: block;
        width: 100%;
        height: 40px;
        margin-top: 0;
      }
    }
  }

  label {
    color: #151a30;
    margin-bottom: 0.5rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .form-group {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 14px;

    label {
      color: #151a30;
      margin-bottom: 0.5rem;
    }

    textarea {
      min-height: 100px;
    }

    input,
    textarea,
    select {
      font-size: 16px;
      color: #8f9bb3;
      background: #ffffff;
      border: 1px solid rgba(194, 204, 217, 0.5);
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: none;

      &::placeholder {
        color: #8f9bb3;
        font-weight: normal;
      }

      &.rw-input {
        padding-left: 1.25rem;
      }
    }

    .text {
      margin-top: 8px;
      color: #8f9bb3;
    }

    .input-group {
      box-shadow: none;

      .input-group-prepend .input-group-text {
        background-color: #fff;
        border-left: none;
        border-right: 1px solid rgba(194, 204, 217, 0.5);
        border-top: 1px solid rgba(194, 204, 217, 0.5);
        border-bottom: 1px solid rgba(194, 204, 217, 0.5);
        border-radius: 0 4px 4px 0;

        i {
          font-size: 14px;
        }
      }
    }

    .tg-date-field {
      margin-bottom: 0px;
    }

    &.footer {
      float: right;
      margin-top: 45px;
      margin-bottom: 0px;

      button {
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: none;
        margin-right: 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }

      button:first-child {
        border: 1px solid #3e4857;
        color: #3e4857;
        background-color: #fff;
      }

      button:last-child {
        color: #fff;
        background-color: #da3451;
        border-color: #da3451;
      }
    }
  }

  .add-more button {
    border: none;
    background: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #062e64;
    i {
      font-size: 16px;
      vertical-align: baseline;
      margin-right: 12px;
    }
  }

  .input-group {
    box-shadow: none;
    input {
      &.form-control {
        font-size: 16px;
        color: #8f9bb3;
        background: #ffffff;
        border: 1px solid rgba(194, 204, 217, 0.5);
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: none;
      }
      &::placeholder {
        color: #8f9bb3;
        font-weight: normal;
      }
    }

    .input-group-prepend .input-group-text {
      background-color: #fff;
      border-left: none;
      border-right: 1px solid rgba(194, 204, 217, 0.5);
      border-top: 1px solid rgba(194, 204, 217, 0.5);
      border-bottom: 1px solid rgba(194, 204, 217, 0.5);
      border-radius: 0 4px 4px 0;

      i {
        font-size: 14px;
      }
    }
  }
`;

export const StyledTable = styled(Table)`
  background: #ffffff;
  border: 1px solid #dee2e6;
  box-sizing: border-box;
  border-radius: 6px;
  /* table-layout: fixed; */
  border-collapse: initial;
  border-spacing: 0;
  overflow: hidden;

  thead tr {
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #8f9bb3;
    background: rgba(62, 72, 87, 0.05);
    border-bottom: 1px solid #edf1f7;
    box-sizing: border-box;
    border-radius: 4px 4px 0px 0px;

    th {
      vertical-align: middle;
      padding: 10px;
      border-bottom: 1px solid #edf1f7;
    }
    th:nth-last-child(2) {
      width: 150px;
    }
    th:last-child {
      padding-right: 24px;
    }
    th:first-child {
      padding-left: 24px;
    }
  }

  tbody tr {
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #3e4857;
    border-bottom: 1px solid #edf1f7;

    td {
      vertical-align: middle;
      padding: 16px 10px;
      width: auto;

      &.nowrap {
        white-space: "nowrap";
      }

      a,
      b {
        font-weight: 500;
        color: #3e4857;
        text-decoration: none;
      }

      > i.danger {
        color: #da3451;
        margin-left: 8px;
        vertical-align: baseline;
      }

      .avatar.avatar-dash {
        width: 35px;
        height: 35px;

        &.avatar-initials {
          font-size: 14px;
        }
      }
    }

    td:first-child {
      padding-left: 24px;
    }

    td:last-child {
      display: flex;
      padding-right: 24px;

      &.cta {
        display: table-cell;
        color: #3e4857;
        text-decoration: none;

        &:empty {
          width: fit-content;
        }
        div.cta-wrapper {
          display: flex;
          justify-content: flex-end;
          a {
            white-space: nowrap;
            align-self: center;
            padding-right: 16px;
            i {
              font-size: 15px;
              color: #3e4857;
              margin-left: 8px;
              vertical-align: middle;
            }
          }
          > button {
            align-self: center;
            padding-left: 5px;
            i {
              color: #3e4857;
            }
          }
        }
      }
    }

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      border-radius: 50px;
      padding: 6px 24px;

      &.pending {
        color: #8f9bb3;
        background: rgba(143, 155, 179, 0.05);
        border: 1px solid rgba(143, 155, 179, 0.04);
      }

      &.overdue {
        color: #eb5757;
        background: rgba(235, 87, 87, 0.04);
        border: 1px solid rgba(235, 87, 87, 0.04);
      }

      &.missed {
        color: #3e4857;
        background: rgba(62, 72, 87, 0.04);
        border: 1px solid rgba(62, 72, 87, 0.04);
      }

      &.completed {
        color: #219653;
        background: rgba(33, 150, 83, 0.04);
        border: 1px solid rgba(33, 150, 83, 0.04);
      }
    }
  }
`;

export const TableFooter = styled.div`
  td {
    display: table-cell !important;
    div {
      display: flex;
      font-size: 14px;
      line-height: 21px;
      justify-content: space-between;

      span {
        color: #3e4857;
        align-self: center;
        padding-left: 0px;
      }

      > nav {
        margin: 0 0 0 auto;
      }
    }
  }

  .page-item.active .page-link {
    background: rgb(6, 46, 100);
    border-color: rgb(6, 46, 100);
  }
  .page-link {
    color: rgb(62, 72, 87);
  }
  .page-link:hover {
    color: #a41e35;
  }
  .page-item.active .page-link {
    color: #fff;
  }
  .pagination {
    margin-top: 20px;
  }
  .flex-items {
    width: 100%;
    display: flex;
    justify-content: space-between;
    width: 79vw;
    background: #fff;
    align-items: center;
    padding: 15px;
  }

  select,
  select:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #dee2e6;
  }
`;

export const ContentSection = styled.div`
  padding-bottom: 60px;

  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
  .save-container {
    height: fit-content;
    display: flex;
    padding: 0px 40px 40px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .header {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #151a30;
    margin-bottom: 24px;
  }

  form {
    .control-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #151a30;
      margin-bottom: 8px;
    }

    input,
    textarea,
    select {
      background: #ffffff;
      border: 1px solid rgba(194, 204, 217, 0.25);
      box-sizing: border-box;
      box-shadow: none;
      border-radius: 4px;
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 24px;
      color: #3e4857;
    }

    .tag-input {
      .input-group {
        box-shadow: none;
        input {
          background: #ffffff;
          border: 1px solid rgba(194, 204, 217, 0.25);
          box-sizing: border-box;
          box-shadow: none;
          border-radius: 4px;
          margin-bottom: 16px;
          font-size: 16px;
          line-height: 24px;
          color: #3e4857;
          &::placeholder {
            padding: 8px 16px;
          }
        }
      }
    }

    .save {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;

      color: #ffffff;
      box-shadow: none;
      border: none;

      background: #da3451;
      border-radius: 4px;

      &:disabled {
        opacity: 0.5;
      }
    }
  }
`;

export const StyledDateTimePicker = styled(DateTimePicker)`
  &.tg-date-field {
    display: inline-block;
    border: 1px solid rgba(194, 204, 217, 0.25);
    box-shadow: initial;
    box-shadow: initial;
    width: 100%;
    margin-bottom: 24px;

    .rw-input {
      background-color: #fff;
      font-size: 16px;
      color: #3e4857;
      margin-bottom: 0;
      border-left: 1px solid rgba(194, 204, 217, 0.5);
      border-bottom: 1px solid rgba(194, 204, 217, 0.5);
      border-top: 1px solid rgba(194, 204, 217, 0.5);
      box-shadow: none;
    }

    .rw-select {
      background-color: #fff;
      border-left: none;
      border-right: 1px solid rgba(194, 204, 217, 0.5);
      border-top: 1px solid rgba(194, 204, 217, 0.5);
      border-bottom: 1px solid rgba(194, 204, 217, 0.5);
      width: 2.9em;
      button.rw-btn {
        color: #8f9bb3;
        width: 2.6em;
        font-size: 16px;
      }
    }

    .rw-calendar .rw-cell.rw-state-selected {
      color: #fff;
      background-color: #da3451 !important;
      border-color: #da3451;

      &:active,
      &:focus,
      &:hover {
        color: #fff;
        background-color: #da3451 !important;
        border-color: #da3451;
      }
    }
  }
`;

export const CustomCheckboxWrapper = styled.div`
  &.custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  .custom-control {
    &-label {
      position: relative;
      margin-bottom: 0 !important;
      vertical-align: top;

      :after,
      :before {
        position: absolute;
        top: 0.25rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        content: "";
      }

      :before {
        border-radius: 0.25rem;
        pointer-events: none;
        background-color: #f8f8f8;
        border: 0 solid #adb5bd;
        box-shadow: 0 2px 4px 0 hsl(0deg 0% 80% / 50%);
      }

      :after {
        background: 50%/50% 50% no-repeat;
      }
    }

    &-input {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 1rem;
      height: 1.25rem;
      opacity: 0;

      :disabled ~ .custom-control-label,
      &[disabled] ~ .custom-control-label {
        color: #6c757d;

        :before {
          background-color: #e9ecef;
        }
      }

      :focus {
        :not(:checked) ~ .custom-control-label:before {
          border-color: #eea0ad;
        }

        ~ .custom-control-label:before {
          box-shadow: 0 2px 4px 0 hsl(0deg 0% 80% / 50%), 0 0 0 0.2rem rgb(0 158 255 / 30%);
        }
      }

      :checked ~ .custom-control-label {
        :before {
          color: #fff;
          border-color: #da3451;
          background-color: #da3451;
        }
        :after {
          background-image: url("${Check}");
        }
      }
    }
  }
`;

export const CustomSelector = styled.div`
  position: relative;
  select {
    padding-right: 35px;
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;

    font-size: 16px;
    color: #8f9bb3;
    background: #ffffff;
    border: 1px solid rgba(194, 204, 217, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: none;
    margin-left: 16px;
    width: 85px;
  }

  select::-ms-expand {
    display: none;
  }

  i {
    font-size: 10px;
    margin: auto;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #8f9bb3;

    &.select-icons {
      top: 40%;
    }
  }
`;

export const WordCount = styled.span`
  right: 20px;
  bottom: 2px;
  font-size: 12px;
`;

export const StyledSearchInput = styled(InputGroup)`
  box-shadow: none;
  border: 1px solid rgb(237, 241, 247);
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 18px;
  padding-left: 4px;

  .input-group-prepend .input-group-text {
    background-color: rgb(255, 255, 255);
    color: rgb(62, 72, 87);
    padding: 0 10px;
    font-size: 16px;
    height: initial;
    border: none;
  }

  > div {
    margin: 0;

    input {
      padding: 0 10px;
      border-radius: 4px;
      border: none;

      ::-ms-clear {
        display: none;
        width: 0;
        height: 0;
      }
      ::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
      }
      /* clears the ‘X’ from Chrome */
      ::-webkit-search-decoration,
      ::-webkit-search-cancel-button,
      ::-webkit-search-results-button,
      ::-webkit-search-results-decoration {
        display: none;
      }

      &:focus {
        border: none !important;
      }
    }
  }

  .search-close-btn {
    display: flex;
    align-items: center;
    padding: 0;
    color: rgb(143, 155, 179);
    font-size: 16px;
  }
`;
