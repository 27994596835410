/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { Col, FormGroup, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { object, string } from "yup";
import { isEqual } from "lodash";

/* -------------------------------------------------------------------------- */
/*                             Internal Dependency                            */
/* -------------------------------------------------------------------------- */
import FieldError from "../../../components/FieldError";
import Input from "../../../components/Input";
import Error from "../../../components/Error";
import { ContentSection } from "../settings/PersonalDetails";
import { getUser, isClient } from "../../../utils/auth";
import Button from "../../../components/Button";
import CountrySelector from "../../../components/CountrySelector";
import { updateOnboardingStage, updateProfile } from "../../../redux/actions/ProfileActions";
import {
  ONBOARDING_FINISH,
  ONBOARDING_STAGE_ONE,
  ONBOARDING_STAGE_THREE,
  ONBOARDING_STAGE_TWO,
} from "../../../configs/constants/ActionTypes";
import { countryListEU, onboardingRouteLinks } from "../../../configs/constants/global";
import { useOnboardingContext } from "../../../context/OnboardingContext";

const StepTwo = () => {
  const user = getUser();
  const { userData } = useOnboardingContext();

  const dispatch = useDispatch();

  const { onBoardingStage, errors } = useSelector(({ Profile }) => Profile);

  const dataSource = user.is_project_owner ? user.company : user.profile;

  const form = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      street: dataSource?.street || "",
      city: dataSource?.city || "",
      country: dataSource?.country || "",
      plot_number: dataSource?.plot_number || "",
      postal_code: dataSource?.postal_code || "",
      vat_number: dataSource?.vat_number || "",
      name: dataSource?.name || "",
    },
    validationSchema: object().shape({
      street: string().required("Street is required"),
      plot_number: string().required("Plot number is required"),
      postal_code: string().required("Postal code is required"),
      city: string().required("City is required"),
      country: string().required("Country is required"),
      name: user.is_project_owner
        ? string().required("Company is required")
        : string().notRequired(),
      vat_number: user.is_project_owner
        ? string()
            .required("VAT is required for countries in EU regions")
            .when("country", (country) => {
              const countryNotInEU = countryListEU.findIndex(({ code }) => country === code) === -1;

              if (countryNotInEU) return string().notRequired();

              return string().required("VAT is required for countries in EU regions");
            })
        : string().notRequired(),
    }),
    onSubmit: (formData) => {
      const reqData = {
        ...userData,
        ...formData,
      };

      if (user.is_project_owner) {
        // Clients get a company object
        reqData.name = userData?.company || reqData?.name;
        reqData.tel_number = userData.phone_number;

        if (isEqual(reqData, user)) {
          dispatch(updateProfile(null, {}, ONBOARDING_FINISH, true));
        } else {
          dispatch(updateProfile(user?.company?.id, reqData, ONBOARDING_FINISH, true));
        }
      } else {
        // Other users get only a company name
        reqData.company = userData.company;

        dispatch(updateProfile(user?.profile?.id, reqData, ONBOARDING_STAGE_THREE));
      }
    },
  });

  useEffect(() => {
    if (errors?.profile) {
      form.setSubmitting(false);
    }
  }, [errors?.profile]);

  if (onBoardingStage.stage !== ONBOARDING_STAGE_TWO) {
    return <Redirect to={onboardingRouteLinks[onBoardingStage.stage]} />;
  }

  return (
    <form onSubmit={form.handleSubmit} className="clearfix">
      <Wrapper>
        {errors && errors?.profile && (
          <Error message={errors.profile || "Sorry, an error occurred. Please try again."} />
        )}

        <div className="form__onboard">
          <Row>
            <Col>
              <FormGroup className="mb-3">
                <label htmlFor="street">
                  Street
                  <span className="label-style">*</span>
                </label>
                <Input
                  id="street"
                  name="street"
                  type="text"
                  className="form-input mb-0 py-0"
                  placeholder="Enter street"
                  value={form.values.street}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  required
                />
                {form.errors.street && form.touched.street && (
                  <FieldError message={form.errors.street} />
                )}
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup className="mb-3">
                <label htmlFor="plot_number">
                  Number/Plot
                  <span className="label-style">*</span>
                </label>
                <Input
                  id="plot_number"
                  name="plot_number"
                  type="text"
                  className="form-input mb-0 py-0"
                  placeholder="Enter number/plot"
                  value={form.values.plot_number}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  required
                />
                {form.errors.plot_number && form.touched.plot_number && (
                  <FieldError message={form.errors.plot_number} />
                )}
              </FormGroup>
            </Col>

            <Col lg={3}>
              <FormGroup className="mb-3">
                <label htmlFor="postal_code">
                  Zip code
                  <span className="label-style">*</span>
                </label>
                <Input
                  className="form-input mb-0 py-0"
                  placeholder="Enter postal code"
                  type="text"
                  id="postal_code"
                  name="postal_code"
                  value={form.values.postal_code}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  required
                />
                {form.errors.postal_code && form.touched.postal_code && (
                  <FieldError message={form.errors.postal_code} />
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <FormGroup className="mb-3">
                <label htmlFor="city">
                  City
                  <span className="label-style">*</span>
                </label>
                <Input
                  className="form-input mb-0 py-0"
                  placeholder="Enter city"
                  id="city"
                  name="city"
                  type="text"
                  value={form.values.city}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  required
                />
                {form.errors.city && form.touched.city && <FieldError message={form.errors.city} />}
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup className="mb-3">
                <label htmlFor="country">
                  Country
                  <span className="label-style">*</span>
                </label>

                <CountrySelector
                  className="mb-0"
                  id="country"
                  value={form.values.country}
                  onBlur={() => form.setFieldTouched("country", true)}
                  onChange={(country) => form.setFieldValue("country", country)}
                  required
                />
                {form.errors.country && form.touched.country && (
                  <FieldError message={form.errors.country} />
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            {user.is_project_owner && (
              <Col lg={6}>
                <FormGroup className="mb-3">
                  <label htmlFor="name">
                    Company
                    <span className="label-style">*</span>
                  </label>
                  <Input
                    className="form-input mb-0 py-0"
                    placeholder="Enter company"
                    id="name"
                    type="text"
                    name="name"
                    value={form.values.name}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                  />
                  {form.errors.name && form.touched.name && (
                    <FieldError message={form.errors.name} />
                  )}
                </FormGroup>
              </Col>
            )}
            {isClient() && (
              <Col lg={6}>
                <FormGroup className="mb-3">
                  <label htmlFor="vat_number">VAT (optional for non European clients)</label>
                  <Input
                    className="form-input mb-0 py-0"
                    placeholder="Enter VAT"
                    id="vat_number"
                    name="vat_number"
                    type="text"
                    value={form.values.vat_number}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                  />
                  {form.errors.vat_number && form.touched.vat_number && (
                    <FieldError message={form.errors.vat_number} />
                  )}
                </FormGroup>
              </Col>
            )}
          </Row>
        </div>
      </Wrapper>

      <div className="content-nav">
        <div className="w-100 d-flex justify-content-between">
          <PreviousBtn
            className="btn"
            type="button"
            onClick={() => dispatch(updateOnboardingStage(ONBOARDING_STAGE_ONE))}
          >
            Previous Step
          </PreviousBtn>

          <Button
            disabled={
              form.isSubmitting ||
              !form.isValid ||
              !form.values.street.trim() ||
              !form.values.plot_number.trim() ||
              !form.values.postal_code.trim() ||
              !form.values.city.trim() ||
              !form.values.country.trim() ||
              (user.is_project_owner && !form.values.name.trim())
            }
            className="next btn"
            type="submit"
          >
            {form.isSubmitting ? "Loading" : isClient() ? "Finish" : "Next"}
          </Button>
        </div>
      </div>
    </form>
  );
};

const Wrapper = styled(ContentSection)`
  padding: 2.3rem;
  max-width: unset;

  .label-style {
    color: #da3451;
    padding-left: 2px;
  }

  .header {
    margin-top: 3rem;
  }

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #151a30;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .form-input {
    box-shadow: none !important;

    input {
      background: #ffffff;
      border: 1px solid rgba(194, 204, 217, 0.25);
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 14px;
      padding: 15px !important;
      margin-bottom: 32px;
    }
  }
`;

const PreviousBtn = styled.button`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #062e64;
  align-self: center;
`;

export default StepTwo;
