/* eslint-disable react/jsx-props-no-spreading */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import { DateTimePicker as DTP } from "react-widgets";
import moment from "moment/moment";
import MomentLocalizer from "react-widgets-moment";
import styled from "styled-components";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import Icon from "./Icon";

// eslint-disable-next-line no-new
new MomentLocalizer(moment);

const DateTimePicker = (props) => {
  return (
    <Wrapper>
      <DTP {...props} selectIcon={<Icon name="calendar2" />} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .rw-widget {
    border: 1px solid rgba(194, 204, 217, 0.5);
    border-radius: 4px;
    .rw-widget-container {
      grid-template: 1fr/1fr 2.3em;
      border: none;
      input,
      button {
        border: none !important;
      }
      input {
        width: inherit;
      }
      button {
        color: rgb(143, 155, 179);
        font-size: 16px;
        width: initial;
        display: flex;
        justify-content: center;
      }
    }
  }
`;

export default DateTimePicker;
