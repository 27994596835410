import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import styled, { createGlobalStyle, withTheme } from "styled-components";

import Icon from "../components/Icon";
import Progress from "../components/Progress";
import SurveyModalContent from "../pages/Dashboard/projects/projectDetail/components/SurveyContent";
import ClientSurvey from "../pages/Dashboard/projects/projectDetail/Reports/Survey/ClientSurvey";
import DeveloperSurvey from "../pages/Dashboard/projects/projectDetail/Reports/Survey/DeveloperSurvey";
import DevPMReport from "../pages/Dashboard/projects/projectDetail/Reports/Survey/DevPMReport";
import { fetchProject, retrieveProgressEvent } from "../redux/actions/ProjectActions";
import { createModal } from "../utils/modals";

const GlobalStyle = createGlobalStyle`
  body {
    background: #f8f8f8;
  }
`;

const ReportCreateLayout = () => {
  const { url, params } = useRouteMatch();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { progress_event, isSaved, isMakingRequest } = useSelector(({ Projects }) => Projects);

  const renderSurvey = (type) => {
    if (!type) return null;

    switch (type) {
      case "developer_rating":
        return <ClientSurvey />;

      case "developer_survey":
        return <DeveloperSurvey />;

      default:
        return <DevPMReport />;
    }
  };

  useEffect(() => {
    dispatch(retrieveProgressEvent(params.report));
  }, []);

  useEffect(() => {
    if (progress_event.project) {
      dispatch(fetchProject(progress_event.project.id));
    }
  }, [progress_event]);

  useEffect(() => {
    if (!isModalOpen && (isSaved.progressReport || isSaved.updateProgressEventQuestion)) {
      const modal = createModal({
        body: <SurveyModalContent returnUrl={url.split("/").splice(0, 5).join("/")} />,
        header: null,
        canClose: false,
        options: {
          title: "",
          className: "modal-report modal-survey",
          hideActions: true,
        },
      });
      modal.open();
      setIsModalOpen(true);
    }
  }, [isSaved?.progressReport, isSaved?.updateProgressEventQuestion]);

  return (
    <Wrapper>
      <GlobalStyle />
      <div className="layout-navbar">
        <Nav>
          <div className="nav-content">
            <span className="layout-close">
              <Link to={url.split("/").splice(0, 5).join("/")}>
                <Icon name="x-circle" />
                Close
              </Link>
            </span>

            <h5>{progress_event.title}</h5>
          </div>
        </Nav>
      </div>

      {isMakingRequest?.retrieveProgressEvent === "undefined" ||
      isMakingRequest?.retrieveProgressEvent ? (
        <Progress />
      ) : (
        renderSurvey(progress_event.type)
      )}
    </Wrapper>
  );
};

const Wrapper = withTheme(styled.div`
  display: block;
  width: 100%;
  margin-top: 73px;
  overflow-y: scroll;

  &:hover::-webkit-scrollbar {
    visibility: visible;
  }

  &::-webkit-scrollbar {
    visibility: hidden;
  }

  ${(props) => props.theme.mixins.scrollbar}

  .layout-navbar {
    display: block;
    background: #fff;
    box-shadow: 0 2px 4px 0 hsla(0, 0%, 80%, 0.5);
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    z-index: 999;
  }
`);

const Nav = withTheme(styled.div`
  .nav-content {
    padding: 24px 40px;

    h5 {
      font-weight: 500;
      font-size: ${(props) => props.theme.functions.pxToRem(20)};
      line-height: ${(props) => props.theme.functions.pxToRem(24)};
      text-align: center;
      color: ${(props) => props.theme.colors["text-black"]};
      margin: 0;
    }

    .layout-close {
      float: left;
      a {
        font-weight: 500;
        font-size: ${(props) => props.theme.functions.pxToRem(16)};
        line-height: ${(props) => props.theme.functions.pxToRem(19)};
        color: ${(props) => props.theme.colors.gray};
      }
    }

    .layout-close a {
      text-decoration: none;
      i {
        font-size: ${(props) => props.theme.functions.pxToRem(20)};
        vertical-align: text-bottom;
        margin-right: 10px;
      }
    }
  }
`);

export default ReportCreateLayout;
