/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";

/* -------------------------------------------------------------------------- */
/* -------------------------- Internal Dependencies ------------------------- */
/* -------------------------------------------------------------------------- */
import IconButton from "../../../../../components/IconButton";
import { HeaderActions, StyledModalHeader } from "../../../../../components/ModalHeader";

const Header = ({ dismiss, created_at, title }) => {
  return (
    <ModalHeader>
      <h3>
        {title} <span>- {moment(created_at).format("DD MMM YYYY")}</span>
      </h3>

      <HeaderActions className="actions">
        <IconButton data-testid="dismissBtn" name="close" onClick={() => dismiss()} />
      </HeaderActions>
    </ModalHeader>
  );
};

const ModalHeader = styled(StyledModalHeader)`
  border-bottom: 1px solid #edf1f7;
  padding-top: 25px !important;
`;

const ModelHeaderPropTypes = {
  dismiss: PropTypes.func,
  created_at: PropTypes.oneOfType([PropTypes.instanceOf(Date)]),
  title: PropTypes.string,
};

Header.propTypes = ModelHeaderPropTypes;

export default Header;
