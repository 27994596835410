/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useState, useCallback, useMemo, Fragment } from "react";
import { debounce, isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import InputGroup from "../InputGroup";
import OverlayTooltip from "../OverlayTooltip";
import useDidUpdate from "../../hooks/useDidUpdate";
import usePrevious from "../../hooks/usePrevious";
import randomString from "../../utils/generateRandomString";
import { isDesigner } from "../../utils/auth";
import { getSkills as getSkillsAction } from "../../redux/actions/SkillAction";
import Star from "../../assets/images/icons/bi_star.svg";
import StarFilled from "../../assets/images/icons/bi_star_filled.svg";
import XIcon from "../../assets/images/icons/x.svg";

const isADesigner = { language: "Design skills", platform: "Design tools" };
const isNotADesigner = {
  language: "Programming languages",
  framework: "Frameworks",
  platform: "Platforms",
  library: "Libraries",
  storage: "Storage engines",
};

const defaultError = {
  oneSkill: "",
  duplicateSkill: "",
};

const PrioritySkillSelector = ({
  className,
  size,
  prepend,
  placeholder,
  selected,
  suggestedSkills,
  setSuggestedSkills,
  onChange,
  selectionKey: key,
}) => {
  const dispatch = useDispatch();

  const { skills } = useSelector(({ Skill }) => Skill);

  const [filters] = useState({
    ...(!isDesigner() ? isNotADesigner : isADesigner),
    other: "Miscellaneous",
  });

  const [prevKey] = useState(null);
  const [selectionKey] = useState(key || randomString.generate());
  const [selectedSkill, setSelectedSkill] = useState(selected);

  const [search, setSearch] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [error, setError] = useState(defaultError);

  const prevSearch = usePrevious(search);
  const prevSelectedSkill = usePrevious(selectedSkill);

  const searchKey = useMemo(() => `${selectionKey}-${search}`, [selectionKey, search]);

  const onSelectSkill = (e, skillType, skill) => {
    e.preventDefault();

    if (!selectedSkill[skillType]) selectedSkill[skillType] = [];

    const skillsToUpdate = selectedSkill[skillType];

    const isContainedSkill = skillsToUpdate.some((itm) => itm.name === skill);

    if (!isContainedSkill) {
      setSearch("");
      setShowSuggestions(false);
      setSelectedSkill((prev) => {
        const newSkill = { ...prev };

        const skillSection = [
          ...newSkill[skillType],
          { name: skill, primary: false, years_of_experience: "", type: skillType },
        ];

        newSkill[skillType] = skillSection;
        return newSkill;
      });
      setError(defaultError);
    } else {
      setError({
        ...error,
        duplicateSkill: `${skill} is already a skill`,
      });
    }
  };

  const getSkills = useCallback(
    debounce((filter) => {
      dispatch(getSkillsAction(filter, searchKey, prevKey));
    }, 300),
    [searchKey, prevKey],
  );

  const checkMaxPrimary = (skillType) => {
    let maxPrimary = 0;

    selectedSkill[skillType]?.forEach((skill) => {
      if (skill.primary) {
        maxPrimary += 1;
      }
    });
    return maxPrimary;
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // NOTE: This is to prevent the form from submitting when the user presses enter
      // also disables creating of a new skill when the user presses enter
    }
  };

  const handleChange = (e) => {
    setError(defaultError);
    const skill = e.target.value;
    setSearch(skill);
    setShowSuggestions(!!skill);
  };

  const onRemoveSkill = (e, skillType, index) => {
    e.preventDefault();

    setSelectedSkill((prev) => {
      const newSkill = { ...prev };
      const splicedItem = newSkill[skillType].filter((_, idx) => idx + 1 !== index);

      if (splicedItem) {
        newSkill[skillType] = splicedItem;
        return newSkill;
      }

      return newSkill;
    });
  };

  const markAsPrimary = (e, skillType, skill) => {
    e.preventDefault();

    if (skill.primary || checkMaxPrimary(skillType) >= 5) return;

    setSelectedSkill((prev) => {
      const newSkill = { ...prev };
      const { name, primary } = skill;
      const filteredSkills = newSkill[skillType].map((item) =>
        item.name === name
          ? {
              ...item,
              primary: !primary,
            }
          : item,
      );

      newSkill[skillType] = filteredSkills;
      return { ...prev, ...newSkill };
    });
  };

  const onAddYearsOfExperience = (e, skillType, skill) => {
    setSelectedSkill((prev) => {
      const newSkill = { ...prev };

      const filteredSkills = newSkill[skillType].map((item) =>
        item.name === skill.name
          ? {
              ...item,
              years_of_experience: e.target.value,
            }
          : item,
      );

      newSkill[skillType] = filteredSkills;
      return { ...prev, ...newSkill };
    });
  };

  const handleAddSuggestion = () => {
    const sSkills = [...suggestedSkills];
    const skillPresent =
      sSkills.findIndex((prev) => prev.toLowerCase() === search.toLowerCase()) !== -1;

    if (skillPresent) {
      setError({ duplicateSkill: "Duplicate Skill. Skill has already been suggested" });
    } else {
      setSuggestedSkills((prev) => [...prev, search]);
      setSearch("");
      setShowSuggestions(false);
    }
  };

  const handleRemoveSuggestion = (suggestedSkill) => {
    setSuggestedSkills((prev) => {
      const cPrev = [...prev];
      const sIndex = cPrev.findIndex((skill) => skill === suggestedSkill);

      cPrev.splice(sIndex, 1);

      return [...cPrev];
    });
  };

  const renderSkillSections = (skillType, skillsArr) => {
    return (
      <SkillSections>
        <SkillType className="skill-type">{filters[skillType]}</SkillType>
        {skillsArr.map((skill, index) => {
          const listKey = `skill-${skill.name}`;

          const isDisabled = checkMaxPrimary(skillType) >= 5 && !skill.primary;
          const overlayText = isDisabled
            ? "Maximum selection limit reached."
            : skill.primary
            ? "Unmark as primary skill"
            : "Mark as primary skill";

          return (
            <SelectedItem data-testid={listKey} key={listKey}>
              <SkillItem className="d-inline-flex align-items-center">
                {skill.name}
                <button
                  type="button"
                  data-testid={`remove-${listKey}`}
                  className="p-0 d-inline-flex justify-content-center align-items-center"
                  onClick={(e) => onRemoveSkill(e, skillType, index + 1)}
                >
                  <img src={XIcon} alt="" />
                </button>
              </SkillItem>

              <div className="actions">
                <div className="years-input" style={{ borderBottom: "2px solid #062E64" }}>
                  <input
                    value={skill.years_of_experience}
                    onChange={(e) => onAddYearsOfExperience(e, skillType, skill)}
                    className="border-zero"
                    data-testid={`years-${index}`}
                    type="number"
                    placeholder="0"
                  />
                  years
                </div>

                <OverlayTooltip
                  tooltipId={`mark-${skill?.slug}-as-primary`}
                  placement="bottom"
                  overlay={overlayText}
                  className="overlay"
                  tooltipClassName="overlay-inner-star"
                >
                  <button
                    type="button"
                    disabled={isDisabled}
                    data-testid={`testId-${skill.name}`}
                    className={`btn p-0 ${isDisabled ? "disable" : ""}`}
                    onClick={(e) => markAsPrimary(e, skillType, skill)}
                  >
                    {skill.primary ? <img src={StarFilled} alt="" /> : <img src={Star} alt="" />}
                  </button>
                </OverlayTooltip>
              </div>
            </SelectedItem>
          );
        })}
      </SkillSections>
    );
  };

  const renderSuggestedSkillSection = () => {
    return (
      <SkillSections>
        {suggestedSkills.length !== 0 && (
          <>
            <SkillType className="skill-type">Suggested Skills</SkillType>

            {suggestedSkills.map((skill) => {
              const listKey = `skill-${skill}`;

              return (
                <SelectedItem data-testid={listKey} key={listKey}>
                  <SkillItem className="d-inline-flex align-items-center opacity">
                    {skill}
                    <button
                      type="button"
                      data-testid={`remove-${listKey}`}
                      className="p-0 d-inline-flex justify-content-center align-items-center"
                      onClick={() => handleRemoveSuggestion(skill)}
                    >
                      <img src={XIcon} alt="" />
                    </button>
                  </SkillItem>
                </SelectedItem>
              );
            })}
          </>
        )}
      </SkillSections>
    );
  };

  useEffect(() => {
    setSelectedSkill(selected);
  }, []);

  useDidUpdate(() => {
    if (!isEqual(selectedSkill, prevSelectedSkill) && onChange) {
      onChange(selectedSkill);
    }

    if (!isEqual(search, prevSearch)) {
      getSkills({
        search,
      });
    }
  }, [JSON.stringify(selectedSkill), search]);

  const searchedSkills = skills[searchKey] || [];

  return (
    <div className="tag-input position-relative">
      <div>
        {error.duplicateSkill && <span style={{ color: "#da3450" }}>{error.duplicateSkill}</span>}

        <InputGroup
          className={className}
          prepend={prepend ? <i className="tg-ic-tag" /> : null}
          size={size}
          placeholder={placeholder}
          selected={selectedSkill}
          value={search}
          onFocus={() => setShowSuggestions(!!search)}
          onChange={handleChange}
          onKeyPress={onKeyPress}
        />

        {showSuggestions && (
          <Suggestions className="list-group suggestions">
            {searchedSkills.map((skill) => {
              let exists = false;
              Object.values(selectedSkill)
                .flat()
                .forEach((item) => {
                  if (item.name === skill.name) {
                    exists = true;
                  }
                });

              if (exists) return null;

              return (
                <button
                  type="button"
                  className="list-group-item text-left"
                  key={skill?.id || skill?.name}
                  onClick={(e) => onSelectSkill(e, skill.type, skill.name)}
                >
                  {skill.name} • {filters[skill.type]}
                </button>
              );
            })}

            {searchedSkills?.length === 0 && (
              <button
                type="button"
                className="list-group-item text-left"
                onClick={handleAddSuggestion}
                data-testid={`suggest-${search}-btn`}
              >
                Suggest to add ‘{search}’ to Tunga’s skill library
              </button>
            )}
          </Suggestions>
        )}
      </div>

      {error.oneSkill && <span style={{ color: "#da3450" }}>{error.oneSkill}</span>}

      <SectionWrap>
        {Object.entries(selectedSkill).map(([skillType, skillsArr]) => {
          return <Fragment key={skillType}>{renderSkillSections(skillType, skillsArr)}</Fragment>;
        })}

        {renderSuggestedSkillSection()}
      </SectionWrap>
    </div>
  );
};

const SectionWrap = styled.div`
  max-height: 500px;
  overflow-y: scroll;
`;

const SelectedItem = withTheme(styled.div`
  font-weight: 500;
  font-size: ${(props) => props.theme.functions.pxToRem(14)};
  line-height: ${(props) => props.theme.functions.pxToRem(17)};
  color: ${(props) => props.theme.colors["dark-blue"]};
  display: flex;
  justify-content: space-between;
  padding: 0;

  :not(:last-of-type) {
    margin-bottom: 24px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .years-input {
      color: #22262a;
      text-align: right;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -1.2px;
      padding-bottom: 8px;
    }

    input {
      color: #9da0a4;
      min-width: 16px !important;
      max-width: 24px;
      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      appearance: none;
      /* Firefox */
      -moz-appearance: textfield;
    }

    .overlay {
      margin-left: 24px;
    }
  }

  button {
    height: auto;
    vertical-align: baseline;
    line-height: unset;

    &:disabled {
      i {
        opacity: 0.3;
      }
    }
  }
`);

const Suggestions = withTheme(styled.div`
  position: absolute;
  width: 100%;
  z-index: 1000;
  max-height: 500px;
  overflow-y: scroll;
  border-radius: 4px;
  border: 1px solid #e3e9f2;
  margin-top: 5px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

  button.list-group-item {
    color: #151a30;
    text-align: left;
    border: none;

    :hover {
      color: #fff;
      background-color: #151a30;
    }
  }
`);

const SkillType = styled.h3`
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 24px;
`;

const SkillSections = styled.section`
  margin-bottom: 24px;

  &:first-of-type {
    margin-top: 24px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SkillItem = withTheme(styled.div`
  padding: 3px 10px 4px;
  border-radius: 50px;
  background: rgba(6, 46, 100, 0.15);
  font-weight: 500;
  font-size: ${(props) => props.theme.functions.pxToRem(14)};
  line-height: ${(props) => props.theme.functions.pxToRem(21)};
  color: ${(props) => props.theme.colors["dark-blue"]};

  &.opacity {
    opacity: 0.5;
  }

  button {
    height: 12px;
    width: 12px;
    vertical-align: baseline;
    line-height: unset;
    margin-left: 10px;
    border: none;
    background-color: transparent;

    &:disabled {
      opacity: 0.3;
    }
  }
`);

PrioritySkillSelector.defaultProps = {
  placeholder: "Add skills or products",
  selectionKey: null,
};

PrioritySkillSelector.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        years_of_experience: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
        slug: PropTypes.string,
        type: PropTypes.string,
        primary: PropTypes.bool,
      }),
    ),
  ),
  onChange: PropTypes.func,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  selectionKey: PropTypes.string,
  prepend: PropTypes.bool,
  suggestedSkills: PropTypes.arrayOf(PropTypes.string),
  setSuggestedSkills: PropTypes.func,
  filter: PropTypes.shape({
    filter: PropTypes.string,
  }),
};
export default PrioritySkillSelector;
