import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  getUser,
  isAdmin,
  isPayAdmin,
  isSpaceLead,
  isCM,
  isCSO,
  isClient,
  isDev,
  isDesigner,
  isStaff,
} from "./auth";

const getUserRole = () => {
  let role = "";

  switch (true) {
    case isPayAdmin():
      role = "Pay Admin";
      break;

    case isStaff():
      role = "Staff";
      break;

    case isAdmin():
      role = "Admin";
      break;

    case isSpaceLead():
      role = "Space Lead";
      break;

    case isCM():
      role = "Client Manager";
      break;

    case isCSO():
      role = "Client Support Officer";
      break;

    case isClient():
      role = "Client";
      break;

    case isDev():
      role = "Developer";
      break;

    case isDesigner():
      role = "Designer";
      break;

    default:
      break;
  }

  return role;
};

const initialiseAnalytics = () => {
  const TRACKING_ID = process.env.REACT_APP_GA_ID;
  if (!TRACKING_ID) return;

  ReactGA.initialize(TRACKING_ID, {
    testMode: process.env.REACT_APP_GA_TEST_MODE === "true",
    gaOptions: {
      userId: getUser().id,
      anonymizeIp: true,
    },
    gtagOptions: {
      user_role: getUserRole(),
    },
  });
};

const usePageTracking = () => {
  if (!process.env.REACT_APP_GA_ID) return;
  const location = useLocation();

  const { isMakingRequest, user } = useSelector(({ Auth }) => Auth);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!isMakingRequest.verify && user?.id) {
      initialiseAnalytics();
      setInitialized(true);
    }

    return () => {
      setInitialized(false);
    };
  }, [isMakingRequest.verify, user?.id]);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }
  }, [initialized, location]);
};

const eventTrack = (category, action, label) => {
  if (!process.env.REACT_APP_GA_ID) return;
  ReactGA.event({
    category,
    action,
    label,
  });
};

export { eventTrack, usePageTracking, initialiseAnalytics };
