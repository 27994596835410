import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Error from "../../../../components/Error";

const UnarchiveInvoice = ({ proceed }) => {
  const { errors } = useSelector(({ Invoice }) => Invoice);
  const [reason, setReason] = useState("");

  const submit = (event) => {
    event.preventDefault();

    if (reason === "") return;

    proceed({ reason });
  };

  return (
    <form onSubmit={submit} id="unarchive-invoice-form" data-testid="unarchiveInvoice">
      <div className="form-group">
        {errors?.unarchive && <Error data-testid="error_msg" message={errors?.unarchive} />}

        <label htmlFor="message" className="mb-2">
          Reason for unarchiving
        </label>
        <textarea
          className="form-control"
          style={{ resize: "none" }}
          placeholder="Enter reason..."
          onChange={(e) => setReason(e.target.value)}
          value={reason}
          data-testid="reason-textarea"
          required
        />
      </div>
    </form>
  );
};

UnarchiveInvoice.propTypes = {
  proceed: PropTypes.func,
};

export default UnarchiveInvoice;
