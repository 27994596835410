/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { DropdownToggle, DropdownMenu, ButtonDropdown, DropdownItem } from "reactstrap";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import SearchBox from "./SearchBox";
import NavLinks from "./NavLinks";
import Button from "./Button";
import Icon from "./Icon";
import Progress from "./Progress";
import useRightNav from "../layouts/RightSideNav/useRightNav";
import Navigation from "../layouts/RightSideNav/Navigation";
import Overview from "../pages/Dashboard/projects/projectDetail/Overview";
import Activity from "../pages/Dashboard/projects/projectDetail/Activity";
import XCircle from "../assets/images/icons/arrow-back-circle.svg";
import { getPermissionByCodeName, isDev } from "../utils/auth";
import { eventTrack } from "../utils/analytics";
import { CATEGORIES, EVENTS } from "../analytics/events";
import { useTalentProfile } from "../context/TalentProfileContext";

function getMainPath(str) {
  const regex = /^\/([^?\\/]+)/;
  return str.match(regex) ? str.match(regex)[1] : "";
}

const NavBar = forwardRef((props, ref) => {
  const canEditDevProfile = getPermissionByCodeName("can-edit-dev-profile");

  const [openBtn, setOpenBtn] = useState(null);
  const [navHeight, setNavHeight] = useState(null);

  const { className } = props;
  const history = useHistory();
  const { pathname } = useLocation();

  const talentId = pathname.split("/").slice(-1);

  const { isMakingRequest, project } = useSelector(({ Projects }) => Projects);
  const { devProfiles } = useSelector(({ Projects }) => Projects);

  const isTalentProfilePage = history.location.pathname.split("/")[2] === "talent";
  const talentProfileName = devProfiles?.data?.user?.display_name;

  const { open } = useRightNav();
  const { handleShowUserContactInfo, handleSyncWithRecruitee, setEnableEditAction } =
    useTalentProfile();

  useEffect(() => {
    if (ref.current) setNavHeight(ref.current.offsetHeight);
  }, [ref.current]);

  const getNavTitle = () => {
    switch (getMainPath(history.location.pathname)) {
      case "talent-pool":
        if (isTalentProfilePage) {
          return {
            title: (
              <nav className="d-flex align-items-center">
                <img src={XCircle} alt="" />

                <p className="m-0 ms-2">
                  {talentProfileName
                    ? `${devProfiles?.data?.user?.display_name}'s Profile`
                    : "Loading Profile..."}
                </p>
              </nav>
            ),
            href: "/talent-pool",
          };
        }

        return {
          title: "Talent Pool",
          href: "/talent-pool",
        };

      case "projects":
        return {
          title: isMakingRequest.fetch ? <Progress /> : project?.title || "Projects",
          href: "/projects/dedicated",
        };

      case "payments":
        return {
          title: "Payments",
          href: `/payments/${isDev() ? "out" : "in"}?status=all`,
        };

      case "settings":
        return {
          title: "Settings",
          href: "/settings",
        };

      case "tests":
        return {
          title: "Tests",
          href: "/tests",
        };

      case "referral":
        return {
          title: "Refer and Earn",
          href: "/#",
        };

      case "community":
        return {
          title: "Community Guide",
          href: "/community",
        };

      case "opportunities":
        return {
          title: "Opportunities",
          href: "/opportunities",
        };
      case "help-and-support":
        return {
          title: "Help and Support",
          href: "/help-and-support",
        };
      default:
        return {
          title: "Dashboard",
          href: "/dashboard",
        };
    }
  };

  const viewTitle = getNavTitle();

  // TODO: move this to another file
  const navSections = [
    {
      component: <Activity project={project} />,
      title: "Activity",
    },
    {
      component: <Overview project={project} />,
      title: "Overview",
    },
  ];

  const canCreateProject = getPermissionByCodeName("can-create-project");

  return (
    <Wrapper ref={ref} className={`navbar ${className || ""}`}>
      <div className="title-bar">
        <Link to={viewTitle.href} className="navbar-brand">
          {viewTitle.title}
        </Link>
        <ul className="navbar-nav ml-auto">
          {viewTitle.title === "Tests" && (
            <li>
              <StyledButton id="createResult" variant="primary">
                <Icon name="round-add" />
                &nbsp;&nbsp;&nbsp;Add New Result
              </StyledButton>
            </li>
          )}
          {!isTalentProfilePage && (
            <>
              <li>
                <SearchBox navHeight={navHeight} />
              </li>

              {!(project?.title || viewTitle.title === "Tests") && (
                <li className="add-item">
                  <ButtonDropdown
                    className="add-btn"
                    isOpen={openBtn}
                    toggle={() => setOpenBtn(!openBtn)}
                  >
                    {canCreateProject && (
                      <DropdownToggle>
                        <Icon name="round-add" />
                        &nbsp;&nbsp;&nbsp;New
                      </DropdownToggle>
                    )}
                    <DropdownMenu className="dropdown-menu">
                      <DropdownItem className="dropdown-item">
                        <Link to="/projects/new/dedicated/details">
                          <Icon name="account-outline" />
                          Dedicated Developer
                        </Link>
                      </DropdownItem>
                      <DropdownItem
                        className="dropdown-item"
                        onClick={() =>
                          eventTrack(
                            CATEGORIES.projects,
                            EVENTS.click_create_new_opportunity_button,
                          )
                        }
                      >
                        <Link to="/projects/new/shortlist">
                          <Icon name="bullhorn-outline" />
                          Shortlist
                        </Link>
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </li>
              )}

              {project?.title && (
                <li>
                  <Button
                    className="right-nav-button ms-4"
                    onClick={() => open(<Navigation navSections={navSections} />)}
                  >
                    <Icon name="dots-horizontal" />
                    &nbsp;&nbsp;&nbsp;More
                  </Button>
                </li>
              )}
            </>
          )}

          {isTalentProfilePage && (
            <li>
              <ButtonDropdown isOpen={openBtn} toggle={() => setOpenBtn(!openBtn)}>
                <DropdownToggle className="right-nav-button">
                  <Icon name="dots-horizontal" />
                  &nbsp;&nbsp;&nbsp;More
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu talent-profile">
                  {canEditDevProfile && (
                    <DropdownItem
                      className="dropdown-item talent-profile"
                      onClick={() => setEnableEditAction(true)}
                    >
                      Edit Profile
                    </DropdownItem>
                  )}
                  <DropdownItem
                    className="dropdown-item talent-profile"
                    onClick={() =>
                      handleShowUserContactInfo({
                        ...devProfiles.data.user,
                        profile: {
                          phone_number: devProfiles.data.phone_number,
                          street: devProfiles.data.street,
                          plot_number: devProfiles.data.plot_number,
                          postal_code: devProfiles.data.postal_code,
                          city: devProfiles.data.city,
                          country_name: devProfiles.data.country_name,
                        },
                      })
                    }
                  >
                    View contact info
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item talent-profile"
                    onClick={() =>
                      handleSyncWithRecruitee(talentId, devProfiles?.data?.user?.display_name)
                    }
                  >
                    Sync with Recruitee
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </li>
          )}
        </ul>
      </div>

      <NavLinks />
    </Wrapper>
  );
});

const Wrapper = styled.nav`
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 hsl(0deg 0% 80% / 50%);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1030;
  left: 220px;
  padding: 0 40px;

  .title-bar {
    padding: 16px 0;
    display: flex;
    align-items: center;
    width: 100%;

    .navbar-brand {
      color: #151a30;
      font-weight: 500;
      line-height: 24px;
      padding: 0;

      &.hover {
        color: #000000;
      }
    }
  }

  .right-nav-button {
    color: #8f9bb3;
    background: rgba(143, 155, 179, 0.1);
    border: none;
    border-radius: 6px !important;
    box-shadow: none;
    line-height: 22px;
    font-size: 14px;
    text-align: right;

    i {
      font-size: 3px;
      vertical-align: middle;
    }

    &:hover,
    &:visited,
    &:link,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    &:focus {
      outline: 0;
      box-shadow: none;
      border: none;
      border-radius: 6px;
      color: #8f9bb3;
      background: rgba(143, 155, 179, 0.2);
    }
  }

  .navbar-nav {
    flex-direction: row;
    margin: 0 0 0 auto;
    > li {
      > a {
        color: #000000;
      }
    }
  }

  .add-item {
    margin-left: 24px;

    .add-btn {
      > button {
        color: #fff;
        font-weight: 500;
        font-size: px-to-rem(16);
        line-height: px-to-rem(22);
        text-align: center;
        background: #da3451;
        border-color: transparent;
        box-shadow: none;
        border-bottom-right-radius: 4px !important;
        border-top-right-radius: 4px !important;
      }
    }
  }

  /*override reactstrap inline styles*/
  .dropdown-menu {
    transform: translate3d(0px, 54px, 0px) !important;
    left: initial !important;
    right: 0px;
    border: 1px solid #edf1f7 !important;
    box-shadow: 0px 4px 8px rgba(62, 72, 87, 0.04) !important;
    border-radius: 6px !important;
    padding: 10px !important;

    &.talent-profile {
      border: 1px solid #e3e9f2 !important;
      border-radius: 8px !important;
      padding: 5px 0 !important;
      box-shadow: 0px 4px 20px 0px rgba(21, 26, 48, 0.15);
    }

    .dropdown-item {
      padding: 0;
      color: #3e4857;

      &.talent-profile {
        padding: 5px 10px;
        color: #151a30;
        font-size: 14px;
        font-weight: 500;
      }

      &:active {
        color: initial;
        background-color: initial;
      }

      a {
        padding: 15px;
        color: #3e4857;
        text-decoration: none;
        display: block;
        i {
          margin-right: 12px;
        }
      }
    }
  }
`;

const StyledButton = styled(Button)`
  box-shadow: none;
`;

NavBar.propTypes = {
  // title: PropTypes.string,
  className: PropTypes.string,
};

export default NavBar;
