/* eslint-disable jsx-a11y/label-has-associated-control */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useContext } from "react";
import { FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import Switch from "react-switch";
import moment from "moment";
/* -------------------------- Internal dependencies ------------------------- */
import styled, { ThemeProvider } from "styled-components";
import {
  PROJECT_STAGE_ACTIVE,
  PROJECT_STAGE_SHORTLIST,
  cleanSkills,
} from "../../../../../utils/api";
import Input from "../../../../../components/Input";
import TextArea from "../../../../../components/TextArea";
import { TempProjectContext } from "../TempProjectContext";
import { Wrapper, StyledDateTimePicker } from "../style";
import theme from "../../../../../assets/theme";
import SkillSelector from "../../../../../components/SkillSelector";
import FieldError from "../../../../../components/FieldError";
import { validateEmailString } from "../../../../../utils/helpers";

const DetailsForm = ({ stage, category }) => {
  const [project, setProject] = useContext(TempProjectContext);

  const setError = (key, error) => {
    setProject({
      ...project,
      error: {
        [key]: error,
      },
    });
  };

  useEffect(() => {
    setProject({ ...project, stage, category });
  }, []);

  const validateEmail = async () => {
    if (project?.contact_email === null || project?.contact_email === "") {
      setError("contact_email", "");
      return false;
    }

    const validationResult = await validateEmailString(project.contact_email);

    if (validationResult !== false) {
      setError("contact_email", "Invalid email address.");
      return false;
    }

    setError("contact_email", "");
    return true;
  };

  const validateAccountManagers = async () => {
    const emails = project.shortlist_notification_recipients
      .split(",")
      .map((email) => email.trim());

    const validationResults = await Promise.all(emails.map((email) => validateEmailString(email)));

    const invalidEmails = validationResults.filter((result) => result !== false);

    if (invalidEmails.length > 0) {
      setError("shortlist_notification_recipients", "Invalid email address.");
    } else {
      setError("shortlist_notification_recipients", "");
    }
  };

  /* istanbul ignore next */
  const onChangeValue = (key, value) => {
    const newState = {};
    newState[key] = value;
    setProject({ ...project, ...newState });
  };

  /* istanbul ignore next */
  const onChangeField = (key, e) => {
    onChangeValue(key, e.target.value);
  };

  /* istanbul ignore next */
  const onSkillChange = (newSkillsValue) => {
    onChangeValue("skills", newSkillsValue);
    const newState = {};
    newState.skills = newSkillsValue;
    setProject({ ...project, ...newState });
    cleanSkills(newSkillsValue).map((skill) => {
      return { ...skill };
    });
  };

  return (
    <Wrapper>
      <div className="header">
        {stage === PROJECT_STAGE_SHORTLIST ? (
          <>
            <h4>Shortlist Details</h4>
            <p>Provide the details below for this shortlist</p>
          </>
        ) : (
          <>
            <h4>Project Details</h4>
            <p>Provide the details below for this project</p>
          </>
        )}
      </div>

      <div className="section">
        <div className="title">
          Enter {stage === PROJECT_STAGE_SHORTLIST ? "shortlist" : "project"} title and description
          <LabelStyle>
            <span className="label-style">*</span>
          </LabelStyle>
        </div>
        <FormGroup>
          <Input
            className="mb-3"
            id="title"
            value={project?.title || ""}
            placeholder={`Enter ${
              stage === PROJECT_STAGE_SHORTLIST ? "shortlist" : "project"
            } title`}
            onChange={(e) => onChangeField("title", e)}
          />
        </FormGroup>
        <FormGroup>
          <TextArea
            id="description"
            className="description"
            value={project?.description || ""}
            placeholder={`Short description of ${
              stage === PROJECT_STAGE_SHORTLIST ? "shortlist" : "project"
            }`}
            onChange={(e) => onChangeField("description", e)}
          />
        </FormGroup>
      </div>

      <div className="section">
        <div className="title">
          {stage === PROJECT_STAGE_SHORTLIST
            ? "Which service category are you looking for?"
            : "Which type of project do you have?"}
          <LabelStyle>
            <span className="label-style">*</span>
          </LabelStyle>
        </div>
        <FormGroup className="custom-radio-group">
          {stage === PROJECT_STAGE_SHORTLIST ? (
            <>
              <div className="radio-button">
                <input
                  type="radio"
                  name="projectCategory"
                  id="managed"
                  checked={project?.category === "managed"}
                  onChange={() => onChangeValue("category", "managed")}
                />
                <label htmlFor="managed">Managed Project</label>
              </div>
              <div className="radio-button">
                <input
                  className="dedicated"
                  type="radio"
                  name="projectCategory"
                  id="dedicated"
                  checked={project?.category === "dedicated"}
                  onChange={() => onChangeValue("category", "dedicated")}
                />
                <label htmlFor="dedicated">Dedicated Developer</label>
              </div>
              <div className="radio-button">
                <input
                  type="radio"
                  name="projectCategory"
                  id="other"
                  checked={project?.category === "other"}
                  onChange={() => onChangeValue("category", "other")}
                />
                <label htmlFor="other">Other</label>
              </div>
            </>
          ) : (
            <>
              <div className="radio-button">
                <input
                  type="radio"
                  name="projecttype"
                  id="web"
                  checked={project?.type === "web"}
                  onChange={() => onChangeValue("type", "web")}
                />
                <label htmlFor="web">Web</label>
              </div>
              <div className="radio-button">
                <input
                  type="radio"
                  name="projecttype"
                  id="mobile"
                  checked={project?.type === "mobile"}
                  onChange={() => onChangeValue("type", "mobile")}
                />
                <label htmlFor="mobile">Mobile</label>
              </div>
              <div className="radio-button">
                <input
                  type="radio"
                  name="projecttype"
                  id="other"
                  checked={project?.type === "other"}
                  onChange={() => onChangeValue("type", "other")}
                />
                <label htmlFor="other">Other</label>
              </div>
            </>
          )}
        </FormGroup>
      </div>

      <div className="section">
        <div className="title">
          What is the expected duration of the project?
          <LabelStyle>
            <span className="label-style">*</span>
          </LabelStyle>
        </div>
        <FormGroup className="row">
          <div className="col">
            <label>Start date</label>
            <StyledDateTimePicker
              className="tg-date-field"
              placeholder="Select start date"
              format="DD MMM YYYY"
              $calendar={true}
              $time={false}
              value={project?.expected_start_date ? new Date(project?.expected_start_date) : null}
              max={
                project?.expected_end_date
                  ? new Date(project?.expected_end_date)
                  : new Date(2099, 11, 31)
              }
              onChange={(date) => {
                onChangeValue(
                  "expected_start_date",
                  moment(date).isValid() ? moment(date).format() : null,
                );
              }}
            />
          </div>
          <div className="col">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label>End date</label>
              <div>
                <span className="mx-1">No end date</span>
                <Switch
                  id="switch"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={13.5}
                  width={22.5}
                  onChange={() => {
                    onChangeValue(
                      "expected_end_date",
                      project?.expected_end_date ? null : +new Date(2099, 11, 31),
                    );
                  }}
                  checked={!project?.expected_end_date}
                  onColor="#062E64"
                />
              </div>
            </div>
            <StyledDateTimePicker
              className="tg-date-field"
              placeholder="Select end date"
              format="DD MMM YYYY"
              $calendar={true}
              $time={false}
              value={
                project?.expected_end_date && typeof project?.expected_end_date === "string"
                  ? new Date(project?.expected_end_date)
                  : null
              }
              min={
                new Date(project?.expected_start_date) >= new Date(Date.now())
                  ? new Date(project?.expected_start_date)
                  : new Date(Date.now())
              }
              onChange={(date) => {
                onChangeValue(
                  "expected_end_date",
                  moment(date).isValid() ? moment(date).format() : null,
                );
              }}
              required
            />
          </div>
        </FormGroup>
      </div>

      <div className="section">
        <div className="title">What technology do you want to use (optional)</div>
        <FormGroup>
          <ThemeProvider theme={theme}>
            <SkillSelector
              onChange={(skill) => onSkillChange(skill)}
              selected={project?.skills || []}
              placeholder="Type to select..."
            />
          </ThemeProvider>
        </FormGroup>
      </div>
      {stage === PROJECT_STAGE_SHORTLIST && (
        <>
          <div className="section">
            <div className="header pb-4 border-0">
              <h4>Client Details</h4>
              <p>
                The client contact shared below will be the point of contact when the client
                requests an interview on a developer’s profile
              </p>
            </div>

            <FormGroup className="row">
              <div className="col">
                <div className="title">Client name</div>
                <FormGroup>
                  <Input
                    className="mb-3"
                    id="clientName"
                    value={project?.contact_name || ""}
                    dataTestId="contact_name"
                    placeholder="Enter client name"
                    onChange={(e) => onChangeField("contact_name", e)}
                  />
                </FormGroup>
              </div>
              <div className="col">
                <div className="title">Client email address</div>
                <FormGroup>
                  <Input
                    className="mb-3"
                    type="email"
                    id="clientEmailAddress"
                    dataTestId="contact_email"
                    value={project?.contact_email || ""}
                    placeholder="Enter client email address"
                    onChange={(e) => onChangeField("contact_email", e)}
                    onBlur={validateEmail}
                  />
                  {project?.error?.contact_email && (
                    <FieldError message={project?.error.contact_email} />
                  )}
                </FormGroup>
              </div>
            </FormGroup>
          </div>

          <div className="section">
            <div className="header pb-4 border-0">
              <h4>Notification Recipients</h4>
            </div>

            <FormGroup>
              <div className="title">Additional email addresses to notify about shortlist.</div>
              <FormGroup>
                <Input
                  className="mb-3"
                  id="shortlist_notification_recipients"
                  value={project?.shortlist_notification_recipients || ""}
                  dataTestId="shortlist_notification_recipients"
                  placeholder="Enter additional email addresses, separated by commas e.g obia@tunga.io, paul@tunga.io"
                  onChange={(e) => onChangeField("shortlist_notification_recipients", e)}
                  onKeyUp={validateAccountManagers}
                />
                {project?.error?.shortlist_notification_recipients && (
                  <FieldError message={project?.error.shortlist_notification_recipients} />
                )}
              </FormGroup>
            </FormGroup>
          </div>
        </>
      )}
    </Wrapper>
  );
};

DetailsForm.defaultProps = {
  stage: PROJECT_STAGE_ACTIVE,
  category: "project",
};

DetailsForm.propTypes = {
  stage: PropTypes.string,
  category: PropTypes.string,
};

export default DetailsForm;
const LabelStyle = styled.span`
  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
`;
