import React, { useMemo } from "react";
import { useRouteMatch, Switch, Route, Redirect, useLocation } from "react-router-dom";
import SectionNav from "../../../../../components/SectionNav";
import Details from "./Details";
import { getPermissionByCodeName, isAdminOrPMOrSpaceLead, isCSO } from "../../../../../utils/auth";
import Team from "./Team";
import Reports from "./Reports";
import MetaTags from "../../../../../components/MetaTags";

const Settings = () => {
  const canViewTeams = getPermissionByCodeName("can-view-team");
  const canViewReportsNotifications = getPermissionByCodeName("can-view-reports-notifications");

  const match = useRouteMatch();

  const location = useLocation();

  const pageTitle = useMemo(() => {
    let pageTitleString;

    switch (true) {
      case location.pathname.includes("settings/reports"):
        pageTitleString = "Project Reports Settings";
        break;

      case location.pathname.includes("settings/team"):
        pageTitleString = "Project Team Settings";
        break;

      case location.pathname.includes("settings/details"):
        pageTitleString = "Project Details Settings";
        break;

      default:
        pageTitleString = "Project Settings";
        break;
    }

    return pageTitleString;
  }, [location.pathname]);

  return (
    <>
      <MetaTags
        title={pageTitle}
        description="Manage project details, team members and reports for your project."
      />
      <SectionNav
        links={[
          ...(isAdminOrPMOrSpaceLead() || isCSO()
            ? [
                {
                  route: `${match.url.replace("/", "")}/details`,
                  name: "Details",
                },
                ...(canViewTeams
                  ? [
                      {
                        route: `${match.url.replace("/", "")}/team`,
                        name: "Team",
                      },
                    ]
                  : []),
                ...(canViewReportsNotifications
                  ? [
                      {
                        route: `${match.url.replace("/", "")}/reports`,
                        name: "Reports",
                      },
                    ]
                  : []),
              ]
            : []),
        ]}
      />

      <Switch>
        {[
          ["details", <Details />],
          ["team", <Team />],
          ["reports", <Reports />],
        ].map((path) => (
          <Route
            key={`report-${path}`}
            path={`${match.url}/${path[0]}`}
            render={() => {
              /* istanbul ignore next */
              return <>{path[1]}</>;
            }}
          />
        ))}
        <Redirect
          exact
          from={`${match.url}`}
          to={`${match.url}/${isAdminOrPMOrSpaceLead() || isCSO() ? "details" : "integrations"}`}
        />
      </Switch>
    </>
  );
};

export default Settings;
