import { parseInt } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Avatar from "../../../../../components/Avatar";
import Icon from "../../../../../components/Icon";
import IconButton from "../../../../../components/IconButton";
import { ModalHeader } from "../../../../../components/ModalHeader";
import Loading from "../../../../../components/svg/Loading";
import ProjectMemberForm from "../../../../../components/ProjectMemberForm";
import {
  deleteParticipation,
  updateParticipation,
  updateProject,
} from "../../../../../redux/actions/ProjectActions";
import { getPermissionByCodeName, isCMOrCSOAndHasProjectAcess } from "../../../../../utils/auth";
import { createModal, openConfirm } from "../../../../../utils/modals";
import { generateUserInitials } from "../../../../../utils/stringUtils";
import { trackEvent } from "../../../../../analytics/segment";
import { CATEGORIES, EVENTS } from "../../../../../analytics/events";

const Team = () => {
  const canAddPo = getPermissionByCodeName("can-add-po");
  const canAddCm = getPermissionByCodeName("can-add-cm");
  const canUpdateCm = getPermissionByCodeName("can-update-cm");
  const canAddCso = getPermissionByCodeName("can-add-cso");
  const canUpdatePo = getPermissionByCodeName("can-update-po");
  const canAddTeamMembers = getPermissionByCodeName("can-add-team-members");
  const canUpdateTeamMembers = getPermissionByCodeName("can-update-team-members");
  const canAddTeamMembersPayoutRates = getPermissionByCodeName("can-add-team-members-payout-rates");
  const canUpdateTeamMembersPayoutRates = getPermissionByCodeName(
    "can-update-team-members-payout-rates",
  );

  const dispatch = useDispatch();
  const { project, isMakingRequest } = useSelector(({ Projects }) => Projects);

  const [addPayment, setAddPayment] = useState(
    project.participation.reduce(
      (acc, curr) => ({ ...acc, [curr.user.id]: curr.payment_structure }),
      {},
    ),
  );

  const [openOwner, setOpenOwner] = useState(false);
  const [openCso, setOpenCso] = useState(false);
  const [openManager, setOpenManager] = useState(false);
  const [openTeam, setOpenTeam] = useState({ id: null, status: false });

  const eventProperties = {
    project_type: project.stage === "active" ? project.category : project.stage,
    project_id: project.id,
    project_name: project.title,
    event_category: CATEGORIES.projects,
  };

  const onAddUsers = (type, title, max, include_self) => {
    const typeMap = {
      cm: "client_manager",
      cso: "client_support_officer",
      owner: "project_owner",
      dev: "developer",
    };

    const modal = createModal({
      body: (
        <ProjectMemberForm
          id="users-form"
          type={typeMap[type]}
          max={max}
          selected_users={(project.participation || []).map((item) => item.user)}
          include_self={include_self}
        />
      ),
      canClose: true,
      header: null,
      options: {
        title,
        size: "sm",
        ok: "Save",
        hideCancel: true,
        form: {
          type: "submit",
          form: "users-form",
        },
      },
      proceed: (users) => {
        if (users && users.length) {
          const reqData = {};
          const isDev = type === "dev";
          modal.setIsLoading(true);

          if (isDev) {
            reqData.participation = users.map((user) => ({
              user: {
                id: user.id,
              },
              payment_structure: {
                hours_per_week: 0,
                payout_rate: 0,
                client_rate: 0,
              },
            }));
          } else {
            reqData[type] = { id: users[0].id };
          }

          const ids = users.map((a) => a.id);
          const usernames = users.map((a) => a.username);

          const feedbackCb = {
            successCb: () => {
              trackEvent(EVENTS.add_new_team_member, {
                ...eventProperties,
                user_id: isDev ? ids.join(",") : ids[0],
                username: isDev ? usernames.join(",") : usernames[0],
              });
              modal.close();
            },
            failureCb: () => modal.setIsLoading(false),
          };
          dispatch(updateProject(project.id, reqData, feedbackCb));
        }
      },
    });
    modal.open();
  };

  const onDeleteUser = (user, type) => {
    openConfirm({
      message: (
        <div>
          Are you sure you want to delete {user?.display_name || user?.user?.display_name} from the
          project?
        </div>
      ),
      title: "",
      canClose: true,
      options: { ok: "Delete user" },
      header: <ModalHeader style={{ paddingBottom: "24px" }} options={{ title: "Remove User" }} />,
    })
      .then(() => {
        if (["cm", "cso", "owner"].includes(type)) {
          const reqData = {};
          reqData[type] = null;
          dispatch(updateProject(project.id, reqData, {}));
        } else if (type === "dev") {
          delete addPayment[user?.user?.id];
          dispatch(deleteParticipation(user.id));
        }
      })
      .catch(() => {});
  };

  const onArchiveUser = (user) => {
    openConfirm({
      message: (
        <div>
          Are you sure you want to {user?.archived ? "unarchive" : "archive"}{" "}
          {user?.display_name || user?.user?.display_name} from the project?
        </div>
      ),
      title: "",
      canClose: true,
      options: { ok: user?.archived ? "Unarchive user" : "Archive user" },
      header: (
        <ModalHeader
          style={{ paddingBottom: "24px" }}
          options={{ title: user?.archived ? "Unarchive User" : "Archive User" }}
        />
      ),
    })
      .then(() => {
        dispatch(
          updateParticipation(user.id, {
            archived: !user?.archived,
            archived_at: user?.archived ? null : new Date(),
          }),
        );
      })
      .catch(() => {});
  };

  const onSavePaymentStructure = () => {
    dispatch(
      updateProject(
        project.id,
        {
          participation: Object.entries(addPayment).map((userParticipation) => ({
            user: {
              id: userParticipation[0],
            },
            payment_structure: {
              hours_per_week: userParticipation[1]?.hours_per_week,
              payout_rate: userParticipation[1]?.payout_rate,
              client_rate: userParticipation[1]?.client_rate,
            },
          })),
        },
        {},
      ),
    );

    trackEvent(EVENTS.update_dev_payment_structure, eventProperties);
  };

  const handleChange = (key, id, value) => {
    const newAddPayment = addPayment;
    newAddPayment[id] = { ...newAddPayment[id], [key]: value };
    setAddPayment({ ...newAddPayment });
  };

  return (
    <StypledWrappeer>
      <div className="section">
        <div className="section-title d-flex justify-content-between align-items-center mb-3">
          <span>Project Owner</span>
          {(isCMOrCSOAndHasProjectAcess(project) || canAddPo) &&
            !project.archived &&
            !project.owner && (
              <button
                type="button"
                className="btn px-0"
                data-testid="poAddBtn"
                onClick={() => onAddUsers("owner", "Add project owner", 1)}
              >
                <Icon name="round-add" size="sm" /> Add
              </button>
            )}
        </div>

        <div className="section-items">
          {project.owner && (
            <div className="rmv">
              <div className="item">
                <Avatar
                  image={project.owner.avatar_url}
                  initials={generateUserInitials(project.owner)}
                  size="dash"
                />
                <span>{project.owner.display_name}</span>

                <div className="item-actions">
                  <span className="completed">Active</span>
                  {(isCMOrCSOAndHasProjectAcess(project) || canUpdatePo) &&
                    !project.archived &&
                    (!openOwner ? (
                      <IconButton
                        className="px-0"
                        name="arrow-down"
                        data-testid="poToggle-down"
                        onClick={() => setOpenOwner(true)}
                      />
                    ) : (
                      <IconButton
                        className="px-0"
                        name="arrow-up"
                        data-testid="poToggle-up"
                        onClick={() => setOpenOwner(false)}
                      />
                    ))}
                </div>
              </div>

              {openOwner && (
                <div className="rmv-border">
                  <button
                    type="button"
                    className="btn red mt-0 px-2 py-1"
                    data-testid="poToggle-rm-user"
                    onClick={() => onDeleteUser(project.owner, "owner")}
                  >
                    Remove user
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="section">
        <div className="section-title d-flex justify-content-between align-items-center  mb-3">
          <span>Client Support Officer</span>
          {canAddCso && !project.archived && !project.cso && (
            <button
              type="button"
              className="btn px-0"
              data-testid="csoAddBtn"
              onClick={() => onAddUsers("cso", "Add Client support officer", 1)}
            >
              <Icon name="round-add" size="sm" /> Add
            </button>
          )}
        </div>
        <div className="section-items">
          {project.cso && (
            <div className="rmv">
              <div className="item">
                <Avatar
                  image={project.cso.avatar_url}
                  initials={generateUserInitials(project.cso)}
                  size="dash"
                />
                <span>{project.cso.display_name}</span>

                <div className="item-actions">
                  <span className="completed">Active</span>
                  {canAddCso &&
                    !project.archived &&
                    (!openCso ? (
                      <IconButton
                        data-testid="csoToggle-down"
                        name="arrow-down"
                        className="px-0"
                        onClick={() => setOpenCso(true)}
                      />
                    ) : (
                      <IconButton
                        data-testid="csoToggle-up"
                        name="arrow-up"
                        className="px-0"
                        onClick={() => setOpenCso(false)}
                      />
                    ))}
                </div>
              </div>
              {openCso && (
                <div className="rmv-border">
                  <button
                    type="button"
                    className="btn red mt-0 px-2 py-1"
                    data-testid="csoToggle-rm-user"
                    onClick={() => onDeleteUser(project.cso, "cso")}
                  >
                    Remove user
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="section">
        <div className="section-title d-flex justify-content-between align-items-center  mb-3">
          <span>Client Manager</span>
          {canAddCm && !project.archived && !project.cm && (
            <button
              type="button"
              className="btn px-0"
              data-testid="cmAddBtn"
              onClick={() => onAddUsers("cm", "Add client manager", 1, true)}
            >
              <Icon name="round-add" size="sm" /> Add
            </button>
          )}
        </div>

        <div className="section-items">
          {project.cm && (
            <div className="rmv">
              <div className="item">
                <Avatar
                  image={project.cm.avatar_url}
                  initials={generateUserInitials(project.cm)}
                  size="dash"
                />
                <span>{project.cm.display_name}</span>

                <div className="item-actions">
                  <span className="completed">Active</span>
                  {canUpdateCm &&
                    !project.archived &&
                    (!openManager ? (
                      <IconButton
                        name="arrow-down"
                        className="px-0"
                        data-testid="cmToggle-down"
                        onClick={() => setOpenManager(true)}
                      />
                    ) : (
                      <IconButton
                        name="arrow-up"
                        className="px-0"
                        data-testid="cmToggle-up"
                        onClick={() => setOpenManager(false)}
                      />
                    ))}
                </div>
              </div>
              {openManager && (
                <div className="rmv-border">
                  <button
                    type="button"
                    className="btn red mt-0 px-2 py-1"
                    data-testid="cmToggle-rm-user"
                    onClick={() => onDeleteUser(project.cm, "cm")}
                  >
                    Remove user
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="section">
        <div className="section-title d-flex justify-content-between align-items-center  mb-3">
          <span>Team Members</span>
          {(isCMOrCSOAndHasProjectAcess(project) || canAddTeamMembers) && !project.archived && (
            <button
              className="btn px-0"
              type="button"
              data-testid="tmAddBtn"
              onClick={() => onAddUsers("dev", "Add team members", 100000)}
            >
              <Icon name="round-add" size="sm" /> Add
            </button>
          )}
        </div>

        <div className="section-items">
          {project.participation.map((participation) => {
            const isArchived = participation.archived;
            const isAccepted = participation.status === "accepted";

            return (
              <div className="rmv" key={participation.user.id}>
                <div className={`item ${isArchived ? "opacity-50" : ""}`}>
                  <Avatar
                    image={participation.user.avatar_url}
                    initials={generateUserInitials(participation.user)}
                    size="dash"
                  />
                  <span>{participation.user.display_name}</span>&nbsp;
                  <span style={{ color: "#8F9BB3" }}>- Developer</span>
                  <div className="item-actions">
                    <span
                      className={`${isArchived ? "overdue" : isAccepted ? "completed" : "pending"}`}
                    >
                      {isArchived ? "Archived" : isAccepted ? "Active" : "Pending"}
                    </span>

                    {(isCMOrCSOAndHasProjectAcess(project) ||
                      canAddTeamMembersPayoutRates ||
                      canUpdateTeamMembersPayoutRates) &&
                      !project.archived &&
                      (openTeam.id === participation.id && openTeam.status === true ? (
                        <IconButton
                          name="arrow-up"
                          className="px-0"
                          data-testid={`team-member-toggle-up-${participation.id}`}
                          onClick={() => setOpenTeam({ id: participation.id, status: false })}
                        />
                      ) : (
                        <IconButton
                          name="arrow-down"
                          className="px-0"
                          data-testid={`team-member-toggle-down-${participation.id}`}
                          onClick={() => setOpenTeam({ id: participation.id, status: true })}
                        />
                      ))}
                  </div>
                </div>

                {openTeam.id === participation.id && openTeam.status === true && (
                  <div className="rmv-border">
                    {project.category === "dedicated" && (
                      <div className={isArchived ? "opacity-50" : ""}>
                        <div
                          className="inp-div"
                          style={{
                            marginLeft: ".05rem",
                            marginRight: "0rem",
                            marginBottom: "-11px",
                          }}
                        >
                          <input
                            style={{ borderLeft: "none", borderRight: "none" }}
                            disabled={isArchived}
                            name="hours_per_week"
                            required
                            aria-label="hours_per_week"
                            type="number"
                            placeholder="Enter expected hours"
                            value={addPayment[participation.user.id]?.hours_per_week}
                            onChange={(e) =>
                              e.target.value >= 0 &&
                              handleChange("hours_per_week", participation.user.id, e.target.value)
                            }
                          />
                          <span className="span-append">hours/week</span>
                        </div>
                        <div className="row" style={{ marginBottom: "-22px" }}>
                          <div className="inp-div col">
                            <input
                              style={{ borderLeft: "none" }}
                              name="payout_rate"
                              disabled={isArchived}
                              aria-label="payout_rate"
                              required
                              value={addPayment[participation.user.id]?.payout_rate}
                              onChange={(e) =>
                                e.target.value >= 0 &&
                                handleChange("payout_rate", participation.user.id, e.target.value)
                              }
                              type="number"
                              placeholder={`${
                                project?.currency === "USD" ? "$" : "€"
                              } Developer rate per hour`}
                            />
                            <span className="span-append">/hour</span>
                          </div>
                          <div className="inp-div col">
                            <input
                              disabled
                              style={{ borderRight: "none" }}
                              value={(
                                (parseInt(addPayment[participation.user.id]?.payout_rate, 10) / 5) *
                                parseInt(addPayment[participation.user.id]?.hours_per_week, 10) *
                                22
                              ).toFixed(2)}
                              type="number"
                            />
                            <span className="span-append">expected total payout/month</span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="inp-div col">
                            <input
                              style={{ borderLeft: "none" }}
                              name="client_rate"
                              required
                              disabled={isArchived}
                              aria-label="client_rate"
                              value={addPayment[participation.user.id]?.client_rate}
                              onChange={(e) =>
                                e.target.value >= 0 &&
                                handleChange("client_rate", participation.user.id, e.target.value)
                              }
                              type="number"
                              placeholder={`${
                                project?.currency === "USD" ? "$" : "€"
                              } Client rate per hour`}
                            />
                            <span className="span-append">/hour</span>
                          </div>
                          <div className="inp-div col">
                            <input
                              disabled
                              style={{ borderRight: "none" }}
                              value={(
                                (parseInt(addPayment[participation.user.id]?.client_rate, 10) / 5) *
                                parseInt(addPayment[participation.user.id]?.hours_per_week, 10) *
                                22
                              ).toFixed(2)}
                              type="number"
                            />
                            <span className="span-append">expected total payment/month</span>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="d-flex btn-groups">
                      <button
                        type="button"
                        className="btn red mt-0 px-2 py-1"
                        data-testid={`tm-rm-user${participation.id}`}
                        onClick={() => onDeleteUser(participation, "dev")}
                      >
                        Remove user
                      </button>

                      <button
                        type="button"
                        className="btn btn-archive mt-0 px-2 py-1"
                        data-testid={`tm-archive-user${participation.id}`}
                        onClick={() => onArchiveUser(participation)}
                      >
                        {isMakingRequest?.updateParticipationStatus ? (
                          <Loading fill="#3e4857" width={20} height={20} />
                        ) : (
                          `${participation?.archived ? "Una" : "A"}rchive user`
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {project.participation.length > 0 &&
          project.category === "dedicated" &&
          (isCMOrCSOAndHasProjectAcess(project) || canUpdateTeamMembers) && (
            <button
              disabled={isMakingRequest?.update}
              className="form-control save-btn"
              type="button"
              onClick={onSavePaymentStructure}
            >
              {isMakingRequest?.update ? "Saving..." : "Save changes"}
            </button>
          )}
      </div>
    </StypledWrappeer>
  );
};

const StypledWrappeer = styled.div`
  .btn {
    line-height: unset;
    height: unset;
    font-size: 0.9375rem;
  }

  .save-btn {
    font-size: 1rem;
    background-color: #f5f7fa;
    width: 10rem;
    color: #000;
  }

  .btn-archive {
    font-size: 0.9375rem;
    border: 1px solid #3e4857;
  }

  input:focus {
    outline: none;
  }

  background: #ffffff;
  border: 1px solid #edf1f7;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 40px;

  .span-append {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    padding: 4px 16px;
    color: #777;
    border-radius: 8px;
  }
  .inp-div {
    position: relative;
    padding: 0px 16px;
    margin-right: -16px;
    margin-left: -16px;
  }
  .row {
    padding: 11px 16px;
    margin-left: 0px;
    margin-right: 0px;
  }
  input {
    height: 40px;
    width: 100%;
    padding: 0 16px;
    border: 1px solid #e3e9f2;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: none;
  }

  .red {
    width: fit-content;
    color: #eb5757;
    margin-top: 20px;
    cursor: pointer;
    border: 1px solid #eb5757;
  }

  .rmv-border {
    /* border-top: 1px solid #edf1f7; */
    margin-top: 20px;
    border-right: none;
    border-left: none;
  }
  .section {
    padding: 40px 0;
    border-bottom: 1px solid #edf1f7;

    &:first-of-type {
      padding-top: 0px;
    }
    &:last-of-type {
      padding-bottom: 0px;
      border-bottom: none;
    }

    .section-title {
      font-size: 16px;
      span {
        font-weight: 500;
        line-height: 19px;
        color: #151a30;
      }

      button {
        font-weight: 500;
        font-size: 1rem;
        line-height: 24px;
        color: #062e64;
        text-decoration: none;

        i {
          margin-right: 12px;
          font-size: 16px;
          vertical-align: baseline;
        }
      }
    }

    .section-items {
      .rmv {
        background: #ffffff;
        border: 1px solid #edf1f7;
        box-sizing: border-box;
        border-radius: 4px;
        margin-bottom: 16px;
        padding: 11px 16px;
      }
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:last-of-type {
          margin-bottom: 0;
        }

        .avatar.avatar-dash {
          width: 35px;
          height: 35px;

          &.avatar-initials {
            font-size: 14px;
          }
        }

        > span {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #3e4857;
        }

        .item-actions {
          margin: 0 0 0 auto;

          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            border-radius: 50px;
            padding: 6px 24px;

            &.pending {
              color: #8f9bb3;
              background: rgba(143, 155, 179, 0.05);
              border: 1px solid rgba(143, 155, 179, 0.04);
            }

            &.completed {
              color: #219653;
              background: rgba(33, 150, 83, 0.04);
              border: 1px solid rgba(33, 150, 83, 0.04);
            }

            &.overdue {
              color: #eb5757;
              background: rgba(235, 87, 87, 0.04);
              border: 1px solid rgba(235, 87, 87, 0.04);
            }
          }

          button {
            margin-left: 30px;

            i {
              color: #8f9bb3;
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  .btn-groups {
    column-gap: 16px;
  }
`;

export default Team;
