import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ArrowUp from "../svg/ArrowUp";

const FilterHead = ({ as = "div", label, isOpen, onClick }) => {
  const isBtn = as === "button";
  const btnClass = isBtn ? "btn d-flex justify-content-between align-items-center" : "";
  const arrowClass = isBtn && !isOpen ? "is-closed" : "";

  const handleClick = () => {
    if (isBtn) {
      onClick();
    }
  };

  return (
    <Wrapper
      as={as}
      className={`d-flex justify-content-between w-100 ${btnClass}`}
      onClick={handleClick}
    >
      <span className="label">{label}</span>

      {isBtn && (
        <span className={arrowClass}>
          <ArrowUp />
        </span>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 15px 17px 8px;

  &.btn {
    height: unset;
    line-height: unset;
    margin: 15px 0 8px !important;
  }

  span.label {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: #8f9bb3;
  }

  .is-closed {
    transition: rotate 0.3s;
    transform: rotate(180deg);
  }
`;

FilterHead.propTypes = {
  as: PropTypes.string,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  label: PropTypes.string.isRequired,
};
export default FilterHead;
