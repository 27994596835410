/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React, { useCallback, useMemo, useState } from "react";

import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  TungaDevProfileOverview,
  TungaDevSkillsToolsSection,
  TungaDevTestResult,
  TungaEducationSection,
  TungaProjectSection,
  TungaTabSwitch,
  TungaWorkExperience,
} from "@tunga/tunga-components-react";

import { CATEGORIES, EVENTS } from "../../../analytics/events";
import { trackEvent } from "../../../analytics/segment";
import SuggestedSkillForm from "../../../components/SuggestedSkillForm";
import { RESET_SKILLS_ERRORS } from "../../../configs/constants/ActionTypes";
import { skillsTitleDictionary } from "../../../configs/constants/global";
import useDidUpdate from "../../../hooks/useDidUpdate";
import usePrevious from "../../../hooks/usePrevious";
import { verify } from "../../../redux/actions/AuthActions";
import {
  gigcvExport,
  requestTest,
  shareProfile,
  updateEducation,
  updateProfile,
  updateProject,
  updateWork,
} from "../../../redux/actions/ProfileActions";
import { suggestSkill } from "../../../redux/actions/SkillAction";
import { decodeHtmlEntities, skillsToArray } from "../../../utils/helpers";
import { createModal, openAlertModal } from "../../../utils/modals";
/* -------------------------------------------------------------------------- */
/*                             Internal Dependency                            */
/* -------------------------------------------------------------------------- */
import BioForm from "./modals/BioForm";
import EducationForm from "./modals/EducationForm";
import ProjectForm from "./modals/ProjectForm";
import ShareProfileModal from "./modals/ShareProfileModal";
import SkillsetForm from "./modals/SkillsetForm";
import WorkForm from "./modals/WorkForm";
import RequestTechnicalTestForm from "./modals/RequestTechnicalTestForm";
import ExportGigCV from "./modals/ExportGigCV";
import ExportGigCVSuccess from "./modals/ExportGigCVSuccess";

const Profile = () => {
  const dispatch = useDispatch();
  const {
    id: userId,
    profile,
    avatar_url: avatarUrl,
    display_name,
    work: workExperience,
    project: userProject,
    education: educationExperience,
    share_settings,
    test_results,
    years_of_experience: yearsOfExperience,
  } = useSelector(({ Auth }) => Auth.user);
  const { isSaved, errors } = useSelector(({ Profile: UserProfile }) => UserProfile);

  const prevIsSaved = usePrevious(isSaved);

  const [skills, setSkills] = useState(skillsToArray(profile.skills_details) || []);

  const [isSubmitting, setIsSubmitting] = useState({
    profile: false,
    work: false,
    project: false,
    education: false,
  });

  const events = {
    user_id: userId,
    username: display_name,
    event_category: CATEGORIES.settings,
  };
  const reVerify = () => dispatch(verify({ silent: true }));

  const submissionStatus = (key, value) =>
    setIsSubmitting((prev) => ({
      ...prev,
      [key]: value,
    }));

  const skillsData = useMemo(() => {
    const skillsDetails = Object.entries(profile?.skills_details || {});

    const data = skillsDetails.map((entries) => {
      const [key, _skills] = entries;

      const skillArray = _skills.map(({ name, years_of_experience }) => ({
        name,
        yearsOfExperience: years_of_experience,
      }));

      return {
        title: skillsTitleDictionary[key],
        skills: skillArray,
      };
    });

    return data;
  }, [profile]);

  const projectData = useMemo(
    () =>
      userProject.map((project) => {
        const {
          start_month_display,
          start_year,
          category,
          title,
          avatar_url,
          details,
          skills: userSkills,
          project_link,
          repository_link,
          end_month_display,
          end_year,
          is_tunga_project,
        } = project;

        return {
          startMonth: start_month_display,
          startYear: start_year,
          project_link,
          repository_link,
          avatarUrl:
            avatar_url || "https://thumbs.dreamstime.com/z/website-url-background-5988824.jpg",
          category,
          skills: userSkills,
          details: details || "N/A",
          name: title || "N/A",
          endMonth: end_month_display,
          endYear: end_year,
          is_tunga_project,
          rawProject: {
            ...project,
          },
        };
      }),
    [userProject],
  );

  const experienceData = useMemo(
    () =>
      workExperience.map((work) => {
        const {
          start_month_display,
          start_year,
          details,
          company,
          position,
          end_month_display,
          end_year,
          location,
          skills: userSkills,
        } = work;

        return {
          startMonth: start_month_display,
          startYear: start_year,
          details,
          company,
          position,
          endMonth: end_month_display,
          endYear: end_year,
          location,
          skills: userSkills,
          rawWork: {
            ...work,
          },
        };
      }),
    [workExperience],
  );

  const educationData = useMemo(
    () =>
      educationExperience.map((education) => {
        const {
          start_month_display,
          start_year,
          institution,
          award,
          end_month_display,
          end_year,
          details,
        } = education;
        return {
          startMonth: start_month_display,
          startYear: start_year,
          institution,
          award,
          endMonth: end_month_display,
          endYear: end_year,
          details,
          rawEducation: {
            ...education,
          },
        };
      }),
    [educationExperience],
  );

  const onUpdateBio = (e) => {
    e.preventDefault();

    const modal = createModal({
      body: <BioForm bio={decodeHtmlEntities(profile?.bio || "")} id="bio-form" />,
      canClose: true,
      header: null,
      options: {
        title: `Bio`,
        ok: `Save changes`,
        form: {
          type: "submit",
          form: `bio-form`,
        },
      },
      proceed: ({ bio }) => {
        modal.setIsLoading(true);
        submissionStatus("profile", true);

        const trackBioUpdate = () => {
          modal.close();
          trackEvent(EVENTS.update_bio, events);
        };

        const feedbackCb = {
          successCb: trackBioUpdate,
          failureCb: () => {
            modal.setIsLoading(false);
            submissionStatus("profile", false);
          },
        };

        dispatch(
          updateProfile(
            profile?.id,
            {
              bio,
            },
            null,
            false,
            true,
            feedbackCb,
          ),
        );
      },
    });
    modal.open();
  };

  const handleSkillSuggestions = (suggestedSkillsArr, modal) => {
    modal.setIsLoading(true);
    const feedbackCb = {
      successCb: () => {
        modal.close();
        openAlertModal("Skill suggested successfully");
      },
      failureCb: () => {
        modal.setIsLoading(false);
      },
    };
    dispatch(suggestSkill({ suggestions: suggestedSkillsArr }, feedbackCb, true));
  };

  const openSuggestedSkill = (suggestedSkills) => {
    const modal = createModal({
      body: <SuggestedSkillForm id="suggested-skill-form" suggestedSkills={suggestedSkills} />,
      canClose: false,
      header: null,
      options: {
        title: `Suggested Skills`,
        canClose: true,
        ok: "Suggest Skills",
        cancel: "Discard Suggestions",
        form: {
          type: "submit",
          form: "suggested-skill-form",
        },
      },
      beforeClose: () => {
        dispatch({ type: RESET_SKILLS_ERRORS });
      },
      proceed: (suggestedSkillsArr) => {
        dispatch({ type: RESET_SKILLS_ERRORS });
        handleSkillSuggestions(suggestedSkillsArr, modal);
      },
    });
    modal.open();
  };

  const handleNextOnUpdateSkillSetsSuccess = (suggestedSkills, modal) => {
    trackEvent(EVENTS.update_skillsets, events);
    modal.close();

    if (suggestedSkills?.length) {
      openSuggestedSkill(suggestedSkills);
    }
  };

  const onUpdateSkillSets = (e) => {
    e.preventDefault();

    const modal = createModal({
      body: <SkillsetForm skills={skills} id="skillset-form" />,
      canClose: true,
      header: null,
      options: {
        title: `Your Skillset`,

        ok: `Save changes`,
        form: {
          type: "submit",
          form: `skillset-form`,
        },
      },
      proceed: (data) => {
        modal.setIsLoading(true);

        submissionStatus("profile", true);

        const feedbackCb = {
          successCb: () => handleNextOnUpdateSkillSetsSuccess(data.suggestedSkills, modal),
          failureCb: () => {
            modal.setIsLoading(false);
            submissionStatus("profile", false);
          },
        };

        dispatch(
          updateProfile(
            profile?.id,
            {
              skills: data.skills,
            },
            null,
            false,
            true,
            feedbackCb,
          ),
        );
      },
    });
    modal.open();
  };

  const onAddProject = (e, selectedProject = {}) => {
    e.preventDefault();

    const { rawProject: project = {} } = selectedProject || { rawProject: {} };

    const modal = createModal({
      body: <ProjectForm id="project-form" project={project} />,
      canClose: true,
      header: null,
      options: {
        title: `${project?.id ? "Edit" : "Add New"} Project`,
        ok: `${project?.id ? "Edit" : "Add"} Project`,
        form: {
          type: "submit",
          form: `project-form`,
        },
        hideActions: false,
      },
      proceed: (data) => {
        submissionStatus("project", true);
        modal.setIsLoading(true);

        const feedbackCb = {
          successCb: () => {
            modal.close();
            reVerify();
            trackEvent(EVENTS.update_projects, events);
          },
          failureCb: () => {
            modal.setIsLoading(false);

            submissionStatus("project", false);
          },
        };

        updateProject(project?.id || "", data)(dispatch, feedbackCb);
      },
    });
    modal.open();
  };

  const onAddWork = (e, selectedWork = {}) => {
    e.preventDefault();

    const { rawWork: work = {} } = selectedWork || { rawWork: {} };

    const modal = createModal({
      body: <WorkForm id="work-form" work={work} />,
      header: null,
      canClose: true,
      options: {
        title: `${work?.id ? "Edit" : "Add New"} Work Experience`,
        ok: `${work?.id ? "Edit" : "Add"} Work Experience`,
        form: {
          type: "submit",
          form: `work-form`,
        },
        hideActions: false,
      },
      proceed: (data) => {
        submissionStatus("work", true);
        modal.setIsLoading(true);

        const feedbackCb = {
          successCb: () => {
            modal.close();

            reVerify();
            trackEvent(EVENTS.update_work_experience, events);
          },
          failureCb: () => {
            modal.setIsLoading(false);
            submissionStatus("work", false);
          },
        };

        updateWork(work?.id || null, data)(dispatch, feedbackCb);
      },
    });
    modal.open();
  };

  const onAddEducation = (e, selectedEducation = {}) => {
    e.preventDefault();
    const { rawEducation: education = {} } = selectedEducation || { rawEducation: {} };

    const modal = createModal({
      body: <EducationForm id="educational-form" education={education} />,
      header: null,
      canClose: true,
      options: {
        title: `${education?.id ? "Edit" : "Add New"} Education`,
        ok: `${education?.id ? "Edit" : "Add"} Education`,
        hideActions: false,
        form: {
          type: "submit",
          form: `educational-form`,
        },
      },
      proceed: (data) => {
        submissionStatus("education", true);
        modal.setIsLoading(true);

        const feedbackCb = {
          successCb: () => {
            modal.close();

            reVerify();
            trackEvent(EVENTS.update_education, events);
          },
          failureCb: () => {
            modal.setIsLoading(false);

            submissionStatus("education", false);
          },
        };

        updateEducation(education?.id || null, data)(dispatch, feedbackCb);
      },
    });
    modal.open();
  };

  /* istanbul ignore next */
  const onDeleteExperience = (id, key) => {
    const feedbackCb = {
      successCb: reVerify,
      failureCb: () => null,
    };

    switch (key) {
      case "work":
        updateWork(id, {}, true)(dispatch, feedbackCb);
        break;

      case "project":
        updateProject(id, {}, true)(dispatch, feedbackCb);
        break;

      case "education":
        updateEducation(id, {}, true)(dispatch, feedbackCb);
        break;

      default:
        break;
    }
  };

  const openShareProfileModal = () => {
    if (share_settings === null) {
      dispatch(shareProfile("", false, { method: "post", silent: false }));
    }

    const modal = createModal({
      body: <ShareProfileModal id="share-profile-form" />,
      canClose: true,
      header: null,
      options: {
        title: "Share Profile",
        className: "share-profile-modal",
        hideActions: true,
      },
    });
    modal.open();
  };

  const openRequestTechnicalTestFormModal = async () => {
    const excludedTests = test_results?.coding_tests?.map(({ skill_name }) =>
      skill_name?.toLowerCase(),
    );

    const modal = createModal({
      body: (
        <RequestTechnicalTestForm
          id="request-technical-test-form"
          noIQTest={!test_results?.iq_test}
          excludedTests={excludedTests || []}
        />
      ),
      canClose: true,
      options: {
        title: "Request Technical Tests",
        className: "request-technical-test-modal",
        ok: "Send Request",
        form: {
          type: "submit",
          form: "request-technical-test-form",
        },
      },
      proceed: (data) => {
        modal.setIsLoading(true);
        const feedbackCb = {
          successCb: () => {
            modal.close();
          },
          failureCb: () => {
            modal.setIsLoading(false);
          },
        };
        requestTest(data, feedbackCb)(dispatch);
      },
    });
    modal.open();
  };

  const openExportSuccessModal = () => {
    const modal = createModal({
      body: <ExportGigCVSuccess id="gig-cv-export" />,
      canClose: true,
      options: {
        title: <p className="invisible">Success</p>,
        style: { paddingBottom: 0 },
        hideActions: true,
      },
      proceed: () => {
        modal.setIsLoading(true);
        const feedbackCb = {
          successCb: () => {
            modal.close();
          },
        };
        dispatch(gigcvExport(feedbackCb));
      },
    });
    modal.open();
  };

  const openExportModal = (e) => {
    e.preventDefault();

    const modal = createModal({
      body: <ExportGigCV id="gig-cv-export" />,
      canClose: true,
      header: null,
      options: {
        title: `Export Your Profile with GigCV`,
        ok: `Download`,
        hideCancel: true,
        form: {
          type: "submit",
          form: `gig-cv-export`,
        },
      },
      proceed: () => {
        modal.setIsLoading(true);
        const feedbackCb = {
          successCb: () => {
            modal.close();
            openExportSuccessModal();
          },
          failureCb: () => {
            modal.setIsLoading(false);
          },
        };
        dispatch(gigcvExport(feedbackCb));
      },
    });
    modal.open();
  };

  /* istanbul ignore next */
  const handleSuccessModals = useCallback(() => {
    switch (true) {
      case isSaved.profile:
        openAlertModal("Profile saved successfully");
        break;

      case isSaved.work:
        openAlertModal("Work Experience saved successfully");
        break;

      case isSaved.education:
        openAlertModal("Education saved successfully");
        break;

      case isSaved.project:
        openAlertModal("Project Experience saved successfully");
        break;

      default:
        break;
    }
  }, [isSaved?.profile, isSaved?.work, isSaved?.project, isSaved?.education]);

  /* istanbul ignore next */
  useDidUpdate(() => {
    switch (true) {
      case !prevIsSaved?.profile && isSaved?.profile:
        document.querySelector("#main-content").scrollTo(0, 0);
        submissionStatus("profile", false);
        break;

      case !prevIsSaved?.work && isSaved?.work:
        submissionStatus("work", false);
        break;

      case !prevIsSaved?.project && isSaved?.project:
        submissionStatus("project", false);
        break;

      case !prevIsSaved?.education && isSaved?.education:
        submissionStatus("education", false);
        break;

      default:
        break;
    }

    if (!isEqual(skills, skillsToArray(profile.skills_details))) {
      setSkills(skillsToArray(profile.skills_details));
    }
  }, [isSaved?.profile, isSaved?.work, isSaved?.project, isSaved?.education]);

  /* istanbul ignore next */
  useDidUpdate(() => {
    handleSuccessModals();
  }, [isSaved?.profile, isSaved?.work, isSaved?.project, isSaved?.education]);

  /* istanbul ignore next */
  useDidUpdate(() => {
    switch (true) {
      case !!errors.profile:
        openAlertModal(errors.profile, true);
        submissionStatus("profile", false);
        break;

      case !!errors.work:
        openAlertModal(errors.work, true);
        submissionStatus("work", false);
        break;

      case !!errors.education:
        openAlertModal(errors.education, true);
        submissionStatus("education", false);
        break;

      case !!errors.project:
        openAlertModal(errors.project, true);
        submissionStatus("project", false);
        break;

      default:
        break;
    }
  }, [errors?.profile, errors?.work, errors?.project, errors?.education]);

  return (
    <ContentSection className="position-relative">
      <TungaDevProfileOverview
        isEditable="true"
        showCloseButton="false"
        bio={decodeHtmlEntities(profile?.bio || "")}
        city={profile.city}
        countryName={profile.country_name}
        avatarUrl={avatarUrl}
        displayName={display_name}
        onEditClick={onUpdateBio}
        editInProgress={isSubmitting?.profile}
        isVetted={profile?.is_vetted || false}
        showHireButton="true"
        actionBtnText="SHARE PROFILE"
        yearsOfExperience={yearsOfExperience || ""}
        onHireBtnClick={openShareProfileModal}
        allowDataExport={true}
        onExportDataBtnClick={openExportModal}
      />
      <div className="bg-wrap">
        <TungaTabSwitch top="108px" />
        <div id="Tests">
          <TungaDevTestResult
            testResult={test_results}
            isUserView
            onTakeATestClick={openRequestTechnicalTestFormModal}
          />
        </div>

        <div className="folder" id="Skills">
          <TungaDevSkillsToolsSection
            isEditable="true"
            skillsData={skillsData}
            onEditClick={onUpdateSkillSets}
            editInProgress={isSubmitting?.profile}
          />
        </div>

        <div className="folder" id="Projects">
          <TungaProjectSection
            isEditable="true"
            addInProgress={isSubmitting?.project}
            projectData={projectData}
            onEditClick={onAddProject}
            onEditItemClick={(e) => onAddProject(e, e.detail)}
            onDeleteItemClick={({ detail }) => onDeleteExperience(detail.rawProject?.id, "project")}
          />
        </div>

        <div className="folder" id="Experience">
          <TungaWorkExperience
            isEditable="true"
            addInProgress={isSubmitting?.work}
            experienceData={experienceData}
            onEditClick={onAddWork}
            onEditItemClick={(e) => onAddWork(e, e.detail)}
            onDeleteItemClick={({ detail }) => onDeleteExperience(detail.rawWork?.id, "work")}
          />
        </div>

        <div className="folder" id="Education">
          <TungaEducationSection
            isEditable="true"
            addInProgress={isSubmitting?.education}
            educationData={educationData}
            onEditClick={onAddEducation}
            onEditItemClick={(e) => onAddEducation(e, e.detail)}
            onDeleteItemClick={({ detail }) =>
              onDeleteExperience(detail.rawEducation?.id, "education")
            }
          />
        </div>
      </div>
    </ContentSection>
  );
};

export const ContentSection = styled.div`
  padding-bottom: 100px !important;
  width: 100%;

  @media only screen and (min-width: 992px) {
    width: 90%;
  }

  .bg-wrap {
    background: #ffffff;
    border: 1px solid #e0e1e2;
    border-top: none;
    padding-bottom: 40px;
  }

  [id] {
    scroll-margin-top: 9.8rem;
  }
`;

export default Profile;
