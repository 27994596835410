/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                            External dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link, useRouteMatch, withRouter } from "react-router-dom";

/* -------------------------------------------------------------------------- */
/* -------------------------- Internal dependencies ------------------------- */
/* -------------------------------------------------------------------------- */
import { generateUserInitials } from "../../../../../utils/stringUtils";
import { isClient, isDev, isDevOrCM } from "../../../../../utils/auth";
import SummaryPlaceholder from "../../../../../components/SummaryPlaceholder/SummaryPlaceholder";
import IconButton from "../../../../../components/IconButton";
import Avatar from "../../../../../components/Avatar";
import { Table } from "./ClientReports";
import ReactTable from "../../../../../components/ReactTable";
import MetaTags from "../../../../../components/MetaTags";
import { getReportsTableColumns, reportsTableData } from "../../../../../utils/projectUtils";

const YourReports = ({ activities, count, onLoadMore, currentPage, pageSize }) => {
  const match = useRouteMatch();
  const { project } = useSelector(({ Projects }) => Projects);

  const isMissed = (date) => {
    return isClient() ? false : moment.utc(date).add(isDev() ? 1 : 3, "day") < moment.utc();
  };

  const calculateStatus = (activity) => {
    if (isMissed(activity.due_at)) {
      let clientReportStatus = false;
      if (project.category === "project") {
        clientReportStatus = activity.progress_reports.length === 0;
      } else {
        clientReportStatus = activity.developer_ratings.length === 0;
      }

      if (isDev() && activity.developer_ratings.length > 0) {
        return "completed";
      }

      if (isClient() && clientReportStatus) {
        return "overdue";
      }

      if (isDevOrCM() && activity.progress_reports.length === 0) {
        return "overdue";
      }
    }

    let clientReportStatus = false;
    if (project.category === "project") {
      clientReportStatus = activity.progress_reports.length > 0;
    } else {
      clientReportStatus = activity.developer_ratings.length > 0;
    }
    if ((isClient() || isDev()) && clientReportStatus) {
      return "completed";
    }

    if (activity.progress_reports.length > 0) {
      return "completed";
    }

    return "pending";
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const getTableDisplayValue = (cell) => {
    const activity = cell.value;
    const key = `cell-${cell.column.id}-${cell.row.id}`;

    switch (cell.column.id) {
      case "due_date": {
        return (
          <td key={`due_date${key}`} className="nowrap">
            {moment.utc(activity.due_at).format("DD MMM YYYY")}
          </td>
        );
      }

      case "report_name": {
        return (
          <td key={`report_name${key}`} className="nowrap">
            {activity.title}
          </td>
        );
      }

      case "user": {
        return (
          <td className="" key={`user${key}`}>
            <div className="d-flex align-items-center">
              <Avatar
                image={activity?.user?.avatar_url}
                initials={generateUserInitials(activity?.user)}
                size="dash"
              />
              {activity?.user?.display_name}
            </div>
          </td>
        );
      }

      case "status": {
        return (
          <td className="nowrap" key={`status${key}`}>
            <span className={`${calculateStatus(activity)}`}>
              {capitalize(calculateStatus(activity))}
            </span>
          </td>
        );
      }

      case "action": {
        return (
          <td className="nowrap d-table-cell" key={`action${key}`}>
            <span className="pl-0 d-block">
              <Link to={`${match.url}/${activity.id}`}>
                <IconButton className="btn-cta h-unset" name="arrow-right" size="sm" />
              </Link>
            </span>
          </td>
        );
      }

      default:
        return null;
    }
  };

  return (
    <>
      <MetaTags title="My Reports" description="Project progress reports." />

      {activities.length === 0 ? (
        <div>
          <SummaryPlaceholder
            className="page-with-filters"
            description="There are no reports yet for this project"
          />
        </div>
      ) : (
        <div className="project-reports table-responsive">
          <Table>
            <ReactTable
              tableData={reportsTableData(activities)}
              tableColumns={getReportsTableColumns()}
              currentPage={currentPage}
              count={count}
              getTableDisplayValue={getTableDisplayValue}
              loadPage={onLoadMore}
              pageSize={pageSize}
            />
          </Table>
        </div>
      )}
    </>
  );
};

export default withRouter(YourReports);
