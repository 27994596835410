import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Icon from "./Icon";

const Success = ({ message, variant, size, ...rest }) => {
  return variant === "icon" ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className="success" {...rest}>
      <Icon name="check" size={size || "lg"} className="status-icon" />
      <div>{message || "Changes saved succesfully!"}</div>
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Alert className={`alert alert-success${variant === "light" ? "-light" : ""}`} {...rest}>
      <Icon name="check" className="status-icon me-1" /> {message || ""}
    </Alert>
  );
};

const Alert = styled.div`
  &.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    display: flex;
    align-items: center;

    &-success {
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
    }
  }
`;

Success.propTypes = {
  message: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
};

export default Success;
