/* eslint-disable default-param-last */
import * as actionTypes from "../../configs/constants/ActionTypes";

const initState = {
  button: false,
  countries: [],
};

const common = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SET_BUTTON:
      return { button: action.data };

    case actionTypes.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: [...action.data],
      };

    case actionTypes.GET_COUNTRIES_FAILED:
      return {
        ...state,
        countries: [],
      };

    default:
      return state;
  }
};

export default common;
