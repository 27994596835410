import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import moment from "moment";
import PropTypes from "prop-types";

import DateFilter from "../../../../components/InvoiceFilters/DateFilter";
import StatusFilter from "../../../../components/InvoiceFilters/StatusFilter";
import Tag from "../../../../components/Tag";
import CSVDownload from "./CSVDownload";
import { canDownloadInvoice, canFilterByPSP, isDev } from "../../../../utils/auth";
import ColumnVisibility from "../../../../components/InvoiceFilters/ColumnVisibility";
import SortFilter from "../../../../components/InvoiceFilters/SortFilter";
import { useInvoices } from "../../../../context/InvoiceContext";
import BulkActions from "../../../../components/BulkActions";
import PaymentPlatformFilter from "../../../../components/InvoiceFilters/PaymentPlatformFilter";

const keyMaps = {
  "paid": "Paid",
  "archived": "Archived",
  "overdue": "Overdue",
  "pending": "Pending",
  "iban": "IBAN",
  "payoneer": "Payoneer",
  "dollar-account": "Dollar Account",
};

const formatDate = (date) => moment.utc(date).format("DD MMM YYYY");
const PayoutFilters = ({ checked, toggleAll, project, options }) => {
  const { payoutState, paymentColumns, updatePayoutState } = useInvoices();

  const removeDateFilter = useCallback(
    () => updatePayoutState("date", { from: null, to: null }),
    [],
  );

  const removeStatusFilter = useCallback(() => updatePayoutState("invoiceStatus", ["all"]), []);

  const removePaymentMethodFilter = useCallback(
    (type) => {
      if (payoutState.paymentPlatform.length > 1) {
        const newTypesArr = payoutState.paymentPlatform.filter((x) => x !== type);
        return updatePayoutState("paymentPlatform", newTypesArr);
      }

      return updatePayoutState("paymentPlatform", ["all"]);
    },
    [payoutState.paymentPlatform, updatePayoutState],
  );

  const renderTags = useMemo(() => {
    const entries = Object.entries(payoutState).filter(([key]) => key !== "ordering");
    const tags = entries.map(([key, value]) => {
      const isArrayAndEmpty = Array.isArray(value) && value.length === 0;
      const isDefaultValues = value?.length === 1 && value[0] === "all";
      const isObjectAndNull = !Array.isArray(value) && !Object.values(value).some(Boolean);

      if (isArrayAndEmpty || isDefaultValues || isObjectAndNull) return null;

      if (key === "date" && Object.values(value).some(Boolean)) {
        return (
          <Tag
            key={key}
            label={`${formatDate(value.from)} - ${formatDate(value.to)}`}
            onClickRemove={removeDateFilter}
          />
        );
      }

      if (key === "paymentPlatform")
        return value.map((type) => (
          <Tag
            key={type}
            label={keyMaps[type]}
            onClickRemove={() => removePaymentMethodFilter(type)}
          />
        ));

      return <Tag key={key} label={keyMaps[value[0]]} onClickRemove={removeStatusFilter} />;
    });

    return tags;
  }, [payoutState]);

  const allColumns = useMemo(
    () => ({
      main: paymentColumns.main,
      extra: paymentColumns.extra,
    }),
    [paymentColumns],
  );

  return (
    <Wrapper>
      <div className="d-flex justify-content-between align-items-center">
        <div className="left-col d-flex">
          <DateFilter paymentType="out" />
          {!isDev() && <StatusFilter paymentType="out" />}
          {canFilterByPSP() && <PaymentPlatformFilter />}
        </div>

        <div className="right-col d-flex align-items-center">
          {checked.length !== 0 && (
            <BulkActions checked={checked} options={options} toggleAll={toggleAll} />
          )}

          {checked.length === 0 && (
            <>
              <ColumnVisibility columns={allColumns} />

              <SortFilter type="out" />

              {canDownloadInvoice() && (
                <div className="ms-2">
                  <CSVDownload type="out" project={project} />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="d-flex mt-2 filter-display">{renderTags}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 17px;

  .left-col {
    column-gap: 10px;
  }

  .filter-display {
    min-height: 28px;
    column-gap: 8px;
    row-gap: 10px;
    flex-wrap: wrap;
  }
`;

PayoutFilters.propTypes = {
  checked: PropTypes.arrayOf(PropTypes.shape({})),
  project: PropTypes.arrayOf(PropTypes.shape({})),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  toggleAll: PropTypes.func,
};

export default PayoutFilters;
