/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

export const StyledWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #edf1f7;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 40px;

  .section {
    padding: 24px 0;
    border-bottom: 1px solid #edf1f7;

    &:first-of-type {
      padding-top: 0px;
    }
    &:last-of-type {
      padding-bottom: 0px;
      border-bottom: none;
    }

    .section-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #151a30;
    }

    p {
      font-size: 14px;
      line-height: 21px;
      color: #151a30;
      margin-bottom: 24px;
    }

    input,
    textarea {
      background: #ffffff;
      border: 1px solid rgba(194, 204, 217, 0.25);
      box-sizing: border-box;
      box-shadow: none;
      border-radius: 4px;
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 24px;
      color: #3e4857;
    }

    button {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #062e64;
      background: rgba(6, 46, 100, 0.05);
      border: none;
      border-radius: 4px;
      box-shadow: none;

      &:disabled {
        color: rgba(6, 46, 100, 0.25);
      }

      i {
        vertical-align: baseline;
      }
    }
  }
`;
