/* -------------------------------------------------------------------------- */
/*                            External dependencies                           */
/* -------------------------------------------------------------------------- */
import PropTypes from "prop-types";
import React from "react";
import { performAction, showAction } from "../../../../utils/invoiceUtils/invoiceActions";

const ActionItem = (props) => {
  const { action, invoice, className, children, disabled } = props;

  const btnStyle = {
    border: "none",
    background: "none",
    color: "#3e4857",
  };

  return showAction(action, invoice) ? (
    <button
      type="button"
      style={btnStyle}
      disabled={disabled}
      className={`btn-trans btn-approve d-flex align-items-center ${className}`}
      onClick={() => {
        performAction(action, invoice);
      }}
    >
      {children}
    </button>
  ) : null;
};

ActionItem.propTypes = {
  action: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  invoice: PropTypes.shape({}),
};

export default ActionItem;
