/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { FormGroup } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import IBAN from "iban";

import FieldError from "../FieldError";
import HelpIcon from "../svg/HelpIcon";
import { Label, Title } from "./styles";
import Input from "../Input";
import AccountOpenSection from "./AccountOpenSection";
import { REGION_SEPA, REGION_US } from "../../configs/constants/invoiceConstants";
import { isSepaCountry } from "../../utils/helpers";

const validationSchema = Yup.object().shape({
  region: Yup.string().oneOf([REGION_US, REGION_SEPA]).required("Region is required"),
  full_name: Yup.string().required("Account Holder Name is required"),
  account_number: Yup.string().when("region", {
    is: REGION_US,
    then: Yup.string()
      .min(8, "Account Number must be at least 8 characters")
      .max(17, "Account Number must be at most 17 characters")
      .required("Account Number is required"),
    otherwise: Yup.string().notRequired(),
  }),
  routing_number: Yup.string().when("region", {
    is: REGION_US,
    then: Yup.string()
      .required("Routing Number is required")
      .matches(/^\d{9}$/, "Routing Number must be exactly 9 digits"),
    otherwise: Yup.string().notRequired(),
  }),
  swift_bic: Yup.string().when("region", {
    is: REGION_US,
    then: Yup.string()
      .required("SWIFT Code is required")
      .matches(/^[A-Z0-9]{8}([A-Z0-9]{3})?$/, "SWIFT Code is not valid"),
    otherwise: Yup.string().matches(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/, "BIC is not valid"),
  }),
  address: Yup.string().when("region", {
    is: REGION_US,
    then: Yup.string().required("Address is required"),
    otherwise: Yup.string().notRequired(),
  }),
  iban: Yup.string().when("region", {
    is: REGION_SEPA,
    then: Yup.string()
      .required("IBAN is required")
      .test("is-valid-iban", "IBAN is not valid", (value) => IBAN.isValid(value))
      .test("is-sepa-country", "IBAN is not from a SEPA country", (value) => isSepaCountry(value)),
    otherwise: Yup.string().notRequired(),
  }),
});

const DollarAccountForm = ({ id, proceed, submitError, payload }) => {
  const form = useFormik({
    initialValues: {
      region: payload?.region || REGION_US,
      full_name: payload?.full_name || "",
      account_number: payload?.account_number || "",
      routing_number: payload?.routing_number || "",
      swift_bic: payload?.swift_bic || "",
      address: payload?.address || "",
      iban: payload?.iban || "",
    },
    validationSchema,
    onSubmit: (formData) => {
      proceed(formData);
    },
  });

  const handleKeyDown = (e) => {
    // Allow control keys like backspace, delete, arrow keys
    if (
      e.key === "Backspace" ||
      e.key === "Delete" ||
      e.key === "ArrowLeft" ||
      e.key === "ArrowRight" ||
      e.key === "Tab"
    ) {
      return;
    }
    // Prevent non-numeric key presses
    if (!/^\d$/.test(e.key)) {
      e.preventDefault();
    }
  };
  const isUSA = form.values.region === REGION_US;

  useEffect(() => {
    const newInitialValues = {
      ...payload,
      region: form.values.region,
    };

    if (!isUSA) {
      delete newInitialValues.routing_number;
      delete newInitialValues.address;
      delete newInitialValues.account_number;
    } else {
      delete newInitialValues.iban;
    }

    form.resetForm({ values: newInitialValues });
  }, [form.values.region]);

  return (
    <form className="mt-3" id={id} data-testid="dollar-account-form" onSubmit={form.handleSubmit}>
      <Title>
        Configure your payment details to receive USD payouts. Choose your region below to provide
        the appropriate banking information.
      </Title>

      {submitError && (
        <div style={{ marginBottom: "1em" }}>
          <FieldError message={submitError} />
        </div>
      )}

      <div>
        <Label htmlFor="region" className="mb-2">
          Select region
        </Label>

        <FormGroup className="custom-radio-group mb-4">
          <div className="radio-button">
            <input
              type="radio"
              name="region"
              value={REGION_US}
              id="usa"
              data-testid="region_usa"
              checked={form.values.region === REGION_US}
              onChange={() => form.setFieldValue("region", REGION_US)}
              required
            />
            <label htmlFor="usa">United States</label>
          </div>

          <div className="radio-button">
            <input
              type="radio"
              name="region"
              id="sepa"
              data-testid="region_sepa"
              value={REGION_SEPA}
              checked={form.values.region === REGION_SEPA}
              onChange={() => form.setFieldValue("region", REGION_SEPA)}
              required
            />
            <label htmlFor="sepa">SEPA Region</label>
          </div>
        </FormGroup>
      </div>

      <FormGroup className="mb-4">
        <Label htmlFor="full_name" className="mb-2">
          Full name of account holder
        </Label>
        <Input
          id="full_name"
          dataTestId="full_name"
          type="text"
          className="py-0"
          placeholder="Enter name"
          value={form.values.full_name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          required
        />
        {form.errors.full_name && form.touched.full_name && (
          <FieldError message={form.errors.full_name} />
        )}
      </FormGroup>

      {!isUSA && (
        <FormGroup className="mb-4">
          <Label className="d-flex justify-content-between mb-2" htmlFor="iban">
            <span className="d-inline-block">Iban</span>
          </Label>
          <Input
            id="iban"
            dataTestId="iban"
            type="text"
            className="py-0"
            placeholder="000000000"
            value={form.values.iban}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            required
          />
          {form.errors.iban && form.touched.iban && <FieldError message={form.errors.iban} />}
        </FormGroup>
      )}

      {isUSA && (
        <FormGroup className="mb-4">
          <Label className="d-flex justify-content-between mb-2" htmlFor="account_number">
            <span className="d-inline-block">Account Number</span>
          </Label>
          <Input
            id="account_number"
            dataTestId="account_number"
            type="text"
            onKeyDown={handleKeyDown}
            className="py-0"
            placeholder="000000000"
            value={form.values.account_number}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            min={0}
            required
          />
          {form.errors.account_number && form.touched.account_number && (
            <FieldError message={form.errors.account_number} />
          )}
        </FormGroup>
      )}

      {isUSA && (
        <FormGroup className="mb-4">
          <Label className="d-flex justify-content-between mb-2" htmlFor="routing_number">
            <span className="d-inline-block">Routing Number</span>
          </Label>
          <Input
            id="routing_number"
            dataTestId="routing_number"
            type="text"
            onKeyDown={handleKeyDown}
            min={0}
            className="py-0"
            placeholder="000000000"
            value={form.values.routing_number}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            required
          />
          {form.errors.routing_number && form.touched.routing_number && (
            <FieldError message={form.errors.routing_number} />
          )}
        </FormGroup>
      )}

      <FormGroup className="mb-4">
        <Label className="d-flex justify-content-between mb-2" htmlFor="swift_bic">
          <span className="d-inline-block">SWIFT/BIC Code</span>
          <span className="d-inline-block text-right align-self-end">
            <HelpIcon />
          </span>
        </Label>
        <Input
          id="swift_bic"
          dataTestId="swift_bic"
          type="text"
          className="py-0"
          placeholder="000000000"
          value={form.values.swift_bic}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          required={isUSA}
        />
        {form.errors.swift_bic && form.touched.swift_bic && (
          <FieldError message={form.errors.swift_bic} />
        )}
      </FormGroup>

      {isUSA && (
        <FormGroup className="mb-4">
          <Label className="d-flex justify-content-between mb-2" htmlFor="address">
            <span className="d-inline-block">Full Address</span>
          </Label>
          <Input
            id="address"
            dataTestId="address"
            type="text"
            className="py-0"
            placeholder="Street, City, State, Country"
            value={form.values.address}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            required
          />
          {form.errors.address && form.touched.address && (
            <FieldError message={form.errors.address} />
          )}
        </FormGroup>
      )}

      <AccountOpenSection
        title="Need to Set Up a Bank Account?"
        subtitle="Here are some platforms where you can set up a new account:"
      />
    </form>
  );
};

export default DollarAccountForm;
