/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";

const PayoutSummary = ({ children }) => {
  return (
    <Wrapper>
      <div className="inner">
        <svg
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 4.66634H13H1ZM3.66667 7.99967H4.33333H3.66667ZM7 7.99967H7.66667H7ZM3 10.6663H11C11.5304 10.6663 12.0391 10.4556 12.4142 10.0806C12.7893 9.70548 13 9.19677 13 8.66634V3.33301C13 2.80257 12.7893 2.29387 12.4142 1.91879C12.0391 1.54372 11.5304 1.33301 11 1.33301H3C2.46957 1.33301 1.96086 1.54372 1.58579 1.91879C1.21071 2.29387 1 2.80257 1 3.33301V8.66634C1 9.19677 1.21071 9.70548 1.58579 10.0806C1.96086 10.4556 2.46957 10.6663 3 10.6663Z"
            stroke="#A8B6C9"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <span className="title">Payouts based on platforms</span>
        {children}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #062e64;
  width: 475px;
  border-radius: 8px;
  color: #fff;
  padding: 12px 22px 16px;
  border: 4px solid #eeee;

  .inner {
    font-size: 12px;
    text-align: center;

    svg {
      margin-bottom: 0.16rem;
    }

    .title {
      margin-top: 10px;
      opacity: 0.65;
      margin-left: 4px;
    }
  }

  .summary-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    flex: 1;
  }

  .platform {
    flex: 1;
    font-size: 14px;
    position: relative;

    .liner {
      position: absolute;
      bottom: -25px;
      right: 0;
      color: #fff;
      opacity: 0.15;
    }
  }
`;

export default PayoutSummary;
