/* eslint-disable react/prop-types */
import { useFormik } from "formik";
import React from "react";
import { FormGroup } from "reactstrap";
import styled from "styled-components";
import moment from "moment";
import { object, date as yupDate, string, lazy } from "yup";

import Input from "../../../../components/Input";
import TextArea from "../../../../components/TextArea";
import FieldError from "../../../../components/FieldError";
import { isDate } from "../../../../utils/dateUtil";
import {
  StyledForm,
  CustomCheckboxWrapper,
  StyledDateTimePicker,
  WordCount,
} from "../../../../utils/styles";

const wordCount = 450;

const EducationForm = ({ id, education, proceed }) => {
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      institution: education?.institution || "",
      award: education?.award || "",
      details: education?.details || "",
      startDate:
        education?.start_year && education?.start_month
          ? new Date(education?.start_year, education.start_month - 1)
          : null,
      endDate:
        education?.end_year && education?.end_month
          ? new Date(education?.end_year, education.end_month - 1)
          : "present",
    },
    validationSchema: object().shape({
      institution: string().required(""),
      award: string().required(""),
      startDate: yupDate()
        .typeError("Start date is required")
        .test("no-future-date", "Start date shouldn't be in the future", (startDate) => {
          return new Date(startDate) < new Date();
        })
        .test(
          "not-greater-than-enDate",
          "Start date is greater than end date",
          (startDate, context) => {
            return (
              context.parent.endDate === "present" ||
              new Date(startDate) < new Date(context.parent.endDate)
            );
          },
        )
        .required("Start date is required"),
      endDate: lazy((date) => {
        switch (typeof date) {
          case "string":
            return string().required("End date is required");
          default:
            return yupDate().typeError("Start date is required").required("End date is required");
        }
      }),
    }),
    onSubmit: (formData) => {
      const start_day = new Date(formData.startDate);
      const end_day = new Date(formData.endDate);

      proceed({
        ...formData,
        start_month: start_day.getMonth() + 1,
        end_month: end_day.getMonth() + 1,
        start_year: start_day.getUTCFullYear(),
        end_year: end_day.getUTCFullYear(),
      });
    },
  });

  const onChangeValue = (key, value) => {
    form.setFieldValue(key, value);
  };

  return (
    <StyledForm id={id} data-testid="educational-form" onSubmit={form.handleSubmit}>
      <FormGroup>
        <label htmlFor="institution">
          Institution
          <LabelStyle>
            <span className="label-style">*</span>
          </LabelStyle>
        </label>

        <Input
          id="institution"
          name="institution"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder="Enter institution name"
          className="py-0"
          value={form.values.institution}
          required
        />
      </FormGroup>

      <FormGroup>
        <label htmlFor="award">
          Degree Awarded
          <LabelStyle>
            <span className="label-style">*</span>
          </LabelStyle>
        </label>

        <Input
          id="award"
          name="award"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder="Enter degree"
          className="py-0"
          value={form.values.award}
          required
        />
      </FormGroup>

      <div className="row">
        <div className="col-sm-12">
          <label htmlFor="timespan">
            Timespan
            <LabelStyle>
              <span className="label-style">*</span>
            </LabelStyle>
          </label>
        </div>
        <div className="col-sm-6">
          <FormGroup>
            <StyledDateTimePicker
              $calendar
              id="startDate"
              name="startDate"
              data-testid="startDate-input"
              className="tg-date-field"
              placeholder="Start date"
              format="MMM YYYY"
              $time={false}
              value={
                form.values.startDate && isDate(form.values.startDate)
                  ? new Date(form.values.startDate)
                  : null
              }
              onChange={
                /* istanbul ignore next */
                (date) => {
                  if (!isDate(date)) return;
                  onChangeValue("startDate", moment(date).format());
                }
              }
              onBlur={form.handleBlur}
              required
            />
            {form.touched.startDate && form.errors.startDate && (
              <FieldError message={form.errors.startDate} />
            )}
          </FormGroup>
        </div>
        <div className="col-sm-6">
          <FormGroup>
            <StyledDateTimePicker
              $calendar
              id="endDate"
              name="endDate"
              className="tg-date-field"
              placeholder="End date"
              format="MMM YYYY"
              valueDisplayFormat={{ dateStyle: "short" }}
              $time={false}
              value={
                form.values.endDate === "present" ||
                !form.values.endDate ||
                !isDate(form.values.startDate)
                  ? null
                  : new Date(form.values.endDate)
              }
              onChange={
                /* istanbul ignore next */
                (date) => {
                  if (!isDate(date)) return;

                  onChangeValue("endDate", moment(date).format());
                }
              }
              onBlur={form.handleBlur}
            />

            <CustomCheckboxWrapper className="custom-control custom-checkbox mt-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customCheck"
                onChange={() => {
                  onChangeValue(
                    "endDate",
                    form.values.endDate === "present"
                      ? ""
                      : form.values.endDate === null
                      ? "present"
                      : "present",
                  );
                }}
                checked={!!(form.values.startDate && form.values.endDate === "present")}
                disabled={!form.values.startDate}
              />
              <label className="custom-control-label" htmlFor="customCheck">
                Present
              </label>
            </CustomCheckboxWrapper>

            {form.touched.endDate && form.errors.endDate && (
              <FieldError message={form.errors.endDate} />
            )}
          </FormGroup>
        </div>
      </div>
      <FormGroup>
        <label className="control-label" htmlFor="details">
          Details (Optional)
        </label>

        <div className="position-relative">
          <TextArea
            id="details"
            name="details"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder="Enter details here"
            value={form.values.details}
            maxLength={wordCount}
          />
          <WordCount className="position-absolute">
            {wordCount - form.values.details.length}
          </WordCount>
        </div>
      </FormGroup>
    </StyledForm>
  );
};

const LabelStyle = styled.span`
  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
`;

export default EducationForm;
