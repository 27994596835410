import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonDropdown } from "reactstrap";

import Toggle from "./Toggle";
import Menu from "./Menu";
import FilterHead from "./FilterHead";
import CheckboxLabel from "./CheckboxLabel";
import { useInvoices } from "../../context/InvoiceContext";

const keyMaps = {
  "payoneer": "Payoneer",
  "iban": "IBAN",
  "dollar-account": "Dollar Account",
};

const PaymentPlatformFilter = () => {
  const { payoutState, updatePayoutState } = useInvoices();

  const [dropdownOpen, setDropdown] = useState(false);
  const [filters, setFilters] = useState(["all"]);

  const toggle = () => {
    setDropdown(!dropdownOpen);
  };

  const onDoneClick = () => {
    updatePayoutState("paymentPlatform", filters);
    toggle();
  };

  const updateFilters = (value) => {
    const newFilters = [...filters.filter((f) => f !== "all")];

    if (newFilters.includes(value)) {
      const n = newFilters.filter((f) => value !== f);
      const final = n.length ? n : ["all"];
      return setFilters(final);
    }

    return setFilters([...newFilters, value]);
  };

  useEffect(() => {
    setFilters(payoutState.paymentPlatform);
  }, [JSON.stringify(payoutState.paymentPlatform)]);

  return (
    <Wrapper className="">
      <ButtonDropdown className="add-btn" isOpen={dropdownOpen} toggle={toggle}>
        <Toggle label="Platforms" />

        <Menu label="Payment Platforms" minWidth="266px" onClick={onDoneClick}>
          <FilterHead label="Filter payment platforms" />

          <div className="options">
            {Object.entries(keyMaps).map(([key, label], index) => {
              return (
                <CheckboxLabel
                  key={index}
                  label={label}
                  value={key}
                  checked={filters.includes(key)}
                  onChange={() => updateFilters(key)}
                />
              );
            })}
          </div>
        </Menu>
      </ButtonDropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .options {
    padding: 0 17px;
  }

  hr {
    margin: 8px 0;
    opacity: 1;
    background-color: #edf1f7;
  }
`;

PaymentPlatformFilter.propTypes = {};

export default PaymentPlatformFilter;
