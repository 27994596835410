/* eslint-disable no-unused-vars */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import ProjectContainer from "./ProjectContainer";

const routes = [
  {
    path: ":step",
    component: ProjectContainer,
  },
];

const Project = () => {
  const match = useRouteMatch();

  return <ProjectContainer />;
};

export default Project;
