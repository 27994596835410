import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { CATEGORIES, EVENTS } from "../../../../analytics/events";
import { trackEvent } from "../../../../analytics/segment";
import downArrow from "../../../../assets/images/icons/chevron-down.svg";
import upArrow from "../../../../assets/images/icons/chevron-up.svg";
import cloud_download from "../../../../assets/images/icons/download-cloud.svg";
import cloud_upload from "../../../../assets/images/icons/upload-cloud.svg";
import { getInvoiceSummary, getPlatformSummary } from "../../../../redux/actions/InvoiceActions";
import { uploadCsv } from "../../../../redux/actions/PaymentActions";
import { cancelLastRequest, IBAN_UNPAID_EXPORT } from "../../../../utils/api";
import {
  canDownloadUnpaidIban,
  canUploadDeveloperInvoice,
  canViewPSPSummary,
  isAdmin,
  isAdminOrPMOrSpaceLead,
  isPayAdmin,
} from "../../../../utils/auth";
import { markAllInvoicesPrompt } from "../../../../utils/invoiceUtils";
import { openAlertModal } from "../../../../utils/modals";
import { numberWithCommas } from "../../../../utils/stringUtils";
import PayoutSummary from "../PayoutSummary";

const up_down = {
  height: "16px",
  marginTop: "1.9px",
  marginLeft: "10px",
  cursor: "pointer",
};

const arrow = {
  marginTop: "-6px",
  height: "14px",
  cursor: "pointer",
};

const DownloadIcon = ({ showExportInfo }) => (
  <span
    aria-hidden="true"
    style={{ marginTop: "-5px" }}
    tabIndex={0}
    role="button"
    data-testid="download"
    onClick={showExportInfo}
  >
    <img alt="dload" src={cloud_download} style={up_down} />
  </span>
);

const PaymentTotals = ({ type, project, summariesRange }) => {
  const [download, setDownload] = useState(true);
  const [unpaidIban, setUnpaidIban] = useState(0);
  const dispatch = useDispatch();
  const {
    summary,
    platformSummary,
    project: activeProject,
  } = useSelector(({ Invoice, Projects }) => ({
    summary: Invoice.summary,
    platformSummary: Invoice.platformSummary,
    project: Projects.project,
  }));
  const isPayment = type === "in";
  const title = isPayment ? "Payments" : "Payouts";

  const scope = project === undefined || project === null ? "sideBarPayment" : "project";

  const showUSDSummary =
    scope === "sideBarPayment" || // Show USD summary if on main payment page
    (scope === "project" && activeProject?.currency === "USD") || // Show USD summary if on project payment page and project currency is USD
    (scope === "project" && activeProject?.currency !== "USD" && summary?.USD?.total !== 0); // Show USD summary if on project payment page and project currency is EUR but summary total in USD as a value

  const showEURSummary =
    scope === "sideBarPayment" || // Show EUR summary if on main payment page
    (scope === "project" && activeProject?.currency !== "USD") || // Show EUR summary if on project payout page and project currency is EUR
    (scope === "project" && activeProject?.currency === "USD" && summary?.EUR?.total !== 0); // Show EUR summary if on project payment page and project currency is USD but summary total in EUR as a value

  const listClassName = showUSDSummary && showEURSummary ? "align-list" : "";

  const invoiceSummary = useCallback(() => {
    const queryParams = {
      min_date: summariesRange?.start,
      max_date: summariesRange?.end,
      type: type === "in" ? "sale" : "purchase",
    };

    if (project) queryParams.project = project;

    cancelLastRequest("getInvoiceSummary");
    getInvoiceSummary(queryParams, {})(dispatch);
    if (isAdmin() || isPayAdmin()) getPlatformSummary()(dispatch);
  }, [type]);

  const fileInput = useRef();

  const onUpload = (e) => {
    markAllInvoicesPrompt(e.target.files[0], uploadCsv);
  };

  const onTrigger = () => {
    /* Collecting node-element and performing click */
    fileInput.current.value = "";
    fileInput.current.click();
  };

  const showExportInfo = () => {
    // Notify user
    if (!(unpaidIban > 0)) openAlertModal("No unpaid payouts found");
  };

  useEffect(() => {
    invoiceSummary();
  }, [type]);

  /** handle unpaid iban payouts */
  useEffect(() => {
    const unpaid = platformSummary?.summary?.iban?.total_amount || 0;

    setUnpaidIban(unpaid);
  }, [platformSummary?.summary]);

  return (
    <Wrapper>
      <div className="project-payments">
        <div className="pay-summary">
          <ul className="p-0 mb-0">
            {showUSDSummary && showEURSummary && (
              <li className={listClassName}>
                <span className="invisible">Currency</span>
                {showEURSummary && <p className="currency">EUR</p>}
                <p className="currency">USD</p>
              </li>
            )}

            {isAdminOrPMOrSpaceLead() && (
              <li className={listClassName}>
                <span>Expected {title}</span>
                {showEURSummary && <p>€{numberWithCommas(summary?.EUR?.total_expected)}</p>}
                {showUSDSummary && <p>${numberWithCommas(summary?.USD?.total_expected)}</p>}
              </li>
            )}
            <li className={listClassName}>
              <span>Total {title}</span>
              {showEURSummary && <p>€{numberWithCommas(summary?.EUR?.total)}</p>}
              {showUSDSummary && <p>${numberWithCommas(summary?.USD?.total)}</p>}
            </li>
            <li className={listClassName}>
              <span>Paid</span>
              {showEURSummary && <p className="green">€{numberWithCommas(summary?.EUR?.paid)}</p>}
              {showUSDSummary && <p className="green">${numberWithCommas(summary?.USD?.paid)}</p>}
            </li>
            <li className={listClassName}>
              <span>Unpaid</span>
              {showEURSummary && <p className="red">€{numberWithCommas(summary?.EUR?.unpaid)}</p>}
              {showUSDSummary && <p className="red">${numberWithCommas(summary?.USD?.unpaid)}</p>}
            </li>

            {type === "in" && (
              <li className={listClassName}>
                <span>Credit Notes</span>
                {showEURSummary && (
                  <p>
                    -€
                    {summary?.EUR?.credit_notes
                      ? numberWithCommas(summary?.EUR?.credit_notes.total)
                      : "0.00"}
                  </p>
                )}
                {showUSDSummary && (
                  <p>
                    -$
                    {summary?.USD?.credit_notes
                      ? numberWithCommas(summary?.USD?.credit_notes.total)
                      : "0.00"}
                  </p>
                )}
              </li>
            )}
          </ul>

          {canViewPSPSummary() && scope === "sideBarPayment" && type === "out" && (
            <PayoutSummary>
              <div className="d-flex align-items-center">
                <div className="summary-wrapper">
                  {canViewPSPSummary() && (
                    <>
                      <div className="d-flex">
                        {Object.keys(platformSummary?.summary || {})?.map(
                          (platform, idx, array) => {
                            const nextElem = array[idx + 1];

                            return (
                              <span key={idx} className="mr-1 platform">
                                <i>
                                  {platform === "iban"
                                    ? platform?.toUpperCase()
                                    : platform.charAt(0).toUpperCase() + platform.slice(1)}
                                </i>

                                <span className="liner">{nextElem ? "|" : null}</span>
                              </span>
                            );
                          },
                        )}
                      </div>

                      <div className="d-flex align-items-center">
                        {Object.keys(platformSummary?.summary || {})?.map((platform, idx) => {
                          const currency = platform === "dollar-account" ? "$" : "€";
                          const totalAmount =
                            platform === "payoneer" ? (
                              <>
                                <p className="m-0">
                                  €
                                  {numberWithCommas(
                                    platformSummary.summary[platform].total_amount.EUR,
                                  ) || 0}
                                </p>
                                <p className="m-0">
                                  $
                                  {numberWithCommas(
                                    platformSummary.summary[platform].total_amount.USD,
                                  ) || 0}
                                </p>
                              </>
                            ) : (
                              <span className="m-0">
                                {currency}
                                {numberWithCommas(platformSummary.summary[platform].total_amount) ||
                                  0}
                              </span>
                            );

                          return (
                            <div key={idx} className="mr-1 platform">
                              {totalAmount}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>

                {canDownloadUnpaidIban() && download ? (
                  <>
                    {unpaidIban > 0 ? (
                      <a
                        href={IBAN_UNPAID_EXPORT}
                        style={{ all: "unset", marginTop: -5 }}
                        download
                        onClick={() => {
                          trackEvent(EVENTS.download_iban, {
                            event_category: CATEGORIES.payouts,
                            download: "Download IBAN",
                          });
                        }}
                      >
                        <DownloadIcon {...{ showExportInfo }} />
                      </a>
                    ) : (
                      <DownloadIcon {...{ showExportInfo }} />
                    )}
                    <span aria-hidden="true" className="ml-1" onClick={() => setDownload(false)}>
                      <img alt="hey" src={downArrow} style={arrow} />
                    </span>
                  </>
                ) : (
                  canUploadDeveloperInvoice() && (
                    <>
                      <input
                        type="file"
                        onChange={onUpload}
                        ref={fileInput}
                        accept=".xlsx"
                        id="file"
                        style={{ display: "none" }}
                      />
                      <span
                        aria-hidden="true"
                        style={{ marginTop: "-5px" }}
                        tabIndex={0}
                        role="button"
                        onClick={onTrigger}
                      >
                        <img alt="trigger" src={cloud_upload} style={up_down} />
                      </span>
                      <span aria-hidden="true" className="ml-1" onClick={() => setDownload(true)}>
                        <img alt="" src={upArrow} style={arrow} />
                      </span>
                    </>
                  )
                )}
              </div>
            </PayoutSummary>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

DownloadIcon.propTypes = {
  showExportInfo: PropTypes.func.isRequired,
};

PaymentTotals.propTypes = {
  project: PropTypes.number,
  type: PropTypes.string.isRequired,
  summariesRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
};

PaymentTotals.defaultProps = {
  project: null,
};

const Wrapper = styled.div`
  border-bottom: 1px solid #edf1f7;
  margin-top: 32px;

  .pay-summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ul {
    /* margin-top: 60px; */
    list-style: none;
    display: inline-flex;
    /* justify-content: space-evenly; */
    justify-content: space-between;
    width: 55%;
    /* margin-left: -1.5%; */

    li {
      text-align: center;
      text-align: center;
      text-transform: capitalize;
      line-height: 150%;

      &.align-list {
        margin: 0 auto 0 0;
      }

      span,
      .currency {
        font-weight: 500;
        font-size: 14px;
        color: #8f9bb3;
      }

      p {
        font-weight: 500;
        font-size: 1.1rem;
        margin-top: 8px;
      }
    }

    .green {
      color: #219653;
    }

    .red {
      color: #eb5757;
    }

    li p {
      color: #3e4857;
    }
  }
`;

export default PaymentTotals;
