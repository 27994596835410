import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import DOMPurify from "dompurify";

import { useDispatch, useSelector } from "react-redux";
import Success from "../../../components/Success";
import Error from "../../../components/Error";
import Avatar from "../../../components/Avatar";
import Button from "../../../components/Button";
import { STATUS_INTERESTED, STATUS_UNINTERESTED } from "../../../utils/api";
import MetaTags from "../../../components/MetaTags";
import {
  removeInterest,
  showInterest,
  fetchOpportunity,
} from "../../../redux/actions/OpportunityActions";
import Progress from "../../../components/Progress";
import SummaryPlaceholder from "../../../components/SummaryPlaceholder/SummaryPlaceholder";
import OpportunityClosedAlert from "./components/OpportunityClosedAlert";
import { OPPORTUNITY_RESET_MESSAGES } from "../../../configs/constants/ActionTypes";
import { success as successAction } from "../../../utils/actions";
import { isDev } from "../../../utils/auth";
import Input from "../../../components/Input";

const OpportunityDetails = ({ match }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ Auth }) => Auth);
  const { isMakingRequest, opportunityReducer } = useSelector(({ Opportunities }) => Opportunities);
  const { opportunity, success, error } = opportunityReducer;

  const LINK = `${process.env.REACT_APP_RECRUITEE_REFERRAL_BASE_LINK}${opportunity?.slug}?source=${user?.email}`;

  const [copied, setCopied] = useState(false);

  const isAvailable = opportunity?.status === "published";

  const onInterestUpdate = (status) => {
    if (status === STATUS_INTERESTED) {
      dispatch(showInterest(opportunity.id));
    } else {
      dispatch(removeInterest(opportunity.id));
    }
  };

  const copyToClipboard = (value) => {
    setCopied(true);

    navigator.clipboard.writeText(value);

    setTimeout(() => setCopied(false), 2000);
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(successAction(OPPORTUNITY_RESET_MESSAGES));
    }, 2000);
  }, [success, error]);

  useEffect(() => {
    if (user.id) {
      dispatch(fetchOpportunity(match.params.id));
    }
  }, [user?.id]);

  const description = () => {
    return { __html: DOMPurify.sanitize(opportunity.description) };
  };
  return (
    <>
      <MetaTags
        title="Recruitee Opportunities Details"
        description="Recruitee Opportunities Details"
      />
      {isMakingRequest.loading ? (
        <Progress />
      ) : opportunity ? (
        <OpportunityWrapper>
          <div className="bs-content-card">
            {success && <Success message="Successfully submitted your availability" />}
            {error && <Error message="Something went wrong! Please try again." />}
            <div className="section">
              <div className="header">Opportunity Details</div>
            </div>
            <div className="section">
              <div className="title">Title</div>
              <p>{opportunity.title}</p>
            </div>
            <div className="section">
              <div className="title">Description</div>
              <div dangerouslySetInnerHTML={description()} />
            </div>
            {opportunity?.hiring_manager && (
              <div className="section">
                <div className="row">
                  <div className="col">
                    <div className="title">Created By</div>
                    <div>
                      <Avatar
                        image={opportunity.hiring_manager.photo_thumb_url}
                        initials={opportunity.hiring_manager.initials}
                        size="dash"
                        className="bsd-mb6"
                      />
                      {opportunity.hiring_manager.first_name} {opportunity.hiring_manager.last_name}
                    </div>
                  </div>
                  <div className="col">
                    <div className="title">Created On</div>
                    <p>
                      {moment.utc(opportunity.created_at).local().format("DD MMM YYYY; hh:mm A")}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {isDev() && opportunity?.user_status && (
              <div className="section">
                {!isAvailable && <OpportunityClosedAlert />}
                {opportunity.user_status.self_disqualified ? (
                  <Button
                    onClick={() => onInterestUpdate(STATUS_INTERESTED)}
                    data-testid="available"
                    className="primary"
                    disabled={isMakingRequest.isUpdatingInterest || !isAvailable}
                  >
                    I&apos;m available and interested
                  </Button>
                ) : !opportunity.user_status.disqualified ? (
                  <Button
                    variant="secondary"
                    onClick={() => onInterestUpdate(STATUS_UNINTERESTED)}
                    data-testid="not_available"
                    disabled={isMakingRequest.isUpdatingInterest}
                  >
                    I&apos;m no longer available
                  </Button>
                ) : (
                  <Button className="primary" disabled>
                    Disqualified
                  </Button>
                )}
              </div>
            )}
            {isDev() && !opportunity?.user_status && (
              <div className="section">
                {!isAvailable && <OpportunityClosedAlert />}

                <Button
                  className="primary"
                  onClick={() => onInterestUpdate(STATUS_INTERESTED)}
                  data-testid="available_and_interested"
                  disabled={isMakingRequest.isUpdatingInterest || !isAvailable}
                >
                  I&apos;m available and interested
                </Button>
              </div>
            )}
          </div>

          <div>
            <div className="bs-content-card refer-card">
              <div className="header">Refer and Earn</div>

              <div className="fw-bolder">
                Do you know someone that would be a good fit for the opportunity?
                <ol>
                  <li>Share your unique link with your friends/network.</li>
                  <li> Earn a reward of 250 euros if they get hired.</li>
                </ol>
              </div>

              <div>
                <label className="fw-bolder" htmlFor="referral-link">
                  Your Referral Link
                </label>

                <div style={{ position: "relative" }}>
                  <Input className="mb-3" id="referral-link" value={LINK} disabled />

                  <CopyContainer
                    onClick={() => {
                      copyToClipboard(LINK);
                    }}
                    data-testid="linkCopy"
                  >
                    {copied ? "Copied" : "Copy Link"}
                  </CopyContainer>
                </div>
              </div>
            </div>
          </div>
        </OpportunityWrapper>
      ) : (
        <SummaryPlaceholder className="page" description="Opportunity not found" />
      )}
    </>
  );
};

const OpportunityWrapper = styled.div`
  display: grid;
  grid-template-columns: 490px 420px;
  column-gap: 28px;

  @media screen and (min-width: 1200px) {
    grid-template-columns: 640px 457px;
    column-gap: 44px;
  }

  .bs-content-card {
    box-shadow: none;
  }

  .section {
    padding: 24px 0;
    border-bottom: 1px solid #edf1f7;
    font-size: 16px;
    line-height: 24px;

    &:first-of-type {
      padding-top: 0px;
      padding-bottom: 34px;
    }

    &:last-of-type {
      border-bottom: none;
    }

    .title {
      font-weight: 500;
      color: #151a30;
      margin-bottom: 16px;
    }

    p {
      color: #3e4857;
      margin-bottom: 0;
    }

    .list {
      display: flex;
      flex-wrap: wrap;

      span {
        padding: 7px 24px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #062e64;
        background: rgba(6, 46, 100, 0.04);
        border: 1px solid rgba(6, 46, 100, 0.04);
        box-sizing: border-box;
        border-radius: 50px;
        margin-right: 16px;
      }
    }

    .avatar.avatar-dash {
      width: 35px;
      height: 35px;

      &.avatar-initials {
        font-size: 14px;
      }
    }

    button {
      background: rgba(6, 46, 100, 0.05);
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;
      color: #062e64;
      box-shadow: none;
      border: none;

      &.primary {
        background: #da3451;
        color: white;
      }

      i {
        margin-right: 18px;
      }
    }
  }

  .header {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #151a30;
  }

  .refer-card {
    padding: 24px;

    .header {
      font-weight: 700;
      margin-bottom: 12px;
    }

    ol {
      margin-top: 12px;
    }
  }

  .opportunity-files {
    list-style-type: none;
  }

  .interest-user {
    padding: px-to-rem(10);
    margin-bottom: 10px;
    color: get-color("white");
    background-color: get-color("dark-grey");
    border: 1px solid rgba(0, 0, 0, 0.125);

    .avatar {
      margin-right: 5px;
    }
  }

  .interest-actions {
    .btn {
      color: get-color("dark-grey");
      margin-right: 5px;
    }

    .btn-accepted {
      color: get-color("green");
    }

    .btn-rejected {
      color: get-color("red");
    }
  }

  .opportunity-actions {
    .btn {
      margin-right: 10px;
    }
  }
  .application-closed {
    height: 40px;
    background: rgba(235, 87, 87, 0.05);
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #eb5757;
    padding: 8px 18px;
  }
`;

const CopyContainer = styled("button")`
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 5px;
  background-color: #da3451;
  color: #ffffff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
  font-weight: 500;
  height: 100%;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
`;

OpportunityDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    url: PropTypes.string,
  }),
};

export default OpportunityDetails;
