import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PayoneerForm from "../components/Payment/PayoneerForm";
import PayoneerHandler from "../components/Payment/PayoneerHandler";
import { addPayoneer, fetchPaymentProviders, fetchPayoneer } from "../redux/actions/PaymentActions";
import { createModal, openAlertModal } from "../utils/modals";
import { trackEvent } from "../analytics/segment";
import { CATEGORIES, EVENTS } from "../analytics/events";

const useConnectPayoneer = () => {
  const dispatch = useDispatch();

  const { payoneerProvider, errors, payoneer } = useSelector(({ Payments }) => Payments);
  const { user } = useSelector(({ Auth }) => Auth);

  const [submitError, setSubmitError] = useState(null);
  const [triggerDefaultPrompt, setShouldTriggerPrompt] = useState(false);

  const connectPayoneer = () => {
    setSubmitError(null);

    const modal = createModal({
      body: (
        <PayoneerForm id="payoneer-connect-form" payload={payoneer[0]} submitError={submitError} />
      ),
      canClose: true,
      header: null,
      options: {
        title: `Connect Payoneer`,
        className: "modal-payments",
        ok: "Connect",
        hideActions: false,
        form: {
          type: "submit",
          form: "payoneer-connect-form",
        },
        style: {
          paddingBottom: 0,
        },
      },
      proceed: (data) => {
        modal.setIsLoading(true);
        setSubmitError(null);

        const trackPayment = () => {
          trackEvent(EVENTS.add_payment_provider, {
            user_id: user.id,
            username: user.display_name,
            event_category: CATEGORIES.settings,
            psp: "Payoneer",
          });
        };

        const feedbackCb = {
          successCb: () => {
            trackPayment();
            modal.close();
          },
          failureCb: () => modal.setIsLoading(false),
        };
        dispatch(addPayoneer(data, feedbackCb));
      },
    });
    modal.open();
  };

  const openConnectionHandler = () => {
    const modal = createModal({
      body: (
        <PayoneerHandler
          message={payoneerProvider.message}
          redirect_url={payoneerProvider.redirect_url}
        />
      ),
      header: null,
      canClose: true,
      options: {
        title: `Connect Payoneer`,
        className: "modal-payments",
        style: {
          paddingBottom: 0,
        },
        hideActions: true,
      },
    });
    modal.open();
  };

  // Handle success
  useEffect(() => {
    if (payoneerProvider.success) {
      if (
        payoneerProvider?.message?.toLowerCase() ===
        "payoneer account is already connected and is active"
      ) {
        openAlertModal(payoneerProvider.message);
        dispatch(fetchPayoneer({ silent: true }));
        dispatch(fetchPaymentProviders());
        setShouldTriggerPrompt(true);
      } else {
        openConnectionHandler();
      }
    }
  }, [payoneerProvider?.success]);

  useEffect(() => {
    if (submitError) {
      connectPayoneer();
    }
  }, [submitError]);

  // Handle error
  useEffect(() => {
    if (errors.addPayoneer) {
      setSubmitError(errors.addPayoneer);
    }
  }, [errors.addPayoneer]);

  return { connectPayoneer, triggerDefaultPrompt, setShouldTriggerPrompt };
};

export default useConnectPayoneer;
