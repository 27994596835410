/* eslint-disable default-param-last */
import { combineReducers } from "redux";
import { LOCATION_CHANGE } from "react-router-redux";

import * as actionTypes from "../../configs/constants/ActionTypes";

const defaultIsRetrieving = {
  profile: false,
  settings: false,
  work: false,
  education: false,
  share_settings: false,
};

function profile(state = {}, action) {
  switch (action.type) {
    case actionTypes.SET_USER_PROFILE:
      return { ...state, ...action.data };
    case actionTypes.SET_USER_SETTINGS:
      return {
        ...state,
        settings: { ...action.data },
      };
    default:
      return state;
  }
}

function onBoardingStage(state = { stage: actionTypes.ONBOARDING_STAGE_ONE }, action) {
  switch (action.type) {
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        stage: action.data.stage,
      };

    case actionTypes.UPDATE_ONBOARDING_STAGE: {
      return {
        ...state,
        stage: action.data.stage,
      };
    }
    default:
      return state;
  }
}

function isRetrieving(state = defaultIsRetrieving, action) {
  switch (action.type) {
    case actionTypes.RETRIEVE_SETTINGS_START:
      return { ...state, settings: true };
    case actionTypes.RETRIEVE_SETTINGS_SUCCESS:
    case actionTypes.RETRIEVE_SETTINGS_FAILED:
      return { ...state, settings: false };

    case actionTypes.SHARE_PROFILE_START:
      return { ...state, share_settings: true };
    case actionTypes.SHARE_PROFILE_SUCCESS:
    case actionTypes.SHARE_PROFILE_FAILED:
      return { ...state, share_settings: false };
    default:
      return state;
  }
}

function isSaved(state = defaultIsRetrieving, action) {
  switch (action.type) {
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: true,
      };

    case actionTypes.UPDATE_PROFILE_FAILED:
      return { ...state, profile: false };

    case actionTypes.UPDATE_WORK_SUCCESS:
      return { ...state, work: true };

    case actionTypes.UPDATE_WORK_FAILED:
      return { ...state, work: false };

    case actionTypes.UPDATE_PROJECTS_SUCCESS:
      return { ...state, project: true };

    case actionTypes.UPDATE_PROJECTS_FAILED:
      return { ...state, project: false };

    case actionTypes.UPDATE_EDUCATION_SUCCESS:
      return { ...state, education: true };

    case actionTypes.UPDATE_EDUCATION_FAILED:
      return { ...state, education: false };

    case actionTypes.UPDATE_COMPANY_SUCCESS:
      return { ...state, company: true };

    case actionTypes.SHARE_PROFILE_FAILED:
      return { ...state, share_settings: false };

    case actionTypes.SHARE_PROFILE_SUCCESS:
      return { ...state, share_settings: true };

    case LOCATION_CHANGE:
      return defaultIsRetrieving;

    default:
      return state;
  }
}

function errors(state = {}, action) {
  switch (action.type) {
    case actionTypes.UPDATE_PROFILE_START:
      return { profile: "" };

    case actionTypes.UPDATE_PROFILE_FAILED:
      return { profile: action.error };

    case actionTypes.UPDATE_WORK_FAILED:
      return { ...state, work: action.error };

    case actionTypes.UPDATE_WORK_SUCCESS:
      return { ...state, work: null };

    case actionTypes.UPDATE_PROJECTS_FAILED:
      return { ...state, project: action.error };

    case actionTypes.UPDATE_PROJECTS_SUCCESS:
      return { ...state, project: null };

    case actionTypes.UPDATE_EDUCATION_FAILED:
      return { ...state, education: action.error };

    case actionTypes.UPDATE_EDUCATION_SUCCESS:
      return { ...state, education: null };

    case actionTypes.UPDATE_COMPANY_FAILED:
      return { ...state, company: action.error };

    case actionTypes.SHARE_PROFILE_SUCCESS:
      return { ...state, share_settings: null };

    case actionTypes.SHARE_PROFILE_FAILED:
      return { ...state, share_settings: action.error };

    case LOCATION_CHANGE:
      return { ...{}, company: null };

    default:
      return state;
  }
}

const Profile = combineReducers({
  profile,
  onBoardingStage,
  errors,
  isSaved,
  isRetrieving,
});

export default Profile;
