/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import moment from "moment";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import Avatar from "../../../../../components/Avatar";
import IconButton from "../../../../../components/IconButton";
import { isClient, isDev } from "../../../../../utils/auth";
import { createModal } from "../../../../../utils/modals";
import { generateUserInitials } from "../../../../../utils/stringUtils";
import ModalHeader from "../components/ModalHeader";
import TeamModalContent from "../components/TeamModalContent";
import DeveloperSurveyContent from "../components/DeveloperSurveyContent";
import MetaTags from "../../../../../components/MetaTags";
import ReactTable from "../../../../../components/ReactTable";
import { Table } from "./ClientReports";
import { getReportsTableColumns, reportsTableData } from "../../../../../utils/projectUtils";
import { PROGRESS_EVENT_TYPE_DEVELOPER_SURVEY } from "../../../../../utils/api";

const TeamReports = ({
  activities,
  count,
  onLoadMore,
  currentPage,
  pageSize,
  shouldDisplaySingleReport,
}) => {
  const { progress_event } = useSelector(({ Projects }) => Projects);

  const isMissed = (date) => {
    return isClient() ? false : moment.utc(date).add(isDev() ? 1 : 3, "day") < moment.utc();
  };

  const getActivityStatus = (activity) => {
    switch (true) {
      case activity.progress_reports.length > 0:
        return "Completed";
      case isMissed(activity.due_at):
        return "Overdue";
      default:
        return "Pending";
    }
  };

  const openTeamReport = (activity) => {
    const isSurvey = activity.type === PROGRESS_EVENT_TYPE_DEVELOPER_SURVEY;

    const createdAt = isSurvey ? activity.created_at : activity.progress_reports[0].created_at;
    const bodyContent = isSurvey ? (
      <DeveloperSurveyContent activity={activity} />
    ) : (
      <TeamModalContent activity={activity} />
    );

    const modal = createModal({
      body: bodyContent,
      canClose: true,
      header: (
        <ModalHeader
          title={activity.title}
          created_at={new Date(createdAt)}
          dismiss={() => modal.close()}
        />
      ),
      options: {
        title: "Report Title",
        className: "modal-report",
      },
      proceed: () => modal.close(),
    });
    modal.open();
  };

  const getTableDisplayValue = (cell) => {
    const activity = cell.value;
    const key = `cell-${cell.column.id}-${cell.row.id}`;

    switch (cell.column.id) {
      case "due_date": {
        return (
          <td key={`due_date${key}`} className="nowrap">
            {moment.utc(activity.due_at).format("DD MMM YYYY")}
          </td>
        );
      }

      case "report_name": {
        return (
          <td key={`report_name${key}`} className="nowrap">
            {activity.title}
          </td>
        );
      }

      case "user": {
        return (
          <td className="" key={`user${key}`}>
            <div className="d-flex align-items-center">
              <Avatar
                image={activity?.user?.avatar_url}
                initials={generateUserInitials(activity?.user)}
                size="dash"
              />
              {activity?.user?.display_name}
            </div>
          </td>
        );
      }

      case "status": {
        const status = getActivityStatus(activity);
        const className = status.toLocaleLowerCase();

        return (
          <td className="nowrap" key={`status${key}`}>
            <span className={className}>{status}</span>
          </td>
        );
      }

      case "action": {
        return (
          <td className="nowrap d-table-cell" key={`action${key}`}>
            <span className="pl-0 d-block">
              {activity.progress_reports.length > 0 && (
                <IconButton
                  onClick={() => openTeamReport(activity)}
                  className="btn-cta h-unset mt-1"
                  data-testid="arrow-right-btn"
                  name="arrow-right"
                  size="sm"
                />
              )}
            </span>
          </td>
        );
      }

      default:
        return null;
    }
  };

  useEffect(() => {
    if (shouldDisplaySingleReport) {
      openTeamReport(progress_event);
    }
  }, [shouldDisplaySingleReport]);

  return (
    <>
      <MetaTags title="Team Reports" description="Project progress reports." />

      <div className="project-reports table-responsive">
        <Table>
          <ReactTable
            tableData={reportsTableData(activities)}
            tableColumns={getReportsTableColumns()}
            currentPage={currentPage}
            count={count}
            getTableDisplayValue={getTableDisplayValue}
            loadPage={onLoadMore}
            pageSize={pageSize}
          />
        </Table>
      </div>
    </>
  );
};

TeamReports.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      due_at: PropTypes.string,
      title: PropTypes.string,
      progress_reports: PropTypes.arrayOf(
        PropTypes.shape({
          created_at: PropTypes.string,
        }),
      ),
      user: PropTypes.shape({
        avatar_url: PropTypes.string,
      }),
    }),
  ),
  count: PropTypes.number,
  onLoadMore: PropTypes.func,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  shouldDisplaySingleReport: PropTypes.bool,
};
export default TeamReports;
