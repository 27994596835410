/* eslint-disable react/jsx-props-no-spreading */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useMemo } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import MetaTags from "../../../components/MetaTags";
import StepOne from "./StepOne";
import { isClient } from "../../../utils/auth";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";

const OnboardingWizard = ({ setWizard }) => {
  const location = useLocation();

  let current = 1;
  let title = "";
  const steps = isClient() ? 2 : 3;

  const pageTitle = useMemo(() => {
    let pageTitleString;

    switch (true) {
      case location.pathname.includes("/onboard/step-one"):
        pageTitleString = "Onboarding - Personal Details";
        break;

      case location.pathname.includes("/onboard/step-two"):
        pageTitleString = "Onboarding - Address";

        break;

      case location.pathname.includes("/onboard/step-three"):
        pageTitleString = "Onboarding - Payments";
        break;

      default:
        pageTitleString = "Onboarding";
        break;
    }

    return pageTitleString;
  }, [location.pathname]);

  useEffect(() => {
    setWizard({
      title,
      steps,
      current,
    });
  }, [location.pathname]);

  return (
    <>
      <MetaTags
        title={pageTitle}
        description="Join Tunga in just 3 steps. Start with step one and complete your onboarding process to become a part of our developer community."
      />

      <StyledContainerCard className="content-wrapper container">
        <div className="create-content">
          <Switch>
            <Redirect exact from="/onboard" to="/onboard/intro" />
            <Route
              path="/onboard/step-one"
              render={(routeProps) => {
                current = 1;
                title = "Personal Details";
                return <StepOne {...routeProps} />;
              }}
            />
            <Route
              path="/onboard/step-two"
              render={(routeProps) => {
                current = 2;
                title = "Address";
                return <StepTwo {...routeProps} />;
              }}
            />

            {!isClient() && (
              <Route
                path="/onboard/step-three"
                render={(routeProps) => {
                  current = 3;
                  title = "Payments";

                  return <StepThree {...routeProps} />;
                }}
              />
            )}
          </Switch>
        </div>
      </StyledContainerCard>
    </>
  );
};

const StyledContainerCard = styled.div`
  width: 800px;
  background: #ffffff;
  border: 1px solid #edf1f7;
  border-radius: 6px;
  box-shadow: 0 1px 1px 0 hsla(0, 0%, 80%, 0.5);
  padding: 0;
  margin-top: 40px;
  margin-bottom: 113px;

  .content-nav {
    padding: 20px 40px;
    display: flex;
    justify-content: flex-end;
    background: rgba(237, 241, 247, 0.25);

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #151a30;
      margin-right: 16px;
    }

    button.previous {
      background: transparent;
      border: none;
      box-shadow: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #062e64;
      padding-left: 0;

      i {
        vertical-align: baseline;
        font-size: 14px;
        margin-right: 12px;
      }
    }

    button.next {
      background: #da3451;
      border: none;
      box-shadow: none;
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }
  }
`;
/* --------------------------- Component propTypes -------------------------- */
const propTypes = {
  setWizard: PropTypes.func,
};

OnboardingWizard.propTypes = propTypes;

export default OnboardingWizard;
