/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: relative;
`;

const Icon = styled.div`
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 2px;

  background-position: center;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjUgMEgxLjVDMS4xMDIzIDAuMDAwMzkyNjQ4IDAuNzIwOTkzIDAuMTU4NTU0IDAuNDM5Nzc0IDAuNDM5Nzc0QzAuMTU4NTU0IDAuNzIwOTkzIDAuMDAwMzkyNjQ4IDEuMTAyMyAwIDEuNVYxNi41QzAuMDAwMzkyODk2IDE2Ljg5NzcgMC4xNTg1NTQgMTcuMjc5IDAuNDM5Nzc0IDE3LjU2MDJDMC43MjA5OTMgMTcuODQxNCAxLjEwMjMgMTcuOTk5NiAxLjUgMThIMTYuNUMxNi44OTc3IDE3Ljk5OTYgMTcuMjc5IDE3Ljg0MTQgMTcuNTYwMiAxNy41NjAyQzE3Ljg0MTQgMTcuMjc5IDE3Ljk5OTYgMTYuODk3NyAxOCAxNi41VjEuNUMxNy45OTk2IDEuMTAyMyAxNy44NDE0IDAuNzIwOTkzIDE3LjU2MDIgMC40Mzk3NzNDMTcuMjc5IDAuMTU4NTU0IDE2Ljg5NzcgMC4wMDAzOTI0IDE2LjUgMFpNMTMuNjQyOCA3LjI5MTk5TDguMTQyMzMgMTIuNTQyQzguMDAyOTkgMTIuNjc1NSA3LjgxNzQ4IDEyLjc1IDcuNjI0NTEgMTIuNzVDNy40MzE1NSAxMi43NSA3LjI0NjAzIDEyLjY3NTUgNy4xMDY2OSAxMi41NDJMNC4zNTcxOCA5LjkxNjk5QzQuMjg1ODEgOS44NDkwNCA0LjIyODUzIDkuNzY3NjggNC4xODg2NCA5LjY3NzU3QzQuMTQ4NzQgOS41ODc0NiA0LjEyNyA5LjQ5MDM2IDQuMTI0NjcgOS4zOTE4NEM0LjEyMjM0IDkuMjkzMzIgNC4xMzk0NiA5LjE5NTMxIDQuMTc1MDQgOS4xMDM0MUM0LjIxMDYzIDkuMDExNTEgNC4yNjM5OSA4LjkyNzUzIDQuMzMyMDcgOC44NTYyOEM0LjQwMDE0IDguNzg1MDIgNC40ODE2IDguNzI3ODkgNC41NzE3OCA4LjY4ODE1QzQuNjYxOTYgOC42NDg0MSA0Ljc1OTA5IDguNjI2ODUgNC44NTc2MiA4LjYyNDY4QzQuOTU2MTQgOC42MjI1MiA1LjA1NDEzIDguNjM5ODEgNS4xNDU5NiA4LjY3NTU2QzUuMjM3OCA4LjcxMTMgNS4zMjE2OSA4Ljc2NDgxIDUuMzkyODIgOC44MzMwMUw3LjYyNDUxIDEwLjk2MjlMMTIuNjA3MiA2LjIwODAxQzEyLjY3ODMgNi4xMzk4MSAxMi43NjIyIDYuMDg2MyAxMi44NTQgNi4wNTA1NkMxMi45NDU5IDYuMDE0ODEgMTMuMDQzOSA1Ljk5NzUyIDEzLjE0MjQgNS45OTk2OEMxMy4yNDA5IDYuMDAxODUgMTMuMzM4IDYuMDIzNDEgMTMuNDI4MiA2LjA2MzE1QzEzLjUxODQgNi4xMDI4OSAxMy41OTk5IDYuMTYwMDIgMTMuNjY3OSA2LjIzMTI4QzEzLjczNiA2LjMwMjUzIDEzLjc4OTQgNi4zODY1MSAxMy44MjUgNi40Nzg0MUMxMy44NjA1IDYuNTcwMzEgMTMuODc3NyA2LjY2ODMyIDEzLjg3NTMgNi43NjY4NEMxMy44NzMgNi44NjUzNiAxMy44NTEzIDYuOTYyNDYgMTMuODExNCA3LjA1MjU3QzEzLjc3MTUgNy4xNDI2OCAxMy43MTQyIDcuMjI0MDQgMTMuNjQyOCA3LjI5MTk5SDEzLjY0MjhaIiBmaWxsPSIjMDYyRTY0Ii8+Cjwvc3ZnPgo=");
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  transition: all 150ms;
  ${(props) => (props.checked ? "border: 2px solid #062e64" : "border: 2px solid #8f9bb3")};

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 2px #da3451;
  }

  ${HiddenCheckbox}:disabled + & {
    opacity: 0.5;
  }
`;

/* ---------------PropType Check---------------- */

const propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

const Checkbox = ({ className, checked, onChange, disabled, ...rest }) => (
  <CheckboxContainer className={className}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <HiddenCheckbox checked={checked} onChange={onChange} disabled={disabled} {...rest} />
    <StyledCheckbox checked={checked} disabled={disabled}>
      <Icon />
    </StyledCheckbox>
  </CheckboxContainer>
);

Checkbox.propTypes = propTypes;

export default Checkbox;
