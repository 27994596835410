/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import fileDownload from "js-file-download";
import moment from "moment";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import styled from "styled-components";

import Icon from "../../../../components/Icon";
import SectionNav from "../../../../components/SectionNav";
import { TIME_SHEET_MONTH_VIEW } from "../../../../configs/constants/global";
import {
  canViewInvoiceSummary,
  getPermissionByCodeName,
  isAdminOrPMOrSpaceLead,
  isClient,
  isDev,
  isInternalUser,
} from "../../../../utils/auth";
import { filterPaymentSummaries } from "../../../../utils/invoiceUtils";
import PaymentTotals from "../../payments/components/PaymentTotals";
import InvoiceListContainer from "../../payments/InvoiceListContainer";
import Payments from "../../payments/Payments";
import Payouts from "../../payments/Payouts";
import { NavActions } from "../../payments/styles";
import Timesheets from "../../payments/Timesheets";
import ViewSelector from "./components/ViewSelector";
import { InvoiceProvider } from "../../../../context/InvoiceContext";
import useURLQuery from "../../../../hooks/useURLQuery";
import { convertToAndString } from "../../../../utils/helpers";

const keyMaps = {
  "all": "All",
  "paid": "Paid",
  "sale": "Sale",
  "final": "Final",
  "credit-notes": "Credit Note",
  "commitment-payments": "Commitment",
  "archived": "Archived",
  "overdue": "Overdue",
  "pending": "Pending",
};

const PaymentContainer = ({ project }) => {
  const urlQuery = useURLQuery();

  const canViewPayouts = getPermissionByCodeName("can-view-payouts");
  const canViewTimesheets = getPermissionByCodeName("can-view-timesheets");

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [openBtn, setOpenBtn] = useState(false);

  const [createAction, setcreateAction] = useState(null);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const [currentMonth, setCurrentMonth] = useState(
    new Date()
      .toLocaleString("en", { month: "2-digit", year: "numeric" })
      .split("/")
      .reverse()
      .join("-"),
  );

  const [currentView, setCurrentView] = useState(TIME_SHEET_MONTH_VIEW);

  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const [summariesRange, setSummariesRange] = useState({
    start: `${moment(firstDay).format(moment.HTML5_FMT.DATE)}T00:00:00`,
    end: `${moment(new Date()).format(moment.HTML5_FMT.DATE)}T23:59:59`,
  });

  const type = pathname?.split("/")[4];

  const paymentType = type === "payments" ? "in" : "out";

  const { csv } = useSelector(({ Invoice }) => Invoice);

  const gotoSelectedMonth = (month, year) => {
    setCurrentView(TIME_SHEET_MONTH_VIEW);

    setCurrentYear(year);

    setCurrentMonth(
      new Date(`${month} ${year}`)
        .toLocaleString("en", { month: "2-digit", year: "numeric" })
        .split("/")
        .reverse()
        .join("-"),
    );
  };

  useEffect(() => {
    if (csv) {
      const isPayments = type === "in";
      const status = urlQuery.get("status") || "all";
      const types = urlQuery.get("type") || "";

      const typeString = types ? types?.split(",")?.map((v) => keyMaps[v]) : [];

      const name = `${keyMaps[status]} ${convertToAndString(typeString)} ${
        isPayments ? "Payments" : "Payouts"
      }.csv`;

      fileDownload(csv, name);
      dispatch({ type: "CLEAR_CSV" });
    }
  }, [csv]);

  return (
    <>
      <SectionNav
        urlExact={false}
        links={[
          ...(isClient() || isInternalUser()
            ? [
                {
                  route: `projects/${project.id}/pay/payments`,
                  name: "Payments",
                },
              ]
            : []),
          ...(canViewPayouts
            ? [
                {
                  route: `projects/${project.id}/pay/payouts`,
                  name: "Payouts",
                },
              ]
            : []),
          ...(canViewTimesheets
            ? [
                {
                  route: `projects/${project.id}/pay/timesheets`,
                  name: "Timesheets",
                },
              ]
            : []),
        ]}
        rightActions={
          <>
            {type !== "timesheets" && isInternalUser() && (
              <WrapperNavActions>
                <button
                  type="button"
                  className="text-capitalize btn filter-btn ml-0"
                  onClick={() =>
                    filterPaymentSummaries(
                      summariesRange,
                      paymentType,
                      setSummariesRange,
                      project.id,
                    )
                  }
                >
                  <Icon name="filter-variant" size="xs" /> Filter Total {type}
                </button>

                {createAction && createAction.visibility && isInternalUser() && (
                  <ButtonDropdown isOpen={openBtn} toggle={() => setOpenBtn(!openBtn)}>
                    <DropdownToggle color="primary" className="create-btn dropdown-toggle">
                      <Icon name="round-add" className="bsd-icon" />
                      Create
                      <Icon
                        style={{ marginTop: "10px" }}
                        name="arrow-down"
                        size="sm"
                        className="bsd-icon"
                      />
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu">
                      {(createAction.add ? createAction.add : []).map((item, idx) => {
                        return (
                          <DropdownItem
                            key={idx}
                            className="dropdown-item add-btn"
                            onClick={item.action}
                          >
                            {item.title}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </ButtonDropdown>
                )}
              </WrapperNavActions>
            )}

            {type === "timesheets" && (
              <NavActions>
                <ViewSelector
                  currentView={currentView}
                  currentMonth={currentMonth}
                  setCurrentView={setCurrentView}
                  setCurrentYear={setCurrentYear}
                  setCurrentMonth={setCurrentMonth}
                />
              </NavActions>
            )}
          </>
        }
      />

      {type !== "timesheets" && (
        <>
          {canViewInvoiceSummary() && (
            <PaymentTotals
              type={type === "payments" ? "in" : "out"}
              project={project.id}
              summariesRange={summariesRange}
            />
          )}

          {/* <SectionNav
            links={[
              ...(isDev()
                ? [
                    {
                      route: `projects/${project.id}/pay/${type}/all`,
                      name: "All",
                    },
                    {
                      route: `projects/${project.id}/pay/${type}/pending`,
                      name: "Pending",
                    },
                    {
                      route: `projects/${project.id}/pay/${type}/paid`,
                      name: "Paid",
                    },
                  ]
                : [
                    {
                      route: `projects/${project.id}/pay/${type}/all`,
                      name: "All",
                    },
                    {
                      route: `projects/${project.id}/pay/${type}/overdue`,
                      name: "Overdue",
                    },
                    {
                      route: `projects/${project.id}/pay/${type}/pending`,
                      name: "Pending",
                    },
                    {
                      route: `projects/${project.id}/pay/${type}/paid`,
                      name: "Paid",
                    },
                    ...(type === "payments"
                      ? [
                          {
                            route: `projects/${project.id}/pay/${type}/credit-notes`,
                            name: "Credit Notes",
                          },
                          {
                            route: `projects/${project.id}/pay/${type}/commitment-payments`,
                            name: "Commitment Payments",
                          },
                        ]
                      : []),
                    {
                      route: `projects/${project.id}/pay/${type}/archived`,
                      name: "Archived",
                    },
                  ]),
            ]}
            style={{ marginTop: "40px" }}
            rightActions={null}
          /> */}
        </>
      )}

      <Switch>
        <Route
          exact
          path={`/projects/${project.id}/pay/timesheets`}
          render={() => {
            return (
              <Timesheets
                currentView={currentView}
                currentYear={currentYear}
                currentMonth={currentMonth}
                gotoSelectedMonth={gotoSelectedMonth}
              />
            );
          }}
        />
        <Route
          exact
          path={`/projects/${project.id}/pay/${type}`}
          render={(props) => {
            return (
              <InvoiceProvider>
                <InvoiceListContainer
                  {...props}
                  type={type === "payments" && (isClient() || isInternalUser()) ? "in" : "out"}
                  project={project}
                >
                  {type === "payments" && (isClient() || isInternalUser()) ? (
                    <Payments {...props} setcreateAction={setcreateAction} />
                  ) : (
                    <Payouts {...props} setcreateAction={setcreateAction} />
                  )}
                </InvoiceListContainer>
              </InvoiceProvider>
            );
          }}
        />

        <Redirect
          exact
          from={`/projects/${project.id}/pay/`}
          to={`/projects/${project.id}/pay/${
            isDev() ? "payouts/" : isAdminOrPMOrSpaceLead() ? "payments/" : "timesheets"
          }`}
        />
      </Switch>
    </>
  );
};

const WrapperNavActions = styled(NavActions)`
  .filter-btn {
    background: #ffffff;
    padding: 1em;
    border-radius: 4px;
    border: 1px solid #e3e9f2;
    color: #8f9bb3;
    box-shadow: none !important;
  }

  .filter-btn,
  .create-btn {
    margin: 0 !important ;
  }

  .btn-group {
    margin-left: 10px !important ;
  }

  .create-btn {
    background-color: #da3451;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    padding: 0 2em;
  }

  .dropdown-toggle::after {
    display: none;
  }

  /*override reactstrap inline styles*/
  .dropdown-menu {
    transform: translate3d(0px, 54px, 0px) !important;
    left: initial !important;
    right: 0px;
    border: 1px solid #edf1f7 !important;
    box-shadow: 0px 4px 8px rgba(62, 72, 87, 0.04) !important;
    border-radius: 6px !important;
    padding: 10px !important;
    line-height: 2rem;
    margin-top: -10px;

    .dropdown-item,
    .dropdown-item:hover {
      padding: 0;
      margin: 0;
      font-size: 0.9rem;
      background-color: #fff !important;

      &:active {
        color: initial;
        background-color: initial;
      }
    }
  }
`;

PaymentContainer.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

export default PaymentContainer;
