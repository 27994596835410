import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonDropdown } from "reactstrap";

import Toggle from "./Toggle";
import Menu from "./Menu";
import FilterHead from "./FilterHead";
import RadioBoxLabel from "./RadioBoxLabel";
import { useInvoices } from "../../context/InvoiceContext";
import { arrayToObject } from "../../utils/helpers";

const EmailStatusFilter = () => {
  const { paymentState, updatePaymentState } = useInvoices();

  const { invoiceEmailStatus } = paymentState;

  const [dropdownOpen, setDropdown] = useState(false);
  const [filters, setFilters] = useState([""]);

  const keyMaps = arrayToObject([
    {
      "pending": "Pending",
    },
    {
      "failed": "Failed",
    },
    {
      "sent": "Sent",
    },
  ]);

  const toggle = () => {
    setDropdown(!dropdownOpen);
  };

  const onDoneClick = () => {
    updatePaymentState("invoiceEmailStatus", filters);
    toggle();
  };

  useEffect(() => {
    setFilters(invoiceEmailStatus);
  }, [JSON.stringify(invoiceEmailStatus)]);

  return (
    <Wrapper className="">
      <ButtonDropdown className="add-btn" isOpen={dropdownOpen} toggle={toggle}>
        <Toggle label="Email Status" />

        <Menu label="Invoice Email Status" minWidth="266px" onClick={onDoneClick}>
          <FilterHead label="Filter status" />

          <div className="options">
            {Object.entries(keyMaps).map(([key, label], index) => {
              return (
                <RadioBoxLabel
                  key={index}
                  label={label}
                  value={key}
                  checked={filters.includes(key)}
                  onChange={() => setFilters([key])}
                />
              );
            })}
          </div>
        </Menu>
      </ButtonDropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .options {
    padding: 0 17px;
  }

  hr {
    margin: 8px 0;
    opacity: 1;
    background-color: #edf1f7;
  }
`;

EmailStatusFilter.propTypes = {};

export default EmailStatusFilter;
