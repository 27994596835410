import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  TungaDevProfileOverview,
  TungaTabSwitch,
  TungaProjectSection,
  TungaWorkExperience,
  TungaEducationSection,
  TungaDevSkillsToolsSection,
  TungaDevTestResult,
} from "@tunga/tunga-components-react";

import { devProfile } from "../../../../redux/actions/ProjectActions";
import useRightNav from "../../../../layouts/RightSideNav/useRightNav";
import Progress from "../../../../components/Progress";
import { skillsTitleDictionary } from "../../../../configs/constants/global";

const UserProfile = ({ userId }) => {
  const { devProfiles } = useSelector(({ Projects }) => Projects);
  const { data } = devProfiles;

  const dispatch = useDispatch();

  const { close } = useRightNav();

  const educationData = useMemo(
    () =>
      data?.education.map(
        ({
          start_month_display,
          start_year,
          institution,
          award,
          end_month_display,
          end_year,
          details,
        }) => ({
          startMonth: start_month_display,
          startYear: start_year,
          institution,
          award,
          endMonth: end_month_display,
          endYear: end_year,
          details,
        }),
      ) || [],
    [data],
  );

  const experienceData = useMemo(
    () =>
      data?.work_experience.map(
        ({
          start_month_display,
          start_year,
          details,
          company,
          position,
          end_month_display,
          end_year,
          location,
          skills,
        }) => ({
          startMonth: start_month_display,
          startYear: start_year,
          details,
          company,
          position,
          endMonth: end_month_display,
          endYear: end_year,
          location,
          skills,
        }),
      ) || [],
    [data],
  );

  const projectData = useMemo(
    () =>
      data?.projects.map(
        ({
          start_month_display,
          start_year,
          category,
          title,
          avatar_url,
          details,
          skills,
          project_link,
          repository_link,
          end_month_display,
          end_year,
          is_tunga_project,
        }) => ({
          startMonth: start_month_display,
          startYear: start_year,
          project_link,
          repository_link,
          avatarUrl:
            avatar_url || "https://thumbs.dreamstime.com/z/website-url-background-5988824.jpg",
          category,
          skills,
          details: details || "N/A",
          name: title || "N/A",
          endMonth: end_month_display,
          endYear: end_year,
          is_tunga_project,
        }),
      ) || [],
    [data],
  );

  const isEmptyArrayAndObject = (testResultObj) => {
    if (testResultObj === undefined) return true;
    const isArrayEmpty =
      Array.isArray(testResultObj?.coding_tests || []) && testResultObj?.coding_tests?.length === 0;
    const isObjectEmpty =
      !testResultObj?.mbti_profile && !testResultObj?.iq_test && !testResultObj?.sa_test;

    return isArrayEmpty && isObjectEmpty;
  };

  const skillsData = useMemo(() => {
    const skillsDetails = Object.entries(data?.skills_details || {});

    const sData = skillsDetails.map((entries) => {
      const [key, _skills] = entries;

      const skillArray = _skills.map(({ name, years_of_experience }) => ({
        name,
        yearsOfExperience: years_of_experience,
      }));

      return {
        title: skillsTitleDictionary[key],
        skills: skillArray,
      };
    });

    return sData;
  }, [data]);

  useEffect(() => {
    devProfile(userId)(dispatch);
  }, [userId]);

  return (
    <>
      {devProfiles.loading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Progress />
        </div>
      ) : (
        <div className="p-4">
          <TungaDevProfileOverview
            displayName={data?.user?.display_name || ""}
            bio={data?.bio || "N/A"}
            city={data?.city || ""}
            avatarUrl={data?.user?.avatar_url || ""}
            countryName={data?.country_name || ""}
            payoutRate={data?.payment_structure?.payout_rate || ""}
            yearsOfExperience={data?.years_of_experience ?? 0}
            onCloseIconClick={() => close()}
            isVetted={`${data?.is_vetted}`}
          />
          <TungaTabSwitch />
          <div id="Tests">
            <TungaDevTestResult
              testResult={
                !isEmptyArrayAndObject(data?.test_results) ? JSON.stringify(data.test_results) : ""
              }
            />
          </div>
          <div id="Skills">
            <TungaDevSkillsToolsSection skillsData={skillsData} />
          </div>
          <div id="Projects">
            <TungaProjectSection projectData={projectData} />
          </div>
          <div id="Experience">
            <TungaWorkExperience experienceData={experienceData} />
          </div>
          <div id="Education">
            <TungaEducationSection educationData={educationData} />
          </div>
        </div>
      )}
    </>
  );
};

UserProfile.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default UserProfile;
