/* eslint-disable no-use-before-define */
/* eslint-disable no-useless-escape */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState, useEffect, useRef, useContext } from "react";
import Switch from "react-switch";
import moment from "moment";
import styled from "styled-components";
import { FormGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import _ from "lodash";

/* -------------------------- Internel Dependencies ------------------------- */

import { getPermissionByCodeName, isCMOrCSOAndHasProjectAcess } from "../../../../../utils/auth";
import Button from "../../../../../components/Button";
import Input from "../../../../../components/Input";
import TextArea from "../../../../../components/TextArea";
import Icon from "../../../../../components/Icon";
import FieldError from "../../../../../components/FieldError";
import { StyledDateTimePicker } from "../../createProjects/style";
import { TempProjectContext } from "../../createProjects/TempProjectContext";
import { openConfirm } from "../../../../../utils/modals";
import { updateProject } from "../../../../../redux/actions/ProjectActions";
import { StyledWrapper } from "./style";
import { trackEvent } from "../../../../../analytics/segment";
import { CATEGORIES, EVENTS } from "../../../../../analytics/events";
import { validateEmailString } from "../../../../../utils/helpers";

const emailSchema = Yup.string().email("Invalid email format").required("Email is required");

const dateFormatter = (date) => moment(date).format("MM/DD/YYYY");

const updateRef = (prevPropsRef, newProps, setCanSaveFunction) => {
  const prevProps = { ...prevPropsRef.current };

  const propsChanged = !_.isEqual(prevProps, newProps);

  if (propsChanged) {
    // eslint-disable-next-line no-param-reassign
    prevPropsRef.current = { ...newProps };
    setCanSaveFunction(false);
  }
};

const compareAndSet = (prevProps, newProps, setFunction, keys, formatter) => {
  const propsChanged = keys.some((key) => {
    const prevValue = formatter ? formatter(prevProps[key]) : prevProps[key];
    const newValue = formatter ? formatter(newProps[key]) : newProps[key];
    return !_.isEqual(prevValue, newValue);
  });
  setFunction(propsChanged);
};

const getPayoutDate = (day) => {
  if (!day) return null;

  const payoutDate = new Date();
  payoutDate.setDate(day);

  return payoutDate;
};

const startOfMonth = moment().startOf("month").toDate();
const endOfMonth = moment().endOf("month").toDate();

const TeamDetails = () => {
  const canConvertProject = getPermissionByCodeName("can-convert-project");
  const canArchiveAProject = getPermissionByCodeName("can-archive-a-project");
  const canUpdateProjectDetails = getPermissionByCodeName("can-update-project-details");

  const dispatch = useDispatch();
  const [project, setProject] = useContext(TempProjectContext);
  const [error, setError] = useState("");
  const [accountManagersErr, setAccountManagersErr] = useState("");

  useEffect(() => {
    setProject({ ...project });
  }, []);

  const validateEmail = () => {
    emailSchema
      .validate(projectDetails.invoicing_email)
      .then(() => {
        setError("");
      })
      .catch((er) => {
        setError(er.message);
      });
  };

  const validateAccountManagers = async () => {
    const emails = projectDetails.account_managers.split(",").map((email) => email.trim());

    const validationResults = await Promise.all(emails.map((email) => validateEmailString(email)));

    const invalidEmails = validationResults.filter((result) => result !== false);

    if (invalidEmails.length > 0) {
      setAccountManagersErr("Invalid email address.");
    } else {
      setAccountManagersErr("");
    }
  };

  const { project: projectFromState, isMakingRequest } = useSelector(({ Projects }) => Projects);
  const {
    id,
    title,
    description,
    archived,
    category,
    expected_start_date,
    expected_end_date,
    payment_structure,
    invoicing_email,
    cc_primary_email,
    account_managers,
    payment_day,
  } = projectFromState;
  const [projectDetails, setprojectDetails] = useState({
    id,
    title,
    description,
    expected_start_date,
    expected_end_date,
    payment_structure,
    amount: payment_structure?.amount,
    hours_per_week: payment_structure?.hours_per_week,
    invoicing_email,
    cc_primary_email,
    account_managers,
    payment_day,
  });

  const eventProperties = {
    project_type: projectFromState.category,
    project_id: projectFromState.id,
    project_name: projectFromState.title,
    event_category: CATEGORIES.projects,
  };

  const [canSave, setcanSave] = useState(false);
  const [canSaveDuration, setCanSaveDuration] = useState(false);
  const [canSavePayment, setCanSavePayment] = useState(false);
  const [canSavePayout, setCanSavePayout] = useState(false);

  const prevProjectDetailsPropsRef = useRef();
  const durPrevProjectDetailsPropsRef = useRef();
  const payPrevProjectDetailsPropsRef = useRef();
  const payoutPrevProjectDetailsPropsRef = useRef();

  useEffect(() => {
    prevProjectDetailsPropsRef.current = {
      title,
      description,
    };
    durPrevProjectDetailsPropsRef.current = {
      expected_start_date,
      expected_end_date,
    };
    payPrevProjectDetailsPropsRef.current = {
      hours_per_week: projectDetails.hours_per_week,
      amount: projectDetails.amount,
      payment_type: projectDetails.payment_type,
      invoicing_email,
    };
    payoutPrevProjectDetailsPropsRef.current = {
      payment_day,
    };
  }, []);

  useEffect(() => {
    if (!isMakingRequest.update) {
      updateRef(prevProjectDetailsPropsRef, { title, description }, setcanSave);

      updateRef(
        durPrevProjectDetailsPropsRef,
        { expected_start_date, expected_end_date },
        setCanSaveDuration,
      );

      updateRef(
        payPrevProjectDetailsPropsRef,
        {
          invoicing_email: projectDetails.invoicing_email,
          cc_primary_email: projectDetails.cc_primary_email,
          account_managers: projectDetails.account_managers,
        },
        setCanSavePayment,
      );

      updateRef(
        payoutPrevProjectDetailsPropsRef,
        {
          payment_day: projectDetails.payment_day,
        },
        setCanSavePayout,
      );
    }
  }, [isMakingRequest.update]);

  useEffect(() => {
    compareAndSet(prevProjectDetailsPropsRef.current, projectDetails, setcanSave, [
      "title",
      "description",
    ]);

    compareAndSet(
      durPrevProjectDetailsPropsRef.current,
      projectDetails,
      setCanSaveDuration,
      ["expected_start_date", "expected_end_date"],
      dateFormatter,
    );

    compareAndSet(payPrevProjectDetailsPropsRef.current, projectDetails, setCanSavePayment, [
      "invoicing_email",
      "cc_primary_email",
      "account_managers",
    ]);

    compareAndSet(payoutPrevProjectDetailsPropsRef.current, projectDetails, setCanSavePayout, [
      "payment_day",
    ]);
  }, [projectDetails]);

  const handleChange = (date) => {
    setprojectDetails({
      ...projectDetails,
      payment_day: new Date(date).getDate(),
    });
  };

  const onToggleArchiveProject = () => {
    openConfirm({
      message: (
        <div>Are you sure you want to {archived ? "un-archive" : "archive"} this project?</div>
      ),
      title: null,
      canClose: false,
      options: {
        ok: "Yes",
        cancel: "No",
      },
    })
      .then(
        () => {
          updateProject(
            id,
            {
              archived: !archived,
            },
            {},
          )(dispatch);

          const event = archived ? EVENTS.unarchive_project : EVENTS.archive_project;
          trackEvent(event, {
            ...eventProperties,
            action: "archived",
          });
        },
        () => {
          // do nothing
        },
      )
      .catch(() => {});
  };

  const onToggleConvertProject = () => {
    openConfirm({
      message: (
        <div>
          Are you sure you want to convert this project to a{" "}
          {category !== "project" ? "managed project" : "dedicated project"}
        </div>
      ),
      title: null,
      canClose: false,
      options: {
        ok: "Yes",
        cancel: "No",
      },
    })
      .then(
        () => {
          updateProject(
            id,
            {
              category: category === "project" ? "dedicated" : "project",
            },
            {},
          )(dispatch);
        },
        () => {
          // do nothing
        },
      )
      .catch(() => {});
  };

  const saveProjectDetails = (updateFields, updatedSection) => {
    updateProject(id, updateFields, {})(dispatch);

    trackEvent(EVENTS.update_project_details, {
      ...eventProperties,
      updated_section: updatedSection,
    });
  };

  const onSaveProject = () => {
    saveProjectDetails(
      {
        title: projectDetails.title,
        description: projectDetails.description,
      },
      "Project Title and Description",
    );
  };

  const onSaveProjectDuration = () => {
    saveProjectDetails(
      {
        expected_start_date: projectDetails.expected_start_date,
        expected_end_date: projectDetails.expected_end_date,
      },
      "Project expected duration",
    );
  };

  const onSaveClientInvoicingEmail = () => {
    saveProjectDetails(
      {
        invoicing_email: projectDetails.invoicing_email,
        cc_primary_email: projectDetails.cc_primary_email,
        account_managers: projectDetails.account_managers,
        cc_account_managers: projectDetails?.account_managers?.length > 0,
      },
      "Client invoicing email address",
    );
  };

  const onSavePayout = () => {
    saveProjectDetails(
      {
        payment_day: projectDetails.payment_day,
      },
      "Payout Date",
    );
  };

  return (
    <StyledWrapper>
      <div className="section">
        <h3 className="section-title">
          Project Title and Description
          <span style={{ color: "#da3451", paddingLeft: "2px" }}>*</span>
        </h3>

        <Input
          placeholder="Title"
          className="form-control"
          dataTestId="title"
          value={projectDetails.title || ""}
          disabled={!(isCMOrCSOAndHasProjectAcess(projectFromState) || canUpdateProjectDetails)}
          onChange={(e) =>
            setprojectDetails({
              ...projectDetails,
              title: e.target.value,
            })
          }
        />
        <TextArea
          placeholder="Description"
          className="form-control"
          data-testid="description"
          value={projectDetails.description || ""}
          disabled={!(isCMOrCSOAndHasProjectAcess(projectFromState) || canUpdateProjectDetails)}
          onChange={(e) =>
            setprojectDetails({
              ...projectDetails,
              description: e.target.value,
            })
          }
        />

        {(isCMOrCSOAndHasProjectAcess(projectFromState) || canUpdateProjectDetails) && (
          <Button
            type="button"
            data-testid="save-project-btn"
            disabled={
              !canSave ||
              isMakingRequest.update ||
              !projectDetails.description ||
              !projectDetails.title
            }
            onClick={onSaveProject}
          >
            Save changes
          </Button>
        )}
      </div>

      <div className="section">
        <div className="title">
          What is the expected duration of the project?
          <LabelStyle>
            <span className="label-style">*</span>
          </LabelStyle>
        </div>
        <FormGroup className="row">
          <div className="col">
            <label htmlFor="startDate">Start date</label>
            <StyledDateTimePicker
              $calendar
              id="startDate"
              className="tg-date-field"
              placeholder="Select start date"
              format="DD MMM YYYY"
              valueEditFormat={{ dateStyle: "short" }}
              valueDisplayFormat={{ dateStyle: "short" }}
              $time={false}
              value={
                projectDetails.expected_start_date
                  ? new Date(projectDetails.expected_start_date)
                  : null
              }
              max={
                projectDetails.expected_end_date
                  ? new Date(projectDetails.expected_end_date)
                  : new Date(2099, 11, 31)
              }
              onChange={(date) =>
                setprojectDetails({
                  ...projectDetails,
                  expected_start_date: moment(date).isValid() ? moment(date).format() : null,
                })
              }
            />
          </div>
          <div className="col">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label htmlFor="endDate">End date</label>
              <div>
                <span className="mx-1">No end date</span>
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={13.5}
                  width={22.5}
                  onChange={() => {
                    setprojectDetails({
                      ...projectDetails,
                      expected_end_date: projectDetails.expected_end_date
                        ? null
                        : +new Date(2099, 11, 31),
                    });
                  }}
                  checked={!projectDetails.expected_end_date}
                  onColor="#062E64"
                />
              </div>
            </div>
            <StyledDateTimePicker
              id="endDate"
              className="tg-date-field"
              placeholder="Select end date"
              format="DD MMM YYYY"
              valueEditFormat={{ dateStyle: "short" }}
              valueDisplayFormat={{ dateStyle: "short" }}
              $calendar
              $time={false}
              value={
                projectDetails.expected_end_date &&
                typeof projectDetails.expected_end_date === "string"
                  ? new Date(projectDetails.expected_end_date)
                  : null
              }
              min={
                new Date(projectDetails.expected_start_date) >= new Date(Date.now())
                  ? new Date(projectDetails.expected_start_date)
                  : new Date(Date.now())
              }
              onChange={(date) =>
                setprojectDetails({
                  ...projectDetails,
                  expected_end_date: moment(date).isValid() ? moment(date).format() : null,
                })
              }
              required
            />
          </div>
        </FormGroup>
        {(isCMOrCSOAndHasProjectAcess(projectFromState) || canUpdateProjectDetails) && (
          <Button
            type="button"
            data-testid="save-project-duration-btn"
            disabled={
              !canSaveDuration ||
              isMakingRequest.update ||
              !projectDetails.expected_start_date ||
              !(
                projectDetails.expected_end_date == null ||
                projectDetails.expected_end_date !== +new Date(2099, 11, 31)
              )
            }
            onClick={() => onSaveProjectDuration()}
          >
            Save changes
          </Button>
        )}
      </div>

      <div className="section">
        <DocWrapper className="mb-4">
          <div className="header">
            <h4>Payment</h4>
            <p>
              Enter payment terms for this client{" "}
              <span style={{ color: "#DA3451", paddingLeft: "2px" }}>*</span>
            </p>
            <div className="mt-4">
              <label className="mb-1" htmlFor="email">
                Client invoicing email address
              </label>
              {error ? <FieldError message={error} /> : null}
              <input
                required
                id="email"
                data-testid="invoicing_email"
                type="email"
                className="mb-0"
                placeholder="Enter email address to send client invoices to"
                value={projectDetails.invoicing_email || ""}
                onChange={(e) =>
                  setprojectDetails({
                    ...projectDetails,
                    invoicing_email: e.target.value,
                  })
                }
                onKeyUp={validateEmail}
              />
              {!projectDetails.invoicing_email && (
                <FieldError message="Please assign a Project Owner to send invoices." />
              )}
            </div>

            {(isCMOrCSOAndHasProjectAcess(projectFromState) || canUpdateProjectDetails) && (
              <div className="mb-3 mt-2 d-flex align-items-center">
                <span className="me-1">Send to primary email</span>
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={13.5}
                  width={22.5}
                  disabled={!projectDetails.invoicing_email}
                  onChange={() => {
                    setprojectDetails({
                      ...projectDetails,
                      cc_primary_email: !projectDetails.cc_primary_email,
                    });
                  }}
                  checked={!!projectDetails.invoicing_email && !!projectDetails.cc_primary_email}
                  onColor="#062E64"
                />
              </div>
            )}

            <div className="mt-3">
              <label className="mb-1" htmlFor="email">
                Additional recipients for invoicing emails
              </label>
              {accountManagersErr ? <FieldError message={accountManagersErr} /> : null}
              <input
                required
                id="account_managers"
                data-testid="account_managers"
                type="account_managers"
                className="mb-0"
                placeholder="e.g obia@tunga.io, paul@tunga.io"
                value={projectDetails.account_managers || ""}
                onChange={(e) =>
                  setprojectDetails({
                    ...projectDetails,
                    account_managers: e.target.value,
                  })
                }
                onKeyUp={validateAccountManagers}
              />
            </div>
          </div>

          {(isCMOrCSOAndHasProjectAcess(projectFromState) || canUpdateProjectDetails) && (
            <div className="mt-3">
              <Button
                type="button"
                data-testid="save-payment-btn"
                disabled={
                  !canSavePayment ||
                  isMakingRequest.update ||
                  !projectDetails.invoicing_email ||
                  error
                }
                onClick={onSaveClientInvoicingEmail}
              >
                Save changes
              </Button>
            </div>
          )}
        </DocWrapper>

        <DocWrapper>
          <div className="header">
            <h4>Payouts</h4>
            <p className="mb-3">Update payout settings for developers on this project</p>

            <div>
              <p className="fw-bold mb-2 fs-6">Schedule Payoneer Payouts</p>
              <label className="mb-2" htmlFor="email">
                Choose a day of the month for automatic payouts.
              </label>

              <DayPicker
                $calendar={false}
                id="startDate"
                className="tg-date-field mb-1 payout-date"
                placeholder="Select start date"
                format="dd"
                valueEditFormat={{ day: "numeric" }}
                valueDisplayFormat={{ day: "numeric" }}
                $time={false}
                value={getPayoutDate(projectDetails?.payment_day)}
                onChange={handleChange}
                min={startOfMonth}
                max={endOfMonth}
              />

              <i>Default Day: 15</i>
            </div>
          </div>

          {(isCMOrCSOAndHasProjectAcess(projectFromState) || canUpdateProjectDetails) && (
            <div className="mt-3">
              <Button
                type="button"
                data-testid="save-payout-btn"
                disabled={!canSavePayout || isMakingRequest.update || error}
                onClick={onSavePayout}
              >
                Save changes
              </Button>
            </div>
          )}
        </DocWrapper>
      </div>

      {(isCMOrCSOAndHasProjectAcess(projectFromState) || canConvertProject) && (
        <div className="section">
          <h3 className="section-title">
            Convert to {category !== "project" ? "Managed" : "Dedicated"} Project
          </h3>
          <Button type="button" onClick={() => onToggleConvertProject()}>
            <Icon name="baseline-folder-open" size="sm" />
            &nbsp; Convert
          </Button>
        </div>
      )}

      {(isCMOrCSOAndHasProjectAcess(projectFromState) || canArchiveAProject) && (
        <div className="section">
          <h3 className="section-title">Archive Project</h3>
          <p>
            Mark this project as archive and read-only. This will also remove this project from your
            project list and send it to the archived list.
          </p>
          <Button type="button" onClick={() => onToggleArchiveProject()}>
            <Icon name="archive-outline" size="sm" />
            &nbsp;
            {archived ? "Un" : null}Archive Project
          </Button>
        </div>
      )}
    </StyledWrapper>
  );
};

const DocWrapper = styled.div`
  .section .file-list .section-item {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    a {
      text-decoration: none;
    }
  }
  .flex-tab {
    display: flex;
  }
  .active-tab {
    background: #062e64;
    color: #fff;
    height: 40px;
    width: 160px;
    cursor: pointer;
  }
  .tab {
    height: 40px;
    width: 160px;
    font-size: 16px;
    cursor: pointer;
  }
  .per-tab {
    border-radius: 4px 0px 0px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #edf1f7;
  }
  .fix-tab {
    border-radius: 0px 4px 4px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #edf1f7;
  }
  .span-append {
    position: absolute;
    right: 8px;
    top: 40%;
    transform: translateY(-50%);
    padding: 4px 16px;
    color: #777;
    border-radius: 8px;
  }
  .inp-div {
    position: relative;
  }
  input {
    height: 40px;
    width: 100%;
    padding: 0 16px;
    border: 1px solid #e3e9f2;
    border-radius: 5px;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  .file-list {
    margin-bottom: 0px;

    &:not(:empty) {
      margin-top: 26px;
    }

    .file-item {
      display: inline-block;
    }
  }

  .section {
    border-bottom: 1px solid #edf1f7;
    padding-top: 43px;
    padding-bottom: 43px;

    &:last-of-type {
      border-bottom: none;
    }

    .section-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #151a30;
      }

      a {
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #062e64;
        i {
          vertical-align: baseline;
        }
      }
    }

    .section-item {
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      border: 1px solid #edf1f7;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 0px 15px;
      place-items: center;

      a,
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #3e4857;

        i {
          vertical-align: baseline;
          margin-right: 20px;
          color: #8f9bb3;
        }
      }

      button {
        color: #8f9bb3;
        height: auto;
      }
    }
  }
  .payout-date {
    max-width: 390px;
    width: 100%;
  }
`;

const LabelStyle = styled.span`
  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
`;

const DayPicker = styled(StyledDateTimePicker)`
  .rw-calendar-header,
  .rw-calendar-head {
    display: none;
  }

  .rw-widget {
    margin: 0;
  }

  .rw-popup-container {
    margin-top: 10px;
  }
`;

export default TeamDetails;
