/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

/* -------------------------- Internal Dependencies ------------------------- */
/* ---------------------------- Image Dependecies --------------------------- */
import Mark from "../../../../../assets/images/icons/mark-green.svg";
import { pxToRem } from "../../../../../assets/theme/functions";

const propTypes = {
  returnUrl: PropTypes.string,
};

const SurveyModalContent = ({ returnUrl }) => {
  return (
    <ModalContentTitle>
      <img src={Mark} alt="" />
      <h3>Submitted Successfully</h3>
      <p>Your report has been successfully submitted. Thanks for taking out time to complete it!</p>
      <a href={returnUrl}>Back to Project</a>
    </ModalContentTitle>
  );
};

const ModalContentTitle = styled.div`
  img {
    margin: 0.1rem auto 1.3rem;
    display: block;
  }
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */

    text-align: center;

    color: #151a30;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    text-align: center;

    color: #3e4857;
  }
  a {
    background: #da3451;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: ${pxToRem(15)};
    line-height: ${pxToRem(19)};
    text-align: center;
    display: block;
    margin: auto;
    box-shadow: none;
    color: #ffffff;
    padding: 11px 27px;
    width: fit-content;
  }
`;

SurveyModalContent.propTypes = propTypes;

export default SurveyModalContent;
