import React from "react";

const ArrowDown = () => {
  return (
    <div>
      <svg
        width={18}
        height={10}
        viewBox="0 0 18 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5 1L9 8.5L1.5 1"
          stroke="#7F8C9F"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default ArrowDown;
