import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import styled, { withTheme, ThemeProvider } from "styled-components";

import SkillSearchInput from "../../../../components/PrioritySkillSelector/SkillSearchInput";
import DeleteIcon from "../../../../assets/images/icons/delete-outline.svg";
import { StyledForm } from "../../../../utils/styles";
import theme from "../../../../assets/theme";

const RequestTechnicalTestForm = ({ id, proceed, noIQTest, excludedTests }) => {
  const [selectedSkills, setSelectedSkills] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const requestedTest = [...selectedSkills];

    if (noIQTest) requestedTest.push("IQ");

    proceed(requestedTest);
  };

  const onSelectSkill = (e, skillType, skill) => {
    e.preventDefault();

    setSelectedSkills((prev) => {
      const newSkill = [...prev];

      newSkill.push(skill);
      return newSkill;
    });
  };

  const deleteSelectedSkill = (item) => () => {
    setSelectedSkills((prev) => {
      const newSkill = [...prev].filter((skill) => skill !== item);
      return newSkill;
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledForm
        style={{ minHeight: "250px" }}
        id={id}
        data-testid="test-request-form"
        onSubmit={handleSubmit}
      >
        <p className="subtitle">Please select up to three skills you’d like to test for</p>
        <FormGroup className="position-relative">
          <SkillSearchInput
            {...{
              placeholder: "Type to search and select a skill...",
              selectedSkills,
              onSelectSkill,
              disabled: selectedSkills.length === 3,
              excludedTests,
            }}
          />

          <div className="mt-4 d-flex flex-column">
            {noIQTest && (
              <SkillWrapper className="d-flex justify-content-between">
                <SkillItem className="d-inline-flex align-items-center opacity">IQ</SkillItem>
              </SkillWrapper>
            )}

            {selectedSkills?.length !== 0 && (
              <>
                {selectedSkills.map((skill, idx) => (
                  <SkillWrapper key={`${skill}-${idx}`} className="d-flex justify-content-between">
                    <SkillItem className="d-inline-flex align-items-center opacity">
                      {skill}
                    </SkillItem>

                    <button
                      type="button"
                      className="p-0"
                      data-testid={`del-${skill}`}
                      onClick={deleteSelectedSkill(skill)}
                    >
                      <img width={24} height={24} src={DeleteIcon} alt="" />
                    </button>
                  </SkillWrapper>
                ))}
              </>
            )}
          </div>
        </FormGroup>
      </StyledForm>
    </ThemeProvider>
  );
};

const SkillWrapper = styled.div`
  padding: 16px 0;
  border-top: 1px solid #edf1f7;

  &:last-child {
    border-bottom: 1px solid #edf1f7;
  }

  button {
    height: unset !important;
    vertical-align: baseline;
    line-height: unset !important;
    margin-left: 10px;
    border: none;
    background-color: transparent;

    &:disabled {
      opacity: 0.3;
    }
  }
`;

const SkillItem = withTheme(styled.div`
  padding: 3px 10px 4px;
  border-radius: 50px;
  border: 1px solid #062e640a;
  background: rgba(6, 46, 100, 0.15);
  font-weight: 500;
  height: 30px;
  font-size: ${(props) => props.theme.functions.pxToRem(14)};
  line-height: ${(props) => props.theme.functions.pxToRem(21)};
  color: ${(props) => props.theme.colors["dark-blue"]};
`);

RequestTechnicalTestForm.propTypes = {
  bio: PropTypes.string,
  proceed: PropTypes.func,
  id: PropTypes.string,
  noIQTest: PropTypes.bool,
  excludedTests: PropTypes.arrayOf(PropTypes.string),
};

export default RequestTechnicalTestForm;
