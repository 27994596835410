/* eslint-disable react/prop-types */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import Filters from "./Filters";
import Progress from "../../../../../components/Progress";
import SummaryPlaceholder from "../../../../../components/SummaryPlaceholder/SummaryPlaceholder";
import {
  listProgressEvents,
  resetFilters,
  retrieveProgressEvent,
  showUsersFilter,
} from "../../../../../redux/actions/ProjectActions";
import { addPropsToChildren } from "../../../../../utils/children";
import {
  CLEAR_DURATION_FILTER,
  REMOVE_STATUS_FILTER,
  REMOVE_USER_FILTER,
} from "../../../../../configs/constants/ActionTypes";

const ReportsListContainer = ({ children, type, params, page }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { reportId } = useParams();

  const defaultFilter = {
    checkedStatus: {},
    checkedUsers: {},
    duration: {
      end: null,
      start: null,
    },
    showUsers: !(page === "yours" || page === "client"),
  };

  const isFilterChanged = (filter) => {
    return JSON.stringify(filter) !== JSON.stringify(defaultFilter);
  };

  const {
    project,
    filters,
    progressEvents,
    progressEventsCount: count,
    isMakingRequest,
    progress_event,
  } = useSelector(({ Projects }) => Projects);

  const [activities, setActivities] = useState([]);
  const [initialFilters, setInitialFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0); // react table pagination page
  const [pageSize, setPageSize] = useState(20);

  const [shouldDisplaySingleReport, setShouldDisplaySingleReport] = useState(false);

  const handleUserFilter = (show) => dispatch(showUsersFilter(show));

  const getList = (filtersParam = {}) => {
    dispatch(
      listProgressEvents({
        ...(filtersParam || {}),
        project: project.id,
      }),
    );
  };

  useEffect(() => {
    if (project.id) {
      const pageFilters = { page: currentPage + 1, page_size: pageSize };
      let param = {};
      if (type && type.split(",").length > 1) {
        param = { types: type };
      } else {
        param = { type };
      }

      if (params) param = params;

      setInitialFilters({ ...param, ...pageFilters });
      getList({ ...param, ...pageFilters });

      if (page === "yours" || page === "client") {
        handleUserFilter(false);
      } else {
        handleUserFilter(true);
      }
    }
    dispatch(resetFilters());
  }, [location, project, currentPage, pageSize]);

  useEffect(() => {
    dispatch(resetFilters());
  }, [location.pathname, project]);

  useEffect(() => {
    if (Object.keys(initialFilters).length > 0 && isFilterChanged(filters)) {
      let newParam = { ...initialFilters };
      const { duration, checkedUsers, checkedStatus } = filters;
      if (duration.start && duration.end) {
        newParam = {
          ...newParam,
          daterange_0: moment(duration.start).format("yyyy-MM-DD"),
          daterange_1: moment(duration.end).format("yyyy-MM-DD"),
        };
      }

      if (Object.keys(checkedUsers).length > 0) {
        newParam = {
          ...newParam,
          users: Object.keys(checkedUsers).join(","),
        };
      }

      if (Object.keys(checkedStatus).length > 0) {
        newParam = {
          ...newParam,
          status: Object.keys(checkedStatus).join(","),
        };
      }
      getList(newParam);
    }
  }, [JSON.stringify(filters), initialFilters]);

  useEffect(() => {
    setActivities(Object.values(progressEvents));
  }, [progressEvents]);

  /**
   * This `useEffect` hook is responsible for retrieving a single progress event report and setting the
   * `shouldDisplaySingleReport` state to `true` if the report exists. It runs whenever `reportId`, `progress_event`,
   * or `dispatch` changes.
   * This is useful for viewing a report directly when there is a report ID present in the URL.
   */
  useEffect(() => {
    if (reportId && !Object.keys(progress_event).length) {
      dispatch(retrieveProgressEvent(reportId));
    }

    if (reportId && Object.keys(progress_event).length) {
      setShouldDisplaySingleReport(true);
    }
  }, [reportId, progress_event, dispatch]);

  const renderChildren = () => {
    return addPropsToChildren(children, {
      activities,
      onLoadMore: (currPage, page_size) => {
        setPageSize(page_size);
        setCurrentPage(currPage);
      },
      currentPage,
      pageSize,
      count,
      shouldDisplaySingleReport,
    });
  };

  const clearDuration = () => dispatch({ type: CLEAR_DURATION_FILTER });

  const removeStatus = (key) =>
    dispatch({
      type: REMOVE_STATUS_FILTER,
      key,
    });

  const removeUser = (key) =>
    dispatch({
      type: REMOVE_USER_FILTER,
      key,
    });

  return (
    <>
      <Filters
        filters={filters}
        activities={count}
        clearDuration={clearDuration}
        removeStatus={removeStatus}
        removeUser={removeUser}
      />

      {isMakingRequest.progressEvent ? (
        <Progress />
      ) : activities.length !== 0 ? (
        renderChildren()
      ) : (
        <SummaryPlaceholder
          className="page-with-filters"
          description="There are no reports yet for this project"
        />
      )}
    </>
  );
};

export default ReportsListContainer;
