import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import ArrowDown from "../../../../../components/svg/ArrowDown";
import {
  TIME_SHEET_MONTH_VIEW,
  TIME_SHEET_YEAR_VIEW,
} from "../../../../../configs/constants/global";
import { genInNum, genInString } from "../../../../../utils/dateUtil";
import { RESET_USER_TIMESHEET_ENTRIES } from "../../../../../configs/constants/ActionTypes";

const generateDateAndYearList = () => {
  const startingYear = 2020;
  const currentDate = new Date();
  const createdDate = new Date(startingYear.toString());

  const dateAndYearList = [{ name: genInString(createdDate), value: genInNum(createdDate) }];

  while (createdDate.setMonth(createdDate.getMonth() + 1) < currentDate) {
    dateAndYearList.unshift({ name: genInString(createdDate), value: genInNum(createdDate) });
  }

  return dateAndYearList;
};

const ViewSelector = ({
  currentView,
  currentMonth,
  setCurrentView,
  setCurrentMonth,
  setCurrentYear,
}) => {
  const startingYear = 2020;

  const diff = parseInt(new Date().getFullYear(), 10) - startingYear;
  const mapMonth = Array.from({ length: diff })
    .map((_, i) => startingYear + i + 1)
    .reverse();

  const dateAndYearList = generateDateAndYearList();

  const dispatch = useDispatch();

  const handleChangeMonth = (e) => {
    dispatch({ type: RESET_USER_TIMESHEET_ENTRIES });
    setCurrentMonth(e.target.value);
  };

  const handleChangeView = (e) => {
    const { value } = e.target;
    if (value === TIME_SHEET_MONTH_VIEW) {
      setCurrentMonth(dateAndYearList[0].value);
    } else {
      setCurrentYear(mapMonth[0]);
    }

    setCurrentView(value);
  };

  return (
    <Wrapper className="d-flex py-1">
      <div className="position-relative">
        <select data-testid="view-selector" value={currentView} onChange={handleChangeView}>
          <option value={TIME_SHEET_MONTH_VIEW}>Month view</option>
          <option value={TIME_SHEET_YEAR_VIEW}>Year view</option>
        </select>
        <ArrowDown className="arrow" />
      </div>

      <div className="position-relative">
        {currentView === TIME_SHEET_MONTH_VIEW ? (
          <select data-testid="month-selector" value={currentMonth} onChange={handleChangeMonth}>
            {dateAndYearList.map(({ name, value }) => {
              return (
                <option value={value} key={value}>
                  {name}
                </option>
              );
            })}
          </select>
        ) : (
          <select data-testid="year-selector" onChange={(e) => setCurrentYear(e.target.value)}>
            {mapMonth.map((year) => {
              return (
                <option value={year} key={year}>
                  {year}
                </option>
              );
            })}
          </select>
        )}
        <ArrowDown className="arrow" />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  border-radius: 4px;
  border: 1px solid #e3e9f2;
  overflow: hidden;
  background: #fff;

  > div:first-child {
    border-right: 1px solid #e3e9f2;
  }

  select {
    width: 135px;
    height: 35px;
    padding-left: 15px;
    padding-right: 2px;
    appearance: none;
    border: none;
    background: #fff;

    ::-ms-expand {
      display: none;
    }

    + div {
      position: absolute;
      right: 15px;
      top: 6.5px;
    }
  }
`;

ViewSelector.propTypes = {
  currentView: PropTypes.string,
  currentMonth: PropTypes.string,
  setCurrentView: PropTypes.func,
  setCurrentMonth: PropTypes.func,
  setCurrentYear: PropTypes.func,
};

export default ViewSelector;
