import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Avatar from "../../../components/Avatar";
import { generateUserInitials } from "../../../utils/stringUtils";

const ContactInfo = ({ userDetails }) => {
  return (
    <Container>
      <div className="d-flex align-items-center mb-4">
        <div className="profile-pic">
          <Avatar
            dataTestId="avatar"
            className="avatar-dash avatar-initials"
            image={userDetails?.image}
            initials={generateUserInitials(userDetails)}
          />
        </div>

        <div className="row w-100">
          <div className="col mb-3">
            <label htmlFor="first-name">First Name</label>
            <p>{userDetails?.first_name || "N/A"}</p>
          </div>
          <div className="col mb-3">
            <label htmlFor="first-name">Last Name</label>
            <p>{userDetails?.last_name || "N/A"}</p>
          </div>
          <div className="col-12">
            <label htmlFor="first-name">Phone Number</label>
            <p>{userDetails?.profile?.phone_number || "N/A"}</p>
          </div>
        </div>
      </div>

      <div className="header mb-4">Address</div>
      <div className="row">
        <div className="col-md-4 mb-3">
          <label htmlFor="first-name">Street</label>
          <p>{userDetails?.profile?.street || "N/A"}</p>
        </div>
        <div className="col-md-4 mb-3">
          <label htmlFor="first-name">Number/Plot</label>
          <p>{userDetails?.profile?.plot_number || "N/A"}</p>
        </div>
        <div className="col-md-4 mb-3">
          <label htmlFor="first-name">Zip Code</label>
          <p>{userDetails?.profile?.postal_code || "N/A"}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="first-name">City</label>
          <p>{userDetails?.profile?.city || "N/A"}</p>
        </div>
        <div className="col-md-4">
          <label htmlFor="first-name">Country</label>
          <p>{userDetails?.profile?.country_name || "N/A"}</p>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  .profile-pic {
    margin-right: 40px;
  }

  .avatar {
    width: 100px;
    height: 100px;

    &-dash {
      margin: 0;
    }

    &-dash {
      margin: 0;
    }

    &.avatar-initials {
      font-size: 24px !important;
    }
  }

  .header,
  p {
    line-height: 24px;
    font-size: 16px;
  }

  .header,
  label {
    color: #151a30;
  }

  .header {
    font-weight: 500;
  }

  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 5px;
  }

  p {
    font-size: 16px;
    color: #3e4857;
    margin: 0;
    font-weight: 400;
  }
`;

ContactInfo.propTypes = {
  userDetails: PropTypes.shape({
    image: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    profile: PropTypes.shape({
      phone_number: PropTypes.string,
      plot_number: PropTypes.string,
      postal_code: PropTypes.string,
      city: PropTypes.string,
      country_name: PropTypes.string,
      street: PropTypes.string,
    }),
  }),
};
export default ContactInfo;
