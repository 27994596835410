/* eslint-disable react/jsx-props-no-spreading */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useState } from "react";
import { debounce, isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import IconButton from "../IconButton";
import InputGroup from "../InputGroup";
import useDidUpdate from "../../hooks/useDidUpdate";
import usePrevious from "../../hooks/usePrevious";
import { filterEventProps } from "../../utils/events";
import { filterInputProps } from "../../utils/forms";
import randomString from "../../utils/generateRandomString";
import { getSkills as getSkillsAction } from "../../redux/actions/SkillAction";

const defaultError = {
  duplicateSkill: "",
};

const SkillSelector = (props) => {
  const {
    className,
    size,
    prepend,
    placeholder,
    selected,
    display,
    max,
    onChange,
    selectionKey: key,
    filter: filterFromProp,
  } = props;

  const dispatch = useDispatch();

  const { skills } = useSelector(({ Skill }) => Skill);

  const [prevKey] = useState(null);
  const [selectionKey] = useState(key || randomString.generate());
  const [selectedSkill, setSelectedSkill] = useState(selected);
  const [search, setSearch] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [error, setError] = useState(defaultError);

  const prevSearch = usePrevious(search);
  const prevSelectedSkill = usePrevious(selectedSkill);

  const searchKey = () => {
    return `${selectionKey}-${search}`;
  };

  const onSelectSkill = (e, skill, type) => {
    e.preventDefault();
    const isContainedSkill = selectedSkill.some((itm) => itm.name === skill);
    /* istanbul ignore if else */
    if (!isContainedSkill) {
      setSearch("");
      setShowSuggestions(false);
      setSelectedSkill([...selectedSkill, { name: skill, type }]);
      setError(defaultError);
    } else {
      setError({
        ...error,
        duplicateSkill: `${skill} is already a skill`,
      });
    }
  };

  const getSkills = debounce((filter) => {
    dispatch(getSkillsAction(filter, searchKey(), prevKey));
  }, 100);

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    const skill = e.target.value;
    setSearch(skill);
    setShowSuggestions(!!skill);
  };

  const onRemoveSkill = (e, index) => {
    e.preventDefault();
    const splicedItem = selectedSkill.filter((_, idx) => idx + 1 !== index);
    if (splicedItem) {
      setSelectedSkill(splicedItem);
    }
  };

  useEffect(() => {
    setSelectedSkill(selected);
  }, [selected]);

  useDidUpdate(() => {
    if (!isEqual(selectedSkill, prevSelectedSkill) && onChange) {
      onChange(selectedSkill);
    }

    if (!isEqual(search, prevSearch)) {
      getSkills({
        search,
        type: filterFromProp && filterFromProp.filter,
      });
    }
  }, [selectedSkill, search]);

  const skillsArr = skills[searchKey()] || [];

  return (
    <div className="tag-input position-relative">
      {(!max || max > selectedSkill.length) && !display && (
        <div>
          {error.duplicateSkill && <span style={{ color: "#da3450" }}>{error.duplicateSkill}</span>}
          <InputGroup
            className={className}
            prepend={prepend ? <i className="tg-ic-tag" /> : null}
            size={size}
            placeholder={placeholder}
            {...filterInputProps(props)}
            {...filterEventProps(props)}
            selected={selectedSkill}
            value={search}
            onFocus={() => setShowSuggestions(!!search)}
            onChange={handleChange}
            onKeyPress={onKeyPress}
          />

          {showSuggestions && (
            <Suggestions className="list-group suggestions">
              {skillsArr.length !== 0 &&
                skillsArr.map((skill) => {
                  let exists = false;
                  selectedSkill.forEach((item) => {
                    if (item.name === skill.name) {
                      exists = true;
                    }
                  });

                  if (exists) return null;

                  return (
                    <button
                      type="button"
                      className="list-group-item text-left"
                      key={skill?.id || skill?.name}
                      onClick={(e) => onSelectSkill(e, skill.name, skill.type)}
                    >
                      {skill.name}
                    </button>
                  );
                })}

              {!skillsArr.length && (
                <button type="button" className="list-group-item text-left" disabled>
                  &quot;{search}&quot; not found
                </button>
              )}
            </Suggestions>
          )}
        </div>
      )}

      {selectedSkill && selectedSkill.length > 0 && (
        <SelectedContainer $display={display}>
          {selectedSkill.map((skill, index) => {
            return (
              <SelectedItem key={`skill-${skill.name}`}>
                <span style={{ display: "flex" }}>
                  {skill.name}{" "}
                  <IconButton
                    data-testid={`testId-del-${skill.name}`}
                    name="x-circle"
                    size="sm"
                    className="p-0"
                    styles="3px"
                    onClick={(e) => onRemoveSkill(e, index + 1)}
                  />
                </span>
              </SelectedItem>
            );
          })}
        </SelectedContainer>
      )}
    </div>
  );
};

const SelectedContainer = withTheme(styled.div`
  display: inline-flex;
  flex-flow: wrap;
  row-gap: 16px;
  ${(props) => props.theme.mixins.clearfix}
  ${(props) => (!props.display ? "margin-top: 16px" : "margin-bottom: 24px")}
`);

const SelectedItem = withTheme(styled.div`
  font-weight: 500;
  font-size: ${(props) => props.theme.functions.pxToRem(14)};
  line-height: ${(props) => props.theme.functions.pxToRem(17)};
  color: ${(props) => props.theme.colors["dark-blue"]};
  display: flex;
  justify-content: space-between;
  padding: 0;

  > span {
    display: inline-block;
    padding: 0px 12px;
    align-items: center;
    border-radius: 50px;
    background: rgba(6, 46, 100, 0.04);
    border: 1px solid rgba(6, 46, 100, 0.04);
    margin-right: 2px;
  }

  button {
    height: auto;
    vertical-align: baseline;
    line-height: unset;

    &:disabled {
      i {
        opacity: 0.3;
      }
    }
  }
`);

const Suggestions = withTheme(styled.div`
  position: absolute;
  width: 100%;
  z-index: 1000;
  max-height: 500px;
  overflow-y: scroll;
  border-radius: 4px;
  border: 1px solid #e3e9f2;
  margin-top: 5px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

  button.list-group-item {
    color: #151a30;
    text-align: left;
    border: none;

    :hover {
      color: #fff;
      background-color: #151a30;
    }
  }
`);

SkillSelector.defaultProps = {
  placeholder: "Add skills or products",
  selectionKey: null,
  max: null,
  display: false,
  enableDelete: false,
};

SkillSelector.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string,
      }),
    ),
  ]),
  onChange: PropTypes.func,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  selectionKey: PropTypes.string,
  max: PropTypes.number,
  prepend: PropTypes.bool,
  filter: PropTypes.shape({
    filter: PropTypes.string,
  }),
  display: PropTypes.bool,
  onRemove: PropTypes.func,
  enableDelete: PropTypes.bool,
};
export default SkillSelector;
