/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from "react";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import ReactTable from "../../../../components/ReactTable";
import { RESET_SKILLS_ERRORS } from "../../../../configs/constants/ActionTypes";
import useDidUpdate from "../../../../hooks/useDidUpdate";
import {
  addToSKillLibrary,
  bulkAddToSkillLibrary,
  listSkillSuggestions,
  rejectSkill,
  updateSuggestedSkill,
} from "../../../../redux/actions/SkillAction";
import { cancelLastRequest } from "../../../../utils/api";
import randomstring from "../../../../utils/generateRandomString";
import { createModal, openAlertModal } from "../../../../utils/modals";
import { ContentSection } from "../../../../utils/styles";
import { AddCircle, Remove } from "../../dashboard/Icons";
import AddToSKillLibrary from "./AddToSkillLibrary";
import EditSkillForm from "./EditSkillForm";
import RejectSkillForm from "./RejectSkillForm";
import SkillsContainer from "./SkillsContainer";
import BulkActions from "./BulkActions";

const SkillSuggestions = () => {
  const [selection] = useState({ selectionKey: randomstring.generate() });
  const { isFetching, isSaved, skillSuggestions } = useSelector(({ Skill }) => Skill);
  const skillsSuggestionsData = skillSuggestions?.results || [];
  const count = skillSuggestions?.count;
  const [page_size, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [skill_type, setSkillType] = useState({ value: "", label: "Show: all" });
  const [checked, setChecked] = useState([]);
  const dispatch = useDispatch();

  const onLoadMore = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(+pageSize);
  };

  const fetchSkills = (page, search = "") => {
    const updatedFilters = {
      page,
      search,
      page_size,
      type: skill_type.value,
    };
    cancelLastRequest("getSkills");
    listSkillSuggestions({ ...(updatedFilters || {}) }, selection.selectionKey)(dispatch);
  };

  useEffect(() => {
    fetchSkills(1);
    setCurrentPage(0);
  }, [skill_type]);

  useDidUpdate(() => {
    fetchSkills(currentPage + 1);
  }, [isSaved, page_size, currentPage]);

  const handleRejectSkill = (id) => {
    const isBulkAction = !id;
    const suffix = isBulkAction ? "s" : "";

    const modal = createModal({
      body: <RejectSkillForm id="reject-skill-form" />,
      options: {
        title: `Reject skill${suffix}`,
        canClose: true,
        className: "modal-skills",
        ok: "Reject",
        size: "md",
        form: {
          type: "submit",
          form: "reject-skill-form",
        },
      },
      beforeClose: () => {
        dispatch({ type: RESET_SKILLS_ERRORS });
      },
      proceed: ({ reason }) => {
        modal.setIsLoading(true);
        const feedbackCb = {
          successCb: () => {
            modal.setIsLoading(false);
            modal.close();
            openAlertModal(`Skill${suffix} rejected successfully`);
            dispatch(listSkillSuggestions());
            if (isBulkAction) setChecked([]);
          },
          failureCb: () => {
            modal.setIsLoading(false);
          },
        };

        if (isBulkAction) {
          dispatch(
            bulkAddToSkillLibrary(
              { skills: [...checked], action: "reject", reason_for_rejection: reason },
              feedbackCb,
            ),
          );
        } else {
          dispatch(
            rejectSkill(id, { reason_for_rejection: reason, status: "rejected" }, feedbackCb),
          );
        }
      },
    });

    modal.open();
  };

  const handleAddToSkillLibrary = (id) => {
    const isBulkAction = !id;

    const modal = createModal({
      body: <AddToSKillLibrary isBulkAction={isBulkAction} id="add-to-skill-library" />,
      options: {
        title: "Add to skill library",
        canClose: true,
        ok: "Add to skill library",
      },
      beforeClose: () => {
        dispatch({ type: RESET_SKILLS_ERRORS });
      },
      proceed: () => {
        modal.setIsLoading(true);
        const feedbackCb = {
          successCb: () => {
            modal.setIsLoading(false);
            modal.close();
            openAlertModal(`Skill${isBulkAction ? "s" : ""} added to library successfully`);
            dispatch(listSkillSuggestions());
            if (isBulkAction) setChecked([]);
          },
          failureCb: () => {
            modal.setIsLoading(false);
          },
        };

        if (isBulkAction) {
          dispatch(bulkAddToSkillLibrary({ skills: [...checked], action: "approve" }, feedbackCb));
        } else {
          dispatch(addToSKillLibrary(id, { status: "approved" }, feedbackCb));
        }
      },
    });
    modal.open();
  };

  const handleEditSkill = (id, skills_name, skills_type) => {
    const modal = createModal({
      body: (
        <EditSkillForm
          id="edit-skill-form"
          data-testid="edit-skill-form"
          skill_name={skills_name}
          skill_type={skills_type}
        />
      ),
      options: {
        title: "Update skill",
        canClose: true,
        ok: "Save Changes",
        form: {
          type: "submit",
          form: "edit-skill-form",
        },
      },
      beforeClose: () => {
        dispatch({ type: RESET_SKILLS_ERRORS });
      },
      proceed: ({ name, type }) => {
        dispatch({ type: RESET_SKILLS_ERRORS });
        modal.setIsLoading(true);
        const feedbackCb = {
          successCb: () => {
            modal.setIsLoading(false);
            modal.close();
            openAlertModal("Skill edited successfully");
            dispatch(listSkillSuggestions());
          },
          failureCb: () => {
            modal.setIsLoading(false);
          },
        };
        dispatch(updateSuggestedSkill(id, { name, type }, feedbackCb));
      },
    });
    modal.open();
  };

  const checkAll = () => {
    const allIDs = skillsSuggestionsData.map(({ id }) => id);
    return setChecked((v) => {
      if (v.length === +skillsSuggestionsData.length) {
        return [];
      }
      return allIDs;
    });
  };

  // istanbul ignore next
  const checkItem = (item) => {
    let newArr = [];

    if (!checked.includes(item)) {
      newArr = [...checked, item];
    } else {
      newArr = checked.filter((a) => a !== item);
    }
    setChecked(newArr);
  };

  const data = useMemo(
    () => [
      ...skillsSuggestionsData.map((skill) => {
        const row = {
          batch_action: skill.id,
          name: skill.name,
          type: skill.type,
          submitted_by: skill.user.display_name,
          submitted_on: skill.created_at,
          bulk_actions: { id: skill.id, skill: skill.name },
          actions: { id: skill.id, skill: skill.name, type: skill.type },
        };
        return row;
      }),
    ],
    [skillsSuggestionsData],
  );

  const columns = useMemo(
    () => [
      {
        Header: (
          <div className="align-items-center">
            <input
              type="checkbox"
              name="check-it"
              data-testid="skill-check-all"
              className="custom-checkbox"
              checked={
                checked.length === skillsSuggestionsData.length && skillsSuggestionsData.length > 0
              }
              onChange={() => checkAll()}
            />
          </div>
        ),
        accessor: "batch_action",
      },
      {
        Header: "Skills",
        accessor: "name",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Submitted By",
        accessor: "submitted_by",
      },
      {
        Header: "Submitted On",
        accessor: "submitted_on",
      },

      {
        Header: "Actions",
        accessor: "bulk_actions",
      },
      {
        Header: "",
        accessor: "actions",
      },
    ],
    [checked, skillsSuggestionsData],
  );

  const getTableDisplayValue = (cell) => {
    const key = `${cell.column.id}-${cell.row.id}`;

    switch (cell.column.id) {
      // istanbul ignore next
      case "batch_action": {
        return (
          <td key={`batch_action${key}`} style={{ width: "75px" }} className="">
            <input
              type="checkbox"
              name="check-it"
              width="24px"
              height="24px"
              className="custom-checkbox"
              data-testid={`batchCheck${cell.value}`}
              id={cell.value}
              onChange={() => checkItem(cell.value)}
              checked={checked.includes(cell.value)}
            />
          </td>
        );
      }
      case "name":
        return <td key={`name${key}`}>{cell.value}</td>;
      case "type":
        return <td key={`type${key}`}>{cell.value}</td>;
      case "submitted_by":
        return <td key={`submitted_by${key}`}>{cell.value}</td>;

      case "submitted_on":
        return <td key={`submitted_on${key}`}>{moment.utc(cell.value).format("MMM D YYYY")}</td>;

      case "bulk_actions":
        return (
          <td key={`bulk_actions${key}`} style={{ display: "table", width: "100%" }}>
            <div className="actions-icons">
              <button
                data-tooltip="Add to skill library"
                disabled=""
                className="btn add-btn p-lg-0"
                style={{ background: "none" }}
                data-testid="add-to-skill-library"
                onClick={() => handleAddToSkillLibrary(cell.value.id, cell.value.skill)}
                type="button"
              >
                <AddCircle />
              </button>
              <button
                data-tooltip="Reject"
                disabled=""
                className="btn reject-btn col-lg-2"
                style={{ background: "none" }}
                onClick={() => handleRejectSkill(cell.value.id)}
                data-testid="reject-skill"
                type="button"
              >
                <Remove />
              </button>
            </div>
          </td>
        );

      case "actions":
        return (
          <td key={`actions${key}`} className="d-table-cell">
            <div className="dropdown">
              <button
                style={{ boxShadow: "none" }}
                className="btn"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                type="button"
              >
                <span
                  style={{ fontSize: "1.5rem", color: "#8F9BB3" }}
                  data-testid={`dot-${cell.value.id}`}
                >
                  ...
                </span>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button
                  type="button"
                  data-testid={`edit-${cell.value.id}`}
                  className="dropdown-item"
                  style={{ background: "#fff", color: "#3e4857", cursor: "pointer" }}
                  onClick={() => handleEditSkill(cell.value.id, cell.value.skill, cell.value.type)}
                >
                  Edit skill
                </button>
              </div>
            </div>
          </td>
        );
      default:
        return null;
    }
  };

  return (
    <SkillsContainer skill_type={skill_type} setSkillType={setSkillType} fetchSkills={fetchSkills}>
      <ContentSection style={{ paddingTop: "0" }}>
        <div>
          <div
            className={`row m-0 mb-3  ${checked.length > 0 ? "" : "hidden"}`}
            style={{ width: "100%" }}
          >
            <div className="col-8 d-flex align-items-center">
              <Text className="m-0">Suggested Skills</Text>
            </div>

            <BulkActions
              checked={checked}
              onAddClick={() => handleAddToSkillLibrary(null)}
              onRejectClick={() => handleRejectSkill(null)}
            />
          </div>

          <div className="section">
            <ReactTable
              scope="skill_library"
              tableData={data}
              colDeps={[checked]}
              tableColumns={columns}
              selectedRows={checked.map((c) => ({ id: c }))}
              currentPage={currentPage}
              count={count}
              getTableDisplayValue={getTableDisplayValue}
              loadPage={onLoadMore}
              pageSize={page_size}
              isLoading={isFetching.loading}
              noDataMessage="No suggested skills found"
            />
          </div>
        </div>
      </ContentSection>
    </SkillsContainer>
  );
};

const Text = styled.p`
  font-weight: 500;
  font-size: 1.2em;
`;

export default SkillSuggestions;
