/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const ProtectedRoute = ({
  component: ProtectedComponent,
  rootProps,
  name,
  childRoutes,
  ...rest
}) => {
  const { user } = useSelector(({ Auth }) => Auth);

  const isAuthenticated = localStorage.getItem("USER");

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && user.id ? (
          <>
            {rest.path.includes("/onboard") || (user && user.can_contribute) ? (
              <ProtectedComponent name={name} childRoutes={childRoutes} {...rootProps} {...props} />
            ) : (
              <Redirect to="/onboard" />
            )}
          </>
        ) : (
          <Redirect to={encodeURI(`/login?next=${window.location.pathname}`)} />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default ProtectedRoute;
