/* eslint-disable react/jsx-props-no-spreading */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState } from "react";
import PropTypes from "prop-types";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import { addPropsToChildren } from "../../utils/children";
import { filterEventProps } from "../../utils/events";
import { filterInputProps } from "../../utils/forms";
import Input from "../Input";

const InputGroup = (props) => {
  const {
    className,
    type,
    size,
    placeholder,
    onFocus,
    append,
    prepend,
    isAppendText,
    isPrependText,
    appendFunc,
    value,
  } = props;
  const [focus, setFocus] = useState(false);

  const onChangeFocus = (focusState) => {
    setFocus(focusState);
    if (onFocus) onFocus();
  };

  return (
    <div className={`input-group ${className || ""} ${focus ? "state-focus" : ""}`}>
      {prepend && (
        <span className="input-group-prepend">
          {isPrependText ? <span className="input-group-text">{prepend}</span> : prepend}
        </span>
      )}

      <Input
        type={type}
        className="form-control"
        size={size}
        dataTestId="input-group"
        placeholder={placeholder}
        {...filterInputProps(props)}
        {...filterEventProps(props)}
        onFocus={() => onChangeFocus(true)}
        onBlur={() => onChangeFocus(false)}
      />
      {append && (
        <span className="input-group-prepend">
          {isAppendText ? (
            <span className="input-group-text">{append}</span>
          ) : appendFunc ? (
            value !== "" &&
            addPropsToChildren(append, {
              onClick: () => appendFunc(this),
            })
          ) : (
            append
          )}
        </span>
      )}
    </div>
  );
};

InputGroup.defaultProps = {
  isPrependText: true,
  isAppendText: true,
};

InputGroup.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  prepend: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  append: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isPrependText: PropTypes.bool,
  isAppendText: PropTypes.bool,
  appendFunc: PropTypes.func,
  size: PropTypes.string,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default InputGroup;
