import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import RadioBox from "../Radiobox";

const RadioBoxLabel = ({ label, checked, disabled, onChange }) => {
  return (
    <Wrapper className="mb-2">
      <label className="font-14 d-flex align-items-center text-capitalize">
        <RadioBox
          className="me-2"
          data-testid={`check-${label}`}
          name="clear-it"
          disabled={disabled}
          checked={checked}
          onChange={onChange}
        />
        {label}
      </label>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

RadioBoxLabel.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default RadioBoxLabel;
