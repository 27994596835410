/* eslint-disable default-param-last */
import { LOCATION_CHANGE } from "react-router-redux";
import { combineReducers } from "redux";

import * as types from "../../configs/constants/ActionTypes";

const defaultMonthViewState = {
  "summary": {
    "total": 0,
    "expected": 0,
  },
  "results": [],
};

const defaultYearViewState = {
  project: "",
  results: [],
};

const monthView = (state = defaultMonthViewState, action) => {
  const { type, data } = action;

  switch (type) {
    case types.LIST_TIMESHEET_MONTHLY_ENTRIES_SUCCESS:
      return { ...data };

    default:
      return state;
  }
};

const yearView = (state = defaultYearViewState, action) => {
  const { type, data } = action;

  switch (type) {
    case types.LIST_TIMESHEET_YEARLY_ENTRIES_SUCCESS:
      return { ...data };

    default:
      return state;
  }
};

const users = (state = {}, action) => {
  const { type, data } = action;

  switch (type) {
    case types.GET_USER_TIMESHEET_ENTRIES_SUCCESS: {
      const userId = data?.userId;
      return { ...state, [userId]: { fetched: data?.fetched, entries: data.entries } };
    }

    case types.RESET_USER_TIMESHEET_ENTRIES: {
      const userId = data?.userId;

      if (userId) {
        return { ...state, [userId]: { fetched: false, entries: data.entries } };
      }

      return {};
    }

    default:
      return state;
  }
};

const isMakingRequest = (state = {}, action) => {
  switch (action.type) {
    case types.LIST_TIMESHEET_MONTHLY_ENTRIES_START:
      return { ...state, monthView: true };

    case types.LIST_TIMESHEET_YEARLY_ENTRIES_START:
      return { ...state, yearView: true };

    case types.LIST_TIMESHEET_MONTHLY_ENTRIES_SUCCESS:
    case types.LIST_TIMESHEET_MONTHLY_ENTRIES_FAILED:
      return { ...state, monthView: false };

    case types.LIST_TIMESHEET_YEARLY_ENTRIES_SUCCESS:
    case types.LIST_TIMESHEET_YEARLY_ENTRIES_FAILED:
      return { ...state, yearView: false };

    case types.TIMESHEET_APPROVE_REJECT_START:
      return { ...state, approveReject: true };

    case types.TIMESHEET_APPROVE_REJECT_SUCCESS:
    case types.TIMESHEET_APPROVE_REJECT_FAILED:
      return { ...state, approveReject: false };

    case types.UPDATE_TIMESHEET_ENTRY_START:
      return { ...state, updateTimeSheetEntry: true };

    case types.UPDATE_TIMESHEET_ENTRY_SUCCESS:
    case types.UPDATE_TIMESHEET_ENTRY_FAILED:
      return { ...state, updateTimeSheetEntry: false };

    default:
      return state;
  }
};

const isSaved = (state = {}, action) => {
  switch (action.type) {
    case types.LIST_TIMESHEET_MONTHLY_ENTRIES_START:
    case types.TIMESHEET_APPROVE_REJECT_START:
      return { approveReject: false };
    case types.TIMESHEET_APPROVE_REJECT_SUCCESS:
      return { approveReject: true, message: action.data.message };

    case types.UPDATE_TIMESHEET_ENTRY_START:
      return { updateTimeSheetEntry: false };
    case types.UPDATE_TIMESHEET_ENTRY_SUCCESS:
      return { updateTimeSheetEntry: true, message: action.data.message };
    case LOCATION_CHANGE:
      return {};

    default:
      return state;
  }
};

const errors = (state = {}, action) => {
  switch (action.type) {
    case types.TIMESHEET_APPROVE_REJECT_FAILED:
      return {
        approveReject: action.error,
      };

    case types.TIMESHEET_APPROVE_REJECT_START:
    case types.TIMESHEET_APPROVE_REJECT_SUCCESS:
    case LOCATION_CHANGE:
      return {};

    default:
      return state;
  }
};

const TimeSheetEntries = combineReducers({
  monthView,
  yearView,
  users,
  isMakingRequest,
  isSaved,
  errors,
});
export default TimeSheetEntries;
