/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";

/* ------------------------- Component dependencies ------------------------- */
import { AnimatedButton } from "../../../../components/Button";
import FormInput from "./FormInput";

const AddAdminEmail = ({ onSave }) => {
  const { user } = useSelector(({ Auth }) => Auth);

  const [invoiceEmail, setInvoiceEmail] = useState(user?.invoice_email || user?.email || "");

  return (
    <form onSubmit={onSave} aria-label="addAdminEmail-form" id="addAdminEmail">
      <div className="header">Add admin email address for invoices</div>
      <FormInput
        value={invoiceEmail}
        label="New Email Address"
        id="invoice_email"
        name="invoice_email"
        type="email"
        placeholder="Enter new email address"
        onChange={(e) => setInvoiceEmail(e.target.value)}
      />
      <Row>
        <Col>
          <AnimatedButton
            aria-label="addAdminEmail-submit"
            targetform="#addAdminEmail"
            className="loader-opaque"
            disabled={user.invoice_email === invoiceEmail}
          >
            Save
          </AnimatedButton>
        </Col>
      </Row>
    </form>
  );
};

AddAdminEmail.propTypes = {
  onSave: PropTypes.func,
};

export default AddAdminEmail;
