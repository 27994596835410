/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import axios from "axios";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import { failed, success } from "../../utils/actions";
import { ENDPOINT_COUNTRIES } from "../../utils/api";
import * as actionTypes from "../../configs/constants/ActionTypes";

const getCountries = () => async (dispatch) => {
  try {
    const response = await axios.get(ENDPOINT_COUNTRIES);
    dispatch(success(actionTypes.GET_COUNTRIES_SUCCESS, response.data));
  } catch (error) {
    dispatch(failed(actionTypes, error));
  }
};

export { getCountries };
