import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import { getPaymentsStatusFilters, getPaymentsTypeQueries } from "../../../../utils/invoiceUtils";
import { downloadInoicesCsv } from "../../../../redux/actions/InvoiceActions";
import { useInvoices } from "../../../../context/InvoiceContext";
import CloudDownloadIcon from "../../../../components/svg/CloudDownloadIcon";

const getInvoiceSet = ({ dispatch, filter, types, invoiceDate, scope, project }) => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

  const startDate = `${moment(firstDay).format(moment.HTML5_FMT.DATE)}T00:00:00`;
  const endDate = `${moment(new Date()).format(moment.HTML5_FMT.DATE)}T23:59:59`;

  const filters = {
    ...getPaymentsStatusFilters(filter),
    ...getPaymentsTypeQueries(types, scope),
    ...(project !== null ? { project: project?.id } : {}),

    start: invoiceDate?.from || startDate,
    end: invoiceDate?.to || endDate,
  };
  dispatch(downloadInoicesCsv(filters, {}));
};

const CSVDownload = ({ type, project = null }) => {
  const dispatch = useDispatch();
  const {
    paymentState: { invoiceStatus, invoiceType, date: invoiceDate },
    payoutState: { invoiceStatus: payoutInvoiceStatus, date: payoutInvoiceDate },
  } = useInvoices();

  const [selectedStatus] = invoiceStatus;
  const [selectedPayoutStatus] = payoutInvoiceStatus;

  const isPayments = type === "in";

  const activeSelectedStatus = isPayments ? selectedStatus : selectedPayoutStatus;
  const activeInvoiceType = isPayments ? invoiceType : "purchase";
  const activeInvoiceDate = isPayments ? invoiceDate : payoutInvoiceDate;

  const handleClick = () => {
    getInvoiceSet({
      dispatch,
      filter: activeSelectedStatus,
      types: activeInvoiceType,
      invoiceDate: activeInvoiceDate,
      scope: type,
      project,
    });
  };

  return (
    <Button
      type="button"
      className="btn download-csv-btn ml-2 d-flex align-items-center"
      onClick={handleClick}
      data-testid="downloadCsv"
    >
      Download.csv <CloudDownloadIcon />
    </Button>
  );
};

const Button = styled.button`
  background: #ffffff;
  padding: 1em;
  border-radius: 4px;
  border: 1px solid #e3e9f2;
  color: #151a30;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 14px;
  height: 32px;
  line-height: unset;
  column-gap: 17px;
  svg {
    stroke: #7f8c9f;
  }
`;

CSVDownload.propTypes = {
  type: PropTypes.string,
  project: PropTypes.number,
};

export default CSVDownload;
