/* eslint-disable react/prop-types */
import React from "react";
import { FormGroup } from "reactstrap";
import styled from "styled-components";

import Input from "../Input";
import Select from "../Select";
import UserSelector from "../UserSelector";
import {
  EXPENSE_REIMBURSEMENT_LINE_ITEM,
  HOURS_WORKED_LINE_ITEM,
  LOAN_LINE_ITEM,
  LOAN_REPAYMENT_LINE_ITEM,
} from "../../configs/constants/invoiceConstants";

const PayoutInvoiceItem = ({
  idx,
  participants,
  user,
  updatePayout,
  amount,
  description,
  lineItemType,
  currency,
  remove,
  required,
}) => {
  return (
    <Container className="d-flex flex-column gap-3">
      <div className="member">
        <label htmlFor="team-member">
          Team Member
          <span style={{ color: "#da3451", paddingLeft: "2px" }}>*</span>
        </label>

        <UserSelector
          users={participants}
          filter={{}}
          label={false}
          type="singular"
          max={1}
          className="py-0"
          variant="bottom"
          placeholder="Select from project"
          selected={user}
          onChange={(users) => updatePayout("user", users[0])}
          noDelete
        />
      </div>

      <div className="invoice-item">
        <div className="input-groups">
          <FormGroup className="type m-0">
            <label htmlFor="line_item_type" className="control-label">
              Type
            </label>

            <Select
              id="line_item_type"
              className="line_item_type"
              data-testid={`line_item_type_${idx}`}
              onChange={(e) => updatePayout("line_item_type", e.target.value)}
              value={lineItemType}
              required
            >
              <option value="">Select Type</option>
              <option value={HOURS_WORKED_LINE_ITEM}>{HOURS_WORKED_LINE_ITEM}</option>
              <option value={LOAN_LINE_ITEM}>{LOAN_LINE_ITEM}</option>
              <option value={LOAN_REPAYMENT_LINE_ITEM}>{LOAN_REPAYMENT_LINE_ITEM}</option>
              <option value={EXPENSE_REIMBURSEMENT_LINE_ITEM}>
                {EXPENSE_REIMBURSEMENT_LINE_ITEM}
              </option>
            </Select>
          </FormGroup>

          <FormGroup className="description m-0">
            <label htmlFor="description">Description</label>

            <Input
              id="description"
              value={description}
              dataTestId={`paymentDescription${idx}`}
              onChange={(e) => updatePayout("description", e.target.value)}
              className="py-0"
              placeholder="Enter a title for this payment"
              required
            />
          </FormGroup>

          <FormGroup className="amount m-0">
            <label htmlFor="amount">Amount {currency}</label>

            <Input
              type="number"
              id="amount"
              value={amount}
              className="py-0"
              onChange={(e) => updatePayout("amount", e.target.value)}
              placeholder="Enter amount"
              dataTestId={`amount${idx}`}
              required={required}
            />
          </FormGroup>
        </div>

        <button
          data-testid={`remove-${idx}`}
          type="button"
          className="btn px-0 bg-transparent"
          onClick={remove}
        >
          Remove
        </button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  .invoice-item {
    width: 100%;
    padding: 15px 20px;
    background-color: #8f9bb30d;
  }

  .selected {
    padding: 0 !important;
  }

  .input-groups {
    display: flex;
    column-gap: 20px;
    margin-bottom: 10px;
  }

  .type {
    flex-basis: 136px;
  }
  .description {
    flex-basis: 340px;
  }
  .amount {
    flex-basis: 190px;
  }

  #description,
  #amount {
    background-color: #fff !important;
  }

  .btn {
    height: unset;
    line-height: unset;
    color: #da3451;
    font-weight: 500;
    font-size: 14px;
  }

  input,
  select.line_item_type {
    padding-left: 15px;
    padding-right: 15px;
  }

  select.line_item_type {
    padding-right: 30px;

    + div {
      right: 10px;
    }
  }
`;

export default PayoutInvoiceItem;
