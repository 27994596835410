import React, { useMemo } from "react";

import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { useTrackPage } from "../../../analytics/segment";
import { pages } from "../../../analytics/segment/tags";
import MetaTags from "../../../components/MetaTags";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { isCSO, isDev, isPM, isSpaceLead } from "../../../utils/auth";
import Account from "./account/Account";
import CompanyDetails from "./CompanyDetails";
import CompanyProfile from "./CompanyProfile";
import ActiveUser from "./Invite/ActiveUser";
import CreateUser from "./Invite/CreateUser";
import DeactivatedUser from "./Invite/DeactivatedUser";
import Invite from "./Invite/InviteUser";
import PendingInvite from "./Invite/pendingInvites/PendingInvites";
import Payment from "./Payment";
import PersonalDetails from "./PersonalDetails";
import Privacy from "./Privacy";
import Profile from "./Profile";
import SkillsLibrary from "./skills/SkillLibrary";
import SkillSuggestions from "./skills/SuggestedSkills";

const Settings = () => {
  useTrackPage(pages.SETTINGS);

  const location = useLocation();

  const { classNames, pageTitle } = useMemo(() => {
    const classes = [];
    let pageTitleString = "";

    switch (true) {
      case location.pathname === "/settings/profile":
        classes.push("border-0");
        pageTitleString = "Profile Settings";
        break;

      case location.pathname === "/settings/payment":
        classes.push("s-payment");
        pageTitleString = "Payment Settings";
        break;

      case location.pathname === "/settings/skills/library":
        classes.push("skills_library");
        pageTitleString = "Skills Library";
        break;

      case location.pathname === "/settings/skills/suggestions":
        classes.push("skills_library");
        pageTitleString = "Skills Suggestions";
        break;

      case location.pathname === "/settings/users/active":
        classes.push("full_width");
        pageTitleString = "Active Users";
        break;

      case location.pathname === "/settings/users/deactivated":
        classes.push("full_width");
        pageTitleString = "Deactivated Users";
        break;

      case location.pathname.includes("/pending"):
        classes.push("full_width pending_invites");
        pageTitleString = "Pending Invites";
        break;

      case location.pathname === "/settings/personal-details":
        pageTitleString = "Personal Details";
        break;

      case location.pathname === "/settings/account":
        pageTitleString = "Account Settings";
        break;

      case location.pathname === "/settings/privacy":
        pageTitleString = "Privacy Settings";
        break;

      case location.pathname === "/settings/company-profile":
        pageTitleString = "Company Profile";
        break;

      case location.pathname === "/settings/company-details":
        pageTitleString = "Company Details";
        break;

      case location.pathname === "/settings/users/invite":
        pageTitleString = "Invite Users";
        break;

      case location.pathname === "/settings/users/create":
        pageTitleString = "Create User";
        break;

      default:
        break;
    }

    return { classNames: classes.join(" "), pageTitle: pageTitleString };
  }, [location.pathname]);

  return (
    <>
      <MetaTags
        title={pageTitle}
        description="Update your personal, account, payment, and privacy settings."
      />
      <DashboardLayout>
        <Switch>
          <Redirect
            exact
            from="/settings"
            to={`/settings/${
              isDev() || isPM() || isSpaceLead() || isCSO() ? "profile" : "personal-details"
            }`}
          />
          <Redirect exact from="/settings/users" to="/settings/users/active" />
          <Redirect exact from="/settings/skills" to="/settings/skills/library" />
          <Route exact path="/settings/profile" component={Profile} />
        </Switch>
        <div className={`content-card settings-card settings-container clearfix ${classNames}`}>
          <Switch>
            <Route exact path="/settings/personal-details" component={PersonalDetails} />
            <Route exact path="/settings/account" component={Account} />
            <Route exact path="/settings/payment" component={Payment} />
            <Route exact path="/settings/privacy" component={Privacy} />
            <Route exact path="/settings/company-profile" component={CompanyProfile} />
            <Route exact path="/settings/company-details" component={CompanyDetails} />
            <Route exact path="/settings/users/active" component={ActiveUser} />
            <Route exact path="/settings/skills/library" component={SkillsLibrary} />
            <Route exact path="/settings/skills/suggestions" component={SkillSuggestions} />
            <Route exact path="/settings/users/deactivated" component={DeactivatedUser} />
            <Route exact path="/settings/users/invite" component={Invite} />
            <Route exact path="/settings/users/create" component={CreateUser} />
            <Route exact path="/settings/users/pending" component={PendingInvite} />
          </Switch>
        </div>
      </DashboardLayout>
    </>
  );
};

export default Settings;
