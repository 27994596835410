/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import { addEventListeners, BUTTON_EVENTS } from "../utils/events";
import { filterButtonProps } from "../utils/forms";
import * as actionTypes from "../configs/constants/ActionTypes";
import Loading from "./svg/Loading";

const Button = (props) => {
  const {
    type,
    form,
    variant,
    className,
    block,
    size,
    "data-tip": dataTip,
    children,
    ...rest
  } = props;

  return (
    <button
      {...rest}
      type={type}
      form={form}
      className={`btn ${variant ? `btn-${variant}` : ""} ${className || ""} ${
        block ? "btn-block" : ""
      } ${size ? `btn-${size}` : ""}`}
      {...filterButtonProps(props)}
      {...addEventListeners(BUTTON_EVENTS, props)}
      data-tip={dataTip}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  type: "button",
  form: null,
  variant: "default",
  block: false,
};

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  block: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  form: PropTypes.string,
  "data-tip": PropTypes.string,
};

export const AnimatedButton = ({ children, ...props }) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const { Common: common } = useSelector((store) => store);

  const setSubmit = () => {
    if (props.default) return;

    dispatch({
      type: actionTypes.SET_BUTTON,
      data: true,
    });
  };

  useEffect(() => {
    if (!common.button) {
      setActive(false);
    }
  }, [common.button]);

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_BUTTON,
      data: false,
    });
    let form;
    if (props.targetform) {
      form = document.querySelector(props.targetform);
    } else {
      const forms = document.querySelectorAll("form");
      form = forms[1] ? forms[1] : forms[0];
    }

    form.addEventListener("submit", setSubmit);
    return () => form.removeEventListener("submit", setSubmit);
  }, []);

  return (
    <button
      type={active && common && common.button ? "button" : "submit"}
      onClick={() => {
        setActive(true);
      }}
      {...props}
      className={`btn btn-primary save animate ${props.className}`}
    >
      {active && common && common.button ? <Loading /> : children}
    </button>
  );
};

AnimatedButton.propTypes = {
  default: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  className: PropTypes.string,
  targetform: PropTypes.string,
};

export default Button;
