/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import styled from "styled-components";

/* -------------------------- Internal Dependencies ------------------------- */
import Avatar from "../../../../components/Avatar";
import { ProjectCardStyle } from "../../projects/components/ProjectCard";
import { eventTrack } from "../../../../utils/analytics";
import { CATEGORIES, EVENTS } from "../../../../analytics/events";

const OpportunityCard = ({ opportunity }) => {
  return (
    <Wrapper>
      <Link
        onClick={() =>
          eventTrack(CATEGORIES.projects, `${EVENTS.view_opportunity}: ${opportunity.title}`)
        }
        to={`/opportunities/${opportunity.id}`}
        className="project-card"
      >
        <h4>
          {_.truncate(opportunity.title, {
            length: 30,
          })}
        </h4>
        <div>
          <p className="time m-0">
            <i className="tg-ic-clock-outline tunga-ic-sz-sm " />
            {moment.utc(opportunity.created_at).local().format("DD MMM YYYY")}
          </p>
        </div>
        {opportunity.hiring_manager && (
          <>
            <p className="created-by">Created By</p>
            <div className="d-flex align-items-center user-wrap">
              <Avatar
                dataTestId="avatar"
                className="avatar-dash avatar-initials"
                image={opportunity.hiring_manager.photo_thumb_url}
                initials={opportunity.hiring_manager.initials}
              />

              <span className="created-by-user">
                {opportunity.hiring_manager.first_name} {opportunity.hiring_manager.last_name}
              </span>
            </div>
          </>
        )}
        {opportunity.user_status && (
          <div>
            {opportunity.user_status.disqualified && !opportunity.user_status.self_disqualified ? (
              <span className="completed disqualified">Disqualified</span>
            ) : (
              <span
                className={`completed ${
                  opportunity.user_status.disqualified && !opportunity.user_status.self_disqualified
                    ? "disqualified"
                    : ""
                }`}
              >
                {opportunity.user_status.status}
              </span>
            )}
          </div>
        )}
      </Link>
    </Wrapper>
  );
};

OpportunityCard.propTypes = {
  opportunity: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string,
    status: PropTypes.oneOf(["published", "closed"]),
    title: PropTypes.string,
    published_at: PropTypes.string,
    created_at: PropTypes.string,
    location: PropTypes.string,
    hiring_manager: PropTypes.shape({
      email: PropTypes.string,
      first_name: PropTypes.string,
      has_avatar: PropTypes.bool,
      id: PropTypes.number,
      initials: PropTypes.string,
      last_name: PropTypes.string,
      photo_normal_url: PropTypes.string,
      photo_thumb_url: PropTypes.string,
      time_format24: PropTypes.bool,
      timezone: PropTypes.string,
    }),
    user_status: PropTypes.oneOf([
      PropTypes.any,
      PropTypes.shape({
        status: PropTypes.string,
        status_id: PropTypes.number,
        status_category: PropTypes.string,
        disqualified: PropTypes.bool,
      }),
    ]),
  }),
};

const Wrapper = styled(ProjectCardStyle)`
  .project-card {
    height: 180px;
    box-shadow: none;
  }

  h4 {
    font-weight: 500;
    margin-bottom: 10px;
  }

  h4,
  p.created-by,
  span.created-by-user {
    font-size: 16px;
    line-height: 24px;
    color: #151a30;
  }

  p.time {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #8f9bb3;

    i {
      margin-right: 4px;
      color: #8f9bb3;
    }
  }

  p.created-by {
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  span.created-by-user {
    font-weight: 400;
    color: #3e4857;
  }

  .avatar {
    width: 30px;
    height: 30px;

    &.avatar-initials {
      font-size: 10px;
    }
  }

  .user-wrap {
    margin-bottom: 10px;
  }

  span.completed {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border-radius: 50px;
    padding: 6px 24px;
    display: inline-block;
    border: 1px solid rgba(33, 150, 83, 0.04);
    color: #219653;
    background: rgba(33, 150, 83, 0.04);
    &.disqualified {
      color: #da3451;
      background: rgb(150 33 33 / 4%);
    }
  }
`;

export default OpportunityCard;
