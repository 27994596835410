import React, { useEffect, useMemo, useState } from "react";
import { ButtonDropdown, DropdownItem } from "reactstrap";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { MenuV2 } from "./Menu";
import { SortToggle } from "./Toggle";
import { useInvoices } from "../../context/InvoiceContext";
import usePrevious from "../../hooks/usePrevious";

const SortFilter = ({ type }) => {
  const isPayments = type === "in";
  const { project } = useSelector(({ Projects }) => Projects);
  const { paymentState, payoutState, updatePaymentState, updatePayoutState } = useInvoices();

  const state = isPayments ? paymentState : payoutState;
  const updateAction = isPayments ? updatePaymentState : updatePayoutState;

  const { ordering } = state;

  const [dropdownOpen, setDropdown] = useState(false);
  const [activeSort, setActiveSort] = useState("Issued At");
  const [sortDirection, setSortDirection] = useState("desc");

  const prevSortDirection = usePrevious(sortDirection);

  const isDesc = sortDirection === "desc";

  const sortItems = useMemo(() => {
    const projectAndClient = !project?.id ? { project_owner: "Client", project: "Project" } : {};

    return {
      issued_at: "Issued At",
      created_at: "Date Created",
      number: "Invoice No",
      ...projectAndClient,
      due_at: "Due Date",
      amount: "Amount",
      status: "Status",
    };
  }, [project]);

  const toggle = () => {
    setDropdown(!dropdownOpen);
  };

  const toggleDirection = () => {
    setSortDirection(isDesc ? "asc" : "desc");
  };

  useEffect(() => {
    const cleanedOrdering = ordering?.replace("-", "");
    setActiveSort(sortItems[cleanedOrdering]);
  }, [ordering]);

  useEffect(() => {
    if (prevSortDirection && prevSortDirection !== sortDirection) {
      const cleanedOrdering = ordering?.replace("-", "");
      updateAction("ordering", `${isDesc ? "-" : ""}${cleanedOrdering}`);
    }
  }, [sortDirection]);

  return (
    <Wrapper className="ms-2">
      <ButtonDropdown className="add-btn" isOpen={dropdownOpen} toggle={toggle}>
        <SortToggle
          label={activeSort}
          className="sort"
          sortDirection={sortDirection}
          toggleDirection={toggleDirection}
        />

        <MenuV2 right minwidth="180px">
          {Object.entries(sortItems).map(([key, value]) => (
            <DropdownItem
              key={key}
              className="dropdown-item add-btn"
              onClick={() => {
                setActiveSort(value);
                updateAction("ordering", `${isDesc ? "-" : ""}${key}`);
              }}
              // data-testid={item.title}
            >
              {value}
            </DropdownItem>
          ))}
        </MenuV2>
      </ButtonDropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .options {
    padding: 0 17px;
  }

  hr {
    margin: 8px 0;
    opacity: 1;
    background-color: #edf1f7;
  }
`;

SortFilter.propTypes = {
  type: PropTypes.string,
};

export default SortFilter;
