import React from "react";
import {
  canSendInvoiceReminder,
  canUnarchiveInvoice,
  isClient,
  isCMOrCSOAndHasProjectAcess,
  isDev,
} from "../auth";
import { Reminder } from ".";
import Icon from "../../components/Icon";
import { STATUS_INTERESTED } from "../api";

export const getPayoutTableColumns = (
  status,
  // project = null,
  scope = "project",
  checked = false,
  onCheckChange = () => null,
) => {
  const isSidebarPayment = scope === "sideBarPayment";
  const isPaidFilter = status === "paid";
  const showActionsColumn =
    (status !== "paid" && status !== "archived") ||
    (status === "archived" && canUnarchiveInvoice());

  const showCheckAllBox = !(status === "archived" || status === "paid") && !(isDev() || isClient());

  // if (scope === "sideBarPayment") {
  return [
    ...(isSidebarPayment && showCheckAllBox
      ? [
          {
            Header: (
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  name="check-it"
                  data-testid="check-all"
                  className="custom-checkbox"
                  checked={checked}
                  onChange={onCheckChange}
                />
              </div>
            ),
            accessor: "batch_action",
          },
        ]
      : []),
    {
      Header: "Date Created",
      accessor: "created_at",
      columnOpts: { main: true, editable: true },
    },
    {
      Header: "Invoice No.",
      accessor: "invoice",
      columnOpts: { main: true, editable: false },
    },
    {
      Header: `Title`,
      accessor: "title",
      columnOpts: { main: true, editable: false },
    },
    ...(!(isDev() || isClient())
      ? [
          {
            Header: `Exact URL`,
            accessor: "exact_invoice_url",
            columnOpts: { main: false, editable: true },
          },
        ]
      : []),
    {
      Header: `Client`,
      accessor: "client",
      columnOpts: { main: true, editable: true },
    },
    ...(isSidebarPayment
      ? [
          {
            Header: `Project`,
            accessor: "project",
            columnOpts: { main: true, editable: true },
          },
        ]
      : []),
    {
      Header: `Freelancer`,
      accessor: "freelancer",
      columnOpts: { main: true, editable: true },
    },
    {
      Header: "Amount",
      accessor: "amount",
      columnOpts: { main: true, editable: true },
    },
    ...(!isPaidFilter
      ? [{ Header: "Due Date", accessor: "due_at", columnOpts: { main: true, editable: true } }]
      : []),
    {
      Header: "Status",
      accessor: "status",
      columnOpts: { main: true, editable: true },
    },
    {
      Header: "Platform",
      accessor: "platform",
      columnOpts: { main: false, editable: true },
    },

    ...(showActionsColumn
      ? [
          {
            Header: "",
            accessor: "actions",
            columnOpts: { main: true, editable: true },
          },
        ]
      : []),
  ];
  // }

  // return [
  //   {
  //     Header: "Date Created",
  //     accessor: "created_at",
  //     columnOpts: { main: true, editable: true },
  //   },
  //   {
  //     Header: "Invoice No.",
  //     accessor: "invoice",
  //     columnOpts: { main: true, editable: false },
  //   },
  //   {
  //     Header: `Title`,
  //     accessor: "title",
  //     columnOpts: { main: true, editable: false },
  //   },
  //   ...(!(isDev() || isClient())
  //     ? [
  //         {
  //           Header: `Exact URL`,
  //           accessor: "exact_invoice_url",
  //           columnOpts: { main: false, editable: true },
  //         },
  //       ]
  //     : []),
  //   {
  //     Header: `Freelancer`,
  //     accessor: "freelancer",
  //     columnOpts: { main: true, editable: true },
  //   },
  //   {
  //     Header: `Client`,
  //     accessor: "client",
  //     columnOpts: { main: true, editable: true },
  //   },
  //   {
  //     Header: "Amount",
  //     accessor: "amount",
  //     columnOpts: { main: true, editable: true },
  //   },
  //   {
  //     Header: "Status",
  //     accessor: "status",
  //     columnOpts: { main: true, editable: true },
  //   },
  //   {
  //     Header: "Platform",
  //     accessor: "platform",
  //     columnOpts: { main: false, editable: true },
  //   },
  //   ...(showActionsColumn
  //     ? [
  //         {
  //           Header: "",
  //           accessor: "actions",
  //           columnOpts: { main: true, editable: true },
  //         },
  //       ]
  //     : []),
  // ];
};

export const getTableColumns = (
  status,
  project = null,
  checked = false,
  onCheckChange = () => null,
) => {
  const showActionsColumn =
    (status !== "paid" && status !== "archived") ||
    (status === "archived" && canUnarchiveInvoice());

  const showCheckAllBox = !(status === "archived" || status === "paid") && !isClient();

  return [
    ...(showCheckAllBox
      ? [
          {
            Header: (
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  name="check-it"
                  data-testid="check-all"
                  className="custom-checkbox"
                  checked={checked}
                  onChange={onCheckChange}
                />
              </div>
            ),
            accessor: "batch_action",
          },
        ]
      : []),
    {
      Header: "Date Created",
      accessor: "created_at",
      columnOpts: { main: true, editable: true },
    },
    ...(!project
      ? [
          {
            Header: "Title",
            accessor: "title",
            columnOpts: { main: true, editable: false },
          },
          {
            Header: "Client",
            accessor: "client",
            columnOpts: { main: true, editable: true },
          },
          {
            Header: "Project",
            accessor: "project",
            columnOpts: { main: true, editable: true },
          },
        ]
      : [
          {
            Header: "Title",
            accessor: "title",
            columnOpts: { main: true, editable: false },
          },
        ]),
    ...(!(isDev() || isClient())
      ? [
          {
            Header: `Exact URL`,
            accessor: "exact_invoice_url",
            columnOpts: { main: false, editable: true },
          },
        ]
      : []),
    {
      Header: "Invoice No.",
      accessor: "invoice",
      columnOpts: { main: true, editable: false },
    },
    {
      Header: "Amount",
      accessor: "amount",
      columnOpts: { main: true, editable: true },
    },
    ...(canSendInvoiceReminder()
      ? [
          {
            Header: <Reminder />,
            accessor: "reminder_count",
            className: "reminder-count",
            columnOpts: { main: false, editable: true },
          },
        ]
      : []),
    // ...(status !== "paid"
    // ? [
    {
      Header: "Due Date",
      accessor: "due_date",
      columnOpts: { main: true, editable: true },
    },
    {
      Header: "Status",
      accessor: "status",
      columnOpts: { main: true, editable: true },
    },
    // ]
    // : []),
    {
      Header: "Email",
      accessor: "email",
      columnOpts: { main: true, editable: true },
    },
    ...(showActionsColumn
      ? [
          {
            Header: "",
            accessor: "actions",
            columnOpts: { main: true, editable: true },
          },
        ]
      : []),
  ];
};

export const getOpportunityTableColumns = (checkAll, status, sendEmail, project, checkElement) => {
  const defaultColumns = [
    {
      Header: "Developer",
      accessor: "user",
    },
    {
      Header: <span>Responded On</span>,
      accessor: "responded_at",
    },
    ...(isCMOrCSOAndHasProjectAcess(project) || canSendInvoiceReminder()
      ? status === "initial"
        ? [
            {
              Header: (
                <div style={{ textAlign: "right", paddingRight: "0px" }}>
                  <button
                    style={{
                      border: "none",
                      color: "#da3451",
                      background: "none",
                      marginLeft: "40px",
                      textAlign: "right",
                    }}
                    onClick={() => sendEmail()}
                    type="button"
                    data-testid="sendEmail"
                  >
                    SEND EMAIL
                    <Icon className="mx-1" name="round-arrow-back-ios" size="sm" />
                  </button>
                </div>
              ),
              accessor: "actions",
            },
          ]
        : status === STATUS_INTERESTED
        ? [
            {
              Header: (
                <div style={{ width: "100%", textAlign: "right", paddingRight: 25 }}>Actions</div>
              ),
              accessor: "actions",
            },
          ]
        : []
      : []),
  ];
  return status === STATUS_INTERESTED
    ? [
        {
          Header: (
            <input
              type="checkbox"
              name="check-it"
              data-testid="checkAllInput"
              className="custom-checkbox"
              onClick={checkAll}
              ref={checkElement}
            />
          ),
          accessor: "batch_action",
        },
        ...defaultColumns,
      ]
    : defaultColumns;
};

export const getShortlistTableColumns = () => [
  {
    Header: "Developer",
    accessor: "developer",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
