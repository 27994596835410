/* eslint-disable react/jsx-props-no-spreading */
/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const RadioBoxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: relative;
`;

const Icon = styled.div`
  background-color: #062e64;
  width: 12px;
  height: 12px;
  border-radius: 5px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenRadioBox = styled.input.attrs({ type: "radio" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledRadioBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 5px;
  transition: all 150ms;

  ${(props) => (props.checked ? "border: 2px solid #062e64" : "border: 2px solid #8f9bb3")};

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

/* ---------------PropType Check---------------- */

const propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

const RadioBox = ({ className, checked, onChange, ...props }) => (
  <RadioBoxContainer className={className}>
    <HiddenRadioBox checked={checked} onChange={onChange} {...props} />
    <StyledRadioBox checked={checked}>
      <Icon />
    </StyledRadioBox>
  </RadioBoxContainer>
);

RadioBox.propTypes = propTypes;

export default RadioBox;
