import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import { FormGroup } from "react-bootstrap";

import { countries as countriesList } from "../../../../components/countries";
import { ContentSection } from "../../../../utils/styles";
import InviteContainer from "./InviteContainer";
import Label from "../../../../components/Label";
import * as inviteActions from "../../../../redux/actions/InvitesActions";
import { SET_BUTTON } from "../../../../configs/constants/ActionTypes";
import { getFormData } from "../../../../utils/forms";
import { AnimatedButton } from "../../../../components/Button";
import Select from "../../../../components/Select";
import { success } from "../../../../utils/actions";
import { trackEvent } from "../../../../analytics/segment";
import { CATEGORIES, EVENTS } from "../../../../analytics/events";
import { openAlertModal } from "../../../../utils/modals";

const CreateUser = () => {
  const [countries, setCountries] = useState([]);
  const [userCountry, setUserCountry] = useState("");

  const dispatch = useDispatch();

  const { user } = useSelector(({ Auth }) => Auth);

  useEffect(() => {
    setCountries(countriesList);
  }, []);

  const onSave = async (e) => {
    e.preventDefault();

    const form = document.querySelector("form");
    const formdata = new FormData(form);
    const data = getFormData(formdata);

    data.username = data.email;
    data.company = {
      name: data.company,
      street: data.street,
      plot_number: data.plot_number,
      city: data.city,
      postal_code: data.postal_code,
      country: data.country,
      vat_number: data.vat_number,
    };

    if (data.country === "choose") {
      openAlertModal("Choose a country", true);

      dispatch(success(SET_BUTTON, false));
    } else {
      inviteActions.createUser({ ...data })(dispatch, () => {
        form.reset();
        trackEvent(EVENTS.create_client, {
          user_id: user.id,
          username: user.display_name,
          event_category: CATEGORIES.settings,
        });
      });
    }
  };

  const btnDisabled = !userCountry || userCountry === "----";

  return (
    <InviteContainer>
      <ContentSection style={{ paddingTop: "0" }}>
        <form onSubmit={onSave}>
          <input type="hidden" name="source" value="3" />
          <input type="hidden" name="type" value="2" />
          <Label name="Email Address">
            <Input placeholder="eg. bart@tunga.io" name="email" aria-label="email-input" required />
          </Label>
          <Label name="First Name">
            <Input
              placeholder="Enter first name"
              name="first_name"
              aria-label="first_name-input"
              required
            />
          </Label>
          <Label name="Last Name">
            <Input
              placeholder="Enter last name"
              name="last_name"
              aria-label="last_name-input"
              required
            />
          </Label>
          <Label name="Company name">
            <Input
              placeholder="Enter name of company"
              name="company"
              aria-label="company-input"
              required
            />
          </Label>

          <Label name="VAT Number: " required={false}>
            <Input placeholder="VAT Number" name="vat_number" aria-label="vat_number-input" />
          </Label>

          <div className="row">
            <div className="col-sm-6" aria-label="label">
              <FormGroup>
                <label htmlFor="street" className="control-label" aria-label="street">
                  Street
                  <span className="label-style">*</span>
                </label>
                <Input
                  id="street"
                  placeholder="Enter street name"
                  name="street"
                  aria-label="street-input"
                  required
                />
              </FormGroup>
            </div>
            <div className="col-sm-3" aria-label="label">
              <FormGroup>
                <label htmlFor="plot_number" className="control-label" aria-label="Number/Plot">
                  Number/Plot
                  <span className="label-style">*</span>
                </label>

                <Input
                  id="plot_number"
                  placeholder="Enter no."
                  type="number"
                  name="plot_number"
                  aria-label="plot_number-input"
                  required
                />
              </FormGroup>
            </div>
            <div className="col-sm-3" aria-label="label">
              <FormGroup>
                <label htmlFor="postal_code" className="control-label" aria-label="Zip code">
                  Zip code
                  <span className="label-style">*</span>
                </label>
                <Input
                  placeholder="Enter ZIP"
                  name="postal_code"
                  aria-label="postal_code-input"
                  required
                />
              </FormGroup>
            </div>
          </div>
          <div className="row" aria-label="label">
            <div className="col-sm-6">
              <FormGroup>
                <label htmlFor="city" className="control-label" aria-label="city">
                  City
                  <span className="label-style">*</span>
                </label>

                <Input
                  id="city"
                  placeholder="Enter city"
                  name="city"
                  aria-label="city-input"
                  required
                />
              </FormGroup>
            </div>
            <div className="col-sm-6" aria-label="label">
              <FormGroup>
                <label htmlFor="country" className="control-label" aria-label="country">
                  Country
                  <span className="label-style">*</span>
                </label>

                <Select
                  className="form-control"
                  defaultValue="Canada"
                  id="country"
                  name="country"
                  onChange={(e) => setUserCountry(e.target.value)}
                  aria-label="country-input"
                  options={countries}
                  required
                >
                  <option value="choose">Choose Country</option>
                </Select>
              </FormGroup>
            </div>
          </div>

          <div className="col-12">
            <AnimatedButton disabled={btnDisabled} aria-label="submit">
              Create User
            </AnimatedButton>
          </div>
        </form>
      </ContentSection>
    </InviteContainer>
  );
};

export default CreateUser;
