import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import moment from "moment";
import PropTypes from "prop-types";

import DateFilter from "../../../../components/InvoiceFilters/DateFilter";
import TypeFilter from "../../../../components/InvoiceFilters/TypeFilter";
import StatusFilter from "../../../../components/InvoiceFilters/StatusFilter";
import Tag from "../../../../components/Tag";
import CSVDownload from "./CSVDownload";
import { canDownloadInvoice } from "../../../../utils/auth";
import ColumnVisibility from "../../../../components/InvoiceFilters/ColumnVisibility";
import SortFilter from "../../../../components/InvoiceFilters/SortFilter";
import { useInvoices } from "../../../../context/InvoiceContext";
import BulkActions from "../../../../components/BulkActions";
import {
  ARCHIVE_ACTION,
  DELETE_ACTION,
  GENERATE_INVOICE_ACTION,
  MARK_AS_PAID_ACTION,
  SEND_REMINDER,
} from "../../../../configs/constants/invoiceConstants";
import EmailStatusFilter from "../../../../components/InvoiceFilters/EmailStatusFilter";
import { showAction } from "../../../../utils/invoiceUtils/invoiceActions";

const keyMaps = {
  "paid": "Paid",
  "sale": "Sale",
  "final": "Final",
  "credit-notes": "Credit Note",
  "commitment-payments": "Commitment",
  "archived": "Archived",
  "overdue": "Overdue",
  "pending": "Pending",
  "failed": "Failed",
  "sent": "Sent",
};

const formatDate = (date) => moment(date).format("DD MMM YYYY");

const PaymentFilters = ({ checked, toggleAll, project }) => {
  const { paymentState, paymentColumns, updatePaymentState } = useInvoices();

  const removeDateFilter = useCallback(
    () => updatePaymentState("date", { from: null, to: null }),
    [],
  );

  const removeStatusFilter = useCallback(
    (key) => () => {
      if (key === "invoiceStatus") {
        return updatePaymentState("invoiceStatus", ["all"]);
      }

      return updatePaymentState("invoiceEmailStatus", ["all"]);
    },
    [],
  );

  const removeTypeFilter = useCallback(
    (type) => {
      if (paymentState.invoiceType.length > 1) {
        const newTypesArr = paymentState.invoiceType.filter((x) => x !== type);
        return updatePaymentState("invoiceType", newTypesArr);
      }

      return updatePaymentState("invoiceType", ["all"]);
    },
    [paymentState.invoiceType, updatePaymentState],
  );

  const renderTags = useMemo(() => {
    const entries = Object.entries(paymentState).filter(
      ([key]) => key !== "columns" && key !== "ordering",
    );

    const tags = entries.map(([key, value]) => {
      const isArrayAndEmpty = Array.isArray(value) && value.length === 0;
      const isDefaultValues = value.length === 1 && value[0] === "all";
      const isObjectAndNull = !Array.isArray(value) && !Object.values(value).some(Boolean);

      if (isArrayAndEmpty || isDefaultValues || isObjectAndNull) return null;

      if (key === "date" && Object.values(value).some(Boolean)) {
        return (
          <Tag
            key={key}
            label={`${formatDate(value.from)} - ${formatDate(value.to)}`}
            onClickRemove={removeDateFilter}
          />
        );
      }
      if (key === "invoiceType")
        return value.map((type) => (
          <Tag key={key} label={keyMaps[type]} onClickRemove={() => removeTypeFilter(type)} />
        ));

      return <Tag key={key} label={keyMaps[value[0]]} onClickRemove={removeStatusFilter(key)} />;
    });

    return tags;
  }, [paymentState]);

  const options = useMemo(
    () => [
      showAction(GENERATE_INVOICE_ACTION, checked) && {
        id: "generate",
        name: "Generate Invoice",
        value: "generate",
      },
      showAction(MARK_AS_PAID_ACTION, checked) && {
        id: "mark_as_paid",
        name: "Mark as paid",
        value: "mark_as_paid",
      },
      showAction(SEND_REMINDER, checked) && {
        id: "send_reminder",
        name: "Send Reminder",
        value: "send_reminder",
      },
      showAction(ARCHIVE_ACTION, checked) && {
        id: "archive",
        name: "Archive Invoice",
        value: "archive",
      },
      showAction(DELETE_ACTION, checked) && {
        id: "delete",
        name: "Delete Invoice",
        value: "delete",
      },
    ],
    [checked],
  );

  return (
    <Wrapper>
      <div className="d-flex justify-content-between align-items-center">
        <div className="left-col d-flex">
          <DateFilter />
          <TypeFilter />
          <StatusFilter />
          <EmailStatusFilter />
        </div>

        <div className="right-col d-flex align-items-center">
          {checked.length !== 0 && (
            <BulkActions checked={checked} options={options} toggleAll={toggleAll} />
          )}

          {checked.length === 0 && (
            <>
              <ColumnVisibility columns={paymentColumns} />

              <SortFilter type="in" />

              {canDownloadInvoice() && (
                <div className="ms-2">
                  <CSVDownload type="in" project={project} />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="d-flex mt-2 filter-display">{renderTags}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 17px;

  .left-col {
    column-gap: 10px;
  }

  .filter-display {
    min-height: 28px;
    column-gap: 8px;
    row-gap: 10px;
    flex-wrap: wrap;
  }
`;

PaymentFilters.propTypes = {
  checked: PropTypes.arrayOf(PropTypes.shape({})),
  project: PropTypes.arrayOf(PropTypes.shape({})),
  toggleAll: PropTypes.func,
};

export default PaymentFilters;
