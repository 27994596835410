import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const UserAgreementPrompt = ({ user, openTCs, dismissRef, dismiss }) => {
  const ref = dismissRef;
  ref.current = dismiss;

  return (
    <div>
      <p>Hi {user.first_name},</p>
      <p className="mb-0">
        A change in our User Agreement has taken place as of{" "}
        {moment(user.latest_terms_created_at).format("dddd, Do MMMM, YYYY")}. Please read it
        carefully{" "}
        <a
          onClick={openTCs}
          href="https://tunga.io/client-agreement/"
          rel="noreferrer noopener"
          target="_blank"
        >
          here
        </a>
        .
      </p>
    </div>
  );
};

UserAgreementPrompt.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    latest_terms_created_at: PropTypes.string,
  }),
  openTCs: PropTypes.func,
  dismiss: PropTypes.func,
  dismissRef: PropTypes.shape({ current: PropTypes.func }),
};
export default UserAgreementPrompt;
