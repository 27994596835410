/* eslint-disable no-console */
// eslint-disable-next-line no-unused-vars
const logger = (store) => (next) => (action) => {
  // console.group(action.type);
  // console.info("dispatching", action);
  const result = next(action);
  // console.groupEnd();
  return result;
};

export default logger;
