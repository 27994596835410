import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { INTEREST_POLL_START } from "../../redux/actions/InterestPollActions";

const useClearInterestUpdateFeedback = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { interestUpdate } = useSelector(({ InterestPoll }) => InterestPoll);

  useEffect(() => {
    let timeout;
    if (interestUpdate?.success || interestUpdate?.error) {
      timeout = setTimeout(() => {
        // Reset Poll state
        dispatch({ type: INTEREST_POLL_START });
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [interestUpdate?.success, interestUpdate?.error]);

  useEffect(() => {
    const unListen = history.listen(() => {
      // Reset Poll state
      dispatch({ type: INTEREST_POLL_START });
    });

    return unListen;
  }, []);

  return null;
};

export default useClearInterestUpdateFeedback;
