import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Error from "../../../../components/Error";

const AddToSKillLibrary = ({ isBulkAction }) => {
  const { errors } = useSelector((state) => state.Skill);
  const errorMessage = errors?.approve_skill_error;
  return (
    <>
      {errorMessage && <Error message={errorMessage} />}
      <div>
        Are you sure you want to add {isBulkAction ? "these skills" : "this skill"} to the library?
      </div>
    </>
  );
};

AddToSKillLibrary.propTypes = {
  isBulkAction: PropTypes.bool,
};

export default AddToSKillLibrary;
