import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useFormik } from "formik";
import { FormGroup } from "reactstrap";
import * as yup from "yup";

import SmileysModal from "../../../../../../components/SmileysModal";
import FieldError from "../../../../../../components/FieldError";
import { pxToRem } from "../../../../../../assets/theme/functions";
import TextArea from "../../../../../../components/TextArea";
import { openAlertModal } from "../../../../../../utils/modals";
import { updateProgressReport } from "../../../../../../redux/actions/ProjectActions";

const initState = {
  satisfaction_rating: "",
  task_clarity_rating: "",
  resource_access_rating: "",
  overall_satisfaction_rating: "",
  stuck_on_tasks: null,
  improvement_suggestions: "",
};

const DeveloperSurvey = () => {
  const {
    progress_event,
    isMakingRequest,
    project: activeProject,
    errors,
  } = useSelector(({ Projects }) => Projects);
  const dispatch = useDispatch();

  const { project } = progress_event;
  const isLoading = isMakingRequest?.progressReport;

  const progressReports = progress_event.progress_reports[0];

  const initialReportValues =
    progressReports && Object.values(progressReports)?.length > 0
      ? {
          satisfaction_rating: progressReports.satisfaction_rating,
          task_clarity_rating: progressReports.task_clarity_rating,
          resource_access_rating: progressReports.resource_access_rating,
          overall_satisfaction_rating: progressReports.overall_satisfaction_rating,
          stuck_on_tasks: progressReports.stuck_on_tasks,
          improvement_suggestions: progressReports.improvement_suggestions,
        }
      : initState;

  const form = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      ...initialReportValues,
    },
    validationSchema: yup.object().shape({
      satisfaction_rating: yup
        .string()
        .required("Please provide a rating for your overall satisfaction with the client"),
      task_clarity_rating: yup
        .string()
        .required("Please provide a rating for the clarity of the assigned tasks"),
      resource_access_rating: yup
        .string()
        .required("Please provide a rating for your access to necessary tools and resources"),
      overall_satisfaction_rating: yup
        .string()
        .required(
          "Please provide a rating for your overall satisfaction with the services and support provided by Tunga",
        ),
      stuck_on_tasks: yup
        .string()
        .required("Please indicate whether you are currently stuck on any part of your work/tasks"),
      improvement_suggestions: yup
        .string()
        .required(
          "Please provide feedback on how either Tunga or the client can improve to better serve and assist you",
        ),
    }),
    onSubmit: (formData) => {
      const reqData = {
        event: { id: progress_event.id },
        ...formData,
      };

      const progress_report = progress_event.progress_reports[0];

      dispatch(updateProgressReport(progress_report?.id || null, reqData));
    },
  });

  useEffect(() => {
    if (!isLoading && errors?.progressReport) {
      openAlertModal(errors?.progressReport || "Sorry, an error occurred", true);
    }
  }, [isLoading, errors]);

  return (
    <Wrapper data-testid="developerSurvey-form" className="container" onSubmit={form.handleSubmit}>
      <div className="content">
        <div className="card">
          <div className="card-body">
            <div className="card__section">
              <h4>
                How would you rate your overall satisfaction with
                <b>
                  {" "}
                  {project?.category === "dedicated"
                    ? progress_event?.project?.owner?.display_name ||
                      activeProject?.owner?.display_name
                    : "Tunga"}
                </b>
                ?<span className="label-style">*</span>
              </h4>

              <SmileysModal
                name="satisfaction_rating"
                selected={`${form.values.satisfaction_rating}`}
                onChange={(value) => form.setFieldValue("satisfaction_rating", value)}
                onBlur={form.handleBlur}
              />
              {form.errors.satisfaction_rating && form.touched.satisfaction_rating && (
                <FieldError message={form.errors.satisfaction_rating} />
              )}
            </div>

            <div className="card__section">
              <h4>
                I have been assigned clear tasks to work on
                <span className="label-style">*</span>
              </h4>

              <SmileysModal
                name="task_clarity_rating"
                selected={`${form.values.task_clarity_rating}`}
                onChange={(value) => form.setFieldValue("task_clarity_rating", value)}
                onBlur={form.handleBlur}
              />
              {form.errors.task_clarity_rating && form.touched.task_clarity_rating && (
                <FieldError message={form.errors.task_clarity_rating} />
              )}
            </div>

            <div className="card__section">
              <h4>
                I have access to all the tools and resources necessary to do my job
                <span className="label-style">*</span>
              </h4>

              <SmileysModal
                name="resource_access_rating"
                selected={`${form.values.resource_access_rating}`}
                onChange={(value) => form.setFieldValue("resource_access_rating", value)}
                onBlur={form.handleBlur}
              />
              {form.errors.resource_access_rating && form.touched.resource_access_rating && (
                <FieldError message={form.errors.resource_access_rating} />
              )}
            </div>

            <div className="card__section">
              <h4>
                Are you currently stuck on any part of your work/tasks?
                <span className="label-style">*</span>
              </h4>

              <FormGroup className="custom-radio-group">
                <div className="radio-button">
                  <input
                    type="radio"
                    name="stuck_on_tasks"
                    id="stuck_on_tasks_yes"
                    checked={form.values.stuck_on_tasks === true}
                    onChange={() => form.setFieldValue("stuck_on_tasks", true)}
                  />
                  <label htmlFor="stuck_on_tasks_yes">Yes</label>
                </div>
                <div className="radio-button">
                  <input
                    type="radio"
                    id="stuck_on_tasks_no"
                    name="stuck_on_tasks"
                    checked={form.values.stuck_on_tasks === false}
                    onChange={() => form.setFieldValue("stuck_on_tasks", false)}
                  />
                  <label htmlFor="stuck_on_tasks_no">No</label>
                </div>
              </FormGroup>
            </div>

            <div className="card__section">
              <h4>
                How satisfied are you with the overall services and support provided by Tunga?
                <span className="label-style">*</span>
              </h4>

              <SmileysModal
                name="overall_satisfaction_rating"
                selected={`${form.values.overall_satisfaction_rating}`}
                onChange={(value) => form.setFieldValue("overall_satisfaction_rating", value)}
                onBlur={form.handleBlur}
              />
              {form.errors.overall_satisfaction_rating &&
                form.touched.overall_satisfaction_rating && (
                  <FieldError message={form.errors.overall_satisfaction_rating} />
                )}
            </div>

            <div className="card__section">
              <h4>
                Is there anything that you think either Tunga or the client can do/improve to better
                serve/assist you?
                <span className="label-style">*</span>
              </h4>

              <FormGroup>
                <TextArea
                  rows={4}
                  name="improvement_suggestions"
                  onChange={form.handleChange}
                  value={form.values.improvement_suggestions}
                  placeholder="Enter answer here"
                  onBlur={form.handleBlur}
                />
                {form.errors.improvement_suggestions && form.touched.improvement_suggestions && (
                  <FieldError message={form.errors.improvement_suggestions} />
                )}
              </FormGroup>
            </div>

            <div className="card-bottom">
              <div className="card-body">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isLoading || !form.isValid}
                >
                  Submit Report
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.form`
  width: 800px;
  background: #ffffff;
  border: 1px solid #edf1f7;
  border-radius: 6px;
  box-shadow: 0 1px 1px 0 hsla(0, 0%, 80%, 0.5);
  padding: 0;
  margin-top: 40px;
  margin-bottom: 113px;

  .content {
    padding: 0px;
  }

  .card {
    background: #ffffff;
    border: none;
    box-sizing: border-box;
    border-radius: 6px;
  }
  .card-bottom {
    background: rgba(237, 241, 247, 0.25);
    border-radius: 0px 0px 6px 6px;
    button {
      background: #da3451;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: ${pxToRem(15)};
      line-height: ${pxToRem(19)};
      text-align: center;
      display: block;
      margin-left: auto;
      box-shadow: none;
      color: #ffffff;
    }
  }
  .card__section {
    padding: 2.4rem 1rem;
    border-bottom: 1px solid #edf1f7;
    &:last-child {
      border: 0px solid #edf1f7;
    }
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: ${pxToRem(16)};
      line-height: 150%;
      margin-bottom: 0.9rem;
      color: #151a30;
    }

    input,
    textarea {
      font-size: 16px;
      color: #8f9bb3;
      background: #ffffff;
      border: 1px solid rgba(194, 204, 217, 0.5);
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: none;

      &::placeholder {
        color: #8f9bb3;
        font-weight: normal;
      }

      &.rw-input {
        padding-left: 1.25rem;
      }
    }
  }

  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
`;

export default DeveloperSurvey;
