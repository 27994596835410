/* eslint-disable default-param-last */
import { combineReducers } from "redux";
import { LOCATION_CHANGE } from "react-router-redux";

import * as types from "../../configs/constants/ActionTypes";
import { reducerUpdate } from "../../utils/reducers";

function activities(
  state = {
    data: [],
    next: null,
    previous: null,
    count: 0,
  },
  action,
) {
  switch (action.type) {
    case types.LIST_ACTIVITIES_SUCCESS:
      return {
        data: action.data.results,
        next: action.data.next,
        previous: action.data.previous,
        count: action.data.count,
      };
    case types.LIST_MORE_ACTIVITIES_SUCCESS:
      return {
        ...action.data,
        data: [...state.data, ...action.data.data],
        next: action.data.next,
        previous: action.data.previous,
        count: action.data.count,
      };
    default:
      return state;
  }
}

function devProfiles(state = {}, action) {
  switch (action.type) {
    case types.DEV_PROFILE_START:
      return { ...state, loading: true };
    case types.DEV_PROFILE_FAILED:
      return { ...state, loading: false };
    case types.DEV_PROFILE_SUCCESS:
      return { data: action.data };
    default:
      return state;
  }
}

function projectPMFilter(state = false, action) {
  switch (action.type) {
    case types.TOGGLE_PROJECT_FILTER:
      return action.data;
    default:
      return state;
  }
}

function documents(state = [], action) {
  switch (action.type) {
    case types.FETCH_PROJECT_SUCCESS:
      return [...(action.data?.documents || [])];
    case types.CREATE_DOCUMENT_SUCCESS:
      return [action.data, ...state];
    case types.UPDATE_DOCUMENT_SUCCESS:
      return reducerUpdate(state, action);
    case types.DELETE_DOCUMENT_SUCCESS:
      return [...state.filter((item) => item.id !== action.data)];
    default:
      return state;
  }
}

function timesheets(state = { results: [] }, action) {
  switch (action.type) {
    case types.LIST_TIMESHEETS_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function progressEvents(state = {}, action) {
  switch (action.type) {
    case types.CREATE_PROGRESS_EVENT_SUCCESS:
      return [action.data, ...state];

    case types.UPDATE_PROGRESS_EVENT_SUCCESS:
      return reducerUpdate(state, action);

    case types.LIST_PROGRESS_EVENTS_SUCCESS: {
      return { ...action.data.results };
    }

    case types.LIST_PROGRESS_EVENTS_FAILED:
    default:
      return state;
  }
}

function progressEventsCount(state = {}, action) {
  switch (action.type) {
    case types.LIST_PROGRESS_EVENTS_SUCCESS: {
      return action.data.count;
    }

    default:
      return state;
  }
}

function progress_event(state = {}, action) {
  switch (action.type) {
    case types.RETRIEVE_PROGRESS_EVENT_SUCCESS: {
      return { ...action.data };
    }
    default:
      return state;
  }
}

function project(state = {}, action) {
  switch (action.type) {
    case types.UPDATE_PROJECT_SUCCESS:
      return { ...state, ...action.data };
    case types.DELETE_PARTICIPATION_SUCCESS: {
      const currentParticipation = [...(state?.participation || {})];
      const newParticipation = currentParticipation.filter(({ id }) => id !== action.data);
      return { ...state, participation: [...newParticipation] };
    }

    case types.UPDATE_PARTICIPATION_SUCCESS: {
      const { data } = action;
      delete data?.project;

      const currentParticipation = [...(state?.participation || {})];

      const currentParticipationIdx = currentParticipation.findIndex(({ id }) => id === data.id);

      if (currentParticipationIdx === -1) {
        currentParticipation.push(data);
      } else {
        currentParticipation[currentParticipationIdx] = data;
      }

      const newState = { ...state, participation: [...currentParticipation] };
      return newState;
    }
    case types.FETCH_PROJECT_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function projects(state = { results: [], next: null }, action) {
  switch (action.type) {
    case types.FETCH_PROJECTS_SUCCESS:
    case types.CREATE_PROJECT_SUCCESS:
      return {
        ...action.data,
      };
    case types.FETCH_MORE_PROJECTS_SUCCESS:
      return {
        ...action.data,
        results: [...state.results, ...action.data.results],
      };
    default:
      return state;
  }
}

function shortlist(
  state = { results: [], next: "", count: 0, previous: "", access_link: "", access_password: "" },
  action,
) {
  switch (action.type) {
    case types.FETCH_SHORTLIST_START:
      return { ...state, loading: true };
    case types.FETCH_SHORTLIST_SUCCESS:
      return { ...action.data, loading: false };
    default:
      return state;
  }
}

function reminder(state = {}, action) {
  switch (action.type) {
    case types.FETCH_PROJECTS_START:
      return {};
    case types.SEND_REMINDER_START:
      return { loading: true };
    case types.SEND_REMINDER_SUCCESS:
      return { loading: false, success: true, data: action.data };
    case types.SEND_REMINDER_FAILED:
      return { loading: false, success: false, error: action.error };
    default:
      return state;
  }
}

function filters(
  state = {
    checkedStatus: {},
    checkedUsers: {},
    duration: { end: null, start: null },
    showUsers: true,
  },
  action,
) {
  switch (action.type) {
    case types.FILTER_PROJECT_REPORTS:
      return { ...state, ...action.filters };
    case types.CLEAR_DURATION_FILTER:
      return { ...state, duration: { end: null, start: null } };
    case types.REMOVE_STATUS_FILTER: {
      const statuses = state.checkedStatus;
      delete statuses[action.key];
      return { ...state, checkedStatus: statuses };
    }
    case types.REMOVE_USER_FILTER: {
      const users = state.checkedUsers;
      delete users[action.key];
      return { ...state, checkedUsers: users };
    }
    case types.SHOW_USERS_FILTER: {
      return { ...state, showUsers: action.show };
    }
    case types.RESET_FILTERS:
      return {
        ...state,
        checkedStatus: {},
        checkedUsers: {},
        duration: { end: null, start: null },
      };
    default:
      return state;
  }
}

function isSaved(
  state = {
    progressReport: false,
    developerRating: false,
  },
  action,
) {
  switch (action.type) {
    case types.UPDATE_PROGRESS_REPORT_SUCCESS:
      return { ...state, progressReport: true };
    case types.UPDATE_PROGRESS_REPORT_FAILED:
    case types.UPDATE_PROGRESS_REPORT_START:
      return { ...state, progressReport: false };

    case types.SUBMIT_DEVELOPER_RATING_SUCCESS:
      return { ...state, developerRating: true };
    case types.SUBMIT_DEVELOPER_RATING_FAILED:
    case types.SUBMIT_DEVELOPER_RATING_START:
      return { ...state, developerRating: false };

    case types.UPDATE_PROGRESS_EVENT_QUESTION_SUCCESS:
      return { ...state, updateProgressEventQuestion: true };
    case types.UPDATE_PROGRESS_EVENT_QUESTION_START:
    case types.UPDATE_PROGRESS_EVENT_QUESTION_FAILED:
      return { ...state, updateProgressEventQuestion: false };

    case types.DEV_PROFILE_SUCCESS:
      return { ...state, devProfile: true, resync: action?.data?.resync };
    case types.DEV_PROFILE_START:
    case types.DEV_PROFILE_FAILED:
      return { ...state, devProfile: false, resync: action?.error?.resync };

    default:
      return state;
  }
}

function isMakingRequest(
  state = {
    fetchMore: false,
    loading: true,
    listActivities: true,
    update: true,
    listMoreActivities: true,
  },
  action,
) {
  switch (action.type) {
    case types.FETCH_PROJECT_START:
      return { loading: true };
    case types.FETCH_PROJECTS_START:
      return { loading: true };
    case types.FETCH_MORE_PROJECTS_START:
    case types.SEARCH_MORE_PROJECTS_START:
      return { fetchMore: true };
    case types.UPDATE_PROJECT_START:
      return { update: true };
    case types.UPDATE_PROJECT_FAILED:
    case types.UPDATE_PROJECT_SUCCESS:
      return {
        update: false,
      };
    case types.CREATE_PROGRESS_EVENT_START:
      return { createEvent: true };
    case types.UPDATE_PROGRESS_EVENT_START:
      return { updateEvent: true };
    case types.UPDATE_DOCUMENT_START:
      return { updateDocument: true };
    case types.CREATE_DOCUMENT_START:
      return { createDocument: true };
    case types.LIST_TIMESHEETS_START:
      return { listTimeSheets: true };
    case types.CREATE_TIMESHEET_START:
      return { createTimeSheet: true };
    case types.LIST_ACTIVITIES_START:
      return { listActivities: true };
    case types.LIST_ACTIVITIES_SUCCESS:
    case types.LIST_ACTIVITIES_FAILED:
      return { listActivities: false };
    case types.LIST_MORE_ACTIVITIES_START:
      return { listMoreActivities: true };
    case types.FETCH_PROJECT_SUCCESS:
    case types.FETCH_PROJECT_FAILED:
    case types.FETCH_PROJECTS_SUCCESS:
    case types.FETCH_PROJECTS_FAILED:
      return {
        loading: false,
      };
    case types.LIST_MORE_ACTIVITIES_FAILED:
    case types.LIST_MORE_ACTIVITIES_SUCCESS:
      return {
        listMoreActivities: false,
      };
    case types.FETCH_MORE_PROJECTS_FAILED:
    case types.FETCH_MORE_PROJECTS_SUCCESS:
    case types.SEARCH_MORE_PROJECTS_SUCCESS:
    case types.SEARCH_MORE_PROJECTS_FAILED:
      return {
        fetchMore: false,
      };
    case types.LIST_PROGRESS_EVENTS_START:
      return {
        progressEvent: true,
      };
    case types.LIST_PROGRESS_EVENTS_SUCCESS:
    case types.LIST_PROGRESS_EVENTS_FAILED:
      return {
        progressEvent: false,
      };
    case types.RETRIEVE_PROGRESS_EVENT_START:
      return {
        ...state,
        retrieveProgressEvent: true,
      };
    case types.RETRIEVE_PROGRESS_EVENT_SUCCESS:
    case types.RETRIEVE_PROGRESS_EVENT_FAILED:
      return {
        ...state,
        retrieveProgressEvent: false,
      };
    case types.UPDATE_PROGRESS_REPORT_START:
      return {
        progressReport: true,
      };
    case types.UPDATE_PROGRESS_REPORT_SUCCESS:
    case types.UPDATE_PROGRESS_REPORT_FAILED:
      return {
        progressReport: false,
      };
    case types.SUBMIT_DEVELOPER_RATING_START:
      return {
        developerRating: true,
      };
    case types.SUBMIT_DEVELOPER_RATING_SUCCESS:
    case types.SUBMIT_DEVELOPER_RATING_FAILED:
      return {
        developerRating: false,
      };
    case types.ASK_TUNGA_START:
      return {
        askTunga: true,
      };
    case types.ASK_TUNGA_SUCCESS:
    case types.ASK_TUNGA_FAILED:
      return {
        askTunga: false,
      };
    case types.UPDATE_PARTICIPATION_START:
      return {
        updateParticipationStatus: true,
      };
    case types.UPDATE_PARTICIPATION_SUCCESS:
    case types.UPDATE_PARTICIPATION_FAILED:
      return {
        updateParticipationStatus: false,
      };
    case types.UPDATE_PROGRESS_EVENT_QUESTION_START:
      return {
        updateProgressEventQuestion: true,
      };
    case types.UPDATE_PROGRESS_EVENT_QUESTION_SUCCESS:
    case types.UPDATE_PROGRESS_EVENT_QUESTION_FAILED:
      return {
        updateProgressEventQuestion: false,
      };
    case types.CREATE_PROJECT_START:
      return {
        ...state,
        createProject: true,
      };
    case types.CREATE_PROJECT_SUCCESS:
    case types.CREATE_PROJECT_FAILED:
      return {
        ...state,
        createProject: false,
      };
    default:
      return state;
  }
}

function errors(state = {}, action) {
  switch (action.type) {
    case types.FETCH_SHORTLIST_FAILED:
      return { shortlist: action.error };
    case types.DEV_PROFILE_FAILED:
      return { devProfile: action.error };
    case types.FETCH_PROJECT_FAILED:
      return { fetch: action.error };
    case types.FETCH_PROJECTS_FAILED:
      return { list: action.error };
    case types.FETCH_MORE_PROJECTS_FAILED:
      return { fetchMore: action.error };
    case types.UPDATE_PROJECT_FAILED:
      return { update: action.error };
    case types.CREATE_PROGRESS_EVENT_FAILED:
      return { createEvent: action.error };
    case types.CREATE_PROJECT_FAILED:
      return { createEvent: action.error };
    case types.UPDATE_PROGRESS_EVENT_FAILED:
      return { updateEvent: action.error };
    case types.UPDATE_DOCUMENT_FAILED:
      return { updateDocument: action.error };
    case types.CREATE_DOCUMENT_FAILED:
      return { createDocument: action.error };
    case types.LIST_TIMESHEETS_FAILED:
      return { listTimeSheets: action.error };
    case types.CREATE_TIMESHEET_FAILED:
      return { createTimeSheet: action.error };
    case types.LIST_ACTIVITIES_FAILED:
      return { listActivities: action.error };
    case types.LIST_MORE_ACTIVITIES_FAILED:
      return { listMoreActivities: action.error };
    case types.LIST_PROGRESS_EVENTS_FAILED:
      return { progressEvent: action.error };
    case types.UPDATE_PROGRESS_REPORT_FAILED:
      return { progressReport: action.error };

    case LOCATION_CHANGE:
      return {};
    default:
      return state;
  }
}

function search(
  state = {
    data: [],
    count: 0,
    next: "",
    previous: "",
  },
  action,
) {
  switch (action.type) {
    case types.SEARCH_PROJECTS_SUCCESS:
      return {
        data: action.data.results,
        count: action.data.count,
        next: action.data.next,
        previous: action.data.previous,
      };
    case types.SEARCH_MORE_PROJECTS_SUCCESS:
      return {
        data: [...state.data, ...action.data.results],
        count: action.data.count,
        next: action.data.next,
        previous: action.data.previous,
      };
    default:
      return state;
  }
}

const Projects = combineReducers({
  projectPMFilter,
  project,
  projects,
  shortlist,
  isMakingRequest,
  errors,
  devProfiles,
  progressEvents,
  progressEventsCount,
  progress_event,
  documents,
  timesheets,
  activities,
  filters,
  isSaved,
  reminder,
  search,
});

export default Projects;
