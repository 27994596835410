/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";

import fileDownload from "js-file-download";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import styled from "styled-components";

import { useTrackPage } from "../../../analytics/segment";
import { pages } from "../../../analytics/segment/tags";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { canViewInvoiceSummary, isDev } from "../../../utils/auth";
import PaymentTotals from "./components/PaymentTotals";
import PaymentFilterAndAddComponent from "./components/PaymentFilterAndAddComponent";
import InvoiceListContainer from "./InvoiceListContainer";
import Payments from "./Payments";
import Payouts from "./Payouts";
import { InvoiceProvider } from "../../../context/InvoiceContext";
import useURLQuery from "../../../hooks/useURLQuery";
import { convertToAndString } from "../../../utils/helpers";

const keyMaps = {
  "all": "All",
  "paid": "Paid",
  "sale": "Sale",
  "final": "Final",
  "credit-notes": "Credit Note",
  "commitment-payments": "Commitment",
  "archived": "Archived",
  "overdue": "Overdue",
  "pending": "Pending",
};

const PaymentsPage = () => {
  useTrackPage(pages.PAYMENTS);

  const [createAction, setcreateAction] = useState(null);
  const { type } = useParams();
  const urlQuery = useURLQuery();

  const dispatch = useDispatch();
  const { csv } = useSelector(({ Invoice }) => Invoice);

  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const [summariesRange, setSummariesRange] = useState({
    start: `${moment(firstDay).format(moment.HTML5_FMT.DATE)}T00:00:00`,
    end: `${moment(new Date()).format(moment.HTML5_FMT.DATE)}T23:59:59`,
  });

  useEffect(() => {
    if (csv) {
      const isPayments = type === "in";
      const status = urlQuery.get("status") || "all";
      const types = urlQuery.get("type") || "";

      const typeString = types ? types?.split(",")?.map((v) => keyMaps[v]) : [];

      const name = `${keyMaps[status]} ${convertToAndString(typeString)} ${
        isPayments ? "Payments" : "Payouts"
      }.csv`;

      fileDownload(csv, name);
      dispatch({ type: "CLEAR_CSV" });
    }
  }, [csv]);

  return (
    <DashboardLayout style={{ paddingTop: "1px" }}>
      <Wrapper>
        {canViewInvoiceSummary() && (
          <>
            <PaymentFilterAndAddComponent
              {...{ paymentScope: type, createAction, summariesRange, setSummariesRange }}
            />
            <PaymentTotals type={type} summariesRange={summariesRange} />
          </>
        )}

        <InvoiceProvider>
          <Switch>
            <Route
              path={`/payments/${type}`}
              render={(props) => {
                return (
                  <InvoiceListContainer {...props} type={type}>
                    {type === "in" ? (
                      <Payments {...props} setcreateAction={setcreateAction} />
                    ) : (
                      <Payouts {...props} setcreateAction={setcreateAction} />
                    )}
                  </InvoiceListContainer>
                );
              }}
            />
            <Redirect from="/payments" to={`/payments/${isDev() ? "out" : type}?status=all`} />
          </Switch>
        </InvoiceProvider>
      </Wrapper>
    </DashboardLayout>
  );
};

const Wrapper = styled.div`
  padding-top: 40px; /* TODO: moving this couldnt work cause navbar doesnt record right hieght on page reload */
  .filter-links a {
    margin-right: 20px;
  }
`;

export default PaymentsPage;
