import React from "react";
import { DropdownMenu } from "reactstrap";
import styled from "styled-components";
import PropTypes from "prop-types";

const Menu = ({ onClick, children, label, right = false, minwidth = "230px" }) => {
  return (
    <Wrapper className="dropdown-menu p-0" right={right} minwidth={minwidth} flip={false}>
      <div className="d-flex justify-content-between align-items-center inner-wrapper">
        <div className="font-14 d-flex align-items-center">{label}</div>

        <button
          data-testid={`done-${label}`}
          type="button"
          className="px-2 btn-done font-14 d-flex align-items-center justify-content-center"
          onClick={onClick}
        >
          Done
        </button>
      </div>

      {children}
    </Wrapper>
  );
};

const MenuV2 = ({ children, right = false, minwidth = "230px" }) => {
  return (
    <Wrapper className="dropdown-menu py-3" right={right} minwidth={minwidth} flip={false}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(DropdownMenu)`
  &.dropdown-menu {
    margin-top: 8px;
    padding-bottom: 20px !important;
    min-width: ${({ minwidth }) => minwidth};
    border-radius: 8px;
    border: 1px solid #e3e9f2;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(21, 26, 48, 0.15);
    /* overflow: hidden; */
  }

  .font-12 {
    font-size: 12px;
  }

  .font-14 {
    font-size: 14px;
    font-weight: 500;
  }

  .inner-wrapper {
    padding-right: 10px;
    padding: 13px 15px;
    background: #f5f7fa;
    height: 47px;
    background: #f5f7fa;
    border-radius: 8px 8px 0px 0px;
  }

  .btn {
    &-clear,
    &-done {
      height: 21px;
      border: 1px solid #062e64;
    }

    &-clear {
      background: #fff;
      border-radius: 4px;
    }

    &-done {
      background: #062e64;
      color: #fff;
      border-radius: 4px;
      height: 24px;
    }
  }
`;

Menu.propTypes = {
  right: PropTypes.bool,
  minwidth: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

MenuV2.propTypes = {
  right: PropTypes.bool,
  minwidth: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
export { MenuV2 };
export default Menu;
