import axios from "axios";

import {
  composeFormData,
  ENDPOINT_PROJECTS,
  ENDPOINT_PROGRESS_EVENTS,
  ENDPOINT_DOCUMENTS,
  ENDPOINT_ACTIVITIES,
  ENDPOINT_DEV_PROFILE,
  ENDPOINT_PROGRESS_REPORTS,
  ENDPOINT_GENERAL_RATING,
  ENDPOINT_DEVELOPER_RATING,
  ENDPOINT_PARTICIPATION,
  getCancelToken,
} from "../../utils/api";
import * as types from "../../configs/constants/ActionTypes";
import { success, start, failed, formatErrorResponse } from "../../utils/actions";
// eslint-disable-next-line import/no-cycle
import { trackEvent } from "../../analytics/segment";
import { CATEGORIES, EVENTS } from "../../analytics/events";
import { openAlertModal } from "../../utils/modals";

export function fetchProject(id, silent = false) {
  return (dispatch) => {
    if (!silent) dispatch(start(types.FETCH_PROJECT_START));
    axios
      .get(`${ENDPOINT_PROJECTS + id}/`)
      .then((response) => {
        dispatch(success(types.FETCH_PROJECT_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.FETCH_PROJECT_FAILED, error));
      });
  };
}

export function fetchShortlist(id, filter) {
  return (dispatch) => {
    dispatch(start(types.FETCH_SHORTLIST_START));
    axios
      .get(`${ENDPOINT_PROJECTS + id}/shortlist/`, { params: filter })
      .then((response) => {
        dispatch(success(types.FETCH_SHORTLIST_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.FETCH_SHORTLIST_FAILED, formatErrorResponse(error)));
      });
  };
}

export function fetchProjects(filter, search = false) {
  return (dispatch) => {
    // Explicity equate search variable to boolean to prevent breaking project selectore
    dispatch(start(search === true ? types.SEARCH_PROJECTS_START : types.FETCH_PROJECTS_START));

    axios
      .get(
        Object.prototype.hasOwnProperty.call(filter, "archived_type")
          ? `${ENDPOINT_PROJECTS}archived/`
          : ENDPOINT_PROJECTS,
        { params: filter, cancelToken: getCancelToken("fetchProjects") },
      )
      .then((response) => {
        dispatch(
          success(
            search === true ? types.SEARCH_PROJECTS_SUCCESS : types.FETCH_PROJECTS_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((error) => {
        if (error.message === "aborted") return;
        dispatch(
          failed(
            search === true ? types.SEARCH_PROJECTS_FAILED : types.FETCH_PROJECTS_FAILED,
            error,
          ),
        );
      });
  };
}

export function fetchMoreProjects(nextUrl, search = false) {
  return (dispatch) => {
    dispatch(start(search ? types.SEARCH_MORE_PROJECTS_START : types.FETCH_MORE_PROJECTS_START));
    axios
      .get(nextUrl)
      .then((response) => {
        dispatch(
          success(
            search ? types.SEARCH_MORE_PROJECTS_SUCCESS : types.FETCH_MORE_PROJECTS_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((error) => {
        dispatch(
          failed(
            search ? types.SEARCH_MORE_PROJECTS_FAILED : types.FETCH_MORE_PROJECTS_FAILED,
            error,
          ),
        );
      });
  };
}

export function createProject(project) {
  return (dispatch) => {
    dispatch(start(types.CREATE_PROJECT_START));
    axios
      .post(ENDPOINT_PROJECTS, project)
      .then(async ({ data }) => {
        dispatch(success(types.CREATE_PROJECT_SUCCESS, data));

        await trackEvent(EVENTS.create_new_project, {
          project_id: data.id,
          project_type: data.stage === "opportunity" ? "opportunity" : "dedicated",
          project_name: data.title,
          project_category: data.category,
          event_category: CATEGORIES.projects,
        });

        window.location.href = `/projects/${data.id}`;
      })
      .catch((error) => {
        const errMsg = formatErrorResponse(error);
        dispatch(failed(types.CREATE_PROJECT_FAILED, error));
        openAlertModal(errMsg.message, true);
      });
  };
}

export function updateProject(id, project, { successCb, failureCb }) {
  return (dispatch) => {
    dispatch(start(types.UPDATE_PROJECT_START));

    const headers = {};
    let data = project;
    if (project.documents && project.documents.length) {
      headers["Content-Type"] = "multipart/form-data";
      data = composeFormData(project);
    }

    axios
      .patch(`${ENDPOINT_PROJECTS}${id}/`, data, {
        headers: { ...headers },
      })
      .then((response) => {
        if (successCb) successCb();
        dispatch(success(types.UPDATE_PROJECT_SUCCESS, response.data));
        openAlertModal("Project updated successfully");
      })
      .catch((error) => {
        if (failureCb) failureCb();

        const errMsg = formatErrorResponse(error);
        dispatch(failed(types.UPDATE_PROJECT_FAILED, errMsg));
        openAlertModal(errMsg.message, true);
      });
  };
}

export function listProgressEvents(filter) {
  return (dispatch) => {
    dispatch({
      type: types.LIST_PROGRESS_EVENTS_START,
      filter,
    });

    axios
      .get(ENDPOINT_PROGRESS_EVENTS, { params: filter })
      .then((response) => {
        dispatch(success(types.LIST_PROGRESS_EVENTS_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.LIST_PROGRESS_EVENTS_FAILED, formatErrorResponse(error)));
      });
  };
}

export function createProgressEvent(progress_event, { successCb, failureCb }) {
  return (dispatch) => {
    dispatch(start(types.CREATE_PROGRESS_EVENT_START));
    axios
      .post(ENDPOINT_PROGRESS_EVENTS, progress_event)
      .then((response) => {
        if (successCb) successCb();
        dispatch(success(types.CREATE_PROGRESS_EVENT_SUCCESS, response.data));
      })
      .catch((error) => {
        if (failureCb) failureCb();
        dispatch(failed(types.CREATE_PROGRESS_EVENT_FAILED, error));
      });
  };
}

export function updateProgressEvent(id, progress_event, { successCb, failureCb }) {
  return (dispatch) => {
    dispatch(start(types.UPDATE_PROGRESS_EVENT_START));
    axios
      .patch(`${ENDPOINT_PROGRESS_EVENTS + id}/`, progress_event)
      .then((response) => {
        if (successCb) successCb();
        dispatch(success(types.UPDATE_PROGRESS_EVENT_SUCCESS, response.data));
      })
      .catch((error) => {
        if (failureCb) failureCb();
        dispatch(failed(types.UPDATE_PROGRESS_EVENT_FAILED, error));
      });
  };
}

export function retrieveProgressEvent(id) {
  return (dispatch) => {
    dispatch(start(types.RETRIEVE_PROGRESS_EVENT_START));

    axios
      .get(`${ENDPOINT_PROGRESS_EVENTS + id}/`)
      .then((response) => {
        dispatch(success(types.RETRIEVE_PROGRESS_EVENT_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.RETRIEVE_PROGRESS_EVENT_FAILED, formatErrorResponse(error)));
      });
  };
}

export function createDocument(document, { successCb, failureCb }) {
  return (dispatch) => {
    dispatch(start(types.CREATE_DOCUMENT_START));

    const headers = {};
    let data = document;

    if (document.file) {
      headers["Content-Type"] = "multipart/form-data";
      data = composeFormData(document);
    }

    axios
      .post(ENDPOINT_DOCUMENTS, data, { headers })
      .then((response) => {
        if (successCb) successCb();
        dispatch(success(types.CREATE_DOCUMENT_SUCCESS, response.data));
      })
      .catch((error) => {
        if (failureCb) failureCb();
        dispatch(failed(types.CREATE_DOCUMENT_FAILED, error));
      });
  };
}

export function updateDocument(id, document) {
  return (dispatch) => {
    dispatch(start(types.UPDATE_DOCUMENT_START));

    const headers = {};
    let data = document;
    if (document.file) {
      headers["Content-Type"] = "multipart/form-data";
      data = composeFormData(document);
    }

    axios
      .patch(`${ENDPOINT_DOCUMENTS + id}/`, data, {
        headers: { ...headers },
      })
      .then((response) => {
        dispatch(success(types.UPDATE_DOCUMENT_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.UPDATE_DOCUMENT_FAILED, error));
      });
  };
}

export function deleteDocument(id) {
  return (dispatch) => {
    dispatch(start(types.DELETE_DOCUMENT_START));
    axios
      .delete(`${ENDPOINT_DOCUMENTS + id}/`)
      .then(() => {
        dispatch(success(types.DELETE_DOCUMENT_SUCCESS, id));
      })
      .catch((error) => {
        dispatch(failed(types.DELETE_DOCUMENT_FAILED, error));
      });
  };
}

export function listTimesheets(id, year) {
  return (dispatch) => {
    dispatch(start(types.LIST_TIMESHEETS_START));
    axios
      .get(`${ENDPOINT_PROJECTS}${id}/timesheets/?year=${year}`)
      .then((response) => {
        dispatch(success(types.LIST_TIMESHEETS_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.LIST_TIMESHEETS_FAILED, error));
      });
  };
}

export function createTimesheet(timesheet, id) {
  return (dispatch) => {
    dispatch(start(types.CREATE_TIMESHEET_START));
    axios
      .post(`${ENDPOINT_PROJECTS}${id}/timesheet/`, timesheet)
      .then((response) => {
        dispatch(success(types.CREATE_TIMESHEET_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.CREATE_TIMESHEET_FAILED, error));
      });
  };
}

export function listActivities(filter) {
  return (dispatch) => {
    dispatch(start(types.LIST_ACTIVITIES_START));
    axios
      .get(ENDPOINT_ACTIVITIES, {
        params: filter,
      })
      .then((response) => {
        dispatch(success(types.LIST_ACTIVITIES_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.LIST_ACTIVITIES_FAILED, error));
      });
  };
}

export function listMoreActivities(url) {
  return (dispatch) => {
    dispatch(start(types.LIST_MORE_ACTIVITIES_START));
    axios
      .get(url)
      .then((response) => {
        dispatch(success(types.LIST_MORE_ACTIVITIES_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.LIST_MORE_ACTIVITIES_FAILED, error));
      });
  };
}

export function showUsersFilter(show) {
  return {
    type: types.SHOW_USERS_FILTER,
    show,
  };
}

export function resetFilters() {
  return {
    type: types.RESET_FILTERS,
  };
}

export function updateProgressReport(id, progress_report, sideEffect) {
  return (dispatch) => {
    dispatch(start(types.UPDATE_PROGRESS_REPORT_START));
    const method = id
      ? { verb: "patch", url: `${ENDPOINT_PROGRESS_REPORTS + id}/` }
      : { verb: "post", url: ENDPOINT_PROGRESS_REPORTS };

    const headers = {};
    let data = progress_report;

    if (progress_report.uploads) {
      headers["Content-Type"] = "multipart/form-data";
      data = composeFormData(progress_report);
    }

    axios
      .request({
        url: method.url,
        method: method.verb,
        data,
        headers,
      })
      .then((response) => {
        if (sideEffect) sideEffect();
        dispatch(success(types.UPDATE_PROGRESS_REPORT_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.UPDATE_PROGRESS_REPORT_FAILED, formatErrorResponse(error)));
      });
  };
}

export function submitDeveloperRating({ id, ...event }) {
  return (dispatch) => {
    const url = event.rate_communication ? ENDPOINT_GENERAL_RATING : ENDPOINT_DEVELOPER_RATING;
    dispatch(start(types.SUBMIT_DEVELOPER_RATING_START));

    axios[id ? "patch" : "post"](`${url}${id ? `${id}/` : ""}`, event)
      .then((response) => {
        dispatch(success(types.SUBMIT_DEVELOPER_RATING_SUCCESS, response.data));
        dispatch(retrieveProgressEvent(event.event.id));
      })
      .catch((error) => {
        dispatch(failed(types.SUBMIT_DEVELOPER_RATING_FAILED, formatErrorResponse(error)));
      });
  };
}

export function updateProgressEventQuestions(id, questions) {
  return (dispatch) => {
    dispatch(start(types.UPDATE_PROGRESS_EVENT_QUESTION_START));
    axios
      .patch(`${ENDPOINT_PROGRESS_EVENTS + id}/update-progress-event-questions/`, questions)
      .then((response) => {
        dispatch(success(types.UPDATE_PROGRESS_EVENT_QUESTION_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.UPDATE_PROGRESS_EVENT_QUESTION_FAILED, error));
      });
  };
}

export function sendReminderStart() {
  return {
    type: types.SEND_REMINDER_START,
  };
}

export function sendReminderSuccess(data) {
  return {
    type: types.SEND_REMINDER_SUCCESS,
    data,
  };
}

export function sendReminderFailed(error) {
  return {
    type: types.SEND_REMINDER_FAILED,
    error,
  };
}

export function sendReminder(id, user) {
  return (dispatch) => {
    dispatch(sendReminderStart());

    let data = {};
    if (user) data = { remind_user_id: user };

    axios
      .post(`${ENDPOINT_PROJECTS}${id}/remind/`, data)
      .then((response) => {
        dispatch(sendReminderSuccess(response.data));
      })
      .catch((error) => {
        dispatch(sendReminderFailed(error.response ? error.response.data : null));
      });
  };
}

export function askTunga({ id, question }, { successCb, failureCb }) {
  return (dispatch) => {
    dispatch(start(types.ASK_TUNGA_START));
    axios
      .post(`${ENDPOINT_PROJECTS}${id}/question/`, { body: question })
      .then((response) => {
        if (successCb) successCb();
        dispatch(success(types.ASK_TUNGA_SUCCESS, response.data));
      })
      .catch((error) => {
        if (failureCb) failureCb();
        dispatch(failed(types.ASK_TUNGA_FAILED, formatErrorResponse(error)));
      });
  };
}

export function devProfile(id, resync = false) {
  return (dispatch) => {
    dispatch(start(types.DEV_PROFILE_START));
    axios
      .get(`${ENDPOINT_DEV_PROFILE + id}/?resync=${resync}`)
      .then((response) => {
        dispatch(success(types.DEV_PROFILE_SUCCESS, { ...response.data, resync }));
      })
      .catch((err) => {
        const error = { ...formatErrorResponse(err), resync };
        dispatch({ type: types.DEV_PROFILE_FAILED, error });
      });
  };
}

export function updateParticipation(id, participation, successAction = null) {
  return (dispatch) => {
    dispatch(start(types.UPDATE_PARTICIPATION_START));

    axios
      .patch(`${ENDPOINT_PARTICIPATION + id}/`, participation)
      .then((response) => {
        if (successAction) successAction();
        dispatch(success(types.UPDATE_PARTICIPATION_SUCCESS, response.data, id));
      })
      .catch((error) => {
        dispatch(failed(types.UPDATE_PARTICIPATION_FAILED, formatErrorResponse(error)));
      });
  };
}

export function deleteParticipation(id) {
  return (dispatch) => {
    dispatch(start(types.DELETE_PARTICIPATION_START));

    axios
      .delete(`${ENDPOINT_PARTICIPATION + id}/`)
      .then(() => {
        dispatch(success(types.DELETE_PARTICIPATION_SUCCESS, id));
      })
      .catch((error) => {
        dispatch(failed(types.DELETE_PARTICIPATION_FAILED, formatErrorResponse(error)));
      });
  };
}
