import React, { createContext, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";

const OnboardingContext = createContext();

const OnboardingProvider = ({ children }) => {
  const [userData, setUserData] = useState({});

  // Function to update user data
  const updateUserData = (data) => {
    setUserData((prev) => ({ ...prev, ...data }));
  };

  const value = useMemo(() => ({ userData, updateUserData }), [userData]);

  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
};

const useOnboardingContext = () => {
  const context = useContext(OnboardingContext);
  return context;
};

const proptypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  columns: PropTypes.shape({}),
};

OnboardingProvider.propTypes = proptypes;

export { OnboardingContext, OnboardingProvider, useOnboardingContext };
