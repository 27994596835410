import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import MetaTags from "../../../components/MetaTags";
import Progress from "../../../components/Progress";
import SectionNav from "../../../components/SectionNav";
import { fetchMoreProjects, fetchProjects } from "../../../redux/actions/ProjectActions";
import { cancelLastRequest } from "../../../utils/api";
import { isClient, isDev, isInternalUser } from "../../../utils/auth";
import { addPropsToChildren } from "../../../utils/children";

const ProjectListContainer = (props) => {
  const {
    filter,
    children,
    match: {
      params: { type },
    },
  } = props;

  const dispatch = useDispatch();
  const { isMakingRequest, projectPMFilter, projects } = useSelector(({ Projects }) => Projects);
  const { user } = useSelector(({ Auth }) => Auth);

  const getProjectFilters = (filterText) => {
    switch (filterText) {
      case "dedicated": {
        const filters = {
          category: "dedicated",
          stage: "active",
          archived: "False",
        };

        return {
          filters,
          pageTitle: "Dedicated Projects",
        };
      }
      case "opportunity": {
        const filters = { stage: "opportunity", archived: "False" };

        return {
          filters,
          pageTitle: "Shortlists",
        };
      }
      case "managed": {
        const filters = {
          category: "project",
          stage: "active",
          archived: "False",
        };

        return {
          filters,
          pageTitle: "Managed Projects",
        };
      }
      default: {
        let param;
        let pageTitle;

        switch (type) {
          case "projects":
            param = {
              category: "project",
              stage: "active",
            };
            pageTitle = "Managed";
            break;
          case "opportunity":
            param = {
              category: "",
              stage: "opportunity",
            };
            pageTitle = "Shortlists";

            break;

          default:
            param = {
              category: type,
              stage: "active",
            };
            pageTitle = "Dedicated";
            break;
        }

        const filters = {
          archived: "True",
          ...param,
        };

        return {
          filters,
          pageTitle: `Archive ${pageTitle} Projects`,
        };
      }
    }
  };

  const getList = () => {
    cancelLastRequest("fetchProjects");

    dispatch(
      fetchProjects({
        ...getProjectFilters(filter).filters,
        show_all: isInternalUser() ? projectPMFilter : true,
      }),
    );
  };

  useEffect(() => {
    if (user.id) {
      getList();
    }
  }, [projectPMFilter, filter, type, user]);

  const renderChildren = () => {
    return addPropsToChildren(children, {
      onLoadMore: () => {
        dispatch(fetchMoreProjects(projects.next));
      },
      ...getProjectFilters(filter).filters,
    });
  };

  const projectFilters = getProjectFilters(filter);

  return (
    <>
      <MetaTags
        title={projectFilters.pageTitle}
        description="Discover our project opportunities for growth and innovation at Tunga"
      />
      {projectFilters.filters?.archived === "True" && (
        <SectionNav
          urlExact={false}
          links={[
            {
              route: `projects/archived/dedicated`,
              name: "Dedicated Developers",
            },
            {
              route: `projects/archived/projects`,
              name: "Managed Projects",
            },
            ...(isClient() || isDev()
              ? [{}]
              : [
                  {
                    route: `projects/archived/opportunity`,
                    name: "Shortlists",
                  },
                ]),
          ]}
        />
      )}

      {isMakingRequest.list ? (
        <Progress
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        />
      ) : (
        renderChildren()
      )}
    </>
  );
};

ProjectListContainer.propTypes = {
  project: PropTypes.shape({}),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  filter: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
};

ProjectListContainer.defaultProps = {
  filter: null,
};

export default ProjectListContainer;
