/* eslint-disable react/jsx-props-no-spreading */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import Button from "./Button";
import Icon from "./Icon";

import { addEventListeners, BUTTON_EVENTS } from "../utils/events";
import { filterButtonProps } from "../utils/forms";

const IconButton = (props) => {
  const {
    type,
    className,
    variant,
    name,
    size,
    styles,
    boxShadow,
    "data-tip": dataTip,
    "data-testid": dataTestId,
  } = props;

  return (
    <StyledButton
      type={type || "button"}
      className={`${className || ""} px-0`}
      variant={variant}
      data-testid={dataTestId}
      {...filterButtonProps(props)}
      {...addEventListeners(BUTTON_EVENTS, props)}
      data-tip={dataTip}
      style={{ fontSize: size || "1.25rem", marginTop: styles || "", boxShadow: boxShadow || "" }}
    >
      <Icon name={name} size={size} />
    </StyledButton>
  );
};

IconButton.defaultProps = {
  variant: "icon",
  type: "button",
  size: "md",
};

IconButton.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  styles: PropTypes.string,
  boxShadow: PropTypes.string,
  onClick: PropTypes?.func,
  "data-tip": PropTypes.string,
  "data-testid": PropTypes.string,
};

const StyledButton = styled(Button)`
  &.red-icon {
    i {
      color: #da3451 !important;
    }
  }
`;

export default IconButton;
