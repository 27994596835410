import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonDropdown } from "reactstrap";

import Toggle from "./Toggle";
import Menu from "./Menu";
import FilterHead from "./FilterHead";
import CheckboxLabel from "./CheckboxLabel";
import { useInvoices } from "../../context/InvoiceContext";
import { canFilterByCommitment, canFilterByCreditNote } from "../../utils/auth";
import { arrayToObject } from "../../utils/helpers";

const TypeFilter = () => {
  const { paymentState, updatePaymentState } = useInvoices();

  const { invoiceType } = paymentState;

  const [filters, setFilters] = useState(["all"]);
  const [dropdownOpen, setDropdown] = useState(false);

  const keyMaps = arrayToObject([
    {
      "sale": "Sale",
    },

    canFilterByCreditNote() && {
      "credit-notes": "Credit Note",
    },
    canFilterByCommitment() && {
      "commitment-payments": "Commitment",
    },
    {
      "final": "Final",
    },
  ]);

  const toggle = () => {
    setDropdown(!dropdownOpen);
  };

  const onDoneClick = () => {
    updatePaymentState("invoiceType", filters);
    toggle();
  };

  const updateFilters = (value) => {
    const newFilters = [...filters.filter((f) => f !== "all")];

    if (newFilters.includes(value)) {
      const n = newFilters.filter((f) => value !== f);
      const final = n.length ? n : ["all"];
      return setFilters(final);
    }

    return setFilters([...newFilters, value]);
  };

  useEffect(() => {
    setFilters(invoiceType);
  }, [JSON.stringify(invoiceType)]);

  return (
    <Wrapper className="">
      <ButtonDropdown className="add-btn" isOpen={dropdownOpen} toggle={toggle}>
        <Toggle label="Type" />

        <Menu label="Invoice Type" minwidth="266px" onClick={onDoneClick}>
          <FilterHead label="select invoice type" />

          <div className="options">
            <CheckboxLabel
              key="all"
              label="Select All"
              value="all"
              checked={filters.includes("all")}
              onChange={() => setFilters(["all"])}
            />
          </div>

          <hr />

          <div className="options">
            {Object.entries(keyMaps).map(([key, label], index) => {
              return (
                <CheckboxLabel
                  key={index}
                  label={label}
                  value={key}
                  checked={filters.includes(key)}
                  onChange={() => updateFilters(key)}
                />
              );
            })}
          </div>
        </Menu>
      </ButtonDropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .options {
    padding: 0 17px;
  }

  hr {
    margin: 8px 0;
    opacity: 1;
    background-color: #edf1f7;
  }
`;

TypeFilter.propTypes = {};

export default TypeFilter;
