import { DOMAIN_PAYMENTS } from "../configs/constants/global";
import store from "../redux/store";

export function getAuth() {
  return store.getState().Auth;
}

export function getUser() {
  return getAuth().user;
}

export function isAdmin() {
  const user = getUser();
  return user.is_admin;
}

export function isPayAdmin() {
  const user = getUser();
  return user.is_pay_admin;
}
export function isNonPayAdmin() {
  const user = getUser();
  return user.is_admin && !isPayAdmin();
}

export function isDev() {
  return getUser().is_developer;
}

export function isDesigner() {
  return getUser().is_designer;
}

export function isClient() {
  return getUser().is_project_owner;
}

export function isPM() {
  return getUser().is_client_manager && !getUser().is_admin;
}

export function isStaff() {
  return getUser().is_staff && !getUser().is_admin;
}

export function isSpaceLead() {
  return getAuth().user.type === 7;
}

export function isAdminOrClient() {
  return isAdmin() || isClient();
}

export function isAdminOrPMOrSpaceLead() {
  return isAdmin() || isPM() || isSpaceLead();
}

export function isPayAdminOrPM() {
  return isPayAdmin() || isPM();
}

export function isAdminOrPMOrClient() {
  return isAdmin() || isPM() || isClient();
}

export function isDevOrClient() {
  return isDev() || isClient();
}

export function isDevOrPM() {
  return isDev() || isPM();
}

export function isCM() {
  return getUser().is_client_manager && !getUser().is_admin;
}
export function isCSO() {
  return getUser().is_client_support_officer && !getUser().is_admin;
}

export function isAdminOrCMOrClientOrCSO() {
  return isAdmin() || isCM() || isClient() || isCSO();
}

export function isDevOrCM() {
  return isDev() || isCM();
}

export function isProjectClient(project) {
  const userId = getUser().id;
  if (project?.owner && project?.owner.id === userId) {
    return true;
  }

  if (project?.user) {
    return project?.user.id === userId && (!project?.owner || !isAdmin());
  }
  return false;
}

export function hasProjectAccess(project) {
  const allowedUserIds = [];
  ["user", "owner", "cm", "cso"].forEach((key) => {
    if (project && project[key]) {
      allowedUserIds.push(project[key].id);
    }
  });
  if (project?.participation) {
    project.participation.forEach((item) => {
      if (item.status === "accepted" && item.user) {
        allowedUserIds.push(item.user.id);
      }
    });
  }
  return isAdmin() || allowedUserIds.includes(getUser().id);
}

export function isPMAndHasProjectAcess(project) {
  return hasProjectAccess(project) && isPM();
}

export function isInternalUser() {
  return isAdmin() || isCM() || isCSO() || isSpaceLead();
}

export function isCMOrCSOAndHasProjectAcess(project) {
  return hasProjectAccess(project) || isInternalUser();
}

export function getMyParticipation(project) {
  let myParticipation = null;
  if (project.participation) {
    (project.participation || []).forEach((item) => {
      if (item.user.id === getUser().id) {
        myParticipation = item;
      }
    });
  }
  return myParticipation;
}

export function isPendingProjectParticipant(project) {
  const isPending = false;
  if (isDev()) {
    const myParticipation = getMyParticipation(project);
    if (myParticipation) {
      return myParticipation.status === "initial";
    }
  }
  return isPending;
}

export function isAdminOrCMOrCSO() {
  return isAdmin() || isCM() || isCSO();
}

/**
 * This function checks if a user has a specific permission based on their role and returns a boolean
 * value.
 * @param permissionCodename - The codename of the permission that needs to be checked for the user. It
 * is a string value.
 * @param roles - The `roles` parameter is an array of role objects. Each role object contains an `id`
 * property and a `permissions` property. The `id` property is a unique identifier for the role, and
 * the `permissions` property is an array of permission objects that the role has.
 * @returns a boolean value indicating whether the user has the required permission codename or not.
 */
export function getPermission(permissionCodename, roles) {
  const decodedString = window.atob(localStorage.getItem("USER"));
  const stringifiedData = decodeURIComponent(escape(decodedString));
  const { user } = JSON.parse(stringifiedData);
  // Find the role object matching the user's role ID
  const userRole = roles.find((role) => role.id === user?.role?.id);

  if (!userRole) return false;

  // Check if the user role has the required permission codename
  const hasPermission = userRole?.permissions?.some(
    (permission) => permission.codename === permissionCodename,
  );

  return hasPermission;
}

/**
 * This function retrieves a permission by its code name from the role permissions stored in the
 * application's state.
 * @param codeName - The codeName parameter is a string that represents the name of a permission code
 * that we want to retrieve from the rolePermissions object in the store.
 * @returns the value of the permission with the given `codeName` if it exists in the `permissions`
 * array in the `Auth.rolePermissions` object in the Redux store. If the permission is not found, the
 * function returns a default value of `false`.
 */
export function getPermissionByCodeName(codeName, domain) {
  const { permissions, groupedPermissions = [] } = store.getState().Auth.rolePermissions;

  const permission = (domain ? groupedPermissions[domain] || [] : permissions ?? []).find(
    (permissionObj) => {
      return Object.keys(permissionObj)[0] === codeName;
    },
  );

  if (permission) {
    return permission[codeName];
  }

  // Return a default value or handle the case when the permission is not found
  return false;
}

// payment permissions for user
// export function canCreateSaleInvoice() {
//   return getPermissionByCodeName("can-create-sale-invoice");
// }

export function canSendInvoiceReminder() {
  return getPermissionByCodeName("can-send-invoice-reminder", DOMAIN_PAYMENTS);
}

export function canGenerateInvoice() {
  return getPermissionByCodeName("can-generate-invoice", DOMAIN_PAYMENTS);
}

export function canDeleteInvoice() {
  return getPermissionByCodeName("can-delete-invoice", DOMAIN_PAYMENTS);
}

export function canMarkInvoiceAsPaid() {
  return getPermissionByCodeName("can-mark-as-paid", DOMAIN_PAYMENTS);
}

export function canArchiveInvoice() {
  return getPermissionByCodeName("can-archive-invoice", DOMAIN_PAYMENTS);
}

export function canApproveInvoice() {
  return getPermissionByCodeName("can-approve-invoice", DOMAIN_PAYMENTS);
}

export function canRejectInvoice() {
  return getPermissionByCodeName("can-reject-invoice", DOMAIN_PAYMENTS);
}

export function canViewDraftInvoice() {
  return getPermissionByCodeName("can-see-draft-invoice", DOMAIN_PAYMENTS);
}

export function canSendInvoiceForApproval() {
  return getPermissionByCodeName("can-send-invoice-for-approval", DOMAIN_PAYMENTS);
}

export function canEditInvoice() {
  return getPermissionByCodeName("can-edit-invoice", DOMAIN_PAYMENTS);
}

export function canFilterByPending() {
  return getPermissionByCodeName("can-filter-by-pending", DOMAIN_PAYMENTS);
}

export function canFilterByPaid() {
  return getPermissionByCodeName("can-filter-by-paid", DOMAIN_PAYMENTS);
}

export function canFilterByCommitment() {
  return getPermissionByCodeName("can-filter-by-commitment", DOMAIN_PAYMENTS);
}

export function canFilterByCreditNote() {
  return getPermissionByCodeName("can-filter-by-credit-note", DOMAIN_PAYMENTS);
}

export function canFilterByArchived() {
  return getPermissionByCodeName("can-filter-by-archived", DOMAIN_PAYMENTS);
}

export function canFilterByOverdue() {
  return getPermissionByCodeName("can-filter-by-overdue", DOMAIN_PAYMENTS);
}

export function canFilterByPSP() {
  return getPermissionByCodeName("can-filter-by-psp", DOMAIN_PAYMENTS);
}

export function canDownloadInvoice() {
  return getPermissionByCodeName("can-download-invoice", DOMAIN_PAYMENTS);
}

export function canCreateFinalInvoice() {
  return getPermissionByCodeName("can-create-final-invoice", DOMAIN_PAYMENTS);
}

export function canCreateCommitmentInvoice() {
  return getPermissionByCodeName("can-create-commitment-invoice", DOMAIN_PAYMENTS);
}

export function canCreateCreditNoteInvoice() {
  return getPermissionByCodeName("can-create-credit-note-invoice", DOMAIN_PAYMENTS);
}

export function canCreateSaleInvoice() {
  return getPermissionByCodeName("can-create-sale-invoice", DOMAIN_PAYMENTS);
}

export function canCreateDeveloperInvoice() {
  return getPermissionByCodeName("can-create-developer-invoice", DOMAIN_PAYMENTS);
}

export function canDeleteDeveloperInvoice() {
  return getPermissionByCodeName("can-delete-developer-invoice", DOMAIN_PAYMENTS);
}

export function canEditDeveloperInvoice() {
  return getPermissionByCodeName("can-update-developer-invoice", DOMAIN_PAYMENTS);
}

export function canApproveDeveloperInvoice() {
  return getPermissionByCodeName("can-approve-developer-invoice", DOMAIN_PAYMENTS);
}

export function canRejectDeveloperInvoice() {
  return getPermissionByCodeName("can-reject-developer-invoice", DOMAIN_PAYMENTS);
}

export function canApproveDeveloperPayoneerInvoice() {
  return getPermissionByCodeName("can-approve-developer-payoneer-invoice", DOMAIN_PAYMENTS);
}

export function canViewInvoiceSummary() {
  return getPermissionByCodeName("can-view-invoice-summary", DOMAIN_PAYMENTS);
}

export function canViewInvoiceHistory() {
  return getPermissionByCodeName("can-view-invoice-history", DOMAIN_PAYMENTS);
}

export function canViewPaymentTab() {
  return getPermissionByCodeName("can-view-payments-tab", DOMAIN_PAYMENTS);
}

export function canViewPayoutTab() {
  return getPermissionByCodeName("can-view-payouts-tab", DOMAIN_PAYMENTS);
}

export function canViewPSPSummary() {
  return getPermissionByCodeName("can-view-psp-summary", DOMAIN_PAYMENTS);
}

export function canDownloadUnpaidIban() {
  return getPermissionByCodeName("can-download-unpaid-iban-payouts", DOMAIN_PAYMENTS);
}

export function canUploadDeveloperInvoice() {
  return getPermissionByCodeName("can-upload-developer-invoice", DOMAIN_PAYMENTS);
}

export function canAddInvoiceComment() {
  return getPermissionByCodeName("can-add-invoice-comment", DOMAIN_PAYMENTS);
}

export function canViewDraftDeveloperInvoice() {
  return getPermissionByCodeName("can-view-all-draft-developer-invoice", DOMAIN_PAYMENTS);
}

export function canViewRejectedInvoices() {
  return getPermissionByCodeName("can-view-rejected-invoices", DOMAIN_PAYMENTS);
}

export function canViewPendingInvoices() {
  return getPermissionByCodeName("can-view-pending-invoices", DOMAIN_PAYMENTS);
}

export function canUnarchiveInvoice() {
  return getPermissionByCodeName("can-unarchive-invoice", DOMAIN_PAYMENTS);
}

export function canCreateClientInvoices() {
  return (
    canCreateSaleInvoice() ||
    canCreateCommitmentInvoice() ||
    canCreateCreditNoteInvoice() ||
    canCreateFinalInvoice()
  );
}
