/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import { debounce } from "lodash";

/* ------------------------- Component dependencies ------------------------- */
import SectionNav from "../../../../components/SectionNav";
import { isAdmin } from "../../../../utils/auth";
import InputGroup from "../../../../components/InputGroup";
import Icon from "../../../../components/Icon";
import IconButton from "../../../../components/IconButton";

const options = [
  { value: "all", label: "Show: all" },
  { value: "developer", label: "Show: Developers" },
  { value: "project_owner", label: "Show: Clients" },
  { value: "client_manager", label: "Show: Client Managers" },
  { value: "admin", label: "Show: Admins" },
  { value: "client_support_officer", label: "Show: Client Support Officers" },
];
// istanbul ignore next
const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: "99999",
  }),
  control: (base) => ({
    ...base,
    height: "100%",
    width: "180px",
    border: "1px solid 	#cccccc",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid 	#cccccc",
    },
  }),
  indicatorSeparator: (base) => ({ ...base, display: "none" }),
  option: (base, { isFocused }) => ({
    ...base,
    background: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: isFocused ? "#fff" : "#fff",
    },
  }),
  input: (base) => ({
    ...base,
    textAlign: "right",
  }),
};

const InviteContainer = ({ children, acct_type, setAcctType, fetchUsers }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const sendQuery = (query) => fetchUsers(1, query);

  const delayedQuery = useMemo(() => debounce((q) => sendQuery(q), 500), []);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    delayedQuery(event.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
    sendQuery("");
  };

  const location = useLocation();
  return (
    <>
      <SectionNav
        style={{
          marginBottom: "0px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        links={[
          ...(isAdmin
            ? [
                {
                  route: `settings/users/active`,
                  name: "ACTIVE USERS",
                },
                {
                  route: `settings/users/deactivated`,
                  name: "DEACTIVATED",
                },
                {
                  route: `settings/users/invite`,
                  name: "INVITE USERS",
                },
                {
                  route: `settings/users/create`,
                  name: "CREATE CLIENT",
                },
                {
                  route: `settings/users/pending`,
                  name: "View pending invites",
                },
              ]
            : [
                {
                  route: `settings/users/invite`,
                  name: "INVITE USERS",
                },
                {
                  route: `settings/users/pending`,
                  name: "View pending invites",
                },
              ]),
        ]}
        rightActions={
          location.pathname === "/settings/users/active" ||
          location.pathname === "/settings/users/pending" ||
          location.pathname === "/settings/users/deactivated" ? (
            <div>
              <div className="d-flex">
                <StyledSearchInput
                  data-testid="input-search"
                  name="search"
                  value={searchTerm}
                  autoComplete="off"
                  onChange={handleChange}
                  className="input-search me-3"
                  placeholder="Search...."
                  prepend={<Icon name="search1" />}
                  isAppendText={false}
                  append={
                    <IconButton
                      data-testid="close-icon"
                      className="search-close-btn bsd-search-ic"
                      name="x-circle"
                    />
                  }
                  appendFunc={clearSearch}
                />

                {location.pathname !== "/settings/users/pending" && (
                  <Select
                    aria-labelledby="account-type-select"
                    styles={customStyles}
                    options={options}
                    value={acct_type}
                    onChange={setAcctType}
                    isSearchable={false}
                  />
                )}
              </div>
            </div>
          ) : null
        }
      />
      {children}
    </>
  );
};

const StyledSearchInput = styled(InputGroup)`
  box-shadow: none;
  border: 1px solid rgb(237, 241, 247);
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 15px;
  background: #fff;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 5px;
  padding-left: 4px;

  .input-group-prepend .input-group-text {
    background-color: rgb(255, 255, 255);
    color: rgb(62, 72, 87);
    padding: 0 5px;
    font-size: 16px;
    height: initial;
    border: none;
  }

  > div {
    margin: 0;

    input {
      width: 145px;
      padding: 0 5px;
      border: none;
      &:focus {
        border: none !important;
      }
    }
  }

  .search-close-btn {
    display: flex;
    align-items: center;
    padding: 0;
    color: rgb(143, 155, 179);
    font-size: 16px;
  }
`;

InviteContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  acct_type: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  setAcctType: PropTypes.func,
  fetchUsers: PropTypes.func,
};

export default InviteContainer;
