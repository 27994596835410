import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addDollarAccount,
  fetchPaymentProviders,
  fetchDollarAccount,
} from "../redux/actions/PaymentActions";
import { openAlertModal, createModal } from "../utils/modals";
import { trackEvent } from "../analytics/segment";
import { CATEGORIES, EVENTS } from "../analytics/events";
import DollarAccountForm from "../components/Payment/DollarAccountForm";

const useConnectDollarAccount = () => {
  const [triggerDefaultPrompt, setShouldTriggerPrompt] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const { dollarAccountProvider, errors, dollarAccount } = useSelector(({ Payments }) => Payments);
  const { user } = useSelector(({ Auth }) => Auth);

  const dispatch = useDispatch();

  const connectDollarAccount = () => {
    setSubmitError(null);
    const modal = createModal({
      body: (
        <DollarAccountForm
          id="dollar-account-form"
          payload={dollarAccount}
          submitError={submitError}
        />
      ),
      canClose: true,
      header: null,
      options: {
        title: "Connect Dollar Account",
        className: "modal-payments",
        hideActions: false,
        form: {
          type: "submit",
          form: "dollar-account-form",
        },
        style: {
          paddingBottom: 0,
        },
      },
      proceed: async (data) => {
        modal.setIsLoading(true);

        const trackPayment = () => {
          trackEvent(EVENTS.add_payment_provider, {
            user_id: user.id,
            username: user.display_name,
            event_category: CATEGORIES.settings,
            psp: "Dollar Account",
          });
        };

        const successCb = () => {
          trackPayment();
          modal.close();
        };
        const failureCb = () => {
          modal.setIsLoading(false);
        };

        await dispatch(
          addDollarAccount({ ...data, id: dollarAccount?.id }, { successCb, failureCb }),
        );
      },
    });

    modal.open();
  };

  useEffect(() => {
    if (dollarAccountProvider?.success) {
      openAlertModal("Successfully connected dollar Account");
      dispatch(fetchDollarAccount({ silent: true }));
      dispatch(fetchPaymentProviders());
      if (!dollarAccountProvider?.is_default) setShouldTriggerPrompt(true);
    }
  }, [dollarAccountProvider]);

  useEffect(() => {
    if (submitError) {
      connectDollarAccount();
    }
  }, [submitError]);

  // Handle error
  useEffect(() => {
    if (errors.connectDollarAccount) {
      setSubmitError(errors.connectDollarAccount);
    }
  }, [errors.connectDollarAccount]);

  return { connectDollarAccount, triggerDefaultPrompt, setShouldTriggerPrompt };
};

export default useConnectDollarAccount;
