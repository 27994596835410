/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import MetaTags from "../../../components/MetaTags";
import OnBoardImage from "../../../assets/images/taxi-task-presentation.png";
import DashboardLayout from "../../../layouts/DashboardLayout";

const Intro = () => {
  return (
    <>
      <MetaTags
        title="Welcome to Tunga"
        description="Glad you’re here. To get started, please complete your account setup."
      />

      <DashboardLayout>
        <IntroWrapper>
          <img src={OnBoardImage} alt="" />
          <h2 data-testid="welcome-to-tunga">Welcome to Tunga</h2>
          <p id="wlc-message">
            Glad you’re here. To get started, please <br /> complete your account setup.
          </p>
          <Link className="btn btn-primary" to="/onboard/step-one">
            Setup account
          </Link>
        </IntroWrapper>
      </DashboardLayout>
    </>
  );
};

const IntroWrapper = styled.div`
  margin: 6rem auto;
  width: fit-content;
  text-align: center;

  a {
    background: #da3451;
    border-radius: 6px;
    box-shadow: none;
    padding: 0px 30px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 150%; */
    text-align: center;
    color: #ffffff;
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 150%;
    text-align: center;
    color: #3e4857de;
    margin-bottom: 1.5rem;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #151a30;
  }
  img {
    width: 243px;
    margin-bottom: 1.89rem;
  }
`;

export default Intro;
