import React, { createContext, useState, useMemo } from "react";
import PropTypes from "prop-types";

export const FilterContext = createContext();

export const FilterProvider = ({ children, state = null }) => {
  const [filter, setFilter] = useState(
    state || {
      skillYearsGroups: [
        {
          groupOperation: "AND",
          skills: [],
        },
      ],
      searchOperation: "AND",
    },
  );

  React.useEffect(() => {
    return () => {
      setFilter({
        skillYearsGroups: [
          {
            groupOperation: "AND",
            skills: [],
          },
        ],
        searchOperation: "AND",
      });
    };
  }, []);
  const addFilterItem = (groupIndex) => {
    const updatedFilter = { ...filter };
    const defaultItem = {
      name: "",
      years: 0,
      operation: "gte",
    };
    updatedFilter.skillYearsGroups[groupIndex].skills.push(defaultItem);
    setFilter(updatedFilter);
  };

  const updateFilterItem = (groupIndex, skillIndex, skill) => {
    const updatedFilter = { ...filter };
    updatedFilter.skillYearsGroups[groupIndex].skills[skillIndex] = skill;
    setFilter(updatedFilter);
  };

  const deleteFilterItem = (groupIndex, skillIndex) => {
    const updatedFilter = { ...filter };
    updatedFilter.skillYearsGroups[groupIndex].skills.splice(skillIndex, 1);
    setFilter(updatedFilter);
  };

  const updateSearchOperation = (operation) => {
    const updatedFilter = { ...filter };
    updatedFilter.searchOperation = operation;
    setFilter(updatedFilter);
  };

  const updateGroupOperation = (groupIndex, operation) => {
    const updatedFilter = { ...filter };
    updatedFilter.skillYearsGroups[groupIndex].groupOperation = operation;
    setFilter(updatedFilter);
  };

  const addFilterGroup = () => {
    const updatedFilter = { ...filter };
    updatedFilter.skillYearsGroups.push({
      groupOperation: "AND",
      skills: [],
    });
    setFilter(updatedFilter);
  };

  const deleteSkillGroup = (groupIndex) => {
    const updatedFilter = { ...filter };
    updatedFilter.skillYearsGroups.splice(groupIndex, 1);
    setFilter(updatedFilter);
  };

  const deleteSkill = (groupIndex, skillIndex) => {
    const updatedFilter = { ...filter };
    updatedFilter.skillYearsGroups[groupIndex].skills.splice(skillIndex, 1);
    setFilter(updatedFilter);
  };

  const setSkillOperation = (groupIndex, skillIndex, operation) => {
    const updatedFilter = { ...filter };
    updatedFilter.skillYearsGroups[groupIndex].skills[skillIndex].operation = operation;
    setFilter(updatedFilter);
  };

  const setSkillYears = (groupIndex, skillIndex, years) => {
    const updatedFilter = { ...filter };
    updatedFilter.skillYearsGroups[groupIndex].skills[skillIndex].years = years;
    setFilter(updatedFilter);
  };

  const setSkillName = (groupIndex, skillIndex, name) => {
    const updatedFilter = { ...filter };
    updatedFilter.skillYearsGroups[groupIndex].skills[skillIndex].name = name;
    setFilter(updatedFilter);
  };

  const clearAllFilters = () => {
    setFilter({
      skillYearsGroups: [],
      searchOperation: "AND",
    });
  };

  const totalSkills = filter.skillYearsGroups.reduce((acc, group) => {
    return acc + group.skills.length;
  }, 0);

  const contextValue = useMemo(() => {
    return {
      filter,
      totalSkills,
      addFilterItem,
      updateFilterItem,
      deleteFilterItem,
      updateSearchOperation,
      addFilterGroup,
      updateGroupOperation,
      deleteSkillGroup,
      deleteSkill,
      setSkillOperation,
      setSkillYears,
      setSkillName,
      clearAllFilters,
    };
  }, [filter, totalSkills]);

  return <FilterContext.Provider value={contextValue}>{children}</FilterContext.Provider>;
};

FilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
  state: PropTypes.shape({
    skillYearsGroups: PropTypes.arrayOf(
      PropTypes.shape({
        groupOperation: PropTypes.string,
        skills: PropTypes.arrayOf({
          name: PropTypes.string,
          years: PropTypes.number,
        }),
      }),
    ),
  }),
};
