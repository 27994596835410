/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import OnboardingContainer from "./OnboardingContainer";
import Intro from "./Intro";
import { useTrackPage } from "../../../analytics/segment";
import { pages } from "../../../analytics/segment/tags";

const routes = [
  {
    path: "intro",
    component: Intro,
  },
  {
    path: ":step",
    component: OnboardingContainer,
  },
];

const Onboarding = () => {
  useTrackPage(pages.ONBOARDING);

  return (
    <Switch>
      <Redirect exact from="/onboard" to="/onboard/intro" />

      {routes.map(({ path, component: Component }) => (
        <Route path={`/onboard/${path}`} render={Component} />
      ))}
    </Switch>
  );
};

export default Onboarding;
