/* eslint-disable react/jsx-props-no-spreading */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import PropTypes from "prop-types";
import React from "react";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import { addEventListeners, BUTTON_EVENTS } from "../utils/events";
import { filterButtonProps } from "../utils/forms";

const Icon = (props) => {
  const { name, size, className } = props;

  return (
    <i
      className={`tg-ic-${name || ""} ${size ? `tunga-ic-sz-${size}` : ""} ${className || ""}`}
      {...filterButtonProps(props)}
      {...addEventListeners(BUTTON_EVENTS, props)}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default Icon;
