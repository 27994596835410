import axios from "axios";

import {
  FETCH_ROLES_PERMISSION_FAILED,
  FETCH_ROLES_PERMISSION_START,
  FETCH_ROLES_PERMISSION_SUCCESS,
} from "../../configs/constants/ActionTypes";
import { failed, formatErrorResponse, success } from "../../utils/actions";
import {
  ENDPOINT_LOGIN,
  ENDPOINT_LOGOUT,
  ENDPOINT_REGISTER,
  ENDPOINT_RESET_PASSWORD,
  ENDPOINT_RESET_PASSWORD_CONFIRM,
  ENDPOINT_ROLES_PERMISSIONS,
  ENDPOINT_VERIFY,
} from "../../utils/api";
import { flattenToString } from "../../utils/helpers";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const VERIFY_START = "@@INIT";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAILED = "VERIFY_FAILED";
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const RESET_PASSWORD_CONFIRM_START = "RESET_PASSWORD_CONFIRM_START";
export const RESET_PASSWORD_CONFIRM_SUCCESS = "RESET_PASSWORD_CONFIRM_SUCCESS";
export const RESET_PASSWORD_CONFIRM_FAILED = "RESET_PASSWORD_CONFIRM_FAILED";
export const AUTH_REDIRECT = "AUTH_REDIRECT";

const arr = [
  "Good to see you back!",
  "Ready to create?",
  "We've missed you!",
  "Good day ahead!",
  "Have a great day!",
  "Hello again!",
  "Let's get started!",
  "How's it going?",
  "How are you doing today?",
  "You're amazing!",
  "Time to shine!",
  "Feeling creative?",
  "Let's do this!",
  "Nice to see you!",
  "What's cooking, chef?",
  "Let's get things rolling!",
  "Great to have you here!",
  "Let's dive in!",
  "Let's rock!",
  "Ready for a great day?",
  "You light my Fire(wall)",
  "You've got the bytes that make my day!",
];

export function authStart(credentials) {
  return {
    type: LOGIN_START,
    credentials,
  };
}

export function authSuccess(data) {
  const index = Math.floor(Math.random() * arr.length);
  const user = { ...data.user, welcomeMessage: arr[index] };

  window.localStorage.setItem("welcomeMsg", arr[index]);

  const stringifiedData = JSON.stringify(data);
  const encodedString = unescape(encodeURIComponent(stringifiedData));

  localStorage.setItem("USER", window.btoa(encodedString));

  return {
    type: LOGIN_SUCCESS,
    user,
  };
}

export function authFailed(error) {
  return {
    type: LOGIN_FAILED,
    error,
  };
}

export function authenticate(credentials) {
  return (dispatch) => {
    dispatch(authStart(credentials));
    return axios
      .post(ENDPOINT_LOGIN, credentials)
      .then((response) => {
        dispatch(authSuccess(response.data));
      })
      .catch((error) => {
        dispatch(authFailed(error?.response?.data));
      });
  };
}

export function verifyStart() {
  return {
    type: VERIFY_START,
  };
}

export function verifySuccess(data) {
  const user = { ...data, welcomeMessage: window.localStorage.getItem("welcomeMsg") };
  return {
    type: VERIFY_SUCCESS,
    user,
  };
}

// user role and permissions methods
export function fetchRolePermissionFailed(error) {
  return {
    type: FETCH_ROLES_PERMISSION_FAILED,
    error,
  };
}

export function fetchRolePermissionStart() {
  return {
    type: FETCH_ROLES_PERMISSION_START,
  };
}

export function fetchRolePermissionSuccess(response) {
  return {
    type: FETCH_ROLES_PERMISSION_SUCCESS,
    response,
  };
}

export function getRoles() {
  return (dispatch) => {
    dispatch(fetchRolePermissionStart());
    axios
      .get(ENDPOINT_ROLES_PERMISSIONS)
      .then((response) => {
        dispatch(success(FETCH_ROLES_PERMISSION_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(FETCH_ROLES_PERMISSION_FAILED, formatErrorResponse(error)));
      });
  };
}

export const verify =
  ({ silent = false } = {}) =>
  async (dispatch) => {
    if (!silent) dispatch(verifyStart());

    try {
      const response = await axios.get(ENDPOINT_VERIFY);
      dispatch(getRoles());
      dispatch(verifySuccess(response.data));
    } catch (err) {
      dispatch({ type: VERIFY_FAILED });
    }
  };

export function logoutStart() {
  return {
    type: LOGOUT_START,
  };
}

export function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

export function logoutFailed(error) {
  return {
    type: LOGOUT_FAILED,
    error,
  };
}

export function logout() {
  return (dispatch) => {
    dispatch(logoutStart());
    axios
      .post(ENDPOINT_LOGOUT, {})
      .then(() => {
        dispatch(logoutSuccess());
      })
      .catch((error) => {
        dispatch(logoutFailed(error.message));
      });
  };
}

export function registerStart(details) {
  return {
    type: REGISTER_START,
    details,
  };
}

export function registerSuccess(data) {
  const { user } = data;
  return {
    type: REGISTER_SUCCESS,
    user,
  };
}

export function registerFailed(error) {
  return {
    type: REGISTER_FAILED,
    error,
  };
}

export function register(details) {
  return (dispatch) => {
    dispatch(registerStart(details));
    axios
      .post(ENDPOINT_REGISTER, details)
      .then((response) => {
        dispatch(registerSuccess(response.data));
      })
      .catch((error) => {
        dispatch(registerFailed(error.response ? error.response.data : error));
      });
  };
}

export function resetPasswordStart(email) {
  return {
    type: RESET_PASSWORD_START,
    email,
  };
}

export function resetPasswordSuccess(response) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    response,
  };
}

export function resetPasswordFailed(error) {
  return {
    type: RESET_PASSWORD_FAILED,
    error,
  };
}

export function resetPassword(email) {
  return (dispatch) => {
    dispatch(resetPasswordStart(email));
    axios
      .post(ENDPOINT_RESET_PASSWORD, email)
      .then((response) => {
        dispatch(resetPasswordSuccess(response.data));
      })
      .catch((error) => {
        dispatch(resetPasswordFailed(flattenToString(error?.response?.data)));
      });
  };
}

export function resetPasswordConfirmStart(credentials) {
  return {
    type: RESET_PASSWORD_CONFIRM_START,
    credentials,
  };
}

export function resetPasswordConfirmSuccess(response) {
  return {
    type: RESET_PASSWORD_CONFIRM_SUCCESS,
    response,
  };
}

export function resetPasswordConfirmFailed(error) {
  return {
    type: RESET_PASSWORD_CONFIRM_FAILED,
    error,
  };
}

export function resetPasswordConfirm(credentials) {
  return (dispatch) => {
    dispatch(resetPasswordConfirmStart(credentials));
    axios
      .post(ENDPOINT_RESET_PASSWORD_CONFIRM, credentials)
      .then((response) => {
        dispatch(resetPasswordConfirmSuccess(response.data));
      })
      .catch((error) => {
        dispatch(resetPasswordConfirmFailed(error?.response?.data));
      });
  };
}

export function authRedirect(path) {
  return {
    type: AUTH_REDIRECT,
    path,
  };
}
