import React from "react";
import DOMPurify from "dompurify";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { useTrackPage } from "../../../analytics/segment";
import { pages } from "../../../analytics/segment/tags";

const HelpAndSupport = () => {
  useTrackPage(pages.HELP_N_SUPPORT);
  const iframe = () => {
    return {
      // Sanitize external HTML to prevent XSS attacks
      __html: DOMPurify.sanitize(
        `<iframe class="clickup-embed clickup-dynamic-height" src="https://forms.clickup.com/4575769/f/4bmgt-28315/DMF84RFX7FHE941QRQ" onwheel="" width="100%" height="1400px" style="background: transparent;"></iframe><script async src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"></script>`,
        {
          ALLOWED_TAGS: ["iframe"],
          ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
        },
      ),
    };
  };

  return (
    <DashboardLayout>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={iframe()} />
    </DashboardLayout>
  );
};

export default HelpAndSupport;
