import React, { useState } from "react";
import PropTypes from "prop-types";
import FieldError from "../../../../components/FieldError";
import Select from "../../../../components/Select";
import { CustomSelector } from "../../../../utils/styles";

const RejectInvoiceForm = (props) => {
  const { proceed } = props;
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [reasonError, setReasonError] = useState("");

  const reasons = [
    { id: "Client Discount not accounted for", name: "Client Discount not accounted for" },
    { id: "Wrong Client rate", name: "Wrong Client rate" },
    { id: "Wrong number of hours", name: "Wrong number of hours" },
    { id: "Loan financing unaccounted for", name: "Loan financing unaccounted for" },
  ];

  const handleReason = (value) => {
    setReason(value);
    setReasonError("");
  };

  const submit = (event) => {
    event.preventDefault();
    if (!reason) {
      setReasonError("This field is required");
      return;
    }

    proceed({ reason, ...(message ? { additional_info: message } : {}) });
  };

  return (
    <form onSubmit={submit} id="reject-invoice-form" data-testid="rejectInvoice">
      <div className="form-group mb-3">
        <label htmlFor="reason">Reason for rejecting</label>
        {reasonError ? <FieldError data-testid="error_msg" message={reasonError} /> : null}
        <CustomSelector>
          <Select
            className="form-control"
            onChange={(e) => {
              handleReason(e.target.value);
            }}
            selected={reason}
            required={true}
            style={{ width: "100%", marginLeft: 0 }}
            data-testid="reason"
          >
            <option value="">Select reason</option>
            {reasons.map((x, i) => (
              <option value={x.id} key={i}>
                {x.name}
              </option>
            ))}
          </Select>
        </CustomSelector>
      </div>

      <div className="form-group">
        <label htmlFor="message">Additional message (optional)</label>
        <textarea
          className="form-control"
          style={{ resize: "none" }}
          placeholder="Type message..."
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          data-testid="additionalMsg"
        />
      </div>
    </form>
  );
};

RejectInvoiceForm.propTypes = {
  proceed: PropTypes.func,
};

export default RejectInvoiceForm;
