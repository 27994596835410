import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { StyledForm } from "../../../../utils/styles";

const ExportGigCVSuccess = ({ id }) => {
  return (
    <Container as="div" id={id} data-testid="gig-cv-export">
      <svg
        width={48}
        height={48}
        viewBox="0 0 48 48"
        fill="none"
        className="mb-3"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx={24} cy={24} r={24} fill="#219653" fillOpacity="0.05" />
        <path
          d="M33 18.9998L21 30.9998L15.5 25.4998L16.91 24.0898L21 28.1698L31.59 17.5898L33 18.9998Z"
          fill="#219653"
        />
      </svg>

      <StyledH5>Downloaded!</StyledH5>
      <StyledP className="text-center">
        Help us improve GigCV by sharing your plans for the PDF in a brief survey.
      </StyledP>

      <StyledA target="_blank" rel="noopener noreferrer" href="https://gigcv.org/questionnaire">
        Take Survey
      </StyledA>
    </Container>
  );
};

const Container = styled(StyledForm)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledH5 = styled.h5`
  font-size: 1.125rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
`;

const StyledP = styled.p`
  margin-bottom: 20px;
`;

const StyledA = styled.a`
  background-color: #da3451;
  border-radius: 4px;
  height: 40px;
  min-width: 156px;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  display: grid;
  place-items: center;

  :hover {
    color: #fff;
  }
`;

ExportGigCVSuccess.propTypes = {
  id: PropTypes.string,
};

export default ExportGigCVSuccess;
