import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

const InvoiceStatus = ({ invoice }) => {
  if (invoice.paid) {
    return <Paid>Paid</Paid>;
  }

  if (invoice.status === "approved") {
    return <Pending>{invoice.type === "purchase" ? "Processing" : "Approved"}</Pending>;
  }

  if (new Date(`${invoice.due_at}`) < new Date() && !invoice.paid) {
    return <Overdue>Overdue</Overdue>;
  }

  if (invoice.status === "draft") {
    return <Pending>Draft</Pending>;
  }

  if (invoice.status === "rejected") {
    return <Overdue>Rejected</Overdue>;
  }

  return <Pending>Pending</Pending>;
};

const commonStyles = css`
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  border-radius: 50px;
  padding: 6px 24px;
`;

const Paid = styled.span`
  ${commonStyles}
  color: #219653;
  background: rgba(33, 150, 83, 0.04);
  border: 1px solid rgba(33, 150, 83, 0.04);
`;

const Pending = styled.span`
  ${commonStyles}
  color: #8f9bb3;
  background: rgba(143, 155, 179, 0.05);
  border: 1px solid rgba(143, 155, 179, 0.04);
`;

const Overdue = styled.span`
  ${commonStyles}
  color: #eb5757;
  background: rgba(235, 87, 87, 0.04);
  border: 1px solid rgba(235, 87, 87, 0.04);
`;

InvoiceStatus.propTypes = {
  invoice: PropTypes.shape({
    paid: PropTypes.bool,
    status: PropTypes.string,
    type: PropTypes.string,
    due_at: PropTypes.string,
  }),
};

export default InvoiceStatus;
