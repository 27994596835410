/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* -------------------------- Internal dependencies ------------------------- */
import DocumentForm from "./DocumentForm";

import { createModal } from "../utils/modals";
import Icon from "./Icon";
import { DOCUMENT_TYPES_MAP } from "../configs/constants/projectConstants";
import { trackEvent } from "../analytics/segment";
import { CATEGORIES, EVENTS } from "../analytics/events";

const DocumentPicker = ({ onChange, showSelected, documentType, project }) => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (onChange) onChange(documents);
  }, [documents]);

  const onSelectDocument = () => {
    const docType = DOCUMENT_TYPES_MAP[documentType];

    const modal = createModal({
      body: <DocumentForm id="document-form" />,
      options: {
        title: `Add ${`${docType}${documentType === "other" ? "s" : ""}`}`,
        ok: "Add Document",
        form: {
          type: "submit",
          form: "document-form",
        },
      },
      proceed: (document) => {
        setDocuments([...documents, { ...document, type: documentType }]);
        trackEvent(EVENTS.add_new_document, {
          project_type:
            project?.stage === "active" ? project?.category || "" : project?.stage || "",
          project_id: project?.id || "",
          project_name: project?.title || "",
          document_type: docType,
          event_category: CATEGORIES.projects,
        });
        modal.close();
      },
    });
    modal.open();
  };

  const onRemoveDoc = (idx) => {
    setDocuments([...documents.slice(0, idx), ...documents.slice(idx + 1)]);
  };

  return (
    <div className="document-input">
      {showSelected && documents.length > 0 && (
        <div className="file-list">
          {documents.map((doc, index) => {
            return (
              <div className="file-item" key={index}>
                <i className="tg-ic-file" /> {doc.title} {doc.file ? doc.file.name : doc.url}
                <button type="button" className="btn" onClick={() => onRemoveDoc(index)}>
                  <i className="tg-ic-close" />
                </button>
              </div>
            );
          })}
        </div>
      )}

      <button
        data-testid="del-btn"
        type="button"
        className="add-btn"
        onClick={() => onSelectDocument()}
      >
        <Icon name="round-add" size="sm" />
        &nbsp;&nbsp;Add New
      </button>
    </div>
  );
};

DocumentPicker.defaultProps = {
  showSelected: true,
};

DocumentPicker.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    stage: PropTypes.string,
    category: PropTypes.string,
  }),
  onChange: PropTypes.func,
  documentType: PropTypes.string,
  showSelected: PropTypes.bool,
};

export default DocumentPicker;
