/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import Avatar from "../../../../../components/Avatar";
import { generateUserInitials } from "../../../../../utils/stringUtils";
import SmileysModal from "../../../../../components/SmileysModal";

const DeveloperSurveyContent = ({ activity }) => {
  const report = activity.progress_reports[0];

  return (
    <>
      <ContentHeader>
        <div>
          <p className="title">Team Member</p>
          <div className="d-flex align-items-center">
            <Avatar
              image={activity.user.avatar_url}
              initials={generateUserInitials(activity.user)}
              size="dash"
            />{" "}
            {activity.user.display_name}
          </div>
        </div>
      </ContentHeader>

      <ContentSection>
        <p className="title">
          How would you rate your job satisfaction at{" "}
          <b>
            {activity.project?.category === "dedicated"
              ? activity.project?.owner?.display_name
              : "Tunga"}
          </b>
          ?
        </p>

        <SmileysModal
          index=""
          name="satisfaction_rating"
          selected={String(report?.satisfaction_rating)}
          disabled={true}
        />
      </ContentSection>

      <ContentSection>
        <p className="title">I have been assigned clear tasks to work on</p>

        <SmileysModal
          index=""
          name="task_clarity_rating"
          selected={String(report?.task_clarity_rating)}
          disabled={true}
        />
      </ContentSection>

      <ContentSection>
        <p className="title">I have access to all the tools and resources necessary to do my job</p>

        <SmileysModal
          index=""
          name="resource_access_rating"
          selected={String(report?.resource_access_rating)}
          disabled={true}
        />
      </ContentSection>

      <ContentSection>
        <p className="title">Are you currently stuck on any part of your work/tasks?</p>
        <p>{report.stuck_on_tasks ? "Yes" : "No"}</p>
      </ContentSection>

      <ContentSection>
        <p className="title">
          How satisfied are you with the overall services and support provided by Tunga?
        </p>

        <SmileysModal
          index=""
          name="overall_satisfaction_rating"
          selected={String(report?.overall_satisfaction_rating)}
          disabled={true}
        />
      </ContentSection>

      <ContentSection>
        <p className="title">
          Is there anything that you think either Tunga or the client can do/improve to better
          serve/assist you?
        </p>
        <p>{report.improvement_suggestions}</p>
      </ContentSection>

      {activity?.questions?.map(({ id, text, answer, question_type }) => {
        if (question_type === "rating") {
          return (
            <ContentSection key={id}>
              <p className="title">{text}</p>
              <SmileysModal index={id} name={`rating${id}`} selected={answer} disabled={true} />
            </ContentSection>
          );
        }

        return (
          <ContentSection key={id}>
            <p className="title">{text}</p>
            <p>{answer}</p>
          </ContentSection>
        );
      })}
    </>
  );
};

const ContentSection = styled.div`
  font-size: 16px;
  line-height: 21px;
  color: #3e4857;
  padding: 24px 0;
  border-bottom: 1px solid #edf1f7;

  &:last-of-type {
    border-bottom: none;
  }

  .title {
    font-weight: 500;
    color: #151a30;
    margin-bottom: 8px;
  }

  p {
    i {
      color: #8f9bb3;
    }
    a {
      float: right;
      font-weight: 500;
      color: #062e64;
      text-decoration: none;
    }
  }
`;

const ContentHeader = styled(ContentSection)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 0px;

  .avatar.avatar-dash {
    width: 35px;
    height: 35px;
  }
  .avatar.avatar-initials {
    font-size: 12px;
  }
  .status {
    height: 35px;
    display: flex;
    span {
      margin: auto;
    }
  }
  .progress-wrapper {
    display: flex;
    flex-direction: row;
    height: 35px;

    .progress {
      width: 150px;
      height: 4px;
      margin: auto;
    }

    > span {
      margin: auto;
    }
  }
`;

DeveloperSurveyContent.propTypes = {
  activity: PropTypes.shape({
    due_at: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        answer: PropTypes.string,
        question_type: PropTypes.string,
      }),
    ),
    project: PropTypes.shape({
      category: PropTypes.string,
      owner: PropTypes.shape({
        display_name: PropTypes.string,
      }),
    }),
    user: PropTypes.shape({
      avatar_url: PropTypes.string,
      display_name: PropTypes.string,
    }),
    developer_ratings: PropTypes.arrayOf(
      PropTypes.shape({
        user: PropTypes.shape({
          id: PropTypes.number,
          display_name: PropTypes.string,
        }),
        rating: PropTypes.number,
      }),
    ),
    progress_reports: PropTypes.shape({
      satisfaction_rating: PropTypes.number,
    }),
  }),
};

export default DeveloperSurveyContent;
