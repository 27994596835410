import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonDropdown } from "reactstrap";
import PropTypes from "prop-types";

import Menu from "./Menu";
import { ToggleV2 } from "./Toggle";
import InnerDropdown from "./InnerDropdown";
import { useInvoices } from "../../context/InvoiceContext";

const ColumnVisibility = ({ columns }) => {
  const [rColumns, setColumns] = useState({ main: [], extra: [] });

  const [dropdownOpen, setDropdown] = useState(false);

  const { updateColumnVisibility } = useInvoices();

  const toggle = () => {
    setDropdown(!dropdownOpen);
  };

  useEffect(() => {
    setColumns(columns);
  }, [columns]);

  return (
    <Wrapper className="">
      <ButtonDropdown className="add-btn" isOpen={dropdownOpen} toggle={toggle}>
        <ToggleV2 label="Column" />

        <Menu right label="Columns" minwidth="266px" onClick={toggle}>
          <InnerDropdown
            label="Main columns"
            columns={rColumns.main}
            onChange={(name, visible) => updateColumnVisibility("main", name, visible)}
          />
          <InnerDropdown
            label="Additional columns"
            columns={rColumns.extra}
            onChange={(name, visible) => updateColumnVisibility("extra", name, visible)}
          />
        </Menu>
      </ButtonDropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .options {
    padding: 0 17px;
  }

  hr {
    margin: 8px 0;
    opacity: 1;
    background-color: #edf1f7;
  }
`;

ColumnVisibility.propTypes = {
  columns: PropTypes.shape({
    main: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        visibility: PropTypes.bool,
        editable: PropTypes.bool,
      }),
    ),
    extra: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        visibility: PropTypes.bool,
        editable: PropTypes.bool,
      }),
    ),
  }).isRequired,
};

export default ColumnVisibility;
