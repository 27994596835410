import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import XIcon from "../assets/images/icons/x-circle.svg";

const Tag = ({ label, onClickRemove, dataTestId = "tag" }) => {
  return (
    <Wrapper data-testid={dataTestId} className="d-inline-flex align-items-center">
      {label}
      <button
        type="button"
        data-testid={`remove-${label}`}
        className="p-0 d-inline-flex justify-content-center align-items-center"
        onClick={onClickRemove}
      >
        <img src={XIcon} alt="" />
      </button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 3px 10px 4px;
  border-radius: 50px;
  background: rgba(6, 46, 100, 0.15);
  font-weight: 500;
  font-size: ${(props) => props.theme.functions.pxToRem(14)};
  line-height: ${(props) => props.theme.functions.pxToRem(21)};
  color: ${(props) => props.theme.colors["dark-blue"]};

  &.opacity {
    opacity: 0.5;
  }

  button {
    height: 12px;
    width: 12px;
    vertical-align: baseline;
    line-height: unset;
    margin-left: 10px;
    border: none;
    background-color: transparent;

    &:disabled {
      opacity: 0.3;
    }
  }
`;

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  onClickRemove: PropTypes.func.isRequired,
};

export default Tag;
