import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const PayoneerHandler = ({ message, redirect_url }) => {
  return (
    <div>
      <Title>{message}</Title>

      <a className="btn btn-primary text-center" data-testid="outlineBtn" href={redirect_url}>
        <span>Setup</span>
      </a>
    </div>
  );
};

const Title = styled.div`
  color: #3e4857;
  margin-bottom: 1.8em;
  font-size: 15px;
`;

PayoneerHandler.propTypes = {
  message: PropTypes.string,
  redirect_url: PropTypes.string,
};

export default PayoneerHandler;
