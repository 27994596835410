/* eslint-disable default-param-last */
import { combineReducers } from "redux";
import * as types from "../../configs/constants/ActionTypes";

function isMakingRequest(
  state = {
    loading: true,
    isUpdatingInterest: false,
  },
  action,
) {
  switch (action.type) {
    case types.FETCH_OPPORTUNITIES_START:
    case types.FETCH_OPPORTUNITY_START:
      return { ...state, loading: true };
    case types.FETCH_OPPORTUNITIES_SUCCESS:
    case types.FETCH_OPPORTUNITIES_FAILED:
    case types.FETCH_OPPORTUNITY_FAILED:
    case types.FETCH_OPPORTUNITY_SUCCESS:
      return { ...state, loading: false };
    case types.UPDATE_INTEREST_SUCCESS:
    case types.UPDATE_INTEREST_FAILED:
      return { ...state, isUpdatingInterest: false };
    case types.UPDATE_INTEREST_START:
      return { ...state, isUpdatingInterest: true };
    default:
      return state;
  }
}

function opportunityReducer(state = { opportunity: null, success: false, error: false }, action) {
  switch (action.type) {
    case types.FETCH_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        opportunity: action.data,
      };
    case types.UPDATE_INTEREST_SUCCESS:
      return {
        ...state,
        opportunity: action.data,
        success: true,
        error: false,
      };
    case types.UPDATE_INTEREST_FAILED:
      return {
        ...state,
        error: true,
        success: false,
      };
    case types.OPPORTUNITY_RESET_MESSAGES:
      return {
        ...state,
        error: false,
        success: false,
      };
    default:
      return state;
  }
}

function opportunities(state = { data: [] }, action) {
  switch (action.type) {
    case types.FETCH_OPPORTUNITIES_SUCCESS:
      return {
        data: action.data,
      };
    default:
      return state;
  }
}

const Opportunity = combineReducers({
  isMakingRequest,
  opportunities,
  opportunityReducer,
});
export default Opportunity;
