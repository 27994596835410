import React from "react";

const OrderAsc = () => {
  return (
    <svg width={19} height={14} viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 11H13"
        stroke="#7F8C9F"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 7H16"
        stroke="#7F8C9F"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 3L18 3"
        stroke="#7F8C9F"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 13L4 1"
        stroke="#7F8C9F"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 4L4 1L7 4"
        stroke="#7F8C9F"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OrderAsc;
