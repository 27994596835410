import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

const proptypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const TempProjectContext = React.createContext([{}, () => {}]);

const TempProjectProvider = ({ children }) => {
  const [state, setState] = useState({});
  const data = useMemo(() => [state, setState], [state]);
  return <TempProjectContext.Provider value={data}>{children}</TempProjectContext.Provider>;
};

TempProjectProvider.propTypes = proptypes;

export { TempProjectContext, TempProjectProvider };
