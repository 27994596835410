import { combineReducers } from "redux";
import Auth from "./AuthReducers";
import invites from "./InvitesReducer";
import Invoice from "./InvoiceReducers";
import Dashboard from "./DashboardReducer";
import Projects from "./ProjectsReducer";
import TestResults from "./TestResultsReducers";
import Profile from "./ProfileReducer";
import Common from "./CommonReducer";
import Payments from "./PaymentReducers";
import Skill from "./SkillReducers";
import User from "./UserReducers";
import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from "../actions/AuthActions";
import InterestPoll from "./InterestPollReducer";
import TimeSheetEntries from "./TimeSheetEntriesReducers";
import Opportunities from "./OpportunityReducer";

const appReducer = combineReducers({
  Auth,
  invites,
  Invoice,
  Dashboard,
  Projects,
  TestResults,
  Profile,
  Common,
  Payments,
  Skill,
  InterestPoll,
  User,
  TimeSheetEntries,
  Opportunities,
});

const rootReducer = (state, action) => {
  let appState = state;

  // Clear all data in redux store to initial.
  if (action.type === LOGIN_SUCCESS || action.type === LOGOUT_SUCCESS) {
    appState = undefined;
  }
  return appReducer(appState, action);
};

export default rootReducer;
