import styled from "styled-components";
import Button from "../Button";

const Title = styled.div`
  color: #3e4857;
  margin-bottom: 1.8em;
  font-size: 15;
`;

const Label = styled.label`
  color: #151a30;
  font-weight: 500;
  line-height: 150%;
  width: 100%;
`;

const Default = styled.span`
  background: #da3451;
  color: #ffffff;
  height: 17px;
  width: 54px;
  border-radius: 4px;
  padding: 3px;
  font-size: 12px;
  margin-left: 5px;
`;

const OutlineBtn = styled(Button)`
  position: absolute;
  bottom: 0;
  border: 1px solid #aaa;
  margin-top: 0.5em;
  padding: 0;
  height: 2.5rem;
  font-weight: 500;
  padding-left: 0.7em;
  padding-right: 0.7em;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 0.5em;
  line-height: 19px;
  border-color: rgba(6, 46, 100, 0.15);
  background-color: rgba(6, 46, 100, 0.15);
  color: rgb(6, 46, 100);
`;

const ConnectedSpan = styled(OutlineBtn)`
  color: rgb(71, 184, 129);
  background-color: rgba(71, 184, 129, 0.05);
  border-color: rgba(71, 184, 129, 0.15);

  &:hover {
    color: rgb(71, 184, 129);
  }
`;

export { Default, OutlineBtn, Label, Title, ConnectedSpan };
