import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Input from "../Input";
import Select from "../Select";
import IconButton from "../IconButton";
import FieldError from "../FieldError";

const options = [
  { value: "", name: "Select a category" },
  { value: "language", name: " Programming Languages" },
  { value: "framework", name: "Frameworks" },
  { value: "platform", name: "Platforms" },
  { value: "library", name: "Libraries " },
  { value: "storage", name: "Storage Engines" },
  { value: "other", name: " Miscellaneous " },
];

const FormInputs = memo(
  ({
    index,
    skillName,
    skillCategory,
    onSkillNameChange,
    onSkillNameBlur,
    onCategoryChange,
    onCategoryBlur,
    onRemoveSkill,
    errObj,
    touched,
  }) => {
    return (
      <Container>
        <div>
          <Input
            placeholder="Enter skill name"
            name="skill_name"
            aria-label={`skill_name-input-${index}`}
            className="py-0"
            value={skillName}
            onChange={onSkillNameChange}
            onBlur={onSkillNameBlur}
            required
          />
          {errObj?.name && touched?.name && (
            <FieldError className="text-sm mt-1" message={errObj.name} />
          )}
        </div>

        <div>
          <div className="d-flex align-items-center">
            <Select
              className="pe-5"
              value={skillCategory}
              options={options}
              onChange={onCategoryChange}
              onBlur={onCategoryBlur}
            />
            <IconButton
              name="delete-outline"
              size="sm"
              data-testid={`delete-skill-group-btn-${index}`}
              className="p-0 delete-button d-flex align-items-center justify-content-center"
              onClick={onRemoveSkill}
            />
          </div>
          {errObj?.type && touched?.type && (
            <FieldError className="text-sm mt-1" message={errObj.type} />
          )}
        </div>
      </Container>
    );
  },
);

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;

  .delete-button {
    margin-left: 16px;
    width: 24px;
    height: 24px;
    color: #8f9bb3;
    font-size: 18px;
  }

  .text-sm {
    font-size: 12px;
  }
`;

FormInputs.propTypes = {
  index: PropTypes.number,
  skillName: PropTypes.string,
  skillCategory: PropTypes.string,
  onSkillNameChange: PropTypes.func,
  onCategoryChange: PropTypes.func,
  onRemoveSkill: PropTypes.func,
  onSkillNameBlur: PropTypes.func,
  onCategoryBlur: PropTypes.func,
  errObj: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  touched: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default FormInputs;
