import React from "react";

import PropTypes from "prop-types";

import Icon from "./Icon";

const Error = ({ message, ...rest }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className="alert alert-danger" {...rest}>
      <Icon name="attention" /> {message || ""}
    </div>
  );
};

Error.propTypes = {
  message: PropTypes.string,
};

export default Error;
