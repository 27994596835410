/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import { useEffect } from "react";
import { useDispatch } from "react-redux";

/* -------------------------------------------------------------------------- */
/*                             Internal Dependency                            */
/* -------------------------------------------------------------------------- */
import { logout } from "../redux/actions/AuthActions";
import { setupInterceptors } from "../utils/api";

const InterceptorSetup = () => {
  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(logout());
  };

  useEffect(() => {
    setupInterceptors(handleLogOut);
  }, []);

  return null;
};

export default InterceptorSetup;
