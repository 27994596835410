/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";

/* ------------------------- Component dependencies ------------------------- */
import { AnimatedButton } from "../../../../components/Button";
import FormInput from "./FormInput";

const ChangePassword = ({ onSave }) => {
  return (
    <form
      onSubmit={onSave}
      className="update-password-form"
      aria-label="changePassword-form"
      id="changePassword"
    >
      <div className="header">Change Password</div>
      <Row>
        <Col>
          <FormInput
            type="password"
            id="old_password"
            name="old_password"
            placeholder="Enter your password"
            label="Current Password"
          />
        </Col>
        <Col>
          <FormInput
            type="password"
            id="new_password1"
            name="new_password1"
            placeholder="Enter your new password"
            label="New Password"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <AnimatedButton targetform="#changePassword" className="loader-opaque">
            Save
          </AnimatedButton>
        </Col>
      </Row>
    </form>
  );
};

ChangePassword.propTypes = {
  onSave: PropTypes.func,
};

export default ChangePassword;
