import axios from "axios";
import { ENDPOINT_INTEREST_POLLS, ENDPOINT_PROJECTS, SEND_EMAIL } from "../../utils/api";
import { fetchProject, fetchShortlist } from "./ProjectActions";
import * as types from "../../configs/constants/ActionTypes";
import { formatErrorResponse } from "../../utils/actions";

export const INTEREST_POLL_START = "INTEREST_POLL_START";
export const INTEREST_POLL_SUCCESS = "INTEREST_POLL_SUCCESS";
export const INTEREST_POLL_FAILED = "INTEREST_POLL_FAILED";
export const UPDATE_INTEREST_POLL_START = "UPDATE_INTEREST_POLL_START";
export const UPDATE_INTEREST_POLL_SUCCESS = "UPDATE_INTEREST_POLL_SUCCESS";
export const UPDATE_INTEREST_POLL_FAILED = "UPDATE_INTEREST_POLL_FAILED";
export const CREATE_INTEREST_POLL_START = "CREATE_INTEREST_POLL_START";
export const CREATE_INTEREST_POLL_SUCCESS = "CREATE_INTEREST_POLL_SUCCESS";
export const CREATE_INTEREST_POLL_FAILED = "CREATE_INTEREST_POLL_FAILED";

export function interestPollStart() {
  return {
    type: INTEREST_POLL_START,
  };
}

export function interestPollSuccess(data) {
  return {
    type: INTEREST_POLL_SUCCESS,
    data,
  };
}

export function interestPollFailed(error) {
  return {
    type: INTEREST_POLL_FAILED,
    error,
  };
}

export function getInterestPolls(filter) {
  return (dispatch) => {
    dispatch(interestPollStart());
    axios
      .get(ENDPOINT_INTEREST_POLLS, { params: filter })
      .then((response) => {
        dispatch(interestPollSuccess(response.data));
      })
      .catch((error) => {
        dispatch(interestPollFailed(error.response ? error.response.data : null));
      });
  };
}

export function updateInterestStart() {
  return {
    type: UPDATE_INTEREST_POLL_START,
  };
}

export function updateInterestSuccess(id, data) {
  return {
    type: UPDATE_INTEREST_POLL_SUCCESS,
    data,
  };
}

export function updateInterestFailure(error) {
  return {
    type: UPDATE_INTEREST_POLL_FAILED,
    error,
  };
}

export function updateInterest(id, data, { successCb, failureCb }) {
  return (dispatch) => {
    dispatch(updateInterestStart(id, data, id));
    axios
      .patch(`${ENDPOINT_INTEREST_POLLS}${id}/`, data)
      .then((response) => {
        if (successCb) successCb();
        dispatch(updateInterestSuccess(id, response.data));
      })
      .catch((error) => {
        if (failureCb) failureCb();
        dispatch(updateInterestFailure(error.response ? error.response.data : null));
      });
  };
}

export function createInterestStart(data) {
  return {
    type: CREATE_INTEREST_POLL_START,
    data,
  };
}

export function createInterestSuccess(data) {
  return {
    type: CREATE_INTEREST_POLL_SUCCESS,
    data,
  };
}

export function createInterestFailure(error) {
  return {
    type: CREATE_INTEREST_POLL_FAILED,
    error,
  };
}

export function createInterest(interest_poll) {
  return (dispatch) => {
    dispatch(createInterestStart(interest_poll));

    axios
      .post(ENDPOINT_INTEREST_POLLS, interest_poll)
      .then((response) => {
        dispatch(createInterestSuccess(response.data));
        dispatch(fetchProject(interest_poll.project?.id));
      })
      .catch((error) => {
        dispatch(createInterestFailure(error.response ? error.response.data : null));
      });
  };
}

export function shortlistStart() {
  return {
    type: UPDATE_INTEREST_POLL_START,
  };
}

export function shortlistSuccess(data) {
  return {
    type: UPDATE_INTEREST_POLL_SUCCESS,
    data,
  };
}

export function shortlistFailure(error) {
  return {
    type: UPDATE_INTEREST_POLL_FAILED,
    error,
  };
}

export function shortlist(project, params, { successCb, failureCb }) {
  return (dispatch) => {
    dispatch(shortlistStart());

    axios
      .patch(`${ENDPOINT_PROJECTS + project.id}/shortlist/action/`, params)
      .then((response) => {
        dispatch(shortlistSuccess(response.data));

        if (params?.action === "remove") {
          dispatch(fetchShortlist(project.id));
        }

        if (successCb) successCb();
      })
      .catch((error) => {
        if (failureCb) failureCb();

        dispatch(shortlistFailure(error.response ? error.response.data : null));
      });
  };
}

export function updateShortlistStatus(project, params, { successCb, failureCb }) {
  return (dispatch) => {
    dispatch(shortlistStart());

    axios
      .patch(`${ENDPOINT_PROJECTS + project.id}/shortlist/status/`, params)
      .then((response) => {
        dispatch(shortlistSuccess(response.data));

        if (successCb) successCb();
      })
      .catch((error) => {
        if (failureCb) failureCb();

        dispatch(shortlistFailure(formatErrorResponse(error)));
      });
  };
}

export function shareShortlistStart() {
  return {
    type: types.SHARE_SHORTLIST_START,
  };
}

export function shareShortlistSuccess(data) {
  return {
    type: types.SHARE_SHORTLIST_SUCCESS,
    data,
  };
}

export function shareShortlistFailure(error) {
  return {
    type: types.SHARE_SHORTLIST_FAILED,
    error,
  };
}

export function shareShortlistAction(params, { successCb, failureCb }) {
  return (dispatch) => {
    dispatch(shareShortlistStart());

    axios
      .post(SEND_EMAIL, params)
      .then((response) => {
        if (successCb) successCb();
        dispatch(shareShortlistSuccess(response.data?.status));
      })
      .catch((error) => {
        if (failureCb) failureCb();

        dispatch(shareShortlistFailure(error.response ? error.response?.data : null));
      });
  };
}
