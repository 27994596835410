/* eslint-disable import/no-cycle */
import { useEffect } from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";

import store from "../../redux/store";
import {
  isAdmin,
  isPayAdmin,
  isSpaceLead,
  isCM,
  isCSO,
  isClient,
  isDev,
  isDesigner,
  isStaff,
} from "../../utils/auth";

let analytics = null;
if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
  analytics = AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY });
}

const getUserRole = () => {
  let role = "";

  switch (true) {
    case isPayAdmin():
      role = "Pay Admin";
      break;

    case isStaff():
      role = "Staff";
      break;

    case isAdmin():
      role = "Admin";
      break;

    case isSpaceLead():
      role = "Space Lead";
      break;

    case isCM():
      role = "Client Manager";
      break;

    case isCSO():
      role = "Client Support Officer";
      break;

    case isClient():
      role = "Client";
      break;

    case isDev():
      role = "Developer";
      break;

    case isDesigner():
      role = "Designer";
      break;

    default:
      break;
  }

  return role;
};

const identifyUser = (id, data) => {
  if (analytics) {
    if (id) {
      analytics.identify(id, {
        ...data,
      });
    } else {
      analytics.identify({
        ...data,
      });
    }
  }
};

const trackPage = (pageName) => {
  if (analytics) {
    analytics.page(pageName, {
      user_role: getUserRole(),
    });
  }
};

const trackEvent = (eventName, eventProperties = {}) => {
  if (analytics) {
    return analytics.track(eventName, {
      ...eventProperties,
      user_role: getUserRole(),
    });
  }

  return false;
};

const resetAnalytics = () => {
  if (analytics) {
    analytics.reset();
  }
};

const pushUserIdentity = () => {
  const {
    Auth: { user },
  } = store.getState();

  identifyUser(user?.id || "", {
    email: user?.email || "",
    name: user?.display_name || "",
    username: user?.username || "",
    phone: user?.profile?.phone_number || "",
    user_role: getUserRole(),
  });
};

const useTrackPage = (pageName) => {
  useEffect(() => {
    trackPage(pageName);
    pushUserIdentity();
  }, []);
};

export { identifyUser, trackPage, useTrackPage, pushUserIdentity, resetAnalytics, trackEvent };
