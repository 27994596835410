import React, { useState } from "react";
import PropTypes from "prop-types";

import "react-quill/dist/quill.snow.css";
import TextArea from "../../../../components/TextArea";

const RejectTimeSheetForm = ({ id, proceed }) => {
  const [reason, setReason] = useState("");

  const handleChange = (e) => {
    setReason(e.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    proceed({ reason });
  };

  return (
    <form id={id} data-testid="reject-timesheet-form" onSubmit={onSubmit}>
      <div className="mt-2" data-testid="message">
        <label className="mb-2" htmlFor="message">
          Reason for rejection
        </label>

        <TextArea
          value={reason}
          required
          onChange={(e) => handleChange(e)}
          data-testid="reason-for-rejection"
        />
      </div>
    </form>
  );
};

RejectTimeSheetForm.propTypes = {
  id: PropTypes.string.isRequired,
  proceed: PropTypes.func,
};

export default RejectTimeSheetForm;
