/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";

/* -------------------------- Internal Dependencies ------------------------- */
import styled from "styled-components";
import { isDev, isPMAndHasProjectAcess, isAdmin } from "../../../../utils/auth";
import { ProjectCardStyle } from "./ProjectCard";
import { eventTrack } from "../../../../utils/analytics";
import { CATEGORIES, EVENTS } from "../../../../analytics/events";

const OpportunityCard = ({ project }) => {
  const shortListedDevs = project.total_shortlisted;

  return (
    <Wrapper>
      <Link
        onClick={() =>
          eventTrack(CATEGORIES.projects, `${EVENTS.view_opportunity}: ${project.title}`)
        }
        to={`/projects/${project.id}`}
        className={`project-card ${
          isPMAndHasProjectAcess(project) || isAdmin() || isDev() ? "active" : "inactive"
        }`}
      >
        <h4>
          {_.truncate(project.title, {
            length: 30,
          })}
        </h4>
        <div>
          <p className="time">
            <i className="tg-ic-clock-outline tunga-ic-sz-sm " />
            {moment.utc(project.created_at).local().format("DD MMM YYYY")}
          </p>

          <div className="d-flex project__card-about">
            <div>
              <p>Shortlisted</p>
              <h4 className="text-left">{shortListedDevs}</h4>
            </div>
          </div>
        </div>
      </Link>{" "}
    </Wrapper>
  );
};

OpportunityCard.propTypes = {
  project: PropTypes.shape({
    created_at: PropTypes.string,
    id: PropTypes.number,
    interest_polls: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        created_by: {
          id: PropTypes.number,
          display_name: PropTypes.number,
          avatar_url: PropTypes.string,
        },
      }),
    ),
    title: PropTypes.string,
  }),
};

const Wrapper = styled(ProjectCardStyle)`
  .project-card {
    height: auto;
  }
  .project__card-about {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #edf1f7;
    padding-top: 24px;
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #151a30;
  }
  p.time {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;

    color: #8f9bb3;
    i {
      margin-right: 4px;
      color: #8f9bb3;
    }
  }
  .d-flex.project__card-about h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #3e4857;
  }
  .d-flex.project__card-about p {
    margin-bottom: 4px;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #8f9bb3;
  }
`;

export default OpportunityCard;
