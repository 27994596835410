import axios from "axios";

import {
  RETRIEVE_SETTINGS_FAILED,
  RETRIEVE_SETTINGS_START,
  RETRIEVE_SETTINGS_SUCCESS,
  SET_USER_SETTINGS,
} from "../../configs/constants/ActionTypes";
import { failed, formatErrorResponse, success } from "../../utils/actions";
import { ENDPOINT_ACCOUNT_SETTINGS } from "../../utils/api";

// TODO:
// - Work error handling
// - Loading via button

const getSettingsStart = () => ({ type: RETRIEVE_SETTINGS_START });
export function getSettings() {
  return (dispatch) => {
    dispatch(getSettingsStart());

    axios
      .get(ENDPOINT_ACCOUNT_SETTINGS)
      .then((res) => {
        dispatch({ type: RETRIEVE_SETTINGS_SUCCESS });
        dispatch(
          success(SET_USER_SETTINGS, {
            ...res.data,
          }),
        );
      })
      .catch((error) => {
        dispatch(failed(RETRIEVE_SETTINGS_FAILED, formatErrorResponse(error)));
      });
  };
}

export function updateSettings(data) {
  return (dispatch) => {
    axios
      .patch(ENDPOINT_ACCOUNT_SETTINGS, data)
      .then((res) => {
        dispatch(
          success(SET_USER_SETTINGS, {
            ...res.data,
          }),
        );
      })
      .catch(() => {});
  };
}
