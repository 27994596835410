/* eslint-disable default-param-last */
import { combineReducers } from "redux";

import {
  FETCH_NOTIFICATIONS_START,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILED,
  CREATE_NOTIFICATION_LOG_SUCCESS,
  CREATE_NOTIFICATION_LOG_FAILED,
  CREATE_NOTIFICATION_LOG_START,
  DELETE_NOTIFICATIONS_START,
  DELETE_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATIONS_FAILED,
  GET_DASHBOARD_STATS_START,
  GET_DASHBOARD_STATS_SUCCESS,
  GET_DASHBOARD_STATS_FAILED,
} from "../../configs/constants/ActionTypes";

const defaultNotifications = {
  profile: {
    cleared: [],
    required: [],
    optional: [],
  },
  projects: [],
  invoices: [],
  events: [],
  reports: [],
  activities: [],
};

const stat = {
  clients: {},
  freelancers: {},
};

function statistics(state = stat, action) {
  switch (action.type) {
    case GET_DASHBOARD_STATS_SUCCESS:
      return {
        ...stat,
        ...action.data,
      };
    default:
      return state;
  }
}

function notifications(state = defaultNotifications, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...defaultNotifications,
        ...action.data,
      };
    case CREATE_NOTIFICATION_LOG_SUCCESS: {
      if (action.data.type === "profile") {
        return {
          ...state,
          profile: {
            ...state.profile,
            cleared: [...state.profile.cleared, action.data.notification_id],
          },
        };
      }

      const newActivites = state.activities.filter(
        (item) => item.id !== action.data.notification_id,
      );

      return {
        ...state,
        activities: newActivites,
      };
    }
    case DELETE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        activities: [],
      };
    default:
      return state;
  }
}

function isMakingRequest(_, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_START:
      return { notification: true };
    case CREATE_NOTIFICATION_LOG_START:
      return { notificationLog: true };
    case DELETE_NOTIFICATIONS_START:
      return { clearNofitication: true };
    case GET_DASHBOARD_STATS_START:
      return { dashboardStats: true };
    default:
      return {};
  }
}

function errors(state = {}, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_FAILED:
      return { notification: action.error };
    case CREATE_NOTIFICATION_LOG_FAILED:
      return { notificationLog: action.error };
    case DELETE_NOTIFICATIONS_FAILED:
      return { clearNofitication: action.error };
    case GET_DASHBOARD_STATS_FAILED:
      return { dashboardStats: action.error };
    default:
      return state;
  }
}

const Dashboard = combineReducers({
  notifications,
  isMakingRequest,
  errors,
  statistics,
});

export default Dashboard;
