import { useContext } from "react";
import { NavContext } from "../../context/NavContext";

const useRightNav = () => {
  const [status, setStatus] = useContext(NavContext);

  function open(content = null, style = {}, overlayVisible = false, closeOnClickOutside = false) {
    setStatus({
      ...status,
      collapsed: false,
      content,
      style,
      overlayVisible,
      closeOnClickOutside,
    });
  }

  function close() {
    setStatus({ ...status, style: {}, collapsed: true, overlayVisible: false });
  }

  return {
    status,
    setStatus,
    open,
    close,
  };
};

export default useRightNav;
