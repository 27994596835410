/* eslint-disable react/no-unescaped-entities */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Label } from "reactstrap";
/* ------------------------- Component dependencies ------------------------- */
// import CustomInputGroup from "../../../components/CustomInputGroup";
import Input from "../../../../components/Input";

const FormInput = ({ id, label, type = "text", name, value, placeholder, onChange }) => (
  <FormGroup>
    <Label>
      {label}
      <span className="label-style">*</span>
    </Label>
    <Input
      id={id}
      type={type}
      name={name}
      aria-label={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      required
    />
  </FormGroup>
);

FormInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default FormInput;
