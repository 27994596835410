import React from "react";
import { createModal } from "./modals";
import store from "../redux/store";
import ProjectDateForm from "../pages/Dashboard/projects/projectDetail/components/ProjectDateForm";
import MilestoneForm from "../pages/Dashboard/projects/projectDetail/components/MilestoneForm";
import PlanningForm from "../pages/Dashboard/projects/projectDetail/components/PlanningForm";
import {
  createDocument,
  createProgressEvent,
  updateDocument,
  updateProgressEvent,
  updateProject,
} from "../redux/actions/ProjectActions";
import { getUser } from "./auth";
import { trackEvent } from "../analytics/segment";
import { CATEGORIES, EVENTS } from "../analytics/events";

/**
 * Create record of which fields have changes showing previous and current values
 * @param {*} fields Object keys of object you are trying to edit e.g project
 * @param {*} reason
 * @param {*} update new object from form
 * @param {*} original old object that you are trying to edit
 * @returns array of fields that have changed and the reason
 */
const parseChangeLog = (fields, update, original) => {
  const changes = [];
  fields.forEach((field) => {
    if (update[field] && update[field] !== original[field]) {
      changes.push({
        field,
        reason: update.reason,
        previous_value: original[field] || null,
        new_value: update[field],
      });
    }
  });
  return changes;
};

export const onManageSchedule = (project, timeline = {}) => {
  const { id, reason, deadline, start_date } = timeline;

  const isNotNew = Object.keys(timeline).length !== 0;

  const modal = createModal({
    body: (
      <ProjectDateForm
        id="timeline-form"
        project={{
          id,
          reason,
          deadline,
          start_date,
        }}
      />
    ),
    options: {
      title: `${isNotNew ? "Update" : "Add"} timeline`,
      className: "modal-payments",
      ok: `Save ${isNotNew ? "Changes" : ""}`,
      form: {
        type: "submit",
        form: `timeline-form`,
      },
    },
    proceed: (data) => {
      modal.setIsLoading(true);

      const newData = { ...data };

      if (newData.reason) {
        const changes = parseChangeLog(["reason", "deadline", "start_date"], newData, project);
        delete newData.reason;

        if (changes.length) {
          newData.change_log = changes;
        }
      }

      const feedbackCb = {
        successCb: () => {
          const event = isNotNew ? EVENTS.update_timeline : EVENTS.add_timeline;

          trackEvent(event, {
            project_id: project.id,
            project_type: project.category,
            project_name: project.title,
            event_category: CATEGORIES.projects,
          });
          modal.close();
        },
        failureCb: () => modal.setIsLoading(false),
      };

      store.dispatch(updateProject(project.id, newData, feedbackCb));
    },
  });
  modal.open();
};

export const onManageMilestone = (project, milestone = {}) => {
  const { id, reason, title, due_at } = milestone;

  const modal = createModal({
    body: <MilestoneForm id="milestone-form" milestone={{ id, reason, title, due_at }} />,
    options: {
      title: `${Object.keys(milestone).length !== 0 ? "Update" : "Add"} Milestone`,
      className: "modal-payments",
      ok: `Save ${Object.keys(milestone).length !== 0 ? "Changes" : ""}`,
      form: {
        type: "submit",
        form: `milestone-form`,
      },
    },
    proceed: (data) => {
      modal.setIsLoading(true);
      const newData = { ...data };

      const feedbackCb = {
        successCb: () => modal.close(),
        failureCb: () => modal.setIsLoading(false),
      };

      if (data.reason) {
        const changes = parseChangeLog(["title", "due_at", "reason"], data, milestone);
        delete newData.reason;
        if (changes.length) {
          newData.change_log = changes;
        }
      }
      newData.type = "milestone";
      newData.project = { id: project.id };

      if (Object.keys(milestone).length > 0) {
        store.dispatch(updateProgressEvent(milestone.id, newData, feedbackCb));
      } else {
        const event = {
          ...newData,
          user: { id: getUser().id },
        };
        store.dispatch(createProgressEvent(event, feedbackCb));
      }
    },
  });
  modal.open();
};

export const onManagePlan = (project, plan = {}) => {
  const { id, reason, title, url, download_url } = plan;

  const modal = createModal({
    body: (
      <PlanningForm
        id="planning-form"
        plan={{
          id,
          reason,
          title,
          url: url || download_url,
        }}
      />
    ),
    options: {
      className: "modal-payments",
      title: `${Object.keys(plan).length !== 0 ? "Update" : "Add"} detailed planning`,
      ok: `Save ${Object.keys(plan).length !== 0 ? "Changes" : ""}`,
      form: {
        type: "submit",
        form: `planning-form`,
      },
    },
    proceed: (data) => {
      modal.setIsLoading(false);

      const feedbackCb = {
        successCb: () => modal.close(),
        failureCb: () => modal.setIsLoading(false),
      };

      const newData = { ...data };
      if (newData.reason) {
        const changes = parseChangeLog(["reason", "title", "url"], data, plan);
        delete newData.reason;
        if (changes.length) {
          newData.change_log = changes;
        }
      }

      newData.type = "planning";
      newData.project = { id: project.id };

      if (Object.keys(plan).length > 0) {
        store.dispatch(updateDocument(plan.id, newData, feedbackCb));
      } else {
        store.dispatch(createDocument(newData, feedbackCb));
      }
    },
  });
  modal.open();
};

export const getReportsTableColumns = () => [
  {
    Header: "Due Date",
    accessor: "due_date",
  },
  {
    Header: "Report Name",
    accessor: "report_name",
  },
  {
    Header: "User",
    accessor: "user",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "action",
  },
];

export const reportsTableData = (data) => [
  ...data.map((report) => {
    const row = {
      due_date: report,
      report_name: report,
      status: report,
      user: report,
      action: report,
    };
    return row;
  }),
];
