import React, { useContext, useMemo, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { debounce } from "lodash";
import styled from "styled-components";

import Icon from "../../../components/Icon";
import SectionNav from "../../../components/SectionNav";
import IconButton from "../../../components/IconButton";
import PoolTable from "./PoolTable";
import { isDev } from "../../../utils/auth";
import { StyledSearchInput } from "../../../utils/styles";
import Button from "../../../components/Button";
import useRightNav from "../../../layouts/RightSideNav/useRightNav";
import AdvancedFilterBar from "./AdvancedFilter";
import Navigation from "../../../layouts/RightSideNav/Navigation";
import { FilterContext } from "../../../context/FilterContext";

const initialSkillsFilter = {
  filters: "",
  operation: "",
};
const TalentPool = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [skillsFilter, setSkillsFilter] = useState(initialSkillsFilter);

  const { totalSkills, filter, clearAllFilters } = useContext(FilterContext);

  const { open: openSideBar } = useRightNav();

  const resetSkillsFilter = () => setSkillsFilter(initialSkillsFilter);

  const clearSearch = () => {
    setSearchTerm("");
    setSearchQuery("");
  };

  const handleSubmitSkillsFilter = (reset = false) => {
    if (reset) {
      clearAllFilters();
      return resetSkillsFilter();
    }

    const operation = filter.searchOperation;

    const skillGroups = filter.skillYearsGroups.map((group) => {
      const { groupOperation, skills } = group;
      return {
        group_operation: groupOperation,
        skills: skills.map((skill) => {
          return `${skill.name},${skill.operation},${skill.years}`;
        }),
      };
    });

    return setSkillsFilter({
      filters: skillGroups,
      operation,
    });
  };

  useMemo(() => {
    const operation = filter.searchOperation;

    const skillGroups = filter.skillYearsGroups.map((group) => {
      const { groupOperation, skills } = group;
      return {
        group_operation: groupOperation,
        skills: skills.map((skill) => {
          return `${skill.name},${skill.operation},${skill.years}`;
        }),
      };
    });
    setSkillsFilter({
      filters: skillGroups,
      operation,
    });
  }, []);

  const handleOpenFilters = () => {
    const navSections = [
      {
        component: <AdvancedFilterBar onSubmit={handleSubmitSkillsFilter} />,
        title: "By skills",
      },
    ];
    openSideBar(
      <WrappedNavBar
        borderBottom={true}
        navSections={navSections}
        className="filter-navbar"
        navTitle="Filters"
      />,
      {
        width: "480px",
      },
    );
  };

  const delayedQuery = useMemo(() => debounce((q) => setSearchQuery(q), 500), []);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    delayedQuery(event.target.value);
  };

  if (isDev()) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <StyledSectionNav
        className="d-flex justify-content-between"
        urlExact={false}
        data-testid="talent-pool-nav"
        links={[
          {
            route: "talent-pool/all",
            name: "All Developers",
          },
          {
            route: "talent-pool/vetted",
            name: "Vetted",
          },
          {
            route: "talent-pool/on-assignment",
            name: "On Assignment",
          },
        ]}
        rightActions={
          <div className="d-flex">
            <Button
              className="filter-btn text-dark-blue me-3"
              type="button"
              onClick={handleOpenFilters}
              data-testid="filter-btn"
            >
              <div className="d-flex">
                <Icon className="me-2 mt-1" name="filter-variant" size="xs" width={18} />
                Filters
                {totalSkills > 0 && (
                  <span className="badge bg-primary rounded-circle">
                    {totalSkills > 9 ? "9+" : totalSkills}
                  </span>
                )}
              </div>
            </Button>
            <StyledSearchInput
              data-testid="talent-search"
              type="search"
              value={searchTerm}
              onChange={handleChange}
              autoComplete="off"
              placeholder="Search Talent Pool"
              prepend={<Icon name="search1" />}
              isAppendText={false}
              append={
                <IconButton
                  data-testid="clear-btn"
                  className="search-close-btn bsd-search-ic"
                  name="x-circle"
                />
              }
              appendFunc={clearSearch}
            />
          </div>
        }
      />

      <Switch>
        <Route
          path="/talent-pool/:filter"
          render={({ match: { params } }) => {
            return (
              <PoolTable
                filter={params.filter}
                searchQuery={searchQuery}
                skillsFilter={skillsFilter}
              />
            );
          }}
        />
        <Redirect from="*" to="/talent-pool/all" />
      </Switch>
    </>
  );
};

const WrappedNavBar = styled(Navigation)`
  .title-filters {
    padding-top: 15px;
    button {
      &.active {
        color: #da3451;
        border-bottom: 3px solid #da3451;
        width: initial;
      }
      &:first-child {
        padding-left: 0px;
        margin-right: 0px;
      }
    }
  }
  .nav-content {
    overflow-y: initial;
  }
`;

const StyledSectionNav = styled(SectionNav)`
  .badge {
    width: 20px;
    height: 20px;
    font-size: 10px;
    line-height: 12px;
    margin-left: 5px;
  }
  .filter-links {
    display: flex;
    align-items: center;

    a {
      text-transform: capitalize;
      font-size: 16px;
      line-height: 21px;
      color: #7f8c9f;
    }
  }

  button.btn.filter-btn {
    height: unset;
    line-height: unset;
    background: rgba(143, 155, 179, 0.05);

    :hover,
    :active {
      color: ${(props) => props.theme.colors.gray || "initial"};
    }
  }
`;

export default TalentPool;
