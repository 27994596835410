import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import WiseLogo from "../../assets/images/wise_logo_full.png";
import PayoneerLogo from "../../assets/images/payoneer_logo_full.png";
import GreyLogo from "../../assets/images/grey.png";
import useConnectPayoneer from "../../hooks/useConnectPayoneer";

const AccountOpenSection = ({ title, subtitle }) => {
  const { connectPayoneer } = useConnectPayoneer();

  return (
    <Container>
      <h4>{title}</h4>
      <p>{subtitle}</p>

      <div className="platforms">
        <a href="https://wise.com/" target="_blank" rel="noreferrer noopener">
          <img src={WiseLogo} alt="Wise" />
        </a>

        <button type="button" onClick={connectPayoneer}>
          <img src={PayoneerLogo} alt="Payoneer" />
        </button>

        <a href="https://grey.co/" target="_blank" rel="noreferrer noopener">
          <img src={GreyLogo} alt="Grey" />
        </a>
      </div>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 40px;

  h4 {
    font-size: 18px;
    font-weight: 500;
  }

  p {
    font-size: 14px;
  }

  .platforms {
    display: flex;
    column-gap: 50px;
  }

  button {
    border: none;
    background-color: #fff;
  }
`;

AccountOpenSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default AccountOpenSection;
