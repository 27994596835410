import React, { useState } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Error from "../../../../components/Error";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";

const options = [
  { value: "language", name: " Programming Languages" },
  { value: "framework", name: "Frameworks" },
  { value: "platform", name: "Platforms" },
  { value: "library", name: "Libraries " },
  { value: "storage", name: "Storage Engines" },
  { value: "other", name: " Miscellaneous " },
];

const AddSkillForm = (props) => {
  const { id, proceed } = props;
  const [name, setSkillName] = useState("");
  const [type, setSkillType] = useState(options[0].value);
  const { errors } = useSelector((state) => state.Skill);
  const errorMessage = errors?.create_skill_error || errors?.skill_suggestion_error;
  const handleChange = (e) => {
    setSkillName(e.target.value);
  };
  const handleTypeChange = (e) => {
    setSkillType(e.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    proceed({ name, type });
  };

  return (
    <form id={id} onSubmit={onSubmit} data-testid="add-skill-form">
      {errorMessage && <Error message={errorMessage} />}
      <label
        className="control-label mb-2"
        htmlFor="form-title"
        style={{ fontSize: "14px", width: "100%" }}
      >
        Skill name
      </label>

      <Input
        className="mb-1"
        id="skill_name"
        value={name}
        type="text"
        dataTestId="skill_name"
        placeholder="Enter skill name"
        onChange={handleChange}
        required
      />

      <label
        className="control-label mb-2 mt-3"
        htmlFor="form-title"
        style={{ fontSize: "14px", width: "100%" }}
      >
        Associated skills type
      </label>
      <Select
        id="skill-type"
        name="skill-type"
        onChange={handleTypeChange}
        aria-label="skill-type-input"
        options={options}
        required
      >
        <option value="">Select skill type</option>
      </Select>
    </form>
  );
};

AddSkillForm.propTypes = {
  id: PropTypes.string.isRequired,
  acct_type: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  proceed: PropTypes.func,
};

export default AddSkillForm;
