import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { debounce, isEqual } from "lodash";

import InputGroup from "../InputGroup";
import { getSkills as getSkillsAction } from "../../redux/actions/SkillAction";
import randomString from "../../utils/generateRandomString";
import usePrevious from "../../hooks/usePrevious";
import useDidUpdate from "../../hooks/useDidUpdate";

const SkillSearchInput = ({
  className,
  size,
  prepend,
  placeholder,
  selectedSkills,
  onSelectSkill,
  disabled,
  excludedTests,
}) => {
  const dispatch = useDispatch();

  const [selectionKey] = useState(randomString.generate());
  const [prevKey] = useState(null);

  const [search, setSearch] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);

  const { skills } = useSelector(({ Skill }) => Skill);

  const prevSearch = usePrevious(search);

  const searchKey = useMemo(() => `${selectionKey}-${search}`, [selectionKey, search]);
  const searchedSkills =
    skills[searchKey]?.filter(({ name }) => !excludedTests?.includes(name.toLowerCase())) || [];

  const getSkills = useCallback(
    debounce((filter) => {
      dispatch(getSkillsAction(filter, searchKey, prevKey));
    }, 300),
    [searchKey, prevKey],
  );

  const handleChange = (e) => {
    const skill = e.target.value;
    setSearch(skill);

    setShowSuggestions(!!skill);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // NOTE: This is to prevent the form from submitting when the user presses enter
      // also disables creating of a new skill when the user presses enter
    }
  };

  useDidUpdate(() => {
    if (!isEqual(search, prevSearch)) {
      getSkills({
        search,
      });
    }
  }, [JSON.stringify(selectedSkills), search]);

  return (
    <>
      <InputGroup
        className={className}
        prepend={prepend ? <i className="tg-ic-tag" /> : null}
        size={size}
        placeholder={placeholder}
        selected={selectedSkills}
        value={search}
        onFocus={() => setShowSuggestions(!!search)}
        onChange={handleChange}
        onKeyPress={onKeyPress}
        disabled={disabled}
      />

      {showSuggestions && (
        <Suggestions className="list-group suggestions">
          {searchedSkills.map((searchedSkill) => {
            let exists = false;
            selectedSkills.forEach((selectedSkill) => {
              if (selectedSkill === searchedSkill?.name) {
                exists = true;
              }
            });

            if (exists) return null;

            return (
              <button
                type="button"
                className="list-group-item text-left"
                key={searchedSkill?.id || searchedSkill?.name}
                onClick={(e) => {
                  onSelectSkill(e, searchedSkill.type, searchedSkill.name);
                  setSearch("");
                  setShowSuggestions(false);
                }}
              >
                {searchedSkill.name}
              </button>
            );
          })}
        </Suggestions>
      )}
    </>
  );
};

const Suggestions = withTheme(styled.div`
  position: absolute;
  width: 100%;
  z-index: 1000;
  max-height: 500px;
  overflow-y: scroll;
  border-radius: 4px;
  border: 1px solid #e3e9f2;
  margin-top: 5px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

  button.list-group-item {
    color: #151a30;
    text-align: left;
    border: none;

    :hover {
      color: #fff;
      background-color: #151a30;
    }
  }
`);

SkillSearchInput.defaultProps = {
  placeholder: "Add skills or products",
};

SkillSearchInput.propTypes = {
  className: PropTypes.string,
  selectedSkills: PropTypes.arrayOf(PropTypes.string),
  excludedTests: PropTypes.arrayOf(PropTypes.string),
  onSelectSkill: PropTypes.func,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  prepend: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SkillSearchInput;
