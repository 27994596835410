// function sumInvoiceAmounts(invoices) {
//   return invoices
//     .map((invoice) => {
//       return invoice.total_amount || invoice.amount || 0;
//     })
//     .reduce((total, number) => {
//       return total + Math.round(number * 100) / 100;
//     }, 0);
// }

/**
 * Group invoices with the same reference into one batch object
 * @param {Array} invoices - List of invoices
 * @returns {Array} Returns array of batch objects
 */
export function batchInvoices(invoices) {
  const batches = {};

  invoices?.forEach((invoice) => {
    const ref = invoice.batch_ref;

    if (!batches[ref]) {
      // Initialize the batch object if it doesn't exist
      batches[ref] = {
        ...invoice, // Copy properties from the invoice
        ref, // Set the reference
        invoices: [], // Initialize the invoices array
        total_amount: 0, // Initialize the total amount
      };
    }

    // Add the invoice to the batch
    batches[ref].invoices.push(invoice);

    // Accumulate the total amount
    batches[ref].total_amount += invoice.total_amount || invoice.amount; // Assumes 'amount' is a property of invoice
  });

  // Return the batch objects as an array
  return Object.values(batches);
}
