/* eslint-disable react/jsx-props-no-spreading */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import { debounce, isEqual } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import InputGroup from "../InputGroup";
import useDidUpdate from "../../hooks/useDidUpdate";
import usePrevious from "../../hooks/usePrevious";
import { filterEventProps } from "../../utils/events";
import { filterInputProps } from "../../utils/forms";
import randomString from "../../utils/generateRandomString";
import { fetchProjects } from "../../redux/actions/ProjectActions";

const ProjectSelector = (props) => {
  const {
    className,
    size,
    placeholder,
    selected,
    max,
    onChange,
    value,
    handleProjectObj,
    queryParams,
    selectionKey: key,
  } = props;

  const dispatch = useDispatch();

  const { projects } = useSelector(({ Projects }) => Projects);

  const [selectionKey] = useState(key || randomString.generate());
  const [selectedProject, setSelectedProject] = useState(selected || value);
  const [search, setSearch] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);

  const prevSearch = usePrevious(search);
  const prevSelectedProject = usePrevious(selectedProject);

  const searchKey = () => {
    return `${selectionKey}-${search}`;
  };

  const onSelectProject = (e, project) => {
    e.preventDefault();

    setSearch(project);
    setShowSuggestions(false);
    setSelectedProject(project);
  };

  const getProjects = useMemo(
    () =>
      debounce((filter) => {
        dispatch(fetchProjects(filter, searchKey()));
      }, 300),
    [],
  );

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const project = e.target.value;
      onSelectProject(e, project);
    }
  };

  const handleChange = (e) => {
    const project = e.target.value;
    setSearch(project);
    setShowSuggestions(!!project);
  };

  useEffect(() => {
    setSelectedProject(selected || value);
    setSearch(value || "");
  }, []);

  useDidUpdate(() => {
    if (!isEqual(search, prevSearch) && !isEqual(search, selected) && search !== "") {
      getProjects({
        search,
        show_all: true,
        ...queryParams,
      });
    }
  }, [search]);

  useDidUpdate(() => {
    if (!isEqual(selectedProject, prevSelectedProject) && onChange) {
      onChange(selectedProject);
    }
  }, [selectedProject]);

  return (
    <div data-testid="project-selector" className="tag-input position-relative">
      {(!max || max > selectedProject.length) && (
        <div>
          <InputGroup
            className={className}
            size={size}
            placeholder={placeholder}
            {...filterInputProps(props)}
            {...filterEventProps(props)}
            selected={selectedProject}
            value={search}
            onFocus={() => setShowSuggestions(!!search)}
            onChange={handleChange}
            onKeyPress={onKeyPress}
          />
          {showSuggestions && (
            <Suggestions className="list-group suggestions tunga-projects-selector">
              {Object.values(projects.results).map((project) => {
                if (selectedProject.indexOf(project.name) > -1) {
                  return null;
                }

                return (
                  <button
                    type="button"
                    className="list-group-item text-left"
                    key={project.id}
                    data-testid={`project-${project.id}`}
                    onClick={(e) => {
                      handleProjectObj(project);
                      onSelectProject(e, project.title);
                    }}
                  >
                    {project.title}
                  </button>
                );
              })}
            </Suggestions>
          )}
        </div>
      )}
    </div>
  );
};

const Suggestions = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1000;
  height: 24em;
  overflow-y: auto;

  button.list-group-item {
    color: #da3451;
    text-align: left;

    :hover {
      color: #fff;
      background-color: #da3451;
    }
  }
`;

ProjectSelector.defaultProps = {
  placeholder: "Add skills or products",
  selectionKey: null,
  max: null,
  display: false,
  enableDelete: false,
  handleProjectObj: (f) => f,
};

ProjectSelector.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  selectionKey: PropTypes.string,
  max: PropTypes.number,
  prepend: PropTypes.bool,
  filter: PropTypes.string,
  value: PropTypes.string,
  display: PropTypes.bool,
  onRemove: PropTypes.func,
  enableDelete: PropTypes.bool,
  queryParams: PropTypes.shape({
    stage: PropTypes.string,
    archived: PropTypes.string,
    show_all: PropTypes.bool,
  }),
  handleProjectObj: PropTypes.func, // Handles actual project object with its data instead of title only
};
export default ProjectSelector;
