import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import angryFace from "../../assets/images/reports/noto_angry-face.png";
import beamingSmileyFace from "../../assets/images/reports/noto_beaming-face-with-smiling-eyes.png";
import frowningFace from "../../assets/images/reports/noto_frowning-face.png";
import neutralFace from "../../assets/images/reports/noto_neutral-face.png";
import slightlySmilingFace from "../../assets/images/reports/noto_slightly-smiling-face.png";

const SmileysModal = ({ name, selected, onChange, onBlur, index = "", disabled }) => {
  return (
    <SurveyIcons data-testid={name + index}>
      <label>
        <input
          type="radio"
          name={name}
          value="1"
          checked={selected === "1"}
          onChange={(e) => onChange(e.target.value, index)}
          disabled={disabled}
          onBlur={onBlur}
        />
        <img src={angryFace} alt="Angry face" />
      </label>
      <label>
        <input
          type="radio"
          name={name}
          value="2"
          checked={selected === "2"}
          onChange={(e) => onChange(e.target.value, index)}
          disabled={disabled}
          onBlur={onBlur}
        />
        <img src={frowningFace} alt="frowning face" />
      </label>
      <label>
        <input
          type="radio"
          name={name}
          value="3"
          checked={selected === "3"}
          onChange={(e) => onChange(e.target.value, index)}
          disabled={disabled}
          onBlur={onBlur}
        />
        <img src={neutralFace} alt="Neutral face" />
      </label>
      <label>
        <input
          type="radio"
          name={name}
          value="4"
          checked={selected === "4"}
          onChange={(e) => onChange(e.target.value, index)}
          disabled={disabled}
          onBlur={onBlur}
        />
        <img src={slightlySmilingFace} alt="Slightly smiling face" />
      </label>
      <label>
        <input
          type="radio"
          name={name}
          value="5"
          checked={selected === "5"}
          onChange={(e) => onChange(e.target.value, index)}
          disabled={disabled}
          onBlur={onBlur}
        />
        <img src={beamingSmileyFace} alt="Beaming smiley face" />
      </label>
    </SurveyIcons>
  );
};

const SurveyIcons = styled.div`
  label {
    margin-bottom: 0px;
  }

  input[type="radio"] {
    position: absolute;
    opacity: 0;
  }

  input[type="radio"] + img {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  input + img {
    cursor: pointer;
  }

  input[type="radio"]:hover + img,
  input[type="radio"]:checked + img,
  input[type="radio"]:focus + img {
    opacity: 1;
  }

  input[type="radio"]:disabled:hover + img {
    opacity: 0.3;
  }

  img {
    height: 25px;
    width: 25px;
    margin-right: 17.5px;
    opacity: 0.3;
  }
`;

SmileysModal.defaultProps = {
  disabled: false,
};

/* --------------------------- Component propTypes -------------------------- */
const propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  index: PropTypes.number,
  disabled: PropTypes.bool,
};

SmileysModal.propTypes = propTypes;

export default SmileysModal;
