import React, { useState, useMemo, useContext, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";

import { constructURLQueryFromState, getValueByKeyPath } from "../utils/helpers";
import useURLQuery from "../hooks/useURLQuery";

const defaultPaymentState = {
  invoiceType: ["all"],
  invoiceStatus: ["all"],
  invoiceEmailStatus: ["all"],
  date: { from: null, to: null },
  ordering: "-issued_at",
};

const defaultPayoutState = {
  invoiceStatus: ["all"],
  paymentPlatform: ["all"],
  date: { from: null, to: null },
  ordering: "-issued_at",
};

const InvoiceContext = React.createContext();

const InvoiceProvider = ({ children }) => {
  const history = useHistory();
  const urlQuery = useURLQuery();
  const { type: paymentType } = useParams();

  const [paymentState, setPaymentState] = useState(defaultPaymentState);

  const [payoutState, setPayoutState] = useState(defaultPayoutState);
  const [paymentColumns, setPaymentColumns] = useState({
    main: [],
    extra: [],
  });

  const updatePaymentState = useCallback(
    (key, value) => {
      setPaymentState((prev) => {
        // Split the key into an array of keys to represent the path
        const keys = key.split(".");

        // Clone the previous state to avoid mutating it
        const newState = { ...prev };

        newState[keys[keys.length - 1]] = value;

        // Create a query string from the newState
        const queryArr = constructURLQueryFromState(newState);
        const queryString = new URLSearchParams(queryArr).toString();

        // Update the URL with the query string
        history.replace(`?${queryString}`);

        return newState;
      });
    },
    [setPaymentState, history],
  );

  const updatePayoutState = useCallback(
    (key, value) => {
      setPayoutState((prev) => {
        // Split the key into an array of keys to represent the path
        const keys = key.split(".");

        // Clone the previous state to avoid mutating it
        const newState = { ...prev };

        newState[keys[keys.length - 1]] = value;

        // Create a query string from the newState
        const queryArr = constructURLQueryFromState(newState);
        const queryString = new URLSearchParams(queryArr).toString();

        // Update the URL with the query string
        history.replace(`?${queryString}`);

        return newState;
      });
    },
    [setPayoutState, history],
  );

  const updateColumnVisibility = useCallback(
    (columnLKey, name, visible) => {
      const colSub = getValueByKeyPath(columnLKey, paymentColumns).map((col) => {
        if (col.name === name) {
          return { ...col, visible };
        }

        return col;
      });

      setPaymentColumns((prev) => ({
        ...prev,
        [columnLKey]: colSub,
      }));
    },
    [setPaymentColumns, paymentColumns],
  );

  const value = useMemo(
    () => ({
      paymentState,
      payoutState,
      paymentColumns,
      setPaymentColumns,
      updatePaymentState,
      updatePayoutState,
      updateColumnVisibility,
    }),
    [paymentState, payoutState, paymentColumns],
  );

  useEffect(() => {
    const status = urlQuery.get("status");
    const emailStatus = urlQuery.get("emailStatus");
    const type = urlQuery.get("type");
    const from = urlQuery.get("from");
    const to = urlQuery.get("to");
    const ordering = urlQuery.get("ordering");
    const paymentPlatform = urlQuery.get("payment-platform");

    if (paymentType === "in") {
      setPaymentState({
        invoiceType: type?.split(",") || defaultPaymentState.invoiceType,
        invoiceStatus: status ? [status] : defaultPaymentState.invoiceStatus,
        invoiceEmailStatus: emailStatus ? [emailStatus] : defaultPaymentState.invoiceEmailStatus,
        date: {
          from: from || defaultPaymentState.date.from,
          to: to || defaultPaymentState.date.to,
        },
        ordering: ordering || defaultPaymentState.ordering,
      });
    } else {
      setPayoutState({
        invoiceStatus: status ? [status] : defaultPayoutState.invoiceStatus,
        paymentPlatform: paymentPlatform ? [paymentPlatform] : defaultPayoutState.paymentPlatform,
        date: {
          from: from || defaultPayoutState.date.from,
          to: to || defaultPayoutState.date.to,
        },
        ordering: ordering || defaultPayoutState.ordering,
      });
    }
  }, []);

  return <InvoiceContext.Provider value={value}>{children}</InvoiceContext.Provider>;
};

const proptypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  columns: PropTypes.shape({}),
};

InvoiceProvider.propTypes = proptypes;

const useInvoices = () => {
  const context = useContext(InvoiceContext);
  return context;
};

export { InvoiceContext, InvoiceProvider, useInvoices };
