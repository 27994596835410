// eslint-disable-next-line no-unused-vars
import React from "react";

import PropTypes from "prop-types";
import { createConfirmation } from "react-confirm";
import { createRoot } from "react-dom/client";

import DynamicModal from "../components/DynamicModal";
import GenericModal from "../components/GenericModal";
import AlertDialogue from "../components/AlertDialogue";

let modalRoot = null;

export const confirm = createConfirmation(GenericModal);

export const openGenericModal = (modalContent, modalHeader, options = {}) => {
  return confirm({
    modalHeader,
    modalContent,
    options: { ...options },
  });
};

const safeClassNameOptions = (className, options) => {
  const safeOptions = options || {};
  if (safeOptions.className) {
    safeOptions.className = `${className} ${safeOptions.className}`;
  } else {
    safeOptions.className = className;
  }
  return safeOptions;
};

export const openConfirm = ({
  message = null,
  title = "",
  canClose = true,
  options = { ok: "Yes", cancel: "Cancel" },
  header = null,
} = {}) => {
  return openGenericModal(message, header, {
    mustRespond: !canClose,
    title,
    ...safeClassNameOptions("modal-confirm", options),
  });
};

export const openModal = ({
  body = null,
  title = "",
  canClose = true,
  options = null,
  header = null,
  hideActions = false,
} = {}) => {
  return openGenericModal(body, header, {
    hideActions,
    mustRespond: !canClose,
    title,
    ...(options || {}),
  });
};

export const createModal = ({
  body = null,
  canClose = true,
  header = null,
  proceed,
  beforeClose,
  options = {
    className: "",
    title: "",
    hideActions: false,
    style: {},
    size: false,
    hideBackdrop: false,
    hideCancel: false,
    cancel: "",
    ok: "",
    form: {},
  },
} = {}) => {
  let isLoading = false;

  const unmount = () => {
    if (modalRoot) {
      modalRoot?.unmount();
      modalRoot = null;
    }
  };

  const closeModal = () => {
    if (beforeClose) beforeClose();
    setTimeout(() => {
      unmount();
    }, 1000);
  };

  const renderModal = () => {
    const props = {
      isLoading,
      modalHeader: header,
      modalContent: body,
      cancel: closeModal,
      proceed,
      options: { ...options, mustRespond: !canClose },
    };

    // eslint-disable-next-line react/jsx-props-no-spreading
    const modalComponent = <DynamicModal {...props} />;

    if (modalRoot) {
      modalRoot.render(modalComponent);
    } else {
      modalRoot = createRoot(document.createElement("modal-root"));
      modalRoot.render(modalComponent);
    }
  };

  const mount = () => {
    renderModal();
    return {
      closeModal,
      setIsLoading: (loading) => {
        isLoading = loading;
        renderModal();
      },
    };
  };

  const open = () => {
    mount();
  };

  return {
    open,
    close: () => unmount(),
    setIsLoading: (loading) => {
      isLoading = loading;
      renderModal();
    },
    modal: null,
  };
};

export const openAlert = ({ body = null, canClose = false, options = null }) => {
  return openGenericModal(body, null, {
    hideCancel: true,
    mustRespond: !canClose,
    ...safeClassNameOptions("modal-alert", options),
  });
};

export const openAlertModal = (message, isError = false) => {
  const alertConfig = isError
    ? { className: "error-dailogue", iconClass: "bs-x-danger" }
    : { className: "alert-dailogue", iconClass: "" };

  openAlert({
    body: <AlertDialogue msg={message} iconClass={alertConfig.className} />,
    canClose: true,
    options: {
      className: alertConfig.className,
      hideActions: true,
      hideBackdrop: true,
    },
  });
};

createModal.propTypes = {
  body: PropTypes.node,
  canClose: PropTypes.bool,
  header: PropTypes.node,
  proceed: PropTypes.func.isRequired,
  options: PropTypes.shape({
    className: PropTypes.string,
    title: PropTypes.string,
    hideActions: PropTypes.bool,
    style: PropTypes.shape({}),
    size: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    hideBackdrop: PropTypes.bool,
    hideCancel: PropTypes.bool,
    cancel: PropTypes.string,
    ok: PropTypes.string,
    form: PropTypes.shape({}),
  }),
};
