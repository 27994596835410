import React from "react";

import Icon from "../../../../components/Icon";

const OpportunityClosedAlert = () => {
  return (
    <div className="application-closed alert alert-danger d-flex align-items-center">
      <Icon className="me-2" name="warning" />
      This opportunity is closed for new applications
    </div>
  );
};

export default OpportunityClosedAlert;
