/* eslint-disable react/jsx-props-no-spreading */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import InputGroup from "../InputGroup";
import Progress from "../Progress";
import useDidUpdate from "../../hooks/useDidUpdate";
import usePrevious from "../../hooks/usePrevious";
import { filterEventProps } from "../../utils/events";
import { filterInputProps } from "../../utils/forms";
import { SEARCH_INITIAL_LIST } from "../../configs/constants/global";

const SearchSelector = (props) => {
  const {
    ref,
    className,
    size,
    placeholder,
    selected,
    max,
    onChange,
    value,
    handleSelectObj,
    disabled,
    itemList,
    setItemList,
    loading,
    actionDispatch,
    showSuggestions: _showSuggestions,
  } = props;
  const [selectedItem, setSelectedItem] = useState(selected || value);
  const [search, setSearch] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);

  const prevSearch = usePrevious(search);
  const prevSelectedItem = usePrevious(selectedItem);

  const onSelectedItem = (e, project) => {
    e.preventDefault();

    setSearch(project);
    setShowSuggestions(false);
    setSelectedItem(project);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const project = e.target.value;
      onSelectedItem(e, project);
    }
  };

  const handleChange = (e) => {
    const project = e.target.value;
    setSearch(project);
    setShowSuggestions(!!project);
  };

  useEffect(() => {
    setSelectedItem(selected || value);
    setSearch(value || "");
  }, []);

  useDidUpdate(() => {
    if (!isEqual(search, prevSearch) && !isEqual(search, selected)) {
      actionDispatch(search);
    }

    if (search !== "") {
      setItemList(JSON.parse(localStorage.getItem(SEARCH_INITIAL_LIST)));
    }
  }, [search]);

  useDidUpdate(() => {
    if (!isEqual(selectedItem, prevSelectedItem) && onChange) {
      onChange(selectedItem);
    }
  }, [selectedItem]);

  return (
    <div ref={ref} data-testid="search-selector" className="tag-input position-relative">
      {(!max || max > selectedItem.length) && (
        <div>
          <div className="position-relative">
            <InputGroup
              className={className}
              size={size}
              placeholder={placeholder}
              {...filterInputProps(props)}
              {...filterEventProps(props)}
              selected={selectedItem}
              value={search}
              onFocus={() => setShowSuggestions(!!search)}
              onChange={handleChange}
              onKeyPress={onKeyPress}
              disabled={disabled}
            />

            {loading && (
              <ProgressWrapper>
                <Progress />
              </ProgressWrapper>
            )}
          </div>

          {(_showSuggestions || showSuggestions) && Object.values(itemList).length !== 0 && (
            <Suggestions className="list-group suggestions tunga-projects-selector">
              {Object.values(itemList).map((item) => {
                if (selectedItem?.indexOf(item.id) > -1) {
                  return null;
                }

                return (
                  <button
                    type="button"
                    className="list-group-item text-left"
                    key={item.id}
                    data-testid={`search-result-${item.id}`}
                    onClick={(e) => {
                      handleSelectObj(item);
                      onSelectedItem(e, item.title);
                    }}
                  >
                    {item.title}
                  </button>
                );
              })}
            </Suggestions>
          )}
        </div>
      )}
    </div>
  );
};

const Suggestions = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1000;
  max-height: 24em;
  overflow-y: auto;

  button.list-group-item {
    color: #da3451;
    text-align: left;

    :hover {
      color: #fff;
      background-color: #da3451;
    }
  }
`;

const ProgressWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
`;

SearchSelector.defaultProps = {
  placeholder: "Add skills or products",
  selectionKey: null,
  max: null,
  display: false,
  enableDelete: false,
  handleSelectObj: (f) => f,
};

SearchSelector.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  selectionKey: PropTypes.string,
  max: PropTypes.number,
  prepend: PropTypes.bool,
  filter: PropTypes.string,
  value: PropTypes.string,
  display: PropTypes.bool,
  onRemove: PropTypes.func,
  enableDelete: PropTypes.bool,
  queryParams: PropTypes.shape({
    stage: PropTypes.string,
    archived: PropTypes.string,
    show_all: PropTypes.bool,
  }),
  ref: PropTypes.shape({}),
  itemList: PropTypes.arrayOf(PropTypes.shape({})),
  handleSelectObj: PropTypes.func, // Handles actual object with its data instead of title only
  actionDispatch: PropTypes.func,
  setItemList: PropTypes.func,
  disabled: PropTypes.bool,
  showSuggestions: PropTypes.bool,
  loading: PropTypes.bool,
};
export default SearchSelector;
