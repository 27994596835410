import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonDropdown } from "reactstrap";
import PropTypes from "prop-types";

import Toggle from "./Toggle";
import Menu from "./Menu";
import FilterHead from "./FilterHead";
import RadioBoxLabel from "./RadioBoxLabel";
import { useInvoices } from "../../context/InvoiceContext";
import { arrayToObject } from "../../utils/helpers";
import {
  canFilterByArchived,
  canFilterByOverdue,
  canFilterByPaid,
  canFilterByPending,
} from "../../utils/auth";

const StatusFilter = ({ paymentType = "in" }) => {
  const isPayments = paymentType === "in";

  const { paymentState, payoutState, updatePaymentState, updatePayoutState } = useInvoices();

  const { invoiceStatus } = isPayments ? paymentState : payoutState;

  const [dropdownOpen, setDropdown] = useState(false);
  const [filters, setFilters] = useState(["all"]);

  const keyMaps = arrayToObject([
    canFilterByPaid() && {
      "paid": "Paid",
    },
    canFilterByPending() && {
      "pending": "Pending",
    },
    canFilterByOverdue() && {
      "overdue": "Overdue",
    },
    canFilterByArchived() && {
      "archived": "Archived",
    },
  ]);

  const toggle = () => {
    setDropdown(!dropdownOpen);
  };

  const onDoneClick = () => {
    if (isPayments) {
      updatePaymentState("invoiceStatus", filters);
    } else {
      updatePayoutState("invoiceStatus", filters);
    }
    toggle();
  };

  useEffect(() => {
    setFilters(invoiceStatus);
  }, [JSON.stringify(invoiceStatus)]);

  return (
    <Wrapper className="">
      <ButtonDropdown className="add-btn" isOpen={dropdownOpen} toggle={toggle}>
        <Toggle label="Status" />

        <Menu label="Invoice Status" minWidth="266px" onClick={onDoneClick}>
          <FilterHead label="Filter status" />

          <div className="options">
            {Object.entries(keyMaps).map(([key, label], index) => {
              return (
                <RadioBoxLabel
                  key={index}
                  label={label}
                  value={key}
                  checked={filters.includes(key)}
                  onChange={() => setFilters([key])}
                />
              );
            })}
          </div>
        </Menu>
      </ButtonDropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .options {
    padding: 0 17px;
  }

  hr {
    margin: 8px 0;
    opacity: 1;
    background-color: #edf1f7;
  }
`;

StatusFilter.propTypes = {
  paymentType: PropTypes.string,
};

export default StatusFilter;
