/* eslint-disable react/jsx-props-no-spreading */
/* -------------------------------------------------------------------------- */
/*                            Exxternal Dependencies                           */
/* -------------------------------------------------------------------------- */
import PropTypes from "prop-types";
import React from "react";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import Button from "./Button";
import Progress from "./Progress";

const LoadMore = ({
  children,
  onLoadMore,
  isLoadingMore,
  bg,
  dataTestId,
  hasMore = true,
  variant,
  size = "sm",
  type = "button",
}) => {
  return isLoadingMore ? (
    <Progress />
  ) : hasMore ? (
    <div className={`${type === "button" ? "text-center" : "text-link"}`}>
      <Button
        {...(variant ? { variant } : {})}
        {...(size ? { size } : {})}
        size="sm"
        onClick={onLoadMore}
        className="btn-primary"
        data-testid={dataTestId}
        style={{ background: !bg ? "none" : "", boxShadow: !bg ? "none" : "" }}
      >
        {children || "Load More"}
      </Button>
    </div>
  ) : null;
};

LoadMore.propTypes = {
  children: PropTypes.string,
  hasMore: PropTypes.bool,
  isLoadingMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  size: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  dataTestId: PropTypes.string,
  bg: PropTypes.bool,
};

export default LoadMore;
