import React from "react";
import { useFormik } from "formik";
import { FormGroup } from "reactstrap";
import PropTypes from "prop-types";

import TextArea from "../../../../components/TextArea";
import { StyledForm, WordCount } from "../../../../utils/styles";

const wordCount = 450;

const BioForm = ({ id, bio, proceed }) => {
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      bio: bio || "",
    },
    onSubmit: (formData) => {
      proceed({
        ...formData,
      });
    },
  });

  return (
    <StyledForm id={id} data-testid="bio-form" onSubmit={form.handleSubmit}>
      <FormGroup className="position-relative">
        <TextArea
          id="bio"
          name="bio"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder="Type your bio here..."
          value={form.values.bio}
          maxLength={wordCount}
        />

        <WordCount className="position-absolute">{wordCount - form.values.bio.length}</WordCount>
      </FormGroup>
    </StyledForm>
  );
};

BioForm.propTypes = {
  bio: PropTypes.string,
  proceed: PropTypes.func,
  id: PropTypes.string,
};

export default BioForm;
