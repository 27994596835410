/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled, { withTheme, createGlobalStyle } from "styled-components";
import PropTypes from "prop-types";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import Icon from "../components/Icon";
import { addPropsToChildren } from "../utils/children";

const GlobalStyle = createGlobalStyle`
  body {
    background: #f8f8f8;
  }
`;

const CreateLayout = ({ children }) => {
  const [wizard, setWizard] = useState({});

  const renderChildren = () => {
    return addPropsToChildren(children, { setWizard });
  };

  return (
    <Wrapper>
      <GlobalStyle />
      <div className="layout-navbar">
        <NavWizard
          showIndicator={wizard.steps > 1}
          percentage={(wizard.current / wizard.steps) * 100}
        >
          <div className="nav-content">
            <span className="layout-close">
              <Link className="d-flex align-items-center" to="/dashboard">
                <Icon name="x-circle" />
                Close
              </Link>
            </span>

            <h5>{wizard.title || ""}</h5>

            <span className="layout-steps">
              {wizard.steps !== 1 ? `Step ${wizard.current} of ${wizard.steps}` : null}
            </span>
          </div>
          <div className="nav-wizard-indicator" />
        </NavWizard>
      </div>
      <div className="content">{renderChildren()}</div>
    </Wrapper>
  );
};

const Wrapper = withTheme(styled.div`
  --nav--height: 73px;
  display: grid;
  grid-template-rows: var(--nav--height) 1fr;
  height: 100vh;

  &:hover::-webkit-scrollbar {
    visibility: visible;
  }

  &::-webkit-scrollbar {
    visibility: hidden;
  }

  ${(props) => props.theme.mixins.scrollbar}

  .layout-navbar {
    display: block;
    background: #fff;
    box-shadow: 0 2px 4px 0 hsla(0, 0%, 80%, 0.5);
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
  }

  .content {
    overflow-y: scroll;
    margin-top: var(--nav--height);
    height: calc(100vh - var(--nav--height));
  }
`);

const NavWizard = withTheme(styled.div`
  .nav-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 40px;

    h5 {
      font-weight: 500;
      font-size: ${(props) => props.theme.functions.pxToRem(20)};
      line-height: ${(props) => props.theme.functions.pxToRem(24)};
      text-align: center;
      color: ${(props) => props.theme.colors["text-black"]};
      margin: 0;
    }

    .layout-close a,
    .layout-steps {
      font-weight: 500;
      font-size: ${(props) => props.theme.functions.pxToRem(16)};
      line-height: ${(props) => props.theme.functions.pxToRem(19)};
      color: ${(props) => props.theme.colors.gray};
    }

    .layout-close a {
      text-decoration: none;
      i {
        font-size: ${(props) => props.theme.functions.pxToRem(20)};
        vertical-align: bottom;
        margin-right: 10px;
      }
    }
  }
  .nav-wizard-indicator:after {
    content: "";
    display: block;
    width: ${(props) => props.percentage}%;
    ${(props) => (props.showIndicator ? props.theme.mixins.borderBottom : "")}
  }
`);

/* --------------------------- Component proptypes -------------------------- */
const proptypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

CreateLayout.propTypes = proptypes;

export default CreateLayout;
