/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useRef, useEffect } from "react";
import styled from "styled-components";

/* -------------------------- Internel Dependencies ------------------------- */
import useRightNav from "./useRightNav";

/* --------------------------- Component proptypes -------------------------- */

const RightSideNav = () => {
  const wrapperRef = useRef(null);

  const {
    status: { collapsed, content, style, overlayVisible, closeOnClickOutside },
    close,
  } = useRightNav();

  const handleClickOutside = (event) => {
    if (wrapperRef && !wrapperRef.current?.contains(event.target)) {
      event.preventDefault();
      event.stopPropagation();
      close();
    }
  };

  const removeClickListener = () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };

  useEffect(() => {
    if (!collapsed && closeOnClickOutside) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      removeClickListener();
    }

    return () => {
      removeClickListener();
    };
  }, [collapsed, closeOnClickOutside]);

  return (
    <Wrapper className={`nav-${collapsed ? "closed" : "open"}`}>
      {overlayVisible && <div className="overlay open" />}
      <div ref={wrapperRef} style={style} className="main">
        {content}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(21, 26, 48, 0);
    transition: 0.5s;
    z-index: 1030;
    position: fixed;

    &.open {
      display: none;
      background: rgba(21, 26, 48, 0.8);
    }
  }

  .main {
    width: 336px;
    background: white;
    position: fixed;
    z-index: 1030;
    top: 0;
    bottom: 0;
    transition: 0.5s;
    right: -480px;
    overflow-y: scroll;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 11%);
    border-left: 1px solid #edf1f7;

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &.nav-open {
    .main {
      right: 0;
    }
    .overlay {
      display: block;
    }
  }
`;

export default RightSideNav;
