import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import SummaryPlaceholder from "../../../../components/SummaryPlaceholder/SummaryPlaceholder";
import TimeSheetList from "./TimeSheetList";
import TimeSheetSummary from "./TimeSheetSummary";
import RejectTimeSheetForm from "../../projects/opportunity/RejectTimeSheetForm";
import {
  approveRejectEntries,
  listTimeSheetsMonthlyEntries,
  listTimeSheetsYearlyEntries,
} from "../../../../redux/actions/TimeSheetActions";
import { createModal, openAlertModal, openConfirm } from "../../../../utils/modals";
import { isAdminOrCMOrClientOrCSO } from "../../../../utils/auth";

const TimeSheet = ({ currentMonth, currentYear }) => {
  const dispatch = useDispatch();

  const { user: userObj } = useSelector(({ Auth }) => Auth);
  const { monthView, isSaved, errors } = useSelector(({ TimeSheetEntries }) => TimeSheetEntries);
  const { project } = useSelector(({ Projects }) => Projects);

  const onApproveTimeSheet = (userId) => {
    openConfirm({
      message: <div>Are you sure you want to approve this timesheet?</div>,
      title: "Approve Timesheet",
      canClose: true,
      options: {
        ok: "Approve",
        cancel: "Cancel",
        dismissAsCancel: true,
        size: "sm",
      },
    })
      .then(() => {
        dispatch(
          approveRejectEntries(
            {
              projectId: project.id,
              userId,
              yearMonth: currentMonth,
              action: "approve",
            },
            {},
          ),
        );
      })
      .catch(() => {});
  };

  const onRejectTimeSheet = (userId) => {
    const modal = createModal({
      body: <RejectTimeSheetForm id="reject-timesheet-form" />,
      canClose: true,
      options: {
        title: "Reject Timesheet",
        className: "modal-payments",
        ok: "Reject",
        cancel: "Cancel",
        size: "md",
        form: {
          type: "submit",
          form: "reject-timesheet-form",
        },
      },
      proceed: ({ reason }) => {
        modal.setIsLoading(true);
        const feedbackCb = {
          successCb: () => {
            modal.close();
          },
          failureCb: () => modal.setIsLoading(false),
        };

        dispatch(
          approveRejectEntries(
            {
              projectId: project.id,
              userId,
              yearMonth: currentMonth,
              action: "reject",
              reason,
            },
            feedbackCb,
          ),
        );
      },
    });
    modal.open();
  };

  const renderTimeSheet = () => {
    if (isAdminOrCMOrClientOrCSO()) {
      return (
        <>
          {monthView?.results.length === 0 ? (
            <SummaryPlaceholder className="page-filters-pay-summary" description="No data found." />
          ) : (
            <>
              <TimeSheetSummary />

              {monthView?.results?.map(({ expected_hours, user, total, status }) => {
                return (
                  <TimeSheetList
                    key={user.id}
                    expectedHours={+expected_hours ?? 0}
                    user={user}
                    total={total}
                    status={status}
                    currentMonth={currentMonth}
                    projectId={project.id}
                    onApproveTimeSheet={onApproveTimeSheet}
                    onRejectTimeSheet={onRejectTimeSheet}
                  />
                );
              })}

              {monthView?.archived?.map(({ expected_hours, user, total, status }) => {
                return (
                  <TimeSheetList
                    archived
                    key={user.id}
                    expectedHours={+expected_hours ?? 0}
                    user={user}
                    total={total}
                    status={status}
                    currentMonth={currentMonth}
                    projectId={project.id}
                    onApproveTimeSheet={onApproveTimeSheet}
                    onRejectTimeSheet={onRejectTimeSheet}
                  />
                );
              })}
            </>
          )}
        </>
      );
    }

    const isArchived = monthView?.archived?.length !== 0;
    const timesheetObj = isArchived ? monthView?.archived?.[0] : monthView.results?.[0];

    const { expected_hours = 0, total = 0, status = "" } = timesheetObj || {};

    return (
      <TimeSheetList
        archived={isArchived}
        key={userObj.id}
        expectedHours={expected_hours}
        user={userObj}
        total={total}
        status={status}
        currentMonth={currentMonth}
        projectId={project.id}
        onApproveTimeSheet={onApproveTimeSheet}
        onRejectTimeSheet={onRejectTimeSheet}
      />
    );
  };

  useEffect(() => {
    if (isSaved?.approveReject) {
      openAlertModal(isSaved.message);
      dispatch(listTimeSheetsMonthlyEntries(project.id, currentMonth, ""));
    }

    if (isSaved?.updateTimeSheetEntry) {
      dispatch(listTimeSheetsMonthlyEntries(project.id, currentMonth, "", true));
      dispatch(listTimeSheetsYearlyEntries(project.id, currentYear, ""));
    }

    if (errors?.approveReject) {
      openAlertModal(errors.approveReject, true);
    }
  }, [isSaved?.approveReject, isSaved?.updateTimeSheetEntry, errors?.approveReject]);

  return <>{renderTimeSheet()}</>;
};

TimeSheet.propTypes = {
  currentMonth: PropTypes.string.isRequired,
  currentYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TimeSheet;
