/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import {
  TungaDevProfileOverview,
  TungaDevSkillsToolsSection,
  TungaDevTestResult,
  TungaEducationSection,
  TungaProjectSection,
  TungaTabSwitch,
  TungaWorkExperience,
} from "@tunga/tunga-components-react";

import { CATEGORIES, EVENTS } from "../../../analytics/events";
import { trackEvent } from "../../../analytics/segment";
/* -------------------------------------------------------------------------- */
/*                             Internal Dependency                            */
/* -------------------------------------------------------------------------- */
import Progress from "../../../components/Progress";
import { skillsTitleDictionary } from "../../../configs/constants/global";
import { useTalentProfile } from "../../../context/TalentProfileContext";
import useDidUpdate from "../../../hooks/useDidUpdate";
import usePrevious from "../../../hooks/usePrevious";
import {
  updateDevProfile,
  updateEducation,
  updateProject,
  updateWork,
} from "../../../redux/actions/ProfileActions";
import { devProfile } from "../../../redux/actions/ProjectActions";
import { skillsToArray } from "../../../utils/helpers";
import { createModal, openAlertModal } from "../../../utils/modals";
import { SyncRecruitee } from "../dashboard/Icons";
import BioForm from "../settings/modals/BioForm";
import EducationForm from "../settings/modals/EducationForm";
import ProjectForm from "../settings/modals/ProjectForm";
import SkillsetForm from "../settings/modals/SkillsetForm";
import WorkForm from "../settings/modals/WorkForm";
import MetaTags from "../../../components/MetaTags";

const TalentProfile = () => {
  const { talentId } = useParams();

  const { id: userId, display_name } = useSelector(({ Auth }) => Auth.user);
  const { isSaved } = useSelector(({ Profile: UserProfile }) => UserProfile);
  const { devProfiles } = useSelector(({ Projects }) => Projects);
  const { data } = devProfiles;

  const prevIsSaved = usePrevious(isSaved);

  const dispatch = useDispatch();

  const { isReSyncing, enableEditAction, setEnableEditAction } = useTalentProfile();

  const [isSubmitting, setIsSubmitting] = useState({
    bio: false,
    skills: false,
    work: false,
    project: false,
    education: false,
  });

  const events = {
    user_id: userId,
    username: display_name,
    event_category: CATEGORIES.settings,
  };

  const fetchDevProfile = () => devProfile(talentId)(dispatch);

  const submissionStatus = (key, value) =>
    setIsSubmitting((prev) => ({
      ...prev,
      [key]: value,
    }));

  const skillsData = useMemo(() => {
    const skillsDetails = Object.entries(data?.skills_details || {});

    const sData = skillsDetails.map((entries) => {
      const [key, _skills] = entries;

      const skillArray = _skills.map(({ name, years_of_experience }) => ({
        name,
        yearsOfExperience: years_of_experience,
      }));

      return {
        title: skillsTitleDictionary[key],
        skills: skillArray,
      };
    });

    return sData;
  }, [data]);

  const educationData = useMemo(() => {
    const mappedData =
      data?.education.map((education) => {
        const {
          start_month_display,
          start_year,
          institution,
          award,
          end_month_display,
          end_year,
          details,
          id,
        } = education;
        return {
          startMonth: start_month_display,
          startYear: start_year,
          institution,
          award,
          endMonth: end_month_display,
          endYear: end_year,
          details,
          id,
          rawEducation: {
            ...education,
          },
        };
      }) || [];
    return mappedData;
  }, [data?.education]);

  const experienceData = useMemo(
    () =>
      data?.work_experience.map((work) => {
        const {
          start_month_display,
          start_year,
          details,
          company,
          position,
          end_month_display,
          end_year,
          location,
          skills,
          id,
        } = work;

        return {
          startMonth: start_month_display,
          startYear: start_year,
          details,
          company,
          position,
          endMonth: end_month_display,
          endYear: end_year,
          location,
          skills,
          id,
          rawWork: {
            ...work,
          },
        };
      }) || [],
    [data?.work_experience],
  );

  const projectData = useMemo(
    () =>
      data?.projects.map((project) => {
        const {
          start_month_display,
          start_year,
          category,
          title,
          avatar_url,
          details,
          skills,
          project_link,
          repository_link,
          end_month_display,
          end_year,
          is_tunga_project,
        } = project;
        return {
          startMonth: start_month_display,
          startYear: start_year,
          project_link,
          repository_link,
          avatarUrl:
            avatar_url || "https://thumbs.dreamstime.com/z/website-url-background-5988824.jpg",
          category,
          skills,
          details: details || "N/A",
          name: title || "N/A",
          endMonth: end_month_display,
          endYear: end_year,
          is_tunga_project,
          rawProject: {
            ...project,
          },
        };
      }) || [],
    [data],
  );

  /* istanbul ignore next */
  const onDeleteExperience = (id, key) => {
    const payload = {
      user: { id: talentId },
    };

    const feedbackCb = {
      successCb: () => fetchDevProfile(),
      failureCb: () => null,
    };

    switch (key) {
      case "work":
        updateWork(id, payload, true)(dispatch, feedbackCb);
        break;

      case "project":
        updateProject(id, {}, true)(dispatch, feedbackCb);
        break;

      case "education":
        updateEducation(id, payload, true)(dispatch, feedbackCb);
        break;

      default:
        break;
    }
  };

  const onUpdateBio = (e) => {
    e.preventDefault();

    const modal = createModal({
      body: <BioForm bio={data.bio} id="bio-form" />,
      canClose: true,
      header: null,
      options: {
        title: `Bio`,
        ok: `Save changes`,
        form: {
          type: "submit",
          form: `bio-form`,
        },
      },
      proceed: ({ bio }) => {
        modal.setIsLoading(true);
        submissionStatus("bio", true);

        const feedbackCb = {
          successCb: () => {
            submissionStatus("bio", false);

            modal.close();
            fetchDevProfile();
            openAlertModal("Bio updated successfully");

            trackEvent(EVENTS.update_bio, events);
          },
          failureCb: () => {
            modal.setIsLoading(false);
            submissionStatus("bio", false);
          },
        };

        const payload = {
          bio,
        };

        dispatch(updateDevProfile(talentId || null, payload, feedbackCb));
      },
    });

    modal.open();
  };

  const onUpdateSkillSets = (e) => {
    e.preventDefault();

    const skills = skillsToArray(data.skills_details);

    const modal = createModal({
      body: <SkillsetForm skills={skills} id="skillset-form" />,
      canClose: true,
      header: null,
      options: {
        title: `Edit Skillset`,
        canClose: true,
        ok: `Save changes`,
        form: {
          type: "submit",
          form: `skillset-form`,
        },
      },
      proceed: (formData) => {
        modal.setIsLoading(true);
        submissionStatus("skills", true);

        const feedbackCb = {
          successCb: () => {
            submissionStatus("skills", false);

            modal.close();
            fetchDevProfile();
            openAlertModal("Skills updated successfully");

            trackEvent(EVENTS.update_bio, events);
          },
          failureCb: () => {
            modal.setIsLoading(false);
            submissionStatus("skills", false);
          },
        };

        const payload = {
          skills: formData.skills,
        };

        dispatch(updateDevProfile(talentId || null, payload, feedbackCb));
      },
    });
    modal.open();
  };

  const onAddProject = (e, selectedProject = {}) => {
    e.preventDefault();

    const { rawProject: project = {} } = selectedProject || { rawProject: {} };

    const modal = createModal({
      body: <ProjectForm id="project-form" project={project} />,
      canClose: true,
      header: null,
      options: {
        title: `${project.id ? "Edit" : "Add New"} Project`,
        ok: `${project.id ? "Edit" : "Add"} Project`,
        form: {
          type: "submit",
          form: `project-form`,
        },
      },
      proceed: (formData) => {
        modal.setIsLoading(true);

        submissionStatus("project", true);

        const payload = {
          user: { id: talentId },
          ...formData,
        };

        const feedbackCb = {
          successCb: () => {
            modal.close();
            fetchDevProfile();
            openAlertModal("Project Experience saved successfully");
            trackEvent(EVENTS.update_projects, events);
          },
          failureCb: () => {
            modal.setIsLoading(false);
            submissionStatus("project", false);
          },
        };

        updateProject(project?.id || "", payload)(dispatch, feedbackCb);
      },
    });

    modal.open();
  };

  const onAddWork = (e, selectedWork = {}) => {
    e.preventDefault();

    const { rawWork: work = {} } = selectedWork || { rawWork: {} };

    const modal = createModal({
      body: <WorkForm id="work-form" work={work} />,
      canClose: true,
      header: null,
      options: {
        title: `${work.id ? "Edit" : "Add New"} Work Experience`,
        ok: `${work.id ? "Edit" : "Add"} Work Experience`,
        form: {
          type: "submit",
          form: `work-form`,
        },
      },
      proceed: (formData) => {
        modal.setIsLoading(true);

        const payload = {
          user: { id: talentId },
          ...formData,
        };

        submissionStatus("work", true);

        const feedbackCb = {
          successCb: () => {
            modal.close();
            fetchDevProfile();
            openAlertModal("Work Experience saved successfully");

            trackEvent(EVENTS.update_work_experience, events);
          },
          failureCb: () => {
            modal.setIsLoading(false);
            submissionStatus("work", false);
          },
        };

        updateWork(work?.id || null, payload)(dispatch, feedbackCb);
      },
    });
    modal.open();
  };

  const onAddEducation = (e, selectedEducation = {}) => {
    e.preventDefault();

    const { rawEducation: education = {} } = selectedEducation || { rawEducation: {} };

    const modal = createModal({
      body: <EducationForm id="educational-form" education={education} />,
      canClose: true,
      header: null,
      options: {
        title: `${education.id ? "Edit" : "Add New"} Education`,
        ok: `${education.id ? "Edit" : "Add"} Education`,
        form: {
          type: "submit",
          form: `educational-form`,
        },
      },
      proceed: (formData) => {
        modal.setIsLoading(true);

        const payload = {
          user: { id: talentId },
          ...formData,
        };

        submissionStatus("education", true);

        const feedbackCb = {
          successCb: () => {
            modal.close();
            fetchDevProfile();
            openAlertModal("Education saved successfully");
            trackEvent(EVENTS.update_education, events);
          },
          failureCb: () => {
            modal.setIsLoading(false);
            submissionStatus("education", false);
          },
        };

        updateEducation(education?.id || null, payload)(dispatch, feedbackCb);
      },
    });

    modal.open();
  };

  const isEmptyArrayAndObject = (testResultObj) => {
    if (testResultObj === undefined) return true;
    const isArrayEmpty =
      Array.isArray(testResultObj?.coding_tests || []) && testResultObj?.coding_tests?.length === 0;
    const isObjectEmpty =
      !testResultObj?.mbti_profile && !testResultObj?.iq_test && !testResultObj?.sa_test;

    return isArrayEmpty && isObjectEmpty;
  };

  /* istanbul ignore next */
  useDidUpdate(() => {
    switch (true) {
      case !prevIsSaved?.work && isSaved?.work:
        submissionStatus("work", false);
        break;

      case !prevIsSaved?.project && isSaved?.project:
        submissionStatus("project", false);
        break;

      case !prevIsSaved?.education && isSaved?.education:
        submissionStatus("education", false);
        break;

      default:
        break;
    }
  }, [isSaved?.education, isSaved.work, isSaved.project]);

  useEffect(() => {
    fetchDevProfile();
    return () => {
      setEnableEditAction(false);
    };
  }, [talentId]);

  return (
    <>
      <MetaTags
        title={`Talent Pool - ${data?.user?.display_name}'s Profile`}
        description={`View ${data?.user?.display_name}'s profile.`}
      />

      {!isReSyncing && devProfiles.loading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Progress />
        </div>
      ) : (
        <ContentSection className="position-relative">
          {devProfiles.loading && (
            <div className="resync-loader">
              <SyncRecruitee isLoading />
            </div>
          )}
          <TungaDevProfileOverview
            showCloseButton="false"
            isEditable={enableEditAction}
            displayName={data?.user?.display_name || ""}
            bio={data?.bio || "N/A"}
            city={data?.city || ""}
            avatarUrl={data?.user?.avatar_url || ""}
            countryName={data?.country_name || ""}
            payoutRate={data?.payment_structure?.payout_rate || ""}
            yearsOfExperience={data?.years_of_experience ?? 0}
            onEditClick={onUpdateBio}
            editInProgress={isSubmitting?.bio}
            isVetted={`${data?.is_vetted}`}
          />
          <div className="bg-wrap">
            <TungaTabSwitch top="72px" />

            <div id="Tests">
              <TungaDevTestResult
                testResult={
                  !isEmptyArrayAndObject(data?.test_results)
                    ? JSON.stringify(data.test_results)
                    : ""
                }
              />
            </div>
            <div className="folder" id="Skills">
              <TungaDevSkillsToolsSection
                isEditable={enableEditAction}
                skillsData={skillsData}
                onEditClick={onUpdateSkillSets}
                editInProgress={isSubmitting?.skills}
              />
            </div>

            <div className="folder" id="Projects">
              <TungaProjectSection
                isEditable={enableEditAction}
                projectData={projectData}
                addInProgress={isSubmitting?.project}
                onEditClick={onAddProject}
                onEditItemClick={(e) => onAddProject(e, e.detail)}
                onDeleteItemClick={({ detail }) =>
                  onDeleteExperience(detail.rawProject.id, "project")
                }
              />
            </div>

            <div className="folder" id="Experience">
              <TungaWorkExperience
                isEditable={enableEditAction}
                experienceData={experienceData}
                addInProgress={isSubmitting?.work}
                onEditClick={onAddWork}
                onEditItemClick={(e) => onAddWork(e, e.detail)}
                onDeleteItemClick={({ detail }) => onDeleteExperience(detail.rawWork.id, "work")}
              />
            </div>

            <div className="folder" id="Education">
              <TungaEducationSection
                isEditable={enableEditAction}
                educationData={educationData}
                addInProgress={isSubmitting?.education}
                onEditClick={onAddEducation}
                onEditItemClick={(e) => onAddEducation(e, e.detail)}
                onDeleteItemClick={({ detail }) => onDeleteExperience(detail.id, "education")}
              />
            </div>
          </div>
        </ContentSection>
      )}
    </>
  );
};

export const ContentSection = styled.div`
  padding-bottom: 100px !important;
  width: 100%;

  .resync-loader {
    position: absolute;
    top: 12px;
    right: 18px;
    z-index: 5;
  }

  @media only screen and (min-width: 992px) {
    width: 90%;
  }

  .bg-wrap {
    background: #ffffff;
    border: 1px solid #e0e1e2;
    border-top: none;
    padding-bottom: 40px;
  }

  [id] {
    scroll-margin-top: 9.8rem;
  }
`;

export default TalentProfile;
