import PropTypes from "prop-types";
import React, { useEffect, useLayoutEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import SummaryPlaceholder from "../../../../components/SummaryPlaceholder/SummaryPlaceholder";
import {
  getMyParticipation,
  hasProjectAccess,
  isClient,
  isCSO,
  isDev,
  isPendingProjectParticipant,
  isPM,
  isSpaceLead,
} from "../../../../utils/auth";
import { fetchProject } from "../../../../redux/actions/ProjectActions";
import Progress from "../../../../components/Progress";
import PaymentContainer from "./PaymentContainer";
import { FETCH_PROJECT_SUCCESS } from "../../../../configs/constants/ActionTypes";
import Planning from "./Planning";
import Docs from "./Docs";
import Reports from "./Reports";
import OpportunityManagement from "../opportunity/OpportunityManagement";
import Settings from "./Settings";

const ProjectDetail = ({ match }) => {
  const { isMakingRequest, project } = useSelector(({ Projects }) => Projects);
  const dispatch = useDispatch();
  const history = useHistory();

  useLayoutEffect(() => {
    if (project === null || project?.id !== match.params.id) {
      dispatch(fetchProject(match.params.id));
    }

    return () => {
      dispatch({
        type: FETCH_PROJECT_SUCCESS,
        data: null,
      });
    };
  }, [match?.params?.id]);

  useEffect(() => {
    if (!isMakingRequest?.loading && project?.id && isPendingProjectParticipant(project)) {
      window.localStorage.setItem("tempProjectTitle", project.title);
      window.localStorage.setItem("tempProjectCategory", project.category);
      window.localStorage.setItem("tempParticipationId", getMyParticipation(project).id);

      history.push(`${match.url}/accept_invite`);
    }
  }, [isMakingRequest?.loading, project]);

  if (isMakingRequest.fetch || isMakingRequest?.loading || project === null || !project.id) {
    // TODO: if project is null show appropriate error
    return <Progress style={{ textAlign: "center" }} />;
  }

  return project.stage === "opportunity" ? (
    <OpportunityManagement project={project} match={match} />
  ) : hasProjectAccess(project) || isPM() || isCSO() || isSpaceLead() ? (
    <Switch>
      {[
        ["pay", <PaymentContainer project={project} key="payment" />],
        ["docs", <Docs project={project} key="document" />],
        ["plan", <Planning project={project} key="plan" />],
        ["reports", <Reports key="reports" />],
        !isClient() && !isDev() ? ["settings", <Settings key="settings" />] : [],
      ].map((path) => {
        return (
          <Route
            key={`project-management-path--${path}`}
            path={`${match.url}/${path[0]}`}
            render={() => path[1]}
          />
        );
      })}
      <Redirect from="*" to={`${match.url}/pay`} />
    </Switch>
  ) : (
    <SummaryPlaceholder
      className="page"
      description={`You don't have permission to access this project's resources`}
    />
  );
};

ProjectDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    url: PropTypes.string,
  }),
};

export default ProjectDetail;
