import React from "react";
import { DropdownToggle } from "reactstrap";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import FilterIconLogo from "../svg/FilterIconLogo";
import ColumnIcon from "../svg/ColumnIcon";
import ArrowDown from "../svg/ArrowDown";
import OrderDesc from "../svg/OrderDesc";
import OrderAsc from "../svg/OrderAsc";

const Toggle = ({ label }) => {
  return (
    <Wrapper role="button" tag="div" className="m-0">
      <span className="cursor-pointer d-flex align-items-center" data-testid="toggle">
        <span className="me-1 label">{label}</span>
        <FilterIconLogo />
      </span>
    </Wrapper>
  );
};

const ToggleV2 = ({ label }) => {
  return (
    <Wrapper role="button" tag="div" className="m-0 column d-flex align-items-center">
      <span className="cursor-pointer d-flex align-items-center" data-testid="toggle">
        <ColumnIcon />
        <span className="ms-1 label">{label}</span>
      </span>
    </Wrapper>
  );
};

const SortToggle = ({ label, className, sortDirection, toggleDirection }) => {
  return (
    <div className="d-flex align-items-center">
      <Wrapper
        role="button"
        tag="div"
        className={`m-0 sort d-flex justify-content-between align-items-center ${className}`}
      >
        <span className="cursor-pointer d-flex align-items-center me-3" data-testid="toggle">
          <span className="ms-1 label">{label}</span>
        </span>
        <ArrowDown />
      </Wrapper>

      <button
        title={`Sort Direction: ${sortDirection}`}
        type="button"
        className="order d-flex align-items-center justify-content-center"
        onClick={toggleDirection}
      >
        {sortDirection === "desc" ? <OrderDesc /> : <OrderAsc />}
      </button>
    </div>
  );
};

const baseStyle = css`
  border-radius: 4px;
  border: 1px solid #e3e9f2;
  background: #fff;
  padding: 4px 5px;
  height: 32px;
`;

const Wrapper = styled(DropdownToggle)`
  ${baseStyle}

  .label {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #7f8c9f;
  }

  &.column {
    border-radius: 8px;
    background: rgba(6, 46, 100, 0.1);

    .label {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      color: #062e64;
    }
  }

  &.sort {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    min-width: 128px;

    .label {
      color: #151a30;
    }
  }

  & + .order {
    ${baseStyle}
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

Toggle.propTypes = {
  label: PropTypes.string.isRequired,
};

ToggleV2.propTypes = {
  label: PropTypes.string.isRequired,
};

SortToggle.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  sortDirection: PropTypes.string.isRequired,
  toggleDirection: PropTypes.func.isRequired,
};

export { ToggleV2, SortToggle };
export default Toggle;
