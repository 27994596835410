/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import { FormGroup } from "reactstrap";
import PropTypes from "prop-types";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */

import { StyledForm } from "../../../../utils/styles";
import { cleanSkills } from "../../../../utils/api";
import PrioritySkillSelector from "../../../../components/PrioritySkillSelector";
import theme from "../../../../assets/theme";

const SkillsetForm = ({ id, skills: skillsFromProps, proceed }) => {
  const [skillsByCategory, setSkillsByCategory] = useState(() => {
    const cleanedSkills = cleanSkills(skillsFromProps) || [];

    return cleanedSkills.reduce((accSkills, skill) => {
      const accumulator = { ...accSkills };
      const skillType = accumulator[skill.type] || [];

      skillType.push(skill);

      accumulator[skill.type] = skillType;

      return accumulator;
    }, {});
  });

  const [suggestedSkills, setSuggestedSkills] = useState([]);

  /* istanbul ignore next */
  const onSkillChange = (newSkillsValue) => {
    setSkillsByCategory({ ...newSkillsValue });
  };

  const onSave = (e) => {
    e.preventDefault();
    const skills = Object.values(skillsByCategory).flat();

    proceed({
      skills,
      suggestedSkills,
    });
  };

  return (
    <StyledForm id={id} data-testid="skillset-form" onSubmit={onSave} role="form">
      <FormGroup>
        <ThemeProvider theme={theme}>
          <PrioritySkillSelector
            onChange={onSkillChange}
            selected={skillsByCategory}
            suggestedSkills={suggestedSkills}
            setSuggestedSkills={setSuggestedSkills}
            placeholder="Type to search and select a skill..."
          />
        </ThemeProvider>
      </FormGroup>
    </StyledForm>
  );
};

SkillsetForm.propTypes = {
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      primary: PropTypes.bool,
      slug: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  proceed: PropTypes.func,
  id: PropTypes.string,
};

export default SkillsetForm;
