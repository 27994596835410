/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import IbanLogo from "../../assets/images/iban_logo.png";
import useConnectIban from "../../hooks/useConnectIban";
import ActionsHandler from "./ActionsHandler";
import PaymentContainer from "./PaymentContainer";
import { PAYMENT_PROVIDER_IBAN } from "../../configs/constants/invoiceConstants";
import { Default } from "./styles";

const Iban = () => {
  const [isDefault, setIsDefault] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [connected, setConnected] = useState(false);
  const [ibanAccount, setIbanAccount] = useState({});

  const { connectIban, triggerDefaultPrompt, setShouldTriggerPrompt } = useConnectIban();

  const {
    iban,
    paymentProvider: { payment_provider_accounts, default_account_provider },
  } = useSelector(({ Payments }) => Payments);

  const payo_details = payment_provider_accounts;
  const filteredResult = payo_details?.filter((w) => w.payment_provider === PAYMENT_PROVIDER_IBAN);
  const payo_id = filteredResult && filteredResult[0]?.id;
  const provider_name = filteredResult && filteredResult[0]?.payment_provider;

  useEffect(() => {
    if (iban && iban.length > 0) {
      setIbanAccount(iban[0]);
      setConnected(true);
    }
  }, [iban]);

  useEffect(() => {
    if (
      default_account_provider?.is_default &&
      default_account_provider?.payment_provider === PAYMENT_PROVIDER_IBAN
    ) {
      setIsDefault(default_account_provider?.is_default);
    } else {
      setIsDefault(false);
    }

    setProviderName(default_account_provider?.payment_provider);
  }, [default_account_provider, providerName, isDefault]);

  return (
    <PaymentContainer>
      <div className="d-flex w-100 h-100">
        <div className="w-20">
          <div>
            <img height={32} width={32} src={IbanLogo} className="mx-auto" alt="Payoneer" />
          </div>
        </div>

        <div className="w-80">
          <div className="media pdb-40">
            <div className="media-body">
              <h4>
                IBAN - Euro Account
                {isDefault && providerName === PAYMENT_PROVIDER_IBAN && <Default>DEFAULT</Default>}
              </h4>

              {connected ? (
                <p className="m-0" data-testid="connected_text">
                  You&#39;ve successfully connected your IBAN account
                  <span className="masked-details">
                    IBAN: ••••••••••{ibanAccount.iban_number?.slice(-3)}
                  </span>
                </p>
              ) : (
                <p className="m-0" data-testid="unconnected_text">
                  Configure your payment details to receive EUR payouts. If you don&apos;t have a
                  Euro account, you can set up an account with a compatible service
                </p>
              )}
            </div>
          </div>

          <ActionsHandler
            connected={connected}
            connectHandler={connectIban}
            paymentProviderID={payo_id}
            providerName={provider_name}
            isDefault={isDefault}
            triggerDefaultPrompt={triggerDefaultPrompt}
            setShouldTriggerPrompt={setShouldTriggerPrompt}
          />
        </div>
      </div>
    </PaymentContainer>
  );
};

export default Iban;
