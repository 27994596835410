/* eslint-disable default-param-last */
import { combineReducers } from "redux";
import { FETCH_PROJECTS_START } from "../../configs/constants/ActionTypes";
import * as InterestActions from "../actions/InterestPollActions";
import * as types from "../../configs/constants/ActionTypes";

function interestPolls(state = {}, action) {
  switch (action.type) {
    case InterestActions.INTEREST_POLL_START:
      return { loading: true };
    case InterestActions.INTEREST_POLL_SUCCESS:
      return { loading: false, data: action.data };
    case InterestActions.INTEREST_POLL_FAILED:
      return { loading: false, error: action.error };
    default:
      return state;
  }
}

function interest(state = {}, action) {
  switch (action.type) {
    case FETCH_PROJECTS_START:
    case InterestActions.INTEREST_POLL_START:
      return {};
    case InterestActions.CREATE_INTEREST_POLL_START:
      return { loading: true };
    case InterestActions.CREATE_INTEREST_POLL_SUCCESS:
      return { loading: false, success: true, data: action.data };
    case InterestActions.CREATE_INTEREST_POLL_FAILED:
      return { loading: false, error: action.error };
    default:
      return state;
  }
}

function interestUpdate(state = {}, action) {
  switch (action.type) {
    case InterestActions.INTEREST_POLL_START:
      return {};
    case InterestActions.UPDATE_INTEREST_POLL_START:
      return { loading: true };
    case InterestActions.UPDATE_INTEREST_POLL_SUCCESS:
      return { loading: false, success: true, data: action.data };
    case InterestActions.UPDATE_INTEREST_POLL_FAILED:
      return { loading: false, error: action.error };
    default:
      return state;
  }
}

function isSaved(state = { shareShortlist: false }, action) {
  switch (action.type) {
    case types.SHARE_SHORTLIST_SUCCESS:
      return { ...state, shareShortlist: action.data };

    default:
      return state;
  }
}

const InterestPoll = combineReducers({
  interestPolls,
  interest,
  interestUpdate,
  isSaved,
});

export default InterestPoll;
