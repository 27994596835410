import React from "react";

const ColumnIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18" fill="none">
      <rect x="2.5" y="2.5" width={13} height={13} rx="2.5" stroke="black" />
      <rect x="6.5" y="2.5" width={5} height={13} stroke="black" />
    </svg>
  );
};

export default ColumnIcon;
