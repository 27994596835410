/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import PropTypes from "prop-types";
import React, { useRef, useEffect, useCallback, useState } from "react";
import { Provider } from "react-redux";
import { LOCATION_CHANGE } from "react-router-redux";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import styled from "styled-components";

/* -------------------------- Internel Dependencies ------------------------- */
import Button from "./Button";
import store from "../redux/store";
import { ModalHeader } from "./ModalHeader";
import Loading from "./svg/Loading";

const DynamicModal = ({ proceed, cancel, options = {}, modalHeader, modalContent, isLoading }) => {
  const wrapperRef = useRef(null);
  const [show, setShow] = useState(true);
  const hasForm = Object.keys(options?.form || {}).length > 0;

  const handleModalClose = () => {
    if (!isLoading) {
      setShow(false);
      cancel();
    }
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (
        wrapperRef &&
        !wrapperRef.current?.contains(event.target) &&
        !options.mustRespond &&
        !isLoading
      ) {
        event.preventDefault();
        event.stopPropagation();
        handleModalClose();
      }
    },
    [isLoading, options.mustRespond, wrapperRef?.current],
  );

  useEffect(() => {
    store.dispatch({ type: LOCATION_CHANGE });
  }, []);

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  const renderModalContent = () => {
    return typeof modalContent === "string"
      ? modalContent
      : React.cloneElement(modalContent, {
          proceed,
          cancel: handleModalClose,
        });
  };

  const renderModalHeader = () => {
    return modalHeader ? (
      React.cloneElement(modalHeader, { proceed, cancel: handleModalClose })
    ) : options.title ? (
      <ModalHeader proceed={proceed} cancel={handleModalClose} options={options} />
    ) : null;
  };

  return (
    <Provider store={store}>
      <Modal
        centered
        style={options.style}
        isOpen={show}
        toggle={handleModalClose}
        className={`${options.size ? `modal-${options.size}` : ""} ${options.className || ""}`}
        backdrop={options.hideBackdrop ? false : options.mustRespond ? "static" : true}
        keyboard={!options.mustRespond}
      >
        <div ref={wrapperRef}>
          {(!options.mustRespond || options.title) && renderModalHeader()}
          <StyledModalBody>
            <div>{renderModalContent()}</div>
          </StyledModalBody>
          {!options.hideActions && (
            <StyledModalFooter>
              {!options.hideCancel && (
                <Button
                  className="cancel"
                  onClick={handleModalClose}
                  variant="secondary"
                  data-testid="modalCancelBtn"
                  disabled={isLoading}
                >
                  {options.cancel || "Cancel"}
                </Button>
              )}

              <Button
                className="okay"
                disabled={isLoading}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(hasForm ? options.form : {})}
                onClick={() => {
                  if (!hasForm) {
                    proceed();
                  }
                }}
                data-testid="modalOkBtn"
              >
                {!isLoading && (options.ok || "OK")}
                {isLoading && <Loading fill="white" width={20} height={20} />}
              </Button>
            </StyledModalFooter>
          )}
        </div>
      </Modal>
    </Provider>
  );
};

const StyledModalBody = styled(ModalBody)`
  padding: 0 40px 40px 40px;
`;

const StyledModalFooter = styled(ModalFooter)`
  padding: 20px 40px;
  background: rgba(237, 241, 247, 0.25);

  button {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 0;

    &.cancel {
      border: 1px solid #3e4857;
      color: #3e4857;
      background-color: #fff;
      margin-right: 16px;
    }

    &.okay {
      color: #fff;
      background-color: #da3451;
      border-color: #da3451;
    }
  }
`;

DynamicModal.propTypes = {
  isLoading: PropTypes.bool,
  proceed: PropTypes.func,
  cancel: PropTypes.func,
  dismiss: PropTypes.func,
  modalContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  modalHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  options: PropTypes.shape({}),
};

DynamicModal.defaultProps = {
  modalHeader: null,
};

export default DynamicModal;
