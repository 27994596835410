/* eslint-disable react/prop-types */
import React, { useState } from "react";
import styled from "styled-components";

import ArrowRight from "../../../../components/ArrowRight";
import TimeSheetCalendar from "./TimeSheetCalendar";
import { generateUserInitials } from "../../../../utils/stringUtils";
import { isDev, isClient } from "../../../../utils/auth";

const TimeSheetList = ({
  archived,
  expectedHours,
  user,
  total,
  status,
  currentMonth,
  projectId,
  onApproveTimeSheet,
  onRejectTimeSheet,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);

  const toggle = () => setShowCalendar(!showCalendar);

  const isApproved = status === "approved";
  const isRejected = status === "rejected";
  const userAvatar = user?.avatar_url;

  return (
    <Container className="mb-4 position-relative">
      {archived && <span className="archived position-absolute px-3">Archived</span>}

      <div
        role="presentation"
        className={`wrapper d-flex justify-content-between align-items-center w-100 ${
          archived ? "archived" : ""
        }`}
        onClick={toggle}
      >
        <div className="w-50 d-flex align-items-center">
          <div className="d-flex align-items-center pe-2 pe-xl-4 border-right w-50 first-col">
            <div
              style={{ backgroundImage: `url(${userAvatar})` }}
              className={`profile-pics ${!userAvatar ? "profile-initials" : ""} me-3`}
            >
              {generateUserInitials(user)}
            </div>
            <div>
              <h6 className="heading">
                {user?.display_name || ""}
                {isDev() && " (You)"}
              </h6>
              <p className="subheading m-0 email-text">{user?.email || ""}</p>
            </div>
          </div>

          <div className="w-50 ps-2 ps-xl-5">
            <h6 className="heading">
              <span className="text-bold">{total}</span>/{expectedHours ?? 0} hr(s)
            </h6>
            <p className="subheading m-0">Total/expected hours worked this month</p>
          </div>
        </div>

        {!archived && (
          <div className="d-flex align-items-center">
            {!isClient() && (
              <>
                <span
                  className={`m-0 badge ${
                    isApproved ? "badge-success" : isRejected ? "badge-rejected" : ""
                  }`}
                >
                  {isApproved ? "Approved" : isRejected ? "Rejected" : "Pending Approval"}
                </span>

                {!isApproved && !isDev() && !isClient() && (
                  <div className="d-flex flex-column flex-xl-row">
                    <button
                      data-testid="approve-btn"
                      className="btn btn-approve ms-3 mb-2 mb-xl-0"
                      type="button"
                      onClick={() => onApproveTimeSheet(user.id)}
                    >
                      Approve
                    </button>
                    <button
                      data-testid="reject-btn"
                      className="btn btn-approve ms-3"
                      type="button"
                      onClick={() => onRejectTimeSheet(user.id)}
                    >
                      Reject
                    </button>
                  </div>
                )}
              </>
            )}

            <span className="ms-4">
              <ArrowRight className={`arrow ${showCalendar ? "arrow-down" : ""}`} />
            </span>
          </div>
        )}
      </div>

      {showCalendar && !archived && (
        <TimeSheetCalendar
          archived={archived}
          userId={user.id}
          projectId={projectId}
          currentMonth={currentMonth}
          isApproved={isApproved}
          expectedHours={expectedHours}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid #edf1f7;
  border-radius: 12px;
  background: #ffffff;
  /* overflow: hidden; */
  cursor: pointer;

  .archived {
    top: -5px;
    right: 40px;
    border-radius: 10px;
    color: #fff;
    background: rgb(218, 52, 81);
    border: 1px solid rgba(235, 87, 87, 0.04);
    font-size: 12px;
    z-index: 2;
  }

  div.wrapper {
    text-align: left;
    padding: 16px 24px;
    border: none;
    background: #ffffff;

    &.archived {
      opacity: 0.5;
      background: #d5d4d4;
    }
  }

  .profile {
    &-pics {
      min-width: 54px;
      height: 54px;
      border-radius: 100px;
      background-color: #062e64;
      border: 1px solid #fff;
      background-size: cover;
      background-position: 50%;
      justify-content: center;
      display: flex;
      box-shadow: rgba(149, 157, 165, 0.2) 5px 8px 24px;
      flex-shrink: 0;

      @media only screen and (min-width: 1200px) {
        min-width: 64px;
        height: 64px;
      }
    }

    &-initials {
      align-items: center;
      color: #fff;
      background-color: #062e64;
    }
  }

  .text-bold {
    font-weight: 500;
    color: #151a30;
  }

  .heading {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #3e4857;
    margin-bottom: 2px;
  }

  .subheading {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #3e4857;
    word-break: break-all;
  }

  .badge {
    background: rgba(143, 155, 179, 0.05);
    border: 1px solid rgba(143, 155, 179, 0.04);
    border-radius: 50px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #8f9bb3;

    &-success {
      background: rgba(33, 150, 83, 0.04);
      border: 1px solid rgba(33, 150, 83, 0.04);
      color: #219653;
    }

    &-rejected {
      background: rgba(235, 87, 87, 0.04);
      border: 1px solid rgba(235, 87, 87, 0.04);
      color: #eb5757;
    }
  }

  button.btn-approve {
    background: #da3451;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    height: 30px;
    color: #ffffff;
  }

  button.btn-submit-hours {
    height: 40px;
  }

  .border-right {
    border-right: 1px solid #e3e9f2;
  }

  .arrow {
    transition: 0.3s ease;
    &-down {
      transform: rotate(90deg);
    }
  }
`;

export default TimeSheetList;
