import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import Progress from "../../../components/Progress";
import SummaryPlaceholder from "../../../components/SummaryPlaceholder/SummaryPlaceholder";
import OpportunityCard from "./components/OpportunityCard";
import { fetchOpportunities } from "../../../redux/actions/OpportunityActions";

const OpportunitiesList = () => {
  const dispatch = useDispatch();

  const { user } = useSelector(({ Auth }) => Auth);
  const getList = () => {
    dispatch(fetchOpportunities());
  };

  useEffect(() => {
    if (user.id) {
      getList();
    }
  }, [user]);

  const { opportunities, isMakingRequest } = useSelector(({ Opportunities }) => Opportunities);

  return isMakingRequest?.loading ? (
    <Progress />
  ) : opportunities.data.length ? (
    <div>
      <Row className="card-list">
        {opportunities.data.map((opportunity) => {
          return (
            <Col key={`opportunity-card--${opportunity.id}`} sm="3">
              <OpportunityCard opportunity={opportunity} />
            </Col>
          );
        })}
      </Row>
    </div>
  ) : (
    <SummaryPlaceholder className="page" description="Seems you don't have any opportunities." />
  );
};

OpportunitiesList.propTypes = {};

export default OpportunitiesList;
