import React from "react";

const SearchIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 25 25"
        fill="none"
      >
        <path
          d="M11.1875 19.3809C15.5367 19.3809 19.0625 15.8551 19.0625 11.5059C19.0625 7.15662 15.5367 3.63086 11.1875 3.63086C6.83826 3.63086 3.3125 7.15662 3.3125 11.5059C3.3125 15.8551 6.83826 19.3809 11.1875 19.3809Z"
          stroke="#7F8C9F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.7559 17.0742L21.3122 21.6305"
          stroke="#7F8C9F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default SearchIcon;
