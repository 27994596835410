/* eslint-disable no-unused-vars */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import PropTypes from "prop-types";
import React, { useEffect } from "react";

/* --------------------------- Component Dependencies -------------------------- */
import { useDispatch, useSelector } from "react-redux";
import ActivityList from "./ActivityList";
import { listActivities, listMoreActivities } from "../../../../redux/actions/ProjectActions";
import { trackEvent } from "../../../../analytics/segment";
import { CATEGORIES, EVENTS } from "../../../../analytics/events";

const Activity = ({ project }) => {
  const dispatch = useDispatch();
  const { activities, isMakingRequest } = useSelector(({ Projects }) => Projects);

  const getList = () => {
    dispatch(
      listActivities({
        project: project.id,
      }),
    );
  };

  useEffect(() => {
    getList();
    trackEvent(EVENTS.view_project_activity, {
      project_type: project.stage === "active" ? project.category : project.stage,
      project_id: project.id,
      project_name: project.title,
      event_category: CATEGORIES.projects,
    });
  }, []);

  return (
    <div>
      <ActivityList
        listId={project.id}
        activities={activities.data?.reverse()}
        onLoadMore={() => {
          dispatch(listMoreActivities(activities.next));
        }}
        isLoading={isMakingRequest.listActivities}
        isLoadingMore={isMakingRequest.listMoreActivities}
        hasMore={!!activities.next}
      />
    </div>
  );
};

Activity.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    stage: PropTypes.string,
    category: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default Activity;
