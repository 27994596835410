import React, { useState } from "react";
import PropTypes from "prop-types";
import Input from "../../../../components/Input";

const RemoveFromShortlistForm = (props) => {
  const { id, proceed } = props;
  const [reason, setReason] = useState("");

  const handleChange = (e) => {
    setReason(e.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    proceed({ reason });
  };

  return (
    <form id={id} onSubmit={onSubmit}>
      <label className="control-label" htmlFor="reason" style={{ fontSize: "14px", width: "100%" }}>
        Reason
        <span
          style={{
            color: "red",
            fontSize: "15px",
          }}
        >
          *
        </span>
      </label>

      <Input
        className="mb-3"
        id="reason"
        value={reason}
        placeholder="Enter reason"
        onChange={handleChange}
        required
      />
    </form>
  );
};

RemoveFromShortlistForm.propTypes = {
  id: PropTypes.string.isRequired,
  proceed: PropTypes.func,
};

export default RemoveFromShortlistForm;
