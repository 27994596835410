/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { isValidElement } from "react";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";

/* -------------------------- Internal Dependencies ------------------------- */
import Icon from "../components/Icon";
import { logout } from "../redux/actions/AuthActions";
import logo from "../assets/images/logo_round.png";
import { isClient, isDev, isInternalUser } from "../utils/auth";
import useRightNav from "./RightSideNav/useRightNav";
import {
  ChartBar,
  ConnectedPeople,
  DashboardIcon,
  HelpAndSupportIcon,
  LogoutIcon,
  OpportunitiesIcon,
  PaymentsIcon,
  ProjectsIcon,
} from "../pages/Dashboard/dashboard/Icons";
import { resetAnalytics } from "../analytics/segment";
import ProfileCard from "./ProfileCard";

const SideBar = () => {
  const dispatch = useDispatch();
  const { close } = useRightNav();
  const { location: loc } = useHistory();

  const onSignOut = (e) => {
    resetAnalytics();
    e.preventDefault();
    dispatch(logout());
  };

  return (
    <Wrapper id="sidebar" className="sidebar">
      <img alt="company-logo" src={logo} />
      <ul className="nav">
        {[
          ["dashboard", "Dashboard", <DashboardIcon active={loc?.pathname === "/dashboard"} />],
          ...(isInternalUser()
            ? [
                [
                  "talent-pool",
                  "Talent Pool",
                  <ChartBar
                    active={!!loc?.pathname.match(/^\/talent-pool\/(all|vetted|on-assignment)$/)}
                  />,
                ],
              ]
            : []),
          [
            "projects",
            "Projects",
            <ProjectsIcon active={loc?.pathname.indexOf("/projects") === 0} />,
          ],
          // ...(isAdminOrPMOrSpaceLead() ? [["tests", "Tests", "award"]] : []),
          [
            `payments/${isDev() ? "out" : "in"}`,
            "Payments",
            <PaymentsIcon active={loc?.pathname.indexOf("/payments") === 0} />,
          ],
          ...(isDev() || isInternalUser()
            ? [
                [
                  "opportunities",
                  "Opportunities",
                  <OpportunitiesIcon active={loc?.pathname.indexOf("/opportunities") === 0} />,
                ],
              ]
            : []),
          ["settings", "Settings", "outline-settings"],
          ...(isDev()
            ? [
                [
                  "referral",
                  "Refer and Earn",
                  <ConnectedPeople active={loc?.pathname === "/referral"} />,
                ],
              ]
            : []),
          ...(!isClient() ? [["community", "Community Guide", "baseline-folder-open"]] : []),
          [
            "help-and-support",
            "Help and Support",
            <HelpAndSupportIcon active={loc?.pathname === "/referral"} />,
          ],
        ].map((item, idx) => {
          let CustomLinks = null;

          switch (item[0]) {
            case "referral":
              CustomLinks = (
                <a href={process.env.REACT_APP_REFERRAL_JOIN_LINK} target="_blank" rel="noreferrer">
                  <span className="menu-icon">
                    {isValidElement(item[2]) ? item[2] : <Icon name={item[2]} size="sidebar" />}
                  </span>
                  <span>{item[1]}</span>
                </a>
              );
              break;
            default:
              CustomLinks = null;
              break;
          }

          return (
            <li key={`dashboard-${idx}`}>
              {CustomLinks ? (
                [CustomLinks]
              ) : (
                <NavLink
                  to={`/${item[0]}`}
                  activeClassName="active"
                  onClick={close}
                  className="pe-0"
                  isActive={(match, location) => {
                    return (
                      location?.pathname.includes("payments/in/") ||
                      location?.pathname.includes("payments/out/") ||
                      location?.pathname?.split("/")[1] === item[0]?.split("/")[0]
                    );
                  }}
                >
                  <span className="menu-icon">
                    {isValidElement(item[2]) ? item[2] : <Icon name={item[2]} size="sidebar" />}
                  </span>
                  <span>{item[1]}</span>
                </NavLink>
              )}
            </li>
          );
        })}
        <li>
          <NavLink data-testid="signout" to="logout" onClick={(e) => onSignOut(e)}>
            <span className="menu-icon">
              <LogoutIcon />
            </span>
            <span>Log Out</span>
          </NavLink>
        </li>
      </ul>

      <ProfileCard />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  img {
    width: 75px;
    margin: 2rem auto;
    display: block;
  }

  display: flex;
  flex-direction: column;

  position: fixed;
  width: 220px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
  background-color: #062e64;
  box-shadow: inset 0 -3px 3px 0 rgba(0, 0, 0, 0.5);
  padding: 0;

  h2 {
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    margin: 24px 18px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    padding: 0 1.25rem 1.875rem;

    li {
      display: block;
      margin: 0 auto 22px;
      width: 100%;

      a,
      a:not([href]):not([class]) {
        display: block;
        color: #8f9bb3;
        padding: 12px 18px;

        &:hover,
        &:focus {
          text-decoration: none;
        }

        &.active {
          color: #ffffff;
          background: rgba(255, 255, 255, 0.05);
          border-radius: 6px;
        }

        span {
          display: inline-block;
          font-size: 13.68px;
        }

        .menu-icon {
          margin-right: 12px;
          vertical-align: baseline;
          width: 20px;
          text-align: right;
        }
      }
    }
  }
`;

export default SideBar;
