import React from "react";

const ArrowUp = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_12299_29923)">
        <path
          d="M3.75 12.5L10 6.25L16.25 12.5"
          stroke="#7F8C9F"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12299_29923">
          <rect width={20} height={20} fill="white" transform="translate(20 20) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowUp;
