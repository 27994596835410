import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { AddCircle, Remove } from "../../dashboard/Icons";

const BulkActions = ({ checked, onAddClick, onRejectClick }) => {
  return (
    <div className="col-4 px-0 float-end">
      <div className="d-flexs">
        <SelectionWrap
          className={`d-flex align-items-center float-end  ${
            checked.length > 0 ? "" : "invisible"
          }`}
        >
          <div className="d-inline-block no-of-selected">{checked.length} selected </div>
          <div className="actions-icons d-flex align-items-center">
            <button
              title="Add to skill library"
              data-tip="Add to skill library"
              className="btn col-lg-2"
              data-testid="bulk-add-skill"
              onClick={onAddClick}
              type="button"
            >
              <AddCircle />
            </button>
            <button
              title="Reject"
              className="btn col-lg-2"
              onClick={onRejectClick}
              data-testid="bulk-reject-skill"
              type="button"
              data-tip="Reject"
            >
              <Remove />
            </button>
          </div>
        </SelectionWrap>
      </div>
    </div>
  );
};

const SelectionWrap = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #686c72;

  .btn {
    background: none;
  }

  .no-of-selected {
    white-space: nowrap;
    vertical-align: middle;
  }

  .no-of-selected,
  .batch-accept-btn {
    margin-right: 28px;
  }

  .batch-reject-btn {
    margin-right: 40px;
  }

  .batch-reject-btn,
  .batch-accept-btn {
    background: none;
    height: unset;
    line-height: unset;
    border: none;
  }
  thead tr th:first-child {
    padding-left: 24px;
    display: none;
  }
`;

BulkActions.propTypes = {
  checked: PropTypes.arrayOf(PropTypes.number),
  onAddClick: PropTypes.func,
  onRejectClick: PropTypes.func,
};

export default BulkActions;
