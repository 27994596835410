import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FormGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import Icon from "../../../../../../components/Icon";
import TextArea from "../../../../../../components/TextArea";
import SmileysModal from "../../../../../../components/SmileysModal";
import Loading from "../../../../../../components/svg/Loading";
import FieldError from "../../../../../../components/FieldError";
import { pxToRem } from "../../../../../../assets/theme/functions";
import { updateProgressReport } from "../../../../../../redux/actions/ProjectActions";
import { openAlertModal } from "../../../../../../utils/modals";

const initState = {
  main_accomplishments: "",
  current_tasks_progress: "",
  confidence_in_targets: "",
  engaging_motivating_aspects: "",
  challenges_delays: "",
};

const DevPMReport = () => {
  const { progress_event, isMakingRequest, errors } = useSelector(({ Projects }) => Projects);
  const isLoading = isMakingRequest?.progressReport;

  const dispatch = useDispatch();

  const [disclaimerNotice, setDisclaimerNotice] = useState(true);

  const dataFromDB = progress_event.progress_reports[0];
  const initialValues =
    dataFromDB && Object.values(dataFromDB)?.length > 0
      ? {
          main_accomplishments: dataFromDB.main_accomplishments,
          current_tasks_progress: dataFromDB.current_tasks_progress,
          confidence_in_targets: dataFromDB.confidence_in_targets,
          engaging_motivating_aspects: dataFromDB.engaging_motivating_aspects,
          challenges_delays: dataFromDB.challenges_delays,
        }
      : initState;

  const form = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: { ...initialValues },
    validationSchema: yup.object().shape({
      main_accomplishments: yup.string().required("Please enter accomplishment"),
      current_tasks_progress: yup.string().required("Please describe current tasks and progress"),
      confidence_in_targets: yup
        .string()
        .required("Please rate your confidence in reaching targets (1-5)."),
      engaging_motivating_aspects: yup
        .string()
        .required("Please share engaging or motivating aspects"),
      challenges_delays: yup
        .string()
        .required("Please detail challenges or delays in task delivery"),
    }),
    onSubmit: (formData) => {
      const reqData = {
        event: { id: progress_event.id },
        ...formData,
      };

      const progress_report = progress_event.progress_reports[0];

      dispatch(updateProgressReport(progress_report?.id || null, reqData));
    },
  });

  const onChangeValue = (key, value) => {
    form.setFieldValue(key, value);
  };

  const onChange = (value) => {
    form.setFieldValue("confidence_in_targets", value);
  };

  const closeDisclaimer = (e) => {
    e.preventDefault();
    setDisclaimerNotice(false);
  };

  useEffect(() => {
    if (!isLoading && errors?.progressReport) {
      openAlertModal(errors?.progressReport || "Sorry, an error occurred", true);
    }
  }, [isLoading, errors]);

  return (
    <Container data-testid="pmSurvey-form" className="container" onSubmit={form.handleSubmit}>
      <div className="content">
        {disclaimerNotice && (
          <div className="section">
            <div className="warning">
              <Icon name="information-outline" />

              <p>
                Your honest and candid updates on your progress, along with your responses, will aid
                us in effectively guiding and supporting you, providing targeted assistance where
                necessary. Please take into account that.
              </p>

              <button className="btn" type="button" onClick={closeDisclaimer}>
                I understand, dismiss
              </button>
            </div>
          </div>
        )}

        <div className="section">
          <label htmlFor="main_accomplishments">
            What were your main accomplishments this week/since the last report?
            <span className="label-style">*</span>
          </label>

          <FormGroup>
            <TextArea
              id="main_accomplishments"
              name="main_accomplishments"
              onChange={(e) => onChangeValue("main_accomplishments", e.target.value)}
              value={form.values.main_accomplishments}
              placeholder="Enter accomplishments here"
              onBlur={form.handleBlur}
            />
          </FormGroup>

          {form.errors.main_accomplishments && form.touched.main_accomplishments && (
            <FieldError message={form.errors.main_accomplishments} />
          )}
        </div>

        <div className="section">
          <label htmlFor="current_tasks_progress">
            What tasks are you currently working on, and what progress have you made?
            <span className="label-style">*</span>
          </label>
          <FormGroup>
            <TextArea
              id="current_tasks_progress"
              onChange={(e) => onChangeValue("current_tasks_progress", e.target.value)}
              value={form.values.current_tasks_progress}
              placeholder="Describe current tasks and progress here"
              onBlur={form.handleBlur}
            />
          </FormGroup>
          {form.errors.current_tasks_progress && form.touched.current_tasks_progress && (
            <FieldError message={form.errors.current_tasks_progress} />
          )}
        </div>

        <div className="section">
          <label htmlFor="rating">
            How confident are you in reaching your current targets?
            <span className="label-style">*</span>
          </label>

          <SmileysModal
            index={1}
            name="rating"
            selected={`${form.values.confidence_in_targets}`}
            onChange={onChange}
            onBlur={form.handleBlur}
          />
          {form.errors.confidence_in_targets && form.touched.confidence_in_targets && (
            <FieldError message={form.errors.confidence_in_targets} />
          )}
        </div>

        <div className="section">
          <label htmlFor="engaging_motivating_aspects">
            Are there any aspects of your tasks that you find particularly engaging or motivating?
            <span className="label-style">*</span>
          </label>
          <FormGroup>
            <TextArea
              id="engaging_motivating_aspects"
              onChange={(e) => onChangeValue("engaging_motivating_aspects", e.target.value)}
              value={form.values.engaging_motivating_aspects}
              placeholder="Share engaging or motivating aspects here"
              onBlur={form.handleBlur}
            />
          </FormGroup>

          {form.errors.engaging_motivating_aspects && form.touched.engaging_motivating_aspects && (
            <FieldError message={form.errors.engaging_motivating_aspects} />
          )}
        </div>

        <div className="section">
          <label htmlFor="challenges_delays">
            Are there any aspects of your tasks presenting challenges or causing delays in your task
            delivery?
            <span className="label-style">*</span>
          </label>
          <FormGroup>
            <TextArea
              id="challenges_delays"
              onChange={(e) => onChangeValue("challenges_delays", e.target.value)}
              value={form.values.challenges_delays}
              placeholder="Detail challenges or delays in task delivery here"
              onBlur={form.handleBlur}
            />
          </FormGroup>

          {form.errors.challenges_delays && form.touched.challenges_delays && (
            <FieldError message={form.errors.challenges_delays} />
          )}
        </div>
      </div>

      <div className="footer">
        <button type="submit" className="btn btn-primary" disabled={isLoading || !form.isValid}>
          {!isLoading ? "Submit Report" : <Loading fill="white" width={20} height={20} />}
        </button>
      </div>
    </Container>
  );
};

const Container = styled.form`
  width: 800px;
  background: #ffffff;
  border: 1px solid #edf1f7;
  border-radius: 6px;
  box-shadow: 0 1px 1px 0 hsla(0, 0%, 80%, 0.5);
  padding: 0;
  margin-top: 40px;
  margin-bottom: 113px;

  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
  .content {
    padding: 40px;
  }

  .footer {
    padding: 20px 40px;
    background: rgba(237, 241, 247, 0.25);
    border-radius: 0px 0px 6px 6px;

    button {
      background: #da3451;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: ${pxToRem(15)};
      line-height: ${pxToRem(19)};
      text-align: center;
      display: block;
      margin-left: auto;
      box-shadow: none;
      color: #ffffff;
    }
  }
  .section {
    padding: 40px 0;
    border-bottom: 1px solid #edf1f7;

    &:first-of-type {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border: 0px solid #edf1f7;
    }

    h4,
    label {
      font-weight: 500;
      font-size: ${pxToRem(16)};
      line-height: 19px;
      margin-bottom: 0.9rem;
      color: #151a30;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #3e4857;

      i {
        color: #8f9bb3;
        font-size: 16px;
      }
    }

    button.btn-icon {
      i.tg-ic-delete-outline {
        color: #8f9bb3;
        font-size: 18px;
      }
    }

    textarea {
      min-height: 100px;
    }

    input,
    textarea,
    select {
      font-size: 16px;
      color: #8f9bb3;
      background: #ffffff;
      border: 1px solid rgba(194, 204, 217, 0.5);
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: none;

      &::placeholder {
        color: #8f9bb3;
        font-weight: normal;
      }

      &.rw-input {
        padding-left: 1.25rem;
      }
    }

    .warning {
      padding: 24px;
      background: rgba(235, 87, 87, 0.05);
      border-radius: 6px;

      i {
        font-size: 20px;
        position: absolute;
        color: #eb5757;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        color: #eb5757;
        padding-left: 40px;

        b {
          font-weight: 500;
        }
      }

      button.btn {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #062e64;
        text-decoration: none;
        margin-left: 40px;
        padding: 0;
      }
    }

    .form-group {
      margin-bottom: 0;
    }

    .custom-radio-group {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;

      .radio-button {
        margin-right: 16px;

        &:last-of-type {
          margin-right: 0px;
        }
      }
    }

    .custom-radio-group input[type="radio"] {
      position: absolute;
      left: -9999px;
    }

    .custom-radio-group input[type="radio"] + label {
      position: relative;
      padding: 0 0 0 30px;
      cursor: pointer;
      font-size: 16px;
      line-height: 24px;
      color: #151a30;
      font-weight: 300;
      min-width: 128px;
    }

    .custom-radio-group input[type="radio"] + label:before {
      content: "";
      background: #fff;
      border: 2px solid #151a30;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      position: absolute;
      top: 3px;
      left: 0;
    }

    .custom-radio-group input[type="radio"] + label:after {
      content: "";
      background: #151a30;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 5px;
      opacity: 0;
      transform: scale(2);
      transition: transform 0.3s linear, opacity 0.3s linear;
    }

    .custom-radio-group input[type="radio"]:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }

    .progress-wrapper {
      display: flex;
      flex-direction: row;
      height: 35px;
      align-items: center;

      > div {
        flex: 1;
        position: relative;
      }

      /* chrome, safari, edge, opera -- hide arrow on type number */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox -- hide arrow on type number */
      input[type="number"] {
        -moz-appearance: textfield;
        appearance: textfield;
      }
      .inp-wid {
        width: 100%;
      }
      .input-group.suffix {
        position: relative;
        display: table;
        border-collapse: separate;
        width: 100px;
        flex: none;
        margin-left: 40px;

        .input-group-addon {
          border-left: 0;
          border-radius: 0px 4px 4px 0px;
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          text-align: center;
          border: 1px solid rgba(194, 204, 217, 0.25);
          width: 1%;
          white-space: nowrap;
          vertical-align: middle;
          display: table-cell;
          border-left: none;
          padding-right: 24px;
          color: #8f9bb3;
          font-size: 16px;
        }

        input {
          border-radius: 4px 0px 0px 4px;
          position: relative;
          z-index: 2;
          float: left;
          width: 100%;
          margin-bottom: 0;
          display: table-cell;
          padding: 8px 0 8px 24px;
          border: 1px solid rgba(194, 204, 217, 0.25);
          border-right: none;
          margin: 0;
          text-align: end;
        }
      }
    }
  }
`;

export default DevPMReport;
