import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const CustomSelect = ({ defaultText, options, handler, style = {}, selectedStyle = {} }) => {
  const [defaultSelectText, setDefaultSelectText] = useState("");
  const [showOptionList, setShowOptionList] = useState(false);

  // This method handles the click that happens outside the
  // select text and list area
  const handleClickOutside = (event) => {
    if (
      !event.target.classList.contains("custom-select-option") &&
      !event.target.classList.contains("selected-text")
    ) {
      setShowOptionList(false);
      if (defaultText === "Actions") setDefaultSelectText(defaultText);
    }
  };

  // This method handles the display of option list
  const handleListDisplay = () => {
    setShowOptionList(!showOptionList);
  };

  // This method handles the setting of name in select text area
  // and list display on selection
  const handleOptionClick = (event) => {
    setDefaultSelectText(event.target.getAttribute("data-name"));
    handler(event.target.getAttribute("data-value"));
    setShowOptionList(false);
  };

  useEffect(() => {
    // Add Event Listner to handle the click that happens outside
    // the Custom Select Container
    document.addEventListener("mousedown", handleClickOutside);
    setDefaultSelectText(defaultText);

    return () => {
      // Remove the event listner on component unmounting
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Wrapper style={style}>
      <div
        role="presentation"
        className={showOptionList ? "selected-text active" : "selected-text"}
        style={{ ...selectedStyle }}
        data-testid="select"
        onClick={handleListDisplay}
      >
        {defaultSelectText}
      </div>
      {showOptionList && (
        <List className="select-options">
          {options.map((option) => {
            return (
              option !== false && (
                <li
                  role="presentation"
                  data-testid={`option-${option.id}`}
                  className={`custom-select-option ${
                    option.name === "Delete invoice" ? "delete" : ""
                  }`}
                  style={{ ...selectedStyle }}
                  data-name={option.name}
                  data-value={option.id}
                  key={option.id}
                  disabled={option.disabled || false}
                  onClick={handleOptionClick}
                >
                  {option.name}
                </li>
              )
            );
          })}
        </List>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-block;
  width: 11vw;
  text-align: center;
  position: relative;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 5px;
  /* margin-bottom: 24px; */
  padding: 1px 1px 0;

  .selected-text {
    padding: 6px 1em;
    text-align: left;
    color: #999;
    font-size: 14px;

    :after {
      content: "";
      position: absolute;
      right: 10px;
      top: 16px;
      border: 7px solid transparent;
      border-top-color: #aaa;
    }

    &.active:after {
      top: 8px;
      border-color: transparent transparent #aaa;
    }
  }
`;

const List = styled.ul`
  margin: 0.6em 0px 0px;
  padding: 0px;
  background-color: white;
  z-index: 20;
  border-radius: 3px;
  box-shadow: rgb(221 221 221) 0px 3px 3px 0px;
  border: 1px solid #999;

  &.select-options {
    position: absolute;
    width: 100%;
    border-color: rgb(221, 221, 221);
  }

  li {
    list-style-type: none;
    padding: 6px 10px;
    background: white;
    cursor: pointer;
    text-align: left;

    &[disabled] {
      opacity: 0.4;
      pointer-events: none;
    }

    &.delete {
      color: red;
    }
  }
`;

CustomSelect.propTypes = {
  defaultText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  handler: PropTypes.func,
  style: PropTypes.shape({}),
  selectedStyle: PropTypes.shape({}),
};
export default CustomSelect;
