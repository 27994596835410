/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import React from "react";

import { addEventListeners, INPUT_EVENTS } from "../utils/events";
import { filterInputProps } from "../utils/forms";

const TextArea = (props) => {
  const { className, placeholder, required, children, ...rest } = props;

  // Pop out type key, this is already a text field
  delete rest.type;

  return (
    <textarea
      className={`form-control ${className || ""}`}
      placeholder={placeholder}
      required={required}
      {...filterInputProps(props)}
      {...addEventListeners(INPUT_EVENTS, props)}
      {...rest}
    >
      {children}
    </textarea>
  );
};

TextArea.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.node,
};

TextArea.defaultProps = {
  type: "text",
};

export default TextArea;
