import axios from "axios";
import {
  ENDPOINT_ACTIVITIES,
  ENDPOINT_INVOICES,
  ENDPOINT_INVOICES_SUMMARY,
  ENDPOINT_GET_PLATFORM_SUMMARY,
  getCancelToken,
} from "../../utils/api";
import * as types from "../../configs/constants/ActionTypes";
import { success, start, failed, formatErrorResponse } from "../../utils/actions";
import { openAlertModal } from "../../utils/modals";
import { fetchProject } from "./ProjectActions";
import { trackEvent } from "../../analytics/segment";
import { EVENTS, CATEGORIES } from "../../analytics/events";

export function createInvoice(invoice, { successCb, failureCb }) {
  return (dispatch) => {
    dispatch(start(types.CREATE_INVOICE_START));
    axios
      .post(ENDPOINT_INVOICES, invoice)
      .then((response) => {
        if (successCb) successCb();

        if (invoice.scope && invoice.scope === "project") {
          dispatch(fetchProject(invoice.project.id, true));
        }
        dispatch(success(types.CREATE_INVOICE_SUCCESS, response.data));

        trackEvent(EVENTS.create_new_payment, {
          project_id: invoice.project.id,
          project_name: invoice.projectTitle,
          payment_type: invoice.type,
          event_category: CATEGORIES.payments,
        });
      })
      .catch((error) => {
        if (failureCb) failureCb();

        const err = error?.response?.data || {};

        dispatch({ type: types.CREATE_INVOICE_FAILED, error: { ...err, data: invoice } });
      });
  };
}

export function createInvoiceBatch(invoices, { successCb, failureCb }) {
  return (dispatch) => {
    dispatch(start(types.CREATE_INVOICE_BATCH_START));
    axios
      .post(`${ENDPOINT_INVOICES}bulk/`, invoices)
      .then((response) => {
        if (successCb) successCb();
        dispatch(success(types.CREATE_INVOICE_BATCH_SUCCESS, response.data));

        const invoice1 = invoices[0];
        const invoice2 = response.data[0];

        const invoice = invoice1.projectTitle ? invoice1 : invoice2;

        trackEvent(EVENTS.create_new_payout, {
          project_id: invoice.project.id,
          project_name: invoice?.projectTitle || invoice.project.title,
          payment_type: invoice.type,
          event_category: CATEGORIES.payouts,
        });
      })
      .catch((error) => {
        if (failureCb) failureCb();

        const err = error?.response?.data[0] || error?.response?.data || null;

        dispatch(failed(types.CREATE_INVOICE_BATCH_FAILED, { message: err }));
      });
  };
}

export function downloadInoicesCsv(invoiceSet, { successCb, failureCb }) {
  return (dispatch) => {
    dispatch(start(types.DOWNLOAD_INVOICE_CSV_STARTED));
    axios
      .get(`${ENDPOINT_INVOICES}export/`, {
        params: invoiceSet,
      })
      .then(({ data }) => {
        if (successCb) successCb();
        dispatch(success(types.DOWNLOAD_INVOICE_CSV_SUCCESS, data));
        trackEvent(EVENTS.download_csv, {
          payment_type: invoiceSet.type || invoiceSet.types,
          event_category: invoiceSet.type === "purchase" ? CATEGORIES.payouts : CATEGORIES.payments,
          start_date: invoiceSet.start,
          end_date: invoiceSet.end,
          filter_params: invoiceSet,
        });
      })
      .catch((error) => {
        if (failureCb) failureCb();
        dispatch(failed(types.DOWNLOAD_INVOICE_CSV_FAILED, error));
      });
  };
}

export function listInvoices(filter, search = false) {
  return (dispatch) => {
    dispatch(start(search ? types.SEARCH_INVOICES_START : types.LIST_INVOICES_START));
    // TODO: refactor even further
    axios
      .get(filter.archived === "True" ? `${ENDPOINT_INVOICES}archived/` : ENDPOINT_INVOICES, {
        params: filter,
        cancelToken: getCancelToken("listInvoices"),
      })
      .then((response) => {
        dispatch(
          success(
            search ? types.SEARCH_INVOICES_SUCCESS : types.LIST_INVOICES_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((error) => {
        if (error.message === "aborted") return;
        dispatch(failed(search ? types.SEARCH_INVOICES_FAILED : types.LIST_INVOICES_FAILED, error));
      });
  };
}

export function retrieveInvoice(id) {
  return (dispatch) => {
    dispatch(start(types.RETRIEVE_INVOICE_START));
    axios
      .get(`${ENDPOINT_INVOICES + id}/`)
      .then((response) => {
        dispatch(success(types.RETRIEVE_INVOICE_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.RETRIEVE_INVOICE_FAILED, error));
      });
  };
}

export function updateInvoice(id, invoice, { successCb, failureCb } = {}) {
  return (dispatch) => {
    dispatch(start(types.UPDATE_INVOICE_START));
    axios
      .patch(`${ENDPOINT_INVOICES + id}/`, invoice)
      .then((response) => {
        if (successCb) successCb();

        if (invoice?.successMsg) {
          openAlertModal(invoice.successMsg);
        }
        dispatch(success(types.UPDATE_INVOICE_SUCCESS, response.data));

        const eventObjMap = {
          "send for approval": EVENTS.click_send_for_approval_action,
          "approved": EVENTS.click_approve_invoice_action,
          "rejected": EVENTS.click_reject_invoice_action,
          "updated": EVENTS.click_edit_invoice_action,
          "mark as paid": EVENTS.click_mark_invoice_as_paid_action,
        };

        const event = eventObjMap[invoice.action.toLowerCase()];

        if (event) {
          trackEvent(event, {
            project_name: response.data.project.title,
            invoice_id: response.data.id,
            invoice_name: response.data.title,
            action: invoice.action,
            event_category: invoice.type === "purchase" ? CATEGORIES.payouts : CATEGORIES.payments,
          });
        }
      })
      .catch((error) => {
        if (failureCb) failureCb();

        const errorMsg = formatErrorResponse(error);
        openAlertModal(errorMsg.message, true);

        dispatch(failed(types.UPDATE_INVOICE_FAILED, errorMsg));
      });
  };
}

export function paymentReminder(invoice) {
  return (dispatch) => {
    dispatch(start(types.PAYMENT_REMINDER_START));
    axios
      .post(`${ENDPOINT_INVOICES}${invoice.id}/payment-reminder/`)
      .then((response) => {
        if (response.status === 200) {
          openAlertModal(response.data.message);
        }
        dispatch(success(types.PAYMENT_REMINDER_SUCCESS, response.data));

        trackEvent(EVENTS.click_send_invoice_reminder_action, {
          invoice_id: invoice.id,
          invoice_name: invoice.title,
          project_name: invoice.project.title,
          action: "send reminder",
        });
      })
      .catch((error) => {
        dispatch(failed(types.PAYMENT_REMINDER_FAILED, error));
      });
  };
}

export function listMoreInvoices(url, search = false) {
  return (dispatch) => {
    dispatch(start(search ? types.SEARCH_MORE_INVOICES_START : types.LIST_MORE_INVOICES_START));
    axios
      .get(url)
      .then((response) => {
        dispatch(
          success(
            search ? types.SEARCH_MORE_INVOICES_SUCCESS : types.LIST_MORE_INVOICES_SUCCESS,
            response.data,
          ),
        );
      })
      .catch((error) => {
        dispatch(
          failed(
            search ? types.SEARCH_MORE_INVOICES_FAILED : types.LIST_MORE_INVOICES_FAILED,
            error,
          ),
        );
      });
  };
}

export function deleteInvoice(invoice) {
  return (dispatch) => {
    dispatch(start(types.DELETE_INVOICE_START));
    axios
      .delete(`${ENDPOINT_INVOICES + invoice.id}/`)
      .then(() => {
        trackEvent(EVENTS.click_delete_invoice_action, {
          action: "delete",
          invoice_id: invoice.id,
          invoice_name: invoice.title,
          project_name: invoice?.full_title?.split(":")[0] || invoice?.project?.title,
          event_category: invoice.type === "purchase" ? CATEGORIES.payouts : CATEGORIES.payments,
        });

        dispatch(
          success(types.DELETE_INVOICE_SUCCESS, {
            id: invoice.id,
          }),
        );

        if (invoice?.scope && invoice?.scope === "project") {
          dispatch(fetchProject(invoice.project.id, true));
        }
      })
      .catch((error) => {
        dispatch(failed(types.DELETE_INVOICE_FAILED, error));
      });
  };
}

export function archiveInvoice(invoice) {
  return (dispatch) => {
    dispatch(start(types.ARCHIVE_INVOICE_START));
    axios
      .post(`${ENDPOINT_INVOICES}${invoice.id}/archive/`)
      .then(() => {
        openAlertModal("Payment archived successfully");

        dispatch(
          success(types.ARCHIVE_INVOICE_SUCCESS, {
            id: invoice.id,
          }),
        );

        trackEvent(EVENTS.click_archive_action, {
          invoice_id: invoice.id,
          invoice_name: invoice.title,
          project_name: invoice.project.title,
          action: "archived",
          event_category: CATEGORIES.payments,
        });
      })
      .catch((error) => {
        dispatch(failed(types.ARCHIVE_INVOICE_FAILED, error));
      });
  };
}

export function unarchiveInvoice(invoice, comment, { successCb, failureCb }) {
  return (dispatch) => {
    dispatch(start(types.UNARCHIVE_INVOICE_START));
    axios
      .patch(`${ENDPOINT_INVOICES}${invoice.id}/unarchive/`, { comment })
      .then(() => {
        openAlertModal("Payment unarchived successfully");

        if (successCb) successCb();

        dispatch(success(types.UNARCHIVE_INVOICE_SUCCESS, {}));

        trackEvent(EVENTS.click_unarchive_action, {
          invoice_id: invoice.id,
          invoice_name: invoice.title,
          project_name: invoice.project.title,
          action: "unarchived",
          event_category: CATEGORIES.payments,
        });
      })
      .catch((error) => {
        if (failureCb) failureCb();
        dispatch(failed(types.UNARCHIVE_INVOICE_FAILED, formatErrorResponse(error)));
      });
  };
}

export function generateInvoice(id) {
  return (dispatch) => {
    dispatch(start(types.GENERATE_INVOICE_START));
    axios
      .post(`${ENDPOINT_INVOICES}${id}/generate/`)
      .then((response) => {
        openAlertModal("Invoice generated successfully");

        dispatch(success(types.GENERATE_INVOICE_SUCCESS, response.data));
        trackEvent(EVENTS.click_generate_invoice_action, {
          project_name: response.data.project.title,
          invoice_id: response.data.id,
          invoice_name: response.data.title,
          action: "generate",
          event_category: CATEGORIES.payments,
        });
      })
      .catch((error) => {
        dispatch(failed(types.GENERATE_INVOICE_FAILED, error));
      });
  };
}

export function bulkAction(invoices, action) {
  return (dispatch) => {
    dispatch(start(types.BULK_ACTION_START));
    axios
      .patch(`${ENDPOINT_INVOICES}bulk/edit/`, {
        invoices,
        action,
      })
      .then((response) => {
        const message = {
          approve: "Invoices approved",
          archive: "Invoices archived",
          mark_as_paid: "Invoices  marked as paid",
          generate: "Invoices generated",
          delete: "Invoices deleted",
        };

        openAlertModal(action === "send_reminder" ? response.data.message : message[action]);

        dispatch(success(types.BULK_ACTION_SUCCESS, {}));
      })
      .catch((error) => {
        dispatch(failed(types.BULK_ACTION_FAILED, error));
      });
  };
}

export function getInvoiceSummary(params, { successCb, failureCb }) {
  return (dispatch) => {
    dispatch(start(types.INVOICE_SUMMARY_START));
    axios
      .get(`${ENDPOINT_INVOICES_SUMMARY}`, {
        params,
        cancelToken: getCancelToken("getInvoiceSummary"),
      })
      .then((response) => {
        if (successCb) successCb();
        dispatch(success(types.INVOICE_SUMMARY_SUCCESS, response.data));
      })
      .catch((error) => {
        if (failureCb) failureCb();

        if (error.message === "aborted") return;
        dispatch(failed(types.INVOICE_SUMMARY_FAILED, error));
      });
  };
}

export function getPlatformSummary(params) {
  return (dispatch) => {
    dispatch(start(types.GET_PLATFORM_SUMMARY_START));
    axios
      .get(`${ENDPOINT_GET_PLATFORM_SUMMARY}`, {
        params,
      })
      .then((response) => {
        dispatch(success(types.GET_PLATFORM_SUMMARY_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.GET_PLATFORM_SUMMARY_FAILED, error));
      });
  };
}

export function getInvoiceActivity(id) {
  return (dispatch) => {
    dispatch(start(types.INVOICE_ACTIVITY_START));

    axios
      .get(`${ENDPOINT_ACTIVITIES}?invoice=${id}`)
      .then((response) => {
        dispatch(success(types.INVOICE_ACTIVITY_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.INVOICE_ACTIVITY_FAILED, formatErrorResponse(error)));
      });
  };
}

export function addInvoiceComments(invoiceId, comment, callback) {
  return (dispatch) => {
    dispatch(start(types.CREATE_INVOICE_COMMENT_START));

    axios
      .post(`${ENDPOINT_INVOICES}${invoiceId}/comments/`, { body: comment })
      .then((response) => {
        if (callback) callback();
        dispatch(success(types.CREATE_INVOICE_COMMENT_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.CREATE_INVOICE_COMMENT_FAILED, formatErrorResponse(error)));
      });
  };
}

export function getInvoiceComments(invoiceId) {
  return (dispatch) => {
    dispatch(start(types.GET_INVOICE_COMMENTS_START));

    axios
      .get(`${ENDPOINT_ACTIVITIES}?invoice=${invoiceId}&verb=invoice_comment`)
      .then((response) => {
        dispatch(success(types.GET_INVOICE_COMMENTS_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.GET_INVOICE_COMMENTS_FAILED, formatErrorResponse(error)));
      });
  };
}
