/* eslint-disable react/jsx-props-no-spreading */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { Suspense, useEffect, useState } from "react";

import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { LOCATION_CHANGE } from "react-router-redux";

import BootLogo from "./components/BootLogo";
import ProtectedRoute from "./components/ProtectedRoute";
/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import { authRoutes, childRoutes } from "./configs/routing/Routes.conf";
import usePrevious from "./hooks/usePrevious";
import Onboarding from "./pages/Dashboard/onboarding";
import { verify } from "./redux/actions/AuthActions";
import store from "./redux/store";
import { usePageTracking } from "./utils/analytics";

const App = (props) => {
  usePageTracking();

  const dispatch = useDispatch();
  const { user, isMakingRequest } = useSelector(({ Auth }) => Auth);

  const location = useLocation();
  const prevLocation = usePrevious(location);

  const [appReady, setAppReady] = useState(false);

  useEffect(() => {
    if (!user?.id) {
      dispatch(verify());
    }
  }, []);

  // Dispatch when location changes
  useEffect(() => {
    if (user?.id && !isEqual(location, prevLocation)) {
      store.dispatch({ type: LOCATION_CHANGE });
    }
  }, [location, user.id]);

  // Handle App Ready
  useEffect(() => {
    if (!isMakingRequest.verify) {
      // Wait one second to prevent flickering
      setTimeout(() => setAppReady(true), 1000);
    }
  }, [isMakingRequest.verify]);

  const rootProps = props;

  return (
    <Suspense fallback={<BootLogo />}>
      <Switch>
        {!appReady ? (
          <BootLogo />
        ) : (
          <>
            {authRoutes.map((route, i) => (
              <Route
                exact={route.exact}
                key={i}
                render={(routeProps) => (
                  <route.component
                    name={route.name}
                    childRoutes={route.childRoutes}
                    {...rootProps}
                    {...routeProps}
                  />
                )}
                path={route.path}
              />
            ))}

            <ProtectedRoute path="/onboard" component={Onboarding} />

            {childRoutes.map((route, i) => {
              return route.redirect ? (
                <Redirect key={i} to={route.redirect} />
              ) : (
                <ProtectedRoute
                  exact={route.exact}
                  key={i}
                  name={route.name}
                  rootProps={rootProps}
                  component={route.component}
                  path={route.path}
                />
              );
            })}
          </>
        )}

        <Redirect from="/signin" to="/login" />
        <Redirect exact from="/signup" to="/login" />
      </Switch>
    </Suspense>
  );
};

export default App;
