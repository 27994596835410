/* eslint-disable default-param-last */
import axios from "axios";

import { ENDPOINT_TIMESHEET_ENTRIES, ENDPOINT_TIMESHEET_ENTRY } from "../../utils/api";
import { failed, formatErrorResponse, start, success } from "../../utils/actions";
import * as actionTypes from "../../configs/constants/ActionTypes";
import { trackEvent } from "../../analytics/segment";
import { CATEGORIES, EVENTS } from "../../analytics/events";

const listTimeSheetsMonthlyEntries = (projectId, yearMonth, userId, silent = false) => {
  return (dispatch) => {
    if (!silent) dispatch(start(actionTypes.LIST_TIMESHEET_MONTHLY_ENTRIES_START));
    axios
      .get(
        `${ENDPOINT_TIMESHEET_ENTRIES}summary/?year_month=${yearMonth}&project=${projectId}&user=${userId}`,
      )
      .then((response) => {
        dispatch(success(actionTypes.LIST_TIMESHEET_MONTHLY_ENTRIES_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(
          failed(actionTypes.LIST_TIMESHEET_MONTHLY_ENTRIES_FAILED, formatErrorResponse(error)),
        );
      });
  };
};

const listTimeSheetsYearlyEntries = (projectId, year) => {
  return (dispatch) => {
    dispatch(start(actionTypes.LIST_TIMESHEET_YEARLY_ENTRIES_START));
    axios
      .get(`${ENDPOINT_TIMESHEET_ENTRIES}timesheets-summary/?year=${year}&project=${projectId}`)
      .then((response) => {
        dispatch(success(actionTypes.LIST_TIMESHEET_YEARLY_ENTRIES_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(
          failed(actionTypes.LIST_TIMESHEET_YEARLY_ENTRIES_FAILED, formatErrorResponse(error)),
        );
      });
  };
};

const approveRejectEntries = (
  { projectId, userId, yearMonth, action, reason },
  { successCb, failureCb },
) => {
  return (dispatch, getState) => {
    dispatch(start(actionTypes.TIMESHEET_APPROVE_REJECT_START));

    const payload = {
      project: projectId,
      user: userId,
      year_month: yearMonth,
      action,
      reason,
    };

    axios
      .post(`${ENDPOINT_TIMESHEET_ENTRY}timesheet-edit/`, payload)
      .then((response) => {
        if (successCb) successCb();
        dispatch(success(actionTypes.TIMESHEET_APPROVE_REJECT_SUCCESS, response.data));

        const {
          Projects: { project },
        } = getState();

        const event =
          action === "reject" ? EVENTS.reject_timesheet_hours : EVENTS.approve_timesheet_hours;

        trackEvent(event, {
          project_type: project.stage === "active" ? project.category : project.stage,
          project_id: project.id,
          project_name: project.title,
          event_category: CATEGORIES.projects,
        });
      })
      .catch((error) => {
        if (failureCb) failureCb();

        dispatch(failed(actionTypes.TIMESHEET_APPROVE_REJECT_FAILED, formatErrorResponse(error)));
      });
  };
};

const getUserTimeSheetEntries = (userId, projectId, yearMonth) => {
  return (dispatch) => {
    axios
      .get(
        `${ENDPOINT_TIMESHEET_ENTRIES}?year_month=${yearMonth}&project=${projectId}&user=${userId}`,
      )
      .then((response) => {
        dispatch(
          success(actionTypes.GET_USER_TIMESHEET_ENTRIES_SUCCESS, {
            userId,
            fetched: true,
            entries: response.data.results,
          }),
        );
      })
      .catch((error) => {
        dispatch(failed(actionTypes.GET_USER_TIMESHEET_ENTRIES_FAILED, formatErrorResponse(error)));
      });
  };
};

const updateTimeSheetEntry = (timesheet_entries, userId) => {
  return (dispatch, getState) => {
    dispatch(start(actionTypes.UPDATE_TIMESHEET_ENTRY_START));

    axios
      .post(`${ENDPOINT_TIMESHEET_ENTRY}bulk/`, timesheet_entries)
      .then((response) => {
        dispatch(success(actionTypes.RESET_USER_TIMESHEET_ENTRIES, { userId }));
        dispatch(success(actionTypes.UPDATE_TIMESHEET_ENTRY_SUCCESS, response.data));

        const {
          Projects: { project },
        } = getState();

        trackEvent(EVENTS.submit_timesheet_hours, {
          project_type: project.stage === "active" ? project.category : project.stage,
          project_id: project.id,
          project_name: project.title,
          event_category: CATEGORIES.projects,
        });
      })
      .catch((error) => {
        dispatch(failed(actionTypes.UPDATE_TIMESHEET_ENTRY_FAILED, formatErrorResponse(error)));
      });
  };
};

export {
  listTimeSheetsMonthlyEntries,
  listTimeSheetsYearlyEntries,
  approveRejectEntries,
  getUserTimeSheetEntries,
  updateTimeSheetEntry,
};
