/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";

/* ------------------------- Component dependencies ------------------------- */
import { AnimatedButton } from "../../../../components/Button";
import FormInput from "./FormInput";

const ChangeEmail = ({ onSave }) => {
  const { user } = useSelector(({ Auth }) => Auth);

  const [email, setEmail] = useState(user?.email || "");

  return (
    <form onSubmit={onSave} aria-label="changeEmail-form" id="changeEmail">
      <div className="header">Change Email Address</div>

      <Row>
        <Col>
          <FormInput
            id="email"
            name="email"
            placeholder="Enter new email address"
            type="email"
            label="New Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Col>
        <Col>
          <FormInput
            id="password"
            name="password"
            placeholder="Enter your password"
            type="password"
            label="Password"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <AnimatedButton
            targetform="#changeEmail"
            className="loader-opaque"
            type="submit"
            disabled={user.email === email}
          >
            Save
          </AnimatedButton>
        </Col>
      </Row>
    </form>
  );
};

ChangeEmail.propTypes = {
  onSave: PropTypes.func,
};

export default ChangeEmail;
