import styled from "styled-components";
import DateTimePicker from "../../../../components/DateTimePicker";

export const Wrapper = styled.div`
  padding: 40px 40px 0px 40px;

  .header {
    border-bottom: 1px solid #edf1f7;
    padding-bottom: 40px;

    h4 {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #151a30;
      margin-bottom: 8px;
    }
    p {
      margin: 0;
      font-size: 16px;
      line-height: 29px;
      color: #3e4857;
    }
  }

  .section {
    padding: 40px 0;
    border-bottom: 1px solid #edf1f7;

    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #151a30;
      margin-bottom: 16px;
    }

    &::last-of-type {
      border-bottom: none;
    }

    .form-group:last-of-type {
      margin-bottom: 0px;
    }

    .input-group {
      box-shadow: none;
    }

    input,
    textarea {
      font-size: 16px;
      color: #8f9bb3;
      background: #ffffff;
      border: 1px solid rgba(194, 204, 217, 0.5);
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: none;

      &::placeholder {
        color: #8f9bb3;
        font-weight: normal;
      }
    }

    .description {
      min-height: 100px;
    }

    .radio-group {
      display: flex;
      justify-content: space-between;

      label {
        display: flex;
        font-size: 16px;
        line-height: 24px;
        color: #151a30;

        input {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
`;

export const StyledDateTimePicker = styled(DateTimePicker)`
  &.tg-date-field {
    display: inline-block;
    border: 1px solid rgba(194, 204, 217, 0.25);
    box-shadow: initial;
    box-shadow: initial;
    width: 100%;
    margin-bottom: 24px;

    .rw-input {
      background-color: #fff;
      font-size: 16px;
      color: #3e4857;
      margin-bottom: 0;
      border-left: 1px solid rgba(194, 204, 217, 0.5);
      border-bottom: 1px solid rgba(194, 204, 217, 0.5);
      border-top: 1px solid rgba(194, 204, 217, 0.5);
      box-shadow: none;
    }

    .rw-select {
      background-color: #fff;
      border-left: none;
      border-right: 1px solid rgba(194, 204, 217, 0.5);
      border-top: 1px solid rgba(194, 204, 217, 0.5);
      border-bottom: 1px solid rgba(194, 204, 217, 0.5);
      width: 2.9em;
      button.rw-btn {
        color: #8f9bb3;
        width: 2.6em;
        font-size: 16px;
      }
    }
  }
`;
