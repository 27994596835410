/** *
 * Created by Kazeem Olanipekun
 * This is where all route declaration is done and reference to when routing to different pages.
 */

export default {
  home: { path: "/", name: "Tunga" },
  userAgreement: {
    path: "/agreement",
    name: "User Agreement",
  },
  login: {
    path: "/login",
    name: "Log in",
    loc: "login",
  },
  signup: {
    path: "/signup/invite/:invitationKey",
    name: "Sign up",
    loc: "signup",
  },
  forgotPassword: {
    path: "/forgot-password/",
    name: "Forgot Password",
  },
  resetPassword: {
    path: "/reset-password/confirm/:uid/:token",
    name: "Reset Password",
  },
  resetClientPassword: {
    path: "/password/:uid/:token",
    name: "Create Account",
  },
  dashboard: {
    path: "/dashboard",
    name: "Dashboard",
    loc: "dashboard",
  },
};
