import axios from "axios";

import { OPPORTUNITIES } from "../../utils/api";
import { success, start, failed, formatErrorResponse } from "../../utils/actions";
import * as types from "../../configs/constants/ActionTypes";

export function fetchOpportunities() {
  return (dispatch) => {
    dispatch(start(types.FETCH_OPPORTUNITIES_START));

    axios
      .get(OPPORTUNITIES)
      .then((response) => {
        dispatch(success(types.FETCH_OPPORTUNITIES_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.FETCH_OPPORTUNITIES_FAILED, formatErrorResponse(error)));
      });
  };
}

export function fetchOpportunity(id) {
  return (dispatch) => {
    dispatch(start(types.FETCH_OPPORTUNITY_START));
    axios
      .get(`${OPPORTUNITIES + id}/`)
      .then((response) => {
        dispatch(success(types.FETCH_OPPORTUNITY_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.FETCH_OPPORTUNITY_FAILED, formatErrorResponse(error)));
      });
  };
}

export function showInterest(id) {
  return (dispatch) => {
    dispatch(start(types.UPDATE_INTEREST_START));
    axios
      .post(`${OPPORTUNITIES + id}/accept/`)
      .then((response) => {
        dispatch(success(types.UPDATE_INTEREST_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.UPDATE_INTEREST_FAILED, formatErrorResponse(error)));
      });
  };
}

export function removeInterest(id) {
  return (dispatch) => {
    dispatch(start(types.UPDATE_INTEREST_START));
    axios
      .post(`${OPPORTUNITIES + id}/reject/`)
      .then((response) => {
        dispatch(success(types.UPDATE_INTEREST_SUCCESS, response.data));
      })
      .catch((error) => {
        dispatch(failed(types.UPDATE_INTEREST_FAILED, formatErrorResponse(error)));
      });
  };
}
