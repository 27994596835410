/* eslint-disable react/jsx-props-no-spreading */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

/* -------------------------- Internal Dependencies ------------------------- */
import Avatar from "../../../../../components/Avatar";
import IconButton from "../../../../../components/IconButton";
import { generateUserInitials } from "../../../../../utils/stringUtils";
import SelectDaysChecks from "./SelectDaysChecks";
import { isCMOrCSOAndHasProjectAcess, getPermissionByCodeName } from "../../../../../utils/auth";
import { updateProject, updateParticipation } from "../../../../../redux/actions/ProjectActions";

/* --------------------------- Component proptypes -------------------------- */

const Reports = () => {
  const canUpdateReportsNotifications = getPermissionByCodeName("can-update-reports-notifications");

  const dispatch = useDispatch();

  const { project } = useSelector(({ Projects }) => Projects);

  const toggleDropdown = (event) => {
    if (canUpdateReportsNotifications) {
      const dropdownContent = event.target.parentNode.parentNode.parentNode.nextElementSibling;
      dropdownContent?.classList.toggle("closed");
      const resultsContainer = dropdownContent?.nextElementSibling;
      if (resultsContainer) {
        resultsContainer?.classList.toggle("closed");
      }
    }
  };

  const toggleUpdates = (participation) => {
    if (canUpdateReportsNotifications) {
      updateParticipation(participation.id, {
        updates_enabled: !participation.updates_enabled,
      })(dispatch);
    }
  };

  const toggleNofitications = () => {
    updateProject(
      project.id,
      {
        notifications_disabled: !project.notifications_disabled,
      },
      {},
    )(dispatch);
  };

  const save = (event, id, days) => {
    const updateDays = days.filter((day) => day.isChecked).map((day) => day.value);
    updateParticipation(id, {
      day_selection_for_updates: true,
      update_days: updateDays.toString(),
    })(dispatch);
    const daysCheckboxes = event.target;
    daysCheckboxes?.classList.toggle("closed");
    const resultsContainer = event.target.nextElementSibling;
    if (resultsContainer) {
      resultsContainer?.classList.toggle("closed");
    }
  };

  const updateDays = {
    0: "Monday",
    1: "Tuesday",
    2: "Wednesday",
    3: "Thursday",
    4: "Friday",
  };

  return (
    <StyledWrapper>
      <div
        className="header"
        style={{
          marginBottom: project.participation.length > 0 ? "16px" : 0,
        }}
      >
        <h3>Team members</h3>
        {(isCMOrCSOAndHasProjectAcess(project) || canUpdateReportsNotifications) && (
          <>
            <span>All Notifications: </span>&nbsp;
            <button
              type="button"
              data-testid="notifications"
              onClick={() => toggleNofitications()}
              className={`toggle-btn updates-switch ${
                !project.notifications_disabled ? "completed" : "pending"
              }`}
            >
              {!project.notifications_disabled ? "On" : "Off"}
            </button>{" "}
          </>
        )}
      </div>

      {project.participation.map((participation) => (
        <div className="item-section" key={`team-member-${participation.id}`}>
          <div className="item">
            <Avatar
              image={participation.user.avatar_url}
              initials={generateUserInitials(participation.user)}
              size="dash"
            />
            <span>{participation.user.display_name}</span>
            <span className="member-title">- Developer</span>

            <div className="item-actions">
              {(isCMOrCSOAndHasProjectAcess(project) || canUpdateReportsNotifications) && (
                <>
                  <button
                    type="button"
                    data-testid="updates"
                    onClick={() => toggleUpdates(participation)}
                    className={`updates-switch ${
                      participation.updates_enabled && !project.notifications_disabled
                        ? "completed"
                        : "pending"
                    }`}
                  >
                    {participation.updates_enabled && !project.notifications_disabled
                      ? "On"
                      : "Off"}
                  </button>
                  <IconButton
                    data-testid={`${participation.id}-dropdown`}
                    name="circle-edit-outline"
                    onClick={(e) => toggleDropdown(e)}
                    disabled={!participation.updates_enabled || project.notifications_disabled}
                  />
                </>
              )}
            </div>
          </div>
          <SelectDaysChecks save={save} {...participation} className="edit-section closed" />

          {participation.updates_enabled &&
          !project.notifications_disabled &&
          participation.update_days !== "" ? (
            <div className="selected-days">
              {participation.update_days
                .split(",")
                .map((day) => updateDays[day])
                .join(", ")}
            </div>
          ) : null}
        </div>
      ))}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #edf1f7;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 40px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #151a30;
    margin: 0 auto 0 0;
  }

  .toggle-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border-radius: 50px;
    padding: 6px 24px;
    cursor: pointer;

    &.pending {
      color: #8f9bb3;
      background: rgba(143, 155, 179, 0.05);
      border: 1px solid rgba(143, 155, 179, 0.04);
    }

    &.completed {
      color: #219653;
      background: rgba(33, 150, 83, 0.04);
      border: 1px solid rgba(33, 150, 83, 0.04);
    }
  }

  .item-section {
    background: #ffffff;
    border: 1px solid #edf1f7;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 40px;
    padding: 16px;

    .selected-days {
      background: rgba(237, 241, 247, 0.15);
      border: 1px solid #edf1f7;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 16px;
      margin-top: 8px;
      margin-left: 39px;
      width: fit-content;

      &.closed {
        display: none;
      }
    }

    .edit-section {
      &.closed {
        display: none;
      }
    }

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;

      &:last-of-type {
        margin-bottom: 0;
      }

      .avatar.avatar-dash {
        width: 35px;
        height: 35px;

        &.avatar-initials {
          font-size: 14px;
        }
      }

      > span {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #3e4857;

        &.member-title {
          color: #8f9bb3;
        }
      }

      .item-actions {
        margin: 0 0 0 auto;

        button {
          box-shadow: none;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          border-radius: 50px;
          padding: 6px 24px;

          &.updates-switch {
            cursor: pointer;
            margin-right: -22px;
          }

          &.pending {
            color: #8f9bb3;
            background: rgba(143, 155, 179, 0.05);
            border: 1px solid rgba(143, 155, 179, 0.04);
          }

          &.completed {
            color: #219653;
            background: rgba(33, 150, 83, 0.04);
            border: 1px solid rgba(33, 150, 83, 0.04);
          }
        }

        button i {
          margin-left: 30px;
          color: #8f9bb3;
          font-size: 20px;
        }

        button:disabled i {
          color: #8f9bb3;
          opacity: 0.5;
        }
      }
    }
  }
`;

export default Reports;
