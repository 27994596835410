/* eslint-disable default-param-last */
import { combineReducers } from "redux";
import * as actionTypes from "../../configs/constants/ActionTypes";

const payoneer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.GET_PAYONEER_SUCCESS:
      return action.data.results;
    default:
      return state;
  }
};

const wise = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_WISE_SUCCESS: {
      return action.data;
    }

    default:
      return state;
  }
};

const iban = (state = [], action) => {
  switch (action.type) {
    case actionTypes.GET_IBAN_SUCCESS: {
      return action.data.results;
    }

    default:
      return state;
  }
};

const dollarAccount = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_DOLLAR_ACCOUNT_SUCCESS: {
      return action.data.results;
    }

    default:
      return state;
  }
};

const payoneerProvider = (state = [], action) => {
  switch (action.type) {
    case actionTypes.ADD_PAYONEER_SUCCESS:
      return { ...action.data, success: true };

    case actionTypes.GET_PAYONEER_SUCCESS:
      return {};

    default:
      return state;
  }
};

const wiseProvider = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CONNECT_WISE_SUCCESS:
      return action.data;

    case actionTypes.FETCH_WISE_SUCCESS:
      return {};

    default:
      return state;
  }
};

const dollarAccountProvider = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CONNECT_DOLLAR_ACCOUNT_SUCCESS:
      return { ...action.data, success: true };

    case actionTypes.FETCH_DOLLAR_ACCOUNT_SUCCESS:
      return {};

    default:
      return state;
  }
};

const ibanProvider = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_IBAN_SUCCESS:
      return { ...action.data, success: true };

    case actionTypes.GET_IBAN_SUCCESS:
      return {};

    default:
      return state;
  }
};

const paymentProvider = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_PROVIDERS_SUCCESS:
    case actionTypes.PATCH_CHANGE_DEFAULT_ACCOUNT_SUCCESS:
      return { ...state, ...action.data, success: true };

    default:
      return state;
  }
};

const isMakingRequest = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_WISE_START:
      return { ...state, fetchWise: true };

    case actionTypes.CONNECT_WISE_START:
      return { ...state, connectWise: true };

    case actionTypes.FETCH_DOLLAR_ACCOUNT_START:
      return { ...state, fetchDollarAccount: true };

    case actionTypes.CONNECT_DOLLAR_ACCOUNT_START:
      return { ...state, connectDollarAccount: true };

    case actionTypes.GET_PAYONEER_START:
      return { ...state, getPayoneer: true };

    case actionTypes.ADD_PAYONEER_START:
      return { ...state, addPayoneer: true };

    case actionTypes.FETCH_PAYMENT_PROVIDERS_START:
      return { ...state, paymentProviders: true };

    case actionTypes.PATCH_CHANGE_DEFAULT_ACCOUNT_START:
      return { ...state, updateDefaultAccount: true };

    case actionTypes.PATCH_CHANGE_DEFAULT_ACCOUNT_SUCCESS:
    case actionTypes.PATCH_CHANGE_DEFAULT_ACCOUNT_FAILED:
      return { ...state, updateDefaultAccount: false };

    case actionTypes.ADD_IBAN_START:
      return { ...state, addIban: true };

    case actionTypes.GET_IBAN_START:
      return { ...state, getIban: true };

    default:
      return {};
  }
};

const errors = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYONEER_FAILED:
      return { getPayoneer: action.error };

    case actionTypes.FETCH_WISE_FAILED:
      return { fetchWise: action.error };

    case actionTypes.ADD_PAYONEER_START:
    case actionTypes.ADD_PAYONEER_SUCCESS:
      return { addPayoneer: null };

    case actionTypes.ADD_PAYONEER_FAILED:
      return { addPayoneer: action.error };

    case actionTypes.CONNECT_WISE_START:
    case actionTypes.CONNECT_WISE_SUCCESS:
      return { connectWise: null };

    case actionTypes.CONNECT_WISE_FAILED:
      return { connectWise: action.error };

    case actionTypes.CONNECT_DOLLAR_ACCOUNT_START:
    case actionTypes.CONNECT_DOLLAR_ACCOUNT_SUCCESS:
      return { connectDollarAccount: null };

    case actionTypes.CONNECT_DOLLAR_ACCOUNT_FAILED:
      return { connectDollarAccount: action.error };

    case actionTypes.PATCH_CHANGE_DEFAULT_ACCOUNT_START:
    case actionTypes.PATCH_CHANGE_DEFAULT_ACCOUNT_SUCCESS:
      return { updateDefaultAccount: null };
    case actionTypes.PATCH_CHANGE_DEFAULT_ACCOUNT_FAILED:
      return { updateDefaultAccount: action.error };

    case actionTypes.GET_IBAN_FAILED:
      return { getIban: action.error };

    case actionTypes.ADD_IBAN_FAILED:
      return { addIban: action.error };

    case actionTypes.ADD_IBAN_START:
    case actionTypes.ADD_IBAN_SUCCESS:
      return { addIban: false };

    default:
      return state;
  }
};

const Payments = combineReducers({
  payoneer,
  wise,
  iban,
  dollarAccount,
  ibanProvider,
  payoneerProvider,
  wiseProvider,
  dollarAccountProvider,
  paymentProvider,
  isMakingRequest,
  errors,
});

export default Payments;
