import React, { useEffect, useMemo, useState } from "react";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import ReactTable from "../../../../components/ReactTable";
import { RESET_SKILLS_ERRORS } from "../../../../configs/constants/ActionTypes";
import useDidUpdate from "../../../../hooks/useDidUpdate";
import { listSkills, updateSkill } from "../../../../redux/actions/SkillAction";
import { cancelLastRequest } from "../../../../utils/api";
import randomstring from "../../../../utils/generateRandomString";
import { createModal, openAlertModal } from "../../../../utils/modals";
import { ContentSection } from "../../../../utils/styles";
import EditSkillForm from "./EditSkillForm";
import SkillsContainer from "./SkillsContainer";

const SkillsLibrary = () => {
  const [selection] = useState({ selectionKey: randomstring.generate() });
  const { isFetching, isSaved, skillLibrary } = useSelector(({ Skill }) => Skill);
  const skillsData = skillLibrary?.results || [];
  const count = skillLibrary?.count;
  const [page_size, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [skill_type, setSkillType] = useState({ value: "", label: "Show: all" });

  const dispatch = useDispatch();

  const onLoadMore = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(+pageSize);
  };

  const fetchSkills = (page, search = "") => {
    const updatedFilters = {
      page,
      search,
      page_size,
      type: skill_type.value,
    };
    cancelLastRequest("listSkills");
    listSkills({ ...(updatedFilters || {}) }, selection.selectionKey)(dispatch);
  };

  useEffect(() => {
    fetchSkills(1);
    setCurrentPage(0);
  }, [skill_type]);

  useDidUpdate(() => {
    fetchSkills(currentPage + 1);
  }, [isSaved, page_size, currentPage]);

  const handleEditSkill = (id, skills_name, skills_type) => {
    const modal = createModal({
      body: (
        <EditSkillForm
          id="edit-skill-form"
          data-testid="edit-skill-form"
          skill_name={skills_name}
          skill_type={skills_type}
        />
      ),
      options: {
        title: "Update skill",
        canClose: true,
        ok: "Save Changes",
        form: {
          type: "submit",
          form: "edit-skill-form",
        },
      },
      beforeClose: () => {
        dispatch({ type: RESET_SKILLS_ERRORS });
      },
      proceed: ({ name, type }) => {
        dispatch({ type: RESET_SKILLS_ERRORS });
        modal.setIsLoading(true);
        const feedbackCb = {
          successCb: () => {
            modal.setIsLoading(false);
            modal.close();
            openAlertModal("Skill edited successfully");
            dispatch(listSkills());
          },
          failureCb: () => {
            modal.setIsLoading(false);
          },
        };
        dispatch(updateSkill(id, { name, type }, feedbackCb));
      },
    });
    modal.open();
  };

  const data = useMemo(
    () => [
      ...skillsData.map((i) => {
        const row = {
          name: i.name,
          type: i.type,
          popularity: i.popularity,
          developers: i.num_developers,
          date_created: i.created_at,
          actions: { id: i.id, skill: i.name, type: i.type },
        };
        return row;
      }),
    ],
    [skillsData],
  );

  const columns = useMemo(
    () => [
      {
        Header: "Skills",
        accessor: "name",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Popularity",
        accessor: "popularity",
      },
      {
        Header: "No. of Developers",
        accessor: "developers",
      },
      {
        Header: "Date Created",
        accessor: "date_created",
      },
      {
        Header: "",
        accessor: "actions",
      },
    ],
    [],
  );

  const getTableDisplayValue = (cell) => {
    const key = `${cell.column.id}-${cell.row.id}`;
    switch (cell.column.id) {
      case "name":
        return (
          <td key={`name${key}`} className="nowrap">
            {cell.value}
          </td>
        );
      case "type":
        return (
          <td key={`type${key}`} className="nowrap">
            {cell.value}
          </td>
        );
      case "popularity":
        return (
          <td key={`popularity${key}`} className="nowrap">
            {cell.value}%
          </td>
        );
      case "developers":
        return (
          <td key={`developers${key}`} className="nowrap">
            {cell.value}
          </td>
        );
      case "date_created":
        return (
          <td key={`date_created${key}`} className="nowrap">
            {moment.utc(cell.value).format("MMM D YYYY")}
          </td>
        );

      case "actions":
        return (
          <td key={`actions${key}`} className="d-table-cell">
            <div className="dropdown">
              <button
                style={{ boxShadow: "none" }}
                className="btn"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                type="button"
              >
                <span
                  style={{ fontSize: "1.5rem", color: "#8F9BB3" }}
                  data-testid={`dot-${cell.value.id}`}
                >
                  ...
                </span>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button
                  type="button"
                  data-testid={`edit-${cell.value.id}`}
                  className="dropdown-item"
                  style={{ background: "#fff", color: "#3e4857", cursor: "pointer" }}
                  onClick={() => handleEditSkill(cell.value.id, cell.value.skill, cell.value.type)}
                >
                  Edit skill
                </button>
              </div>
            </div>
          </td>
        );
      default:
        return null;
    }
  };

  return (
    <SkillsContainer skill_type={skill_type} setSkillType={setSkillType} fetchSkills={fetchSkills}>
      <ContentSection style={{ paddingTop: "0" }}>
        <div className="section">
          <ReactTable
            scope="skill_library"
            tableData={data}
            tableColumns={columns}
            currentPage={currentPage}
            count={count}
            getTableDisplayValue={getTableDisplayValue}
            loadPage={onLoadMore}
            pageSize={page_size}
            isLoading={isFetching.loading}
            noDataMessage="No skills found"
          />
        </div>
      </ContentSection>
    </SkillsContainer>
  );
};

export default SkillsLibrary;
