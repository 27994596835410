/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import styled from "styled-components";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import Progress from "./Progress";
import Logo from "../assets/images/logo_new_color.png";

const BootLogo = () => {
  return (
    <Wrapper className="boot-logo">
      <div>
        <img className="logo" src={Logo} alt="Logo" />
      </div>
      <Progress />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100vh;
  background-color: #f8f8f8;

  img.logo {
    height: 50px;
  }

  .loading {
    text-align: center;
    margin: 20px auto;
  }
`;

export default BootLogo;
