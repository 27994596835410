/* eslint-disable react/jsx-props-no-spreading */
/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";

/* -------------------- Internel Dependencies (Utilites) -------------------- */
import {
  canViewPaymentTab,
  canViewPayoutTab,
  getPermissionByCodeName,
  isAdmin,
  isClient,
  isCSO,
  isDev,
  isPM,
  isSpaceLead,
} from "../utils/auth";
/* ------------------------- Component dependencies ------------------------- */
import TitleBarContent from "./TitleBarContent";
import TitleBarSwitch from "./TitleBarSwitch";

/* --------------------------- Component proptypes -------------------------- */
const proptypes = {
  user: PropTypes.shape({}),
  Project: PropTypes.shape({}),
};

const NavLinks = () => {
  const canViewPlan = getPermissionByCodeName("can-view-plan");
  const canViewReports = getPermissionByCodeName("can-view-reports");
  const canViewDocuments = getPermissionByCodeName("can-view-documents");
  const canViewArchivedProjects = getPermissionByCodeName("can-view-archived-projects");
  const canViewDedicatedProjects = getPermissionByCodeName("can-view-dedicated-projects");
  const canViewManagedProjects = getPermissionByCodeName("can-view-managed-projects");
  const canViewOpportunityProjects = getPermissionByCodeName("can-view-opportunity-projects");
  const canToggleViewAllProjects = getPermissionByCodeName("can-toggle-view-all-projects");

  const { project } = useSelector(({ Projects }) => Projects);

  const getUrl = (section) => {
    return (match) => {
      return `/projects/${match.params.projectId}/${section}`;
    };
  };

  const shortlistLength = () => {
    return <LinkBadge>{project?.total_shortlisted}</LinkBadge>;
  };

  const projectsSections = [
    ...(canViewDedicatedProjects ? [["/projects/dedicated", "Dedicated Developers"]] : []),
    ...(canViewManagedProjects ? [["/projects/managed", "Managed Projects"]] : []),
    ...(canViewOpportunityProjects ? [["/projects/opportunity", "Shortlists"]] : []),
    ...(canViewArchivedProjects ? [["/projects/archived", "Archive"]] : []),
    ...(canToggleViewAllProjects ? [[null, <TitleBarSwitch key="title-bar-switch" />]] : []),
  ];

  const projectDetailSections = project?.id
    ? project?.stage === "opportunity"
      ? [
          [getUrl("details"), "Details"],
          ...(!isDev()
            ? [
                [getUrl("network/shortlist"), "Shortlist", shortlistLength()],
                [getUrl("network/talent-pool"), "Talent Pool"],
                ...(!isClient() ? [[getUrl("settings"), "Settings"]] : []),
              ]
            : []),
        ]
      : [
          [getUrl("pay"), "Payments"],
          ...(canViewPlan ? [[getUrl("plan"), "Planning"]] : []),
          ...(canViewReports ? [[getUrl("reports"), "Reports"]] : []),
          ...(canViewDocuments ? [[getUrl("docs"), "Documents"]] : []),
          ...(!isClient() && !isDev() ? [[getUrl("settings"), "Settings"]] : []),
        ]
    : [];

  const paymentSections = [
    ...(canViewPaymentTab() ? [["/payments/in", "Payments"]] : []),

    ...(canViewPayoutTab() ? [["/payments/out", "Payouts"]] : []),
  ];

  const settingsSections = [
    ...(isDev() || isPM() || isCSO() || isSpaceLead()
      ? [
          ["/settings/profile", "Profile"], // All
          ["/settings/personal-details", "Personal details"], // All
          ["/settings/payment", "Payment"],
          ["/settings/account", "Account"], // All
          ["/settings/privacy", "Privacy"],
          ...(isPM() || isSpaceLead()
            ? [
                ["/settings/users", "Users"],
                ["/settings/skills", "Skills Library"],
              ]
            : []),
        ]
      : [
          ["/settings/personal-details", "Personal details"], // All
          ...(!isAdmin()
            ? [
                ["/settings/company-profile", "Company Profile"],
                ["/settings/company-details", "Company Details"],
              ]
            : []),
          ["/settings/account", "Account"], // All
          ["/settings/privacy", "Privacy"], // All
          ...(isAdmin() || isPM() || isSpaceLead()
            ? [
                ["/settings/users", "Users"],
                ["/settings/skills", "Skills Library"],
              ]
            : []),
        ]),
  ];

  return (
    <Wrapper>
      <Switch>
        {[
          ["/projects/archived/:section", [...projectsSections]],
          ["/projects/:projectId/:section", [...projectDetailSections]],
          ["/projects", projectsSections],
          ["/payments", paymentSections],
          ["/settings", settingsSections],
        ].map((path, i) => {
          return (
            <Route
              key={`title-path--${i}`}
              path={path[0]}
              render={(props) => (
                <TitleBarContent {...props} sectionLinks={path[1]} {...(path[2] || {})} />
              )}
            />
          );
        })}
      </Switch>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
`;

const LinkBadge = styled.span`
  background: grey;
  border-radius: 50%;
  min-width: 25px;
  min-height: 25px;
  text-align: center;
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  color: white;
  font-size: 12px;
  padding-top: 4px;
  font-weight: 500;
`;

NavLinks.propTypes = proptypes;

export default NavLinks;
