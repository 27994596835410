/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import styled from "styled-components";
import { ProgressBar } from "react-bootstrap";
import moment from "moment";
import PropTypes from "prop-types";

import { REPORT_STATUSES } from "../../../../../configs/constants/global";
import Avatar from "../../../../../components/Avatar";
import { generateUserInitials } from "../../../../../utils/stringUtils";
import SmileysModal from "../../../../../components/SmileysModal";
import Icon from "../../../../../components/Icon";

/* -------------------------- Internel Dependencies ------------------------- */

const TeamModalContent = ({ activity }) => {
  const report = activity.progress_reports[0];

  const getStatus = () => {
    let statusValue = "";

    REPORT_STATUSES.forEach((status) => {
      if (status[0] === report.status) {
        const [, sV] = status;
        statusValue = sV;
      }
    });

    return statusValue;
  };

  return (
    <>
      <ContentHeader>
        <div>
          <p className="title">Team Member</p>
          <div className="d-flex align-items-center">
            <Avatar
              image={activity.user.avatar_url}
              initials={generateUserInitials(activity.user)}
              size="dash"
            />{" "}
            {activity.user.display_name}
          </div>
        </div>

        {report?.status && (
          <div>
            <p className="title">Task Status</p>
            <div className="status">
              <span>{getStatus()}</span>
            </div>
          </div>
        )}

        {report?.percentage && (
          <div>
            <p className="title">% Completed</p>
            <div className="progress-wrapper">
              <ProgressBar variant="success" now={report.percentage} label={null} />
              &nbsp;&nbsp;
              <span>{report.percentage}%</span>
            </div>
          </div>
        )}
      </ContentHeader>

      <ContentSection>
        <p className="title">
          What were your main accomplishments this week/since the last report?
        </p>
        <p>{report.main_accomplishments}</p>
      </ContentSection>
      <ContentSection>
        <p className="title">
          What tasks are you currently working on, and what progress have you made?
        </p>
        <p>{report.current_tasks_progress}</p>
      </ContentSection>
      <ContentSection>
        <p className="title"> How confident are you in reaching your current targets?</p>
        <SmileysModal name="rating" selected={`${report.confidence_in_targets}`} disabled={true} />
      </ContentSection>
      <ContentSection>
        <p className="title">
          Are there any aspects of your tasks that you find particularly engaging or motivating?
        </p>
        <p>{report.engaging_motivating_aspects}</p>
      </ContentSection>
      <ContentSection>
        <p className="title">
          Are there any aspects of your tasks presenting challenges or causing delays in your task
          delivery?
        </p>
        <p>{report.challenges_delays}</p>
      </ContentSection>

      {report?.last_deadline_met && activity.type === "cm" && (
        <ContentSection>
          <p className="title">Was the last deadline met?</p>
          <p>{report.last_deadline_met ? "Yes" : "No"}</p>
        </ContentSection>
      )}

      {report?.deadline_report && activity.type === "cm" && !report.last_deadline_met && (
        <>
          <ContentSection>
            <p className="title">Why wasn&#39;t the last deadline met?</p>
            <p>{report.deadline_report}</p>
          </ContentSection>
          <ContentSection>
            <p className="title">
              Did you inform the client promptly about not making the deadline?
            </p>
            <p>{report.deadline_miss_communicated ? "Yes" : "No"}</p>
          </ContentSection>
        </>
      )}

      {report?.accomplished && (
        <ContentSection>
          <p className="title">What has been accomplished since the last update?</p>
          <p>{report.accomplished}</p>
        </ContentSection>
      )}

      {report?.attachments?.length > 0 && (
        <ContentSection>
          <p className="title">File</p>
          {report.attachments &&
            report.attachments.map((attachment) => {
              return (
                <p key={attachment.id}>
                  <Icon name="paperclip" /> {attachment.name}
                  <a target="_blank" href={attachment.url} rel="noreferrer">
                    View
                  </a>
                </p>
              );
            })}
        </ContentSection>
      )}

      {report?.rate_deliverables && activity.type === "developer" && (
        <ContentSection>
          <p className="title">How do you rate your latest deliverable?</p>
          <SmileysModal name="rating" selected={`${report.rate_deliverables}`} disabled={true} />
        </ContentSection>
      )}

      {report?.todo && (
        <ContentSection>
          <p className="title">
            {activity.type === "cm"
              ? "What are the next steps?"
              : "What do you intend to accomplish today?"}
          </p>
          <p>{report.todo}</p>
        </ContentSection>
      )}

      {report?.next_deadline && (
        <ContentSection>
          <p className="title">When is the next deadline?</p>
          <p>{moment(report.next_deadline).format("dddd, DD MMM YYYY")}</p>
        </ContentSection>
      )}

      {report?.next_deadline_meet && activity.type === "developer" && (
        <>
          <ContentSection>
            <p className="title">Do you anticipate to meet this deadline?</p>
            <p>{report.next_deadline_meet ? "Yes" : "No"}</p>
          </ContentSection>

          {!report.next_deadline_meet && (
            <>
              <ContentSection data-testid="miss_deadline_reason">
                <p className="title">What is preventing you from meeting this deadline?</p>
                <p>{report.next_deadline_fail_reason}</p>
              </ContentSection>

              <ContentSection data-testid="miss_deadline_solution">
                <p className="title">What can be done to make sure the next deadline is met?</p>
                <p>{report.remarks}</p>
              </ContentSection>
            </>
          )}
        </>
      )}

      {activity?.developer_ratings?.length > 0 && activity.type === "cm" && (
        <ContentSection>
          <p className="title">
            Are you satisfied with the performance of the developers on this project, please give
            details?
          </p>
          {activity.developer_ratings.map((dev, index) => (
            <div key={index} style={{ marginBottom: "16px" }}>
              How do you rate {dev.user.display_name}
              <SmileysModal
                index={index}
                name={`rating-${dev.user.id}`}
                selected={`${dev.rating}`}
              />
            </div>
          ))}
        </ContentSection>
      )}

      <ContentSection>
        <p className="title">Submitted on</p>
        <p>{moment(report.created_at).format("DD MMM YYYY; hh:mm A")}</p>
      </ContentSection>
    </>
  );
};

const ContentSection = styled.div`
  font-size: 16px;
  line-height: 21px;
  color: #3e4857;
  padding: 24px 0;
  border-bottom: 1px solid #edf1f7;

  &:last-of-type {
    border-bottom: none;
  }

  .title {
    font-weight: 500;
    color: #151a30;
    margin-bottom: 8px;
  }

  p {
    i {
      color: #8f9bb3;
    }
    a {
      float: right;
      font-weight: 500;
      color: #062e64;
      text-decoration: none;
    }
  }
`;

const ContentHeader = styled(ContentSection)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 0px;

  .avatar.avatar-dash {
    width: 35px;
    height: 35px;
  }
  .avatar.avatar-initials {
    font-size: 12px;
  }
  .status {
    height: 35px;
    display: flex;
    span {
      margin: auto;
    }
  }
  .progress-wrapper {
    display: flex;
    flex-direction: row;
    height: 35px;

    .progress {
      width: 150px;
      height: 4px;
      margin: auto;
    }

    > span {
      margin: auto;
    }
  }
`;

TeamModalContent.propTypes = {
  activity: PropTypes.shape({
    due_at: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    progress_reports: PropTypes.arrayOf(
      PropTypes.shape({
        created_at: PropTypes.string,
        status: PropTypes.string,
        deadline_report: PropTypes.string,
        accomplished: PropTypes.string,
        remarks: PropTypes.string,
        next_deadline_fail_reason: PropTypes.string,
        main_accomplishments: PropTypes.string,
        current_tasks_progress: PropTypes.string,
        engaging_motivating_aspects: PropTypes.string,
        challenges_delays: PropTypes.string,
        confidence_in_targets: PropTypes.number,
        next_deadline: PropTypes.string,
        todo: PropTypes.string,
        percentage: PropTypes.number,
        rate_deliverables: PropTypes.number,
        last_deadline_met: PropTypes.bool,
        next_deadline_meet: PropTypes.bool,
        deadline_miss_communicated: PropTypes.bool,
        attachments: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string,
            id: PropTypes.number,
          }),
        ),
      }),
    ),
    user: PropTypes.shape({
      avatar_url: PropTypes.string,
      display_name: PropTypes.string,
    }),
    developer_ratings: PropTypes.arrayOf(
      PropTypes.shape({
        user: PropTypes.shape({
          id: PropTypes.number,
          display_name: PropTypes.string,
        }),
        rating: PropTypes.number,
      }),
    ),
  }),
};
export default TeamModalContent;
