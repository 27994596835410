import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import IbanForm from "../components/Payment/IbanForm";
import { addIban, fetchIban, fetchPaymentProviders } from "../redux/actions/PaymentActions";
import { openAlertModal, createModal } from "../utils/modals";
import { trackEvent } from "../analytics/segment";
import { CATEGORIES, EVENTS } from "../analytics/events";
import { PAYMENT_PROVIDER_IBAN } from "../configs/constants/invoiceConstants";

const useConnectIban = () => {
  const [triggerDefaultPrompt, setShouldTriggerPrompt] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const {
    ibanProvider,
    iban: ibanDataArr,
    errors,
    paymentProvider: { default_account_provider },
  } = useSelector(({ Payments }) => Payments);
  const { user } = useSelector(({ Auth }) => Auth);

  const dispatch = useDispatch();

  const ibanData = ibanDataArr?.[0];

  const connectIban = () => {
    setSubmitError(null);
    const modal = createModal({
      body: <IbanForm id="iban-connect-form" payload={ibanData} submitError={submitError} />,
      header: null,
      canClose: true,
      options: {
        title: "Connect Euro Account",
        className: "modal-payments",
        ok: "Connect",
        hideActions: false,
        form: {
          type: "submit",
          form: "iban-connect-form",
        },
        style: {
          paddingBottom: 0,
        },
      },
      proceed: async (data) => {
        modal.setIsLoading(true);
        const { name, iban } = data;

        const trackPayment = () => {
          trackEvent(EVENTS.add_payment_provider, {
            user_id: user.id,
            username: user.display_name,
            event_category: CATEGORIES.settings,
            psp: "Iban",
          });
        };

        const feedbackCb = {
          successCb: () => {
            modal.close();
            trackPayment();
          },
          failureCb: () => modal.setIsLoading(true),
        };

        const ibanPayload = {
          account_holder: name,
          iban_number: iban,
          ...(ibanData?.id ? { id: ibanData?.id } : {}),
        };
        await dispatch(addIban(ibanPayload, feedbackCb));
      },
    });
    modal.open();
  };

  useEffect(() => {
    if (ibanProvider?.success) {
      openAlertModal("Successfully connected IBAN");
      dispatch(fetchIban({ silent: true }));
      dispatch(fetchPaymentProviders());
      if (
        !(
          default_account_provider?.is_default &&
          default_account_provider?.payment_provider === PAYMENT_PROVIDER_IBAN
        )
      )
        setShouldTriggerPrompt(true);
    }
  }, [ibanProvider?.success]);

  useEffect(() => {
    if (submitError) {
      connectIban();
    }
  }, [submitError]);

  // Handle error
  useEffect(() => {
    if (errors.addIban) {
      setSubmitError(errors.addIban);
    }
  }, [errors.addIban]);

  return { connectIban, triggerDefaultPrompt, setShouldTriggerPrompt };
};

export default useConnectIban;
