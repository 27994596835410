/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";

/* -------------------------------------------------------------------------- */
/*                            Internal Dependencies                           */
/* -------------------------------------------------------------------------- */
import ProjectWizard from "./ProjectWizard";
import CreateLayout from "../../../../layouts/CreateLayout";

const ProjectContainer = () => {
  return (
    <CreateLayout>
      <ProjectWizard />
    </CreateLayout>
  );
};

export default ProjectContainer;
