import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";
import { useFormik } from "formik";
import { object, array, string } from "yup";

import SmileysModal from "../../../../../../components/SmileysModal";
import Progress from "../../../../../../components/Progress";
import {
  submitDeveloperRating,
  updateProgressEventQuestions,
} from "../../../../../../redux/actions/ProjectActions";
import { getSurveyQuestions, renderSurveyQuestions } from "../../../../../../utils/forms";
import { pxToRem } from "../../../../../../assets/theme/functions";
import FieldError from "../../../../../../components/FieldError";

const ClientSurvey = () => {
  const { params } = useRouteMatch();
  const dispatch = useDispatch();

  const [isReady, setIsReady] = useState(false);

  const { progress_event, project, isMakingRequest } = useSelector(({ Projects }) => Projects);

  const { initialValues, validationSchemaObj } = getSurveyQuestions(progress_event.questions);

  const onSave = (formData) => {
    if (project.category === "project") {
      const payload = {
        event: {
          id: params.report,
        },
      };

      const ratingId =
        progress_event.progress_reports.length > 0 ? progress_event.progress_reports[0].id : null;

      payload.rate_communication = formData.projectRating.rating;

      dispatch(
        submitDeveloperRating({
          ...payload,
          id: ratingId,
        }),
      );
    } else {
      formData.developers.forEach((developer) => {
        const payload = {
          event: {
            id: params.report,
          },
        };

        let ratingId = null;
        progress_event.developer_ratings.forEach((rating) => {
          if (rating.user.id === developer.userId) {
            ratingId = rating.id;
          }
        });

        payload.rating = developer.rating;
        payload.user = developer.userId;

        dispatch(
          submitDeveloperRating({
            ...payload,
            id: ratingId,
          }),
        );
      });
    }
    return true;
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      developers: [],
      projectRating: { rating: "" },
    },
    validationSchema: object().shape({
      ...validationSchemaObj,
      projectRating:
        project?.category !== "dedicated"
          ? object().shape({
              rating: string().required("Please complete rating"),
            })
          : object().notRequired(),
      developers:
        project?.category === "dedicated"
          ? array().of(
              object().shape({
                rating: string().required("Please complete rating"),
              }),
            )
          : array().notRequired(),
    }),
    onSubmit: (formData) => {
      const answeredQuestions = Object.entries(formData).map(([id, answer]) => {
        if (id === "rating" || id === "developers" || id === "projectRating") return {};

        return {
          "id": id,
          "answer": answer,
        };
      });

      const payload = {
        questions: answeredQuestions.filter((obj) => Object.keys(obj).length),
      };

      onSave(formData);

      dispatch(updateProgressEventQuestions(progress_event.id, payload));
    },
  });

  const onChange = (value, idx) => {
    if (project.category === "project") {
      form.setFieldValue("projectRating", {
        ...form.values.projectRating,
        rating: value,
      });
    } else {
      const devs = form.values.developers;
      devs[idx] = { ...devs[idx], rating: value };

      form.setFieldValue("developers", [...devs]);
    }
  };

  useEffect(() => {
    if (project !== null && progress_event.project && Object.keys(project || {}).length !== 0) {
      if (project.category === "project") {
        if (progress_event.progress_reports.length > 0) {
          form.setFieldValue("projectRating", {
            id: progress_event.progress_reports[0].id,
            rating: progress_event.progress_reports[0].rate_communication,
          });
        } else {
          form.setFieldValue("projectRating", "");
        }
      } else {
        const devs = project.participation.map((rating) => {
          return {
            id: rating.id,
            name: rating.user.display_name,
            rating: "",
            userId: rating.user.id,
          };
        });

        const developerRatings = devs;
        devs.forEach((rating, i) => {
          let ratingValue = "";
          if (progress_event.developer_ratings) {
            progress_event.developer_ratings.forEach((developer_rating) => {
              if (rating.userId === developer_rating.user.id) {
                ratingValue = `${developer_rating.rating}`;
              }
            });
          }
          developerRatings[i].rating = ratingValue;
        });

        form.setFieldValue("developers", [...developerRatings]);
      }
      setIsReady(true);
    }
  }, [project?.id]);

  return (
    <Wrapper data-testid="clientSurvey-form" onSubmit={form.handleSubmit} className="container">
      <div className="content">
        {!isReady || project === null ? (
          <Progress />
        ) : form.values.developers.length > 0 || project?.category === "project" ? (
          <div className="card">
            <div className="card-body">
              {renderSurveyQuestions(progress_event.questions, form)}

              {project?.category === "dedicated" ? (
                <>
                  {form.values.developers.map((dev, idx) => (
                    <div className="card__section" key={`dev-${idx}`}>
                      <h4>
                        How satisfied are you with the work provided by <b>{dev.name}</b>?
                        <span className="label-style">*</span>
                      </h4>
                      <SmileysModal
                        index={idx}
                        name={`rating-${dev.id}`}
                        selected={dev.rating}
                        onChange={onChange}
                      />
                      {form.errors?.developers &&
                        form.touched?.developers &&
                        form.errors.developers[idx] &&
                        form.touched.developers[idx] &&
                        form.errors.developers[idx].rating &&
                        form.touched.developers[idx].rating && (
                          <FieldError message={form.errors.developers[idx].rating} />
                        )}
                    </div>
                  ))}
                </>
              ) : (
                <div className="card__section">
                  <h4>
                    How would you rate your collaboration with Tunga on this project?{" "}
                    <span className="label-style">*</span>
                  </h4>
                  <SmileysModal
                    index={1}
                    name="rating-10"
                    selected={`${form.values.projectRating.rating}`}
                    onChange={onChange}
                    onBlur={() => form.setFieldTouched("projectRating", true)}
                  />
                  {form.errors?.projectRating && form.touched?.projectRating && (
                    <FieldError message={form.errors.projectRating?.rating} />
                  )}
                </div>
              )}
            </div>

            <div className="card-bottom">
              <div className="card-body">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isMakingRequest?.developerRating || form.isSubmitting || !form.isValid}
                >
                  Submit Report
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="card">
            <div className="card-body">
              The project currently has no developers attached to it for you to rate. Please ask a
              CM to enable this.
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.form`
  width: 800px;
  background: #ffffff;
  border: 1px solid #edf1f7;
  border-radius: 6px;
  box-shadow: 0 1px 1px 0 hsla(0, 0%, 80%, 0.5);
  padding: 0;
  margin-top: 40px;
  margin-bottom: 113px;

  .content {
    padding: 0px;
  }

  .card {
    background: #ffffff;
    border: none;
    box-sizing: border-box;
    border-radius: 6px;
  }
  .card-bottom {
    background: rgba(237, 241, 247, 0.25);
    border-radius: 0px 0px 6px 6px;
    button {
      background: #da3451;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: ${pxToRem(15)};
      line-height: ${pxToRem(19)};
      text-align: center;
      display: block;
      margin-left: auto;
      box-shadow: none;
      color: #ffffff;
    }
  }

  .card__section {
    padding: 2.4rem 1rem;
    border-bottom: 1px solid #edf1f7;
    &:last-child {
      border: 0px solid #edf1f7;
    }
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: ${pxToRem(16)};
      line-height: 150%;
      margin-bottom: 0.9rem;
      color: #151a30;
    }
    textarea {
      font-size: 16px;
      color: #8f9bb3;
      background: #ffffff;
      border: 1px solid rgba(194, 204, 217, 0.5);
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: none;

      &::placeholder {
        color: #8f9bb3;
        font-weight: normal;
      }

      &.rw-input {
        padding-left: 1.25rem;
      }
    }
  }
  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
`;

export default ClientSurvey;
