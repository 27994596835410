/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "../../../../components/ReactTable";
import InviteContainer from "./InviteContainer";
import { generateUserInitials } from "../../../../utils/stringUtils";
import Avatar from "../../../../components/Avatar";
import randomstring from "../../../../utils/generateRandomString";
import { ContentSection } from "../../../../utils/styles";
import { listUsers, deactivate_reactivate_User } from "../../../../redux/actions/UserActions";
import useDidUpdate from "../../../../hooks/useDidUpdate";
import { cancelLastRequest } from "../../../../utils/api";

const ActiveUsers = () => {
  const [selection] = useState({ selectionKey: randomstring.generate() });
  const { isFetching, users, isSaved, allUsersData } = useSelector(({ User }) => User);
  const userData = Object.values(users);
  const count = allUsersData?.count;
  const [filter] = useState("True");
  const [page_size, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [acct_type, setAcctType] = useState({ value: "all", label: "Show: all" });

  const dispatch = useDispatch();

  const onLoadMore = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(+pageSize);
  };

  const fetchUsers = (page, search = "") => {
    const updatedFilters = {
      page,
      search,
      page_size,
      is_active: filter,
      account_type: acct_type.value,
    };
    cancelLastRequest("listUsers");
    listUsers({ ...(updatedFilters || {}) }, selection.selectionKey)(dispatch);
  };

  useEffect(() => {
    fetchUsers(1);
    setCurrentPage(0);
  }, [acct_type]);

  useDidUpdate(() => {
    fetchUsers(currentPage + 1);
  }, [isSaved, page_size, currentPage]);

  const data = useMemo(
    () => [
      ...userData.map((user) => {
        const row = {
          username: {
            display_name: user.display_name,
            email: user.email,
            avatar_url: user.avatar_url,
          },
          type: user.display_type,
          status: "Active",
          joined: user.date_joined,
          actions: { id: user.id, display_name: user.display_name },
        };
        return row;
      }),
    ],
    [userData],
  );

  const Deactivate_Reactivate = (user, action) => {
    const item = {
      action,
    };
    deactivate_reactivate_User(user, item)(dispatch);
  };

  const columns = useMemo(
    () => [
      {
        Header: "User",
        accessor: "username", // accessor is the "key" in the data
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Joined",
        accessor: "joined",
      },
      {
        Header: "",
        accessor: "actions",
      },
    ],
    [],
  );

  const getTableDisplayValue = (cell) => {
    const key = `${cell.column.id}-${cell.row.id}`;
    switch (cell.column.id) {
      case "username": {
        const user = cell.value;
        return (
          <td key={`username${key}`}>
            <div className="d-flex align-items-center">
              <Avatar image={user.avatar_url} initials={generateUserInitials(user)} size="dash" />

              <p style={{ marginBottom: "0px" }} className="small-text">
                <span style={{ marginLeft: "-24px" }}>{user.display_name}</span>
                <br />
                <span
                  style={{
                    textTransform: "lowercase",
                    fontSize: "small",
                    marginLeft: "-24px",
                  }}
                >
                  {user.email}
                </span>
              </p>
            </div>
          </td>
        );
      }
      case "type": {
        return (
          <td key={`type${key}`} className="nowrap">
            {cell.value}
          </td>
        );
      }
      case "status":
        return (
          <td key={`status${key}`}>
            <span className="active">{cell.value}</span>
          </td>
        );
      case "joined":
        return (
          <td className="nowrap" key={`joined${key}`}>
            {moment.utc(cell.value).format("DD MMM YYYY")}
          </td>
        );
      case "actions":
        return (
          <td key={`actions${key}`} className="d-table-cell">
            <div className="dropdown">
              <button
                style={{ boxShadow: "none" }}
                className="btn"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                type="button"
              >
                <span style={{ fontSize: "1.5rem" }} data-testid={`dot-${cell.value.id}`}>
                  ...
                </span>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button
                  type="button"
                  data-testid={`deactivate-${cell.value.id}`}
                  className="dropdown-item"
                  style={{ background: "#fff", color: "#3e4857", cursor: "pointer" }}
                  onClick={() => Deactivate_Reactivate(cell.value, "deactivate")}
                >
                  Deactivate user
                </button>
              </div>
            </div>
          </td>
        );

      default:
        return null;
    }
  };

  return (
    <InviteContainer acct_type={acct_type} setAcctType={setAcctType} fetchUsers={fetchUsers}>
      <ContentSection style={{ paddingTop: "0" }}>
        <div className="section">
          <ReactTable
            scope="active_users"
            tableData={data}
            tableColumns={columns}
            currentPage={currentPage}
            count={count}
            getTableDisplayValue={getTableDisplayValue}
            loadPage={onLoadMore}
            pageSize={page_size}
            isLoading={isFetching.loading}
            noDataMessage="No active users"
          />
        </div>
      </ContentSection>
    </InviteContainer>
  );
};

export default ActiveUsers;
