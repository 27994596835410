/* eslint-disable no-param-reassign */
import { useContext } from "react";
import { TempProjectContext } from "./TempProjectContext";

const UseTempProject = () => {
  const [project, setProject] = useContext(TempProjectContext);
  function createDocument(doc) {
    const documents = project.documents || [];
    documents.push({ id: documents.length + 1, ...doc });
    setProject({ ...project, documents });
  }

  function deleteDocument(id) {
    const documents = project.documents.filter((doc) => doc.id !== id);
    setProject({ ...project, documents });
  }

  function createMileStone(milestone) {
    const milestones = project.progress_events || [];
    milestones.push({ id: milestones.length + 1, ...milestone });
    setProject({ ...project, progress_events: milestones });
  }

  function deleteMileStone(id) {
    const milestones = project.progress_events.filter((milestone) => milestone.id !== id);
    setProject({ ...project, progress_events: milestones });
  }

  function deleteTeamMember(id) {
    const participation = project.participation.filter((user) => user.id !== id);
    setProject({ ...project, participation });
  }

  function addPaymentStructure(id, key, value) {
    const participation = project.participation.map((user) => {
      if (user.id === id) {
        user[key] = value;
        return user;
      }
      return user;
    });
    setProject({ ...project, participation });
  }

  return {
    project,
    setProject,
    createDocument,
    deleteDocument,
    createMileStone,
    deleteMileStone,
    deleteTeamMember,
    addPaymentStructure,
  };
};

export default UseTempProject;
