import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { ModalHeader } from "../components/ModalHeader";
import TermsNdConditions from "../components/TermsNdConditions";
import UserAgreementPrompt from "../components/UserAgreementPrompt";
import { createModal, openConfirm } from "../utils/modals";
import { updateAuthUser } from "../redux/actions/ProfileActions";
import { isClient, isDev } from "../utils/auth";

window.isAgreementOpen = false;

const useAgreement = () => {
  const agreementDismissRef = useRef();
  const openAgreementPromptRef = useRef();

  const dispatch = useDispatch();

  const { user } = useSelector(({ Auth }) => Auth);

  const dismissCallback = () => openAgreementPromptRef.current(); // onClose/dismiss open the agreement prompt again

  const openTCs = (e) => {
    if (isClient()) return;

    e.preventDefault();
    agreementDismissRef.current();

    const modal = createModal({
      body: <TermsNdConditions />,
      header: (
        <ModalHeader
          style={{ paddingBottom: "8px" }}
          options={{
            title: "Terms & Conditions",
          }}
        />
      ),
      canClose: true,
      options: {
        className: "modal-tcs",
        hideActions: true,
        dismissAsCancel: true,
      },
      beforeClose: () => dismissCallback(),
    });

    modal.open();
  };

  const openAgreementPrompt = () => {
    openConfirm({
      message: (
        <UserAgreementPrompt user={user} dismissRef={agreementDismissRef} openTCs={openTCs} />
      ),
      title: "",
      canClose: false,
      options: {
        ok: "I agree",
        cancel: "I don't agree",
        mustRespond: true,
        dismissAsCancel: true,
      },
    })
      .then(
        () => {
          dispatch(
            updateAuthUser({
              agreed_at: moment.utc().format(),
            }),
          );
        },
        () => {
          window.isAgreementOpen = false;
          dispatch(
            updateAuthUser({
              disagreed_at: moment.utc().format(),
            }),
          );
        },
      )
      .catch(() => {});
  };

  openAgreementPromptRef.current = openAgreementPrompt;

  useEffect(() => {
    const agreeableUsers = isDev() || isClient();

    if (
      user &&
      user.id &&
      agreeableUsers &&
      !user.has_agreed_latest_terms &&
      !window.isAgreementOpen
    ) {
      openAgreementPrompt();
      window.isAgreementOpen = true;
    }
  }, []);

  return null;
};

export default useAgreement;
