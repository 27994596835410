/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";

import DashboardLayout from "../../../layouts/DashboardLayout";
import ReportCreateLayout from "../../../layouts/ReportCreateLayout";
import CreateProject from "./createProjects";
import ProjectDetail from "./projectDetail";
import ProjectInvite from "./ProjectInvite";
import ProjectList from "./ProjectList";
import ProjectListContainer from "./ProjectListContainer";
import { useTrackPage } from "../../../analytics/segment";
import { pages } from "../../../analytics/segment/tags";

const Projects = ({ match }) => {
  useTrackPage(pages.PROJECTS);

  return (
    <div>
      <Switch>
        <Route path={`${match?.path}/new`} render={(props) => <CreateProject {...props} />} />
        <Route
          path={`${match?.path}/:id/accept_invite`}
          render={(props) => <ProjectInvite {...props} />}
        />
        <Route
          path={`${match?.path}/:id/reports/yours/:report`}
          render={(props) => <ReportCreateLayout {...props} />}
        />
        <Route
          path="/"
          render={() => (
            <DashboardLayout>
              <Switch>
                {["dedicated", "managed", "opportunity", "archived/:type"].map((path) => {
                  return (
                    <Route
                      key={`projects-${path}`}
                      path={`${match?.path}/${path}`}
                      render={(props) => (
                        <ProjectListContainer {...props} filter={path}>
                          <ProjectList />
                        </ProjectListContainer>
                      )}
                    />
                  );
                })}
                <Redirect from="/projects/archived" to="/projects/archived/dedicated" />
                <Route
                  path={`${match?.path}/:id`}
                  render={(props) => <ProjectDetail {...props} />}
                />
                <Redirect from="/projects" to="/projects/dedicated" />
              </Switch>
            </DashboardLayout>
          )}
        />
      </Switch>
    </div>
  );
};

Projects.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

export default Projects;
