/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Switch, Route, useHistory } from "react-router-dom";
import DashboardLayout from "../../../layouts/DashboardLayout";
import OpportunitiesList from "./OpportunitiesList";
import OpportunityDetails from "./OpportunityDetails";
import { useTrackPage } from "../../../analytics/segment";
import { pages } from "../../../analytics/segment/tags";
import MetaTags from "../../../components/MetaTags";
import { isDev, isInternalUser } from "../../../utils/auth";

const Opportunities = ({ match }) => {
  useTrackPage(pages.OPPORTUNITIES);
  const history = useHistory();

  useEffect(() => {
    if (!(isDev() || isInternalUser())) {
      history.push("/dashboard");
    }
  }, []);

  return (
    <div>
      <MetaTags
        title="Recruitee Opportunities"
        description="Recruitee Opportunities"
        ogTitle="Recruitee Opportunities"
        ogDescription="Recruitee Opportunities"
      />
      <DashboardLayout>
        <Route
          path="/"
          render={() => (
            <Switch>
              <Route exact path={`${match?.path}`} render={() => <OpportunitiesList />} />
              <Route
                exact
                path={`${match?.path}/:id`}
                render={(props) => <OpportunityDetails {...props} />}
              />
            </Switch>
          )}
        />
      </DashboardLayout>
    </div>
  );
};

Opportunities.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

export default Opportunities;
