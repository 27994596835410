/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */
import React, { useMemo, useCallback, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

/* -------------------------------------------------------------------------- */
/*                             Internal Dependency                            */
/* -------------------------------------------------------------------------- */
import ContactInfo from "../pages/Dashboard/talent-pool/ContactInfo";
import usePrevious from "../hooks/usePrevious";
import { devProfile } from "../redux/actions/ProjectActions";
import { createModal, openAlertModal } from "../utils/modals";
import { trackEvent } from "../analytics/segment";
import { CATEGORIES, EVENTS } from "../analytics/events";

const TalentProfileContext = React.createContext({
  isReSyncing: false,
  enableEditAction: false,
  setEnableEditAction: () => null,
  handleShowUserContactInfo: () => null,
  handleSyncWithRecruitee: () => null,
});

const TalentProfileProvider = ({ children }) => {
  const dispatch = useDispatch();

  const { devProfiles, errors, isSaved } = useSelector(({ Projects }) => Projects);

  const [isReSyncing, setIsReSyncing] = useState(false);
  const [enableEditAction, setEnableEditAction] = useState(false);

  const prevIsReSyncing = usePrevious(devProfiles?.loading || false);

  const handleShowUserContactInfo = useCallback((userDetails) => {
    const modal = createModal({
      body: <ContactInfo userDetails={userDetails} />,
      options: {
        title: `${userDetails?.first_name || userDetails?.display_name}’s contact info`,
        hideActions: true,
      },
    });
    modal.open();
  }, []);

  const handleSyncWithRecruitee = useCallback((id, display_name) => {
    trackEvent(EVENTS.resync_profile, {
      event_category: CATEGORIES.talentPool,
      active_tab: "talent pool",
      dev_name: display_name,
      dev_id: id,
    });
    setIsReSyncing(true);
    dispatch(devProfile(id, true));
  }, []);

  const value = useMemo(() => {
    return {
      isReSyncing,
      enableEditAction,
      setEnableEditAction,
      handleShowUserContactInfo,
      handleSyncWithRecruitee,
    };
  }, [isReSyncing, enableEditAction]);

  useEffect(() => {
    const isReSyncingCompleted = prevIsReSyncing && !devProfiles?.loading;

    if (isReSyncingCompleted) {
      setIsReSyncing(false);

      const hasResyncError = errors?.devProfile?.resync && !isSaved?.devProfile;
      const isSuccessfulSync = isSaved?.devProfile && isSaved.resync;

      if (hasResyncError) {
        openAlertModal(errors.devProfile.message, true);
      } else if (isSuccessfulSync) {
        openAlertModal("Profile synced successfully");
      }
    }
  }, [devProfiles?.loading, isSaved?.devProfile, isSaved?.resync, errors?.devProfile]);

  return <TalentProfileContext.Provider value={value}>{children}</TalentProfileContext.Provider>;
};

const useTalentProfile = () => {
  const context = useContext(TalentProfileContext);

  return context;
};

const proptypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

TalentProfileProvider.propTypes = proptypes;

export { TalentProfileContext, TalentProfileProvider, useTalentProfile };
